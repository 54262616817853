import React, { useState, useEffect } from "react";
import axiosInstance from "../../../../api/axios";
import ErrorHandler from "../../../../error-handler/ErrorHandler";
import { Row, Col } from "react-bootstrap";
import {  Dialog, DialogContent } from '@material-ui/core';
import { useSelector } from "react-redux";
import useLanguage from "../../../../hooks/useLanguage";
import { useTranslation } from "react-i18next";
import { useSettleRequestStatusColumns } from "../../../layout/tableColumns";
import TablePro from "../../../tables/TablePro";

function SettleRequestStatus(props) {
  const { t } = useTranslation();
  const marketerID = useSelector((state) => state.auth.user.user.id);
  const user_role = useSelector((state) => state.auth.user.role);
  const branch = useSelector((state) => state.branch.branch);
  const lang = useLanguage();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [adminNotes, setAdminNote] = useState([]);
  const [marketerNotes, setMarketerNote] = useState([]);
  const [rid, setRid] = useState(null)
  const [loading, setLoading] = useState(false);
  const [openn, setOpenn] = useState(false)
  
// Function to handle opening the image preview dialog
const handleImagePreview = (imageURL) => {
    setSelectedImage(imageURL);
    setOpen(true);
  };
 
  const getSettleRequests = async () => {
    setLoading(true);
    let result = await axiosInstance
      .get(`/settle-requests/${marketerID}/`, {
        params: {
          page_size: 10,
          page: page,
        },
      })
      .catch((err) => console.log(err));

    if (result) {
      setData(result.data.data);
      setCount(result.data.count);
    }
    setLoading(false);
  };


  
  
  useEffect(() => {
    getSettleRequests();
  }, [page]);

  useEffect(() => {
    getSettleRequests();
    
  }, []);

  const tableColumns = useSettleRequestStatusColumns( handleImagePreview);
  return (
    <>
      
      <Row className="mb-4">
        <Col sm="12">
          <span className="title1">{t("sideMenu.wallet")}</span>
          <i class="fas fa-chevron-right px-3 title2"></i>
      
          <span className="title2">{t("settleRequest")}</span>
        </Col>
        <Col sm="12">
          <h2 className="pageTitle">{t("settleRequest")}</h2>
        </Col>
        
      </Row>
    
      <div className="reqTableContainer">
        <div className="tableHeader">
          <h6>{branch} {t("settleRequest")}</h6>
          
        </div>
        <TablePro
            loading={loading}
            selection={"radio"}
            columns={tableColumns}
            dataSource={data}
            pagination={{
                current: page,
                total: count,
                onChange: (page) => setPage(page),
            }}
        />
         
      </div>
        <Dialog open={open} onClose={() => setOpen(false)}>
              <DialogContent>
                <img src={selectedImage} alt="Preview" style={{ maxWidth: '100%' }} />
              </DialogContent>
            </Dialog>
    </>
  );
}
export default ErrorHandler(SettleRequestStatus, axiosInstance);
