import React, { useMemo, useState } from "react";
import styles from "./SectionedDatePicker.module.css";
import { DatePicker } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";

export default function SectionedDatePicker(props) {
    const dateFormat = "YYYY/MM/DD";
    const { t } = useTranslation();
    const ranges = {
        [t("date_picker.today")]: [moment(), moment()],
        [t("date_picker.week")]: getRange("week"),
        [t("date_picker.month")]: getRange("month"),
        [t("date_picker.year")]: getRange("year"),
    };

    function getRange(type) {
        return [moment().startOf(type), moment().endOf(type)];
    }

    return (
        <DatePicker.RangePicker
            format={dateFormat}
            ranges={ranges}
            className={styles.container}
            placeholder={[
                t("date_picker.start_date"),
                t("date_picker.end_date"),
            ]}
            {...props}
        ></DatePicker.RangePicker>
    );
}
