import React from "react";
import styles from "./WMButton.module.css";
import { Button as AntButton } from "antd";

export default function SecondaryWMButton({
                                            className,
                                            type,
                                            icon,
                                            iconAlt,
                                            iconPosition,
                                            ...rest
                                        }) {
    return (
        <AntButton
            className={[
                styles.secondary,
                "row-centered",
                iconPosition === "end" ? styles.iconEnd : "",
                type ? `button-${type}` : "button-default",
                className,
            ].join(" ")}
            icon={icon}
            {...rest}
        />
    );
}