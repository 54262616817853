import { mdiDotsHorizontal } from "@mdi/js";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@mdi/react";
import ErrorHandler from "../../error-handler/ErrorHandler";
import React, { useState, useEffect } from "react";
import axiosInstance from "../../api/axios";
import {
    CreationConfirmation,
    UnSuccessfulRequest,
} from "../modals/confirmationModal";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import isReAssigned, {
    getReassignedSheet,
} from "../../global-state/actions/reassignedRsAction";
import { useDispatch } from "react-redux";
import useLanguage from "../../hooks/useLanguage";

function ThreeDotsMenuRS({
    data,
    unAssigned,
    setUnassigned,
    link,
    setArchived,
    isArchived,
}) {
    const histoy = useHistory();
    const { t } = useTranslation();

    console.log({ data });
    const lang = useLanguage();

    const [anchorEl, setAnchorEl] = useState(null);
    const [showWarning, setShowWarning] = useState(false);
    const [showArchiveModel, setShowArchivedModel] = useState(false);
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);

    function handleMenuClick(Val1) {
        console.log(Val1);
    }

    const unAssignedItem = async () => {
        let result = await axiosInstance
            .post(`/${link}/${data.id}`)
            .catch((err) => {
                if (err.response.status === 500) {
                    console.log("here");
                    setShowWarning(false);
                    setError(
                        lang === "en"
                            ? "User has no wallet"
                            : "هذا المستخدم ليس لديه محفظة"
                    );
                } else {
                    setError(err.response.data);
                }
                setShowError(true);
            });
        if (result) {
            console.log(result);
            setUnassigned(!unAssigned);
            setShowWarning(false);
            // getBranches()
        }
    };

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const hide = () => {
        setShowWarning(false);
        setShowArchivedModel(false);
    };

    const ArchiveReq = async () => {
        let result = await axiosInstance
            .delete(`/Runsheet/${data.id}`)
            .catch((err) => {
                setShowArchivedModel(false);
                console.log(err.response.data);
                setError(err.response.data);
                setShowError(true);
            });
        if (result) {
            console.log(result);
            setArchived(!isArchived);
            setShowArchivedModel(false);
        }
    };
    const isReassignedDispatch = useDispatch();
    const selectedRsDispatch = useDispatch();

    return (
        <div className="actionMenu">
            <Button onClick={handleClick}>
                <Icon path={mdiDotsHorizontal} size={1.2} />
            </Button>
            <Menu
                id="card-actions-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        handleClose();
                        histoy.push(`/add_to_runsheet/${data.id}`);
                        console.log(data);
                    }}
                >
                    <i class="fas fa-plus mx-2 my-3"></i>
                    {t("addTicketsToRunsheet")}
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        handleClose();
                        selectedRsDispatch(getReassignedSheet(data));
                        isReassignedDispatch(isReAssigned(true));
                        histoy.push(`/edit_runsheet_priorities/${data.id}`);
                    }}
                >
                    <i class="fas fa-edit mx-2 my-3"></i>
                    {t("edit_priorities")}
                </MenuItem>
                {data.state != 1 && (
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            selectedRsDispatch(getReassignedSheet(data));
                            isReassignedDispatch(isReAssigned(true));
                            histoy.push(`/reassign_runsheet/${data.id}`);
                        }}
                    >
                        <i class="fas fa-user-edit mx-2 my-3"></i>
                        {t("reAssign")}
                    </MenuItem>
                )}
                {data.state == 1 && (
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            histoy.push(`/closeRunsheet/${data.id}`);
                            console.log(data);
                        }}
                    >
                        <i class="fas fa-user-edit mx-2 my-3"></i>
                        {t("settle")}
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setShowWarning(true);
                    }}
                >
                    <i class="fas fa-user-edit mx-2 my-3"></i>
                    {t("unassign")}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setShowArchivedModel(true);
                    }}
                >
                    <i className="fas fa-archive mx-2 my-3"></i>
                    {t("archiveRunsheet")}
                </MenuItem>
            </Menu>
            <CreationConfirmation
                hide={hide}
                sendRequest={unAssignedItem}
                title={t("unassignRunsheet")}
                body={
                    lang === "en"
                        ? `Are you sure you want to Unassign #${data.id} from ${data.courier.user.name}?`
                        : `هل انت متأكد انك تريد إلغاء إسناد خط سير رقم ${data.id} من ${data.courier.user.name}؟`
                }
                show={showWarning}
            />
            <CreationConfirmation
                hide={hide}
                sendRequest={ArchiveReq}
                title={t("archiveRunsheet")}
                body={
                    lang === "en"
                        ? `Are you sure you want to Archive Runsheet #${data.id}?`
                        : `هل انت متأكد انك تريد إرسال خط السير رقم ${data.id} إلى الأرشيف؟`
                }
                show={showArchiveModel}
            />
            <UnSuccessfulRequest
                title={t("archiveRunsheet")}
                show={showError}
                body={error}
                hide={() => setShowError(false)}
            />
        </div>
    );
}

export default ErrorHandler(ThreeDotsMenuRS, axiosInstance);
