import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import "../../../style-sheets/sidemenu.css";
import Sticky from "react-sticky-el";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SingleRoute from "./SingleRoute";
import { useAdminRoutes, useSellerRoutes , useMarketerRoutes} from "../../../global-state/routes";
import RouteGroup from "./RouteGroup";
import useLanguage from "../../../hooks/useLanguage";

const drawerWidth = 240;

function ResponsiveDrawer(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const { t, i18n } = useTranslation();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const lang = useLanguage();
    const user = useSelector((state) => state.auth.user);

    const addOrder = () => {
        let permission = user.user.permissions?.map((permission) =>
            permission[1] === "Can add order" ? true : null
        );
        return permission.length > 0
            ? permission.filter((perm) => perm)[0]
            : false;
    };

    const changeTicket = () => {
        let permission = user.user.permissions?.map((permission) =>
            permission[1] === "Can change ticket" ? true : null
        );
        return permission.length > 0
            ? permission.filter((perm) => perm)[0]
            : false;
    };

    const deleteTicket = () => {
        let permission = user.user.permissions?.map((permission) =>
            permission[1] === "Can delete ticket" ? true : null
        );
        return permission.length > 0
            ? permission.filter((perm) => perm)[0]
            : false;
    };
    const viewWallet = () => {
        let permission = user.user.permissions?.map((permission) =>
            permission[1] === "Can view wallet" ? true : null
        );
        return permission.length > 0
            ? permission.filter((perm) => perm)[0]
            : false;
    };

    const currentLang = useLanguage();
    const adminRoutes = useAdminRoutes();
    const drawer = (
        // <Sticky>
        <div
            style={{
                backgroundColor: "#F6F6F7",
                borderRight: "1px solid rgba(0,0,0,0.12)",
                minHeight: "95vh",
                fontFamily: "Cairo",
                direction: lang === "ar" ? "rtl" : "ltr",
            }}
            className="sidemenu"
        >
            <SingleRoute
                setMobileOpen={setMobileOpen}
                path={"/dashboard"}
                details={adminRoutes.common["/dashboard"]}
            />
            <RouteGroup
                key={"Accounts"}
                meta={adminRoutes.accounts.meta}
                pathsGroup={adminRoutes.accounts}
                setMobileOpen={setMobileOpen}
                paths={[
                    "/create_account",
                    // "/create_bulk_accounts",
                    "/all_accounts",
                    "/archived_users",
                    "/NewMarketers",
                ]}
            />
            <RouteGroup
                key={"Tickets"}
                meta={adminRoutes.orders.meta}
                pathsGroup={adminRoutes.orders}
                setMobileOpen={setMobileOpen}
                paths={[
                    "/create_order",
                    // "/postponed",
                    "/return_ticket",
                    // "/replace_ticket",
                    "/archived_tickets",
                    "/archived_order"
                ]}
            />
              <RouteGroup
                    key={"extra_orders"}
                    meta={adminRoutes.extra_orders.meta ?? {}}
                    pathsGroup={adminRoutes.extra_orders}
                    setMobileOpen={setMobileOpen}
                    paths={[
                        `/create_extraorder`,
                        "/extra_orders",
                    ]}
                />
            <RouteGroup
                key={"Support Tickets"}
                meta={adminRoutes.supportTickets.meta}
                pathsGroup={adminRoutes.supportTickets}
                setMobileOpen={setMobileOpen}
                paths={[
                    "/create_support_ticket",
                    "/all_support_tickets",
                    "/archived_support_tickets",
                ]}
            />
            {/* <RouteGroup
                key={"WorkModels"}
                meta={adminRoutes.workModels.meta}
                pathsGroup={adminRoutes.workModels}
                paths={["/create_work_model", "/all_work_models"]}
            /> */}
            {/*{true ||*/}
            {/*(user.role === "Operation" && viewWallet()) ||*/}
            {/*user.role === "Organization Admin" ?}*/}
            <RouteGroup
                key={"Wallet"}
                meta={adminRoutes.wallet.meta}
                pathsGroup={adminRoutes.wallet}
                setMobileOpen={setMobileOpen}
                paths={["/sellers_wallets", "/review_refunds","/refunds_status" , "/marketer_settle", "/settle_status" ,"/payments",]}
            />
            {/*<List>*/}
            {/*    <NavLink activeClassName="activeLink" to="/payments">*/}
            {/*        <ListItem button key={"Inbox"}>*/}
            {/*            <ListItemIcon>*/}
            {/*                <Icon source={CreditCardMajor} color="base"/>*/}
            {/*            </ListItemIcon>*/}
            {/*            <ListItemText primary={t("sideMenu.ordersPackage")}/>*/}
            {/*        </ListItem>*/}
            {/*    </NavLink>*/}
            {/*</List>*/}
            {/*<SingleRoute*/}
            {/*    path="/ticket_scan"*/}
            {/*    details={adminRoutes.orders["/ticket_scan"]}*/}
            {/*    overrideIcon={*/}
            {/*        <span className="material-icons">document_scanner</span>*/}
            {/*    }*/}
            {/*/>*/}
            <p className="general">{t("sideMenu.general")}</p>
            <SingleRoute
                path="/select_reports"
                setMobileOpen={setMobileOpen}
                details={adminRoutes.reports["/select_reports"]}
            />
            <SingleRoute
                path={"/settings"}
                className="settingsLink"
                setMobileOpen={setMobileOpen}
                details={adminRoutes.settings["/settings"]}
            />

           
            <SingleRoute
                path={"/logs"}
                className="settingsLink"
                setMobileOpen={setMobileOpen}
                details={adminRoutes.unused["/logs"]}
            />
       
        </div>
        // </Sticky>
    );

    const sellerRoutes = useSellerRoutes();
    const sellerDrawer = (
        <Sticky>
            <div
                style={{
                    backgroundColor: "white",
                    borderRight: "1px solid rgba(0,0,0,0.12)",
                    minHeight: "95vh",
                }}
                className="sidemenu"
            >
                {/* <Toolbar /> */}
                {/*<List>*/}
                {/*    <NavLink activeClassName="sellerActiveLink" to="/dashboard">*/}
                {/*        <ListItem button key={"Inbox"}>*/}
                {/*            <ListItemIcon>*/}
                {/*                <Icon source={HomeMajor} color="base"/>*/}
                {/*            </ListItemIcon>*/}
                {/*            <ListItemText primary={t("sideMenu.dashboard")}/>*/}
                {/*        </ListItem>*/}
                {/*    </NavLink>*/}
                {/*</List>*/}
                <SingleRoute
                    path={"/dashboard"}
                    details={sellerRoutes.common["/dashboard"]}
                    setMobileOpen={setMobileOpen}
                    isSeller
                />
                {/*<RouteGroup*/}
                {/*    key={"Inbox"}*/}
                {/*    meta={sellerRoutes.products.meta ?? {}}*/}
                {/*    pathsGroup={sellerRoutes.products}*/}
                {/*    paths={["/add_product", "/products"]}*/}
                {/*    isSeller*/}
                {/*/>*/}
                <RouteGroup
                    key={"Orders"}
                    meta={sellerRoutes.orders.meta ?? {}}
                    pathsGroup={sellerRoutes.orders}
                    setMobileOpen={setMobileOpen}
                    paths={[
                        "/create_order",
                        // "/postponed",
                        "/return_ticket",
                        // "/replace_ticket",
                        "/archived_tickets",
                        "/archived_order",
                    ]}
                    isSeller
                />
                 <RouteGroup
                    key={"extra_orders"}
                    meta={sellerRoutes.extra_orders.meta ?? {}}
                    pathsGroup={sellerRoutes.extra_orders}
                    paths={[
                        "/extra_orders",
                        
                    ]}
                    setMobileOpen={setMobileOpen}
                    isSeller
                />
                <RouteGroup
                    key={"Support Tickets"}
                    meta={sellerRoutes.supportTickets.meta}
                    pathsGroup={sellerRoutes.supportTickets}
                    setMobileOpen={setMobileOpen}
                    paths={[
                        "/create_support_ticket",
                        "/all_support_tickets",
                        "/archived_support_tickets",
                    ]}
                    isSeller
                />
                <RouteGroup
                    key={"Wallets"}
                    meta={sellerRoutes.wallet.meta ?? {}}
                    pathsGroup={sellerRoutes.wallet}
                    paths={[
                        `/seller_wallet`,
                        "/recharge_seller_wallet",
                        "/request_refund",
                    ]}
                    setMobileOpen={setMobileOpen}
                    isSeller
                />
                 
                <SingleRoute
                    path={"/settings"}
                    className="settingsLink"
                    details={sellerRoutes.settings["/settings"]}
                    setMobileOpen={setMobileOpen}
                    isSeller
                />
                {/* <NavLink activeClassName="sellerActiveLink" to="/products">
          <ListItem button key={"Products"}>
            {" "}
            <ListItemIcon>
              {" "}
              <Icon source={ProductsMajor} color="base" />
            </ListItemIcon>
            <ListItemText primary={"Products"} />
          </ListItem>
        </NavLink>
        <NavLink activeClassName="sellerActiveLink" to="/Customers">
          <ListItem button key={"Customers"}>
            {" "}
            <ListItemIcon>
              {" "}
              <Icon source={WholesaleMajor} color="base" />
            </ListItemIcon>
            <ListItemText primary={"Customers"} />
          </ListItem>
        </NavLink> */}

                {/*<NavLink activeClassName="activeLink" to="/integration">*/}
                {/*    <ListItem button key={"Integration Apps"}>*/}
                {/*        <ListItemIcon>*/}
                {/*            <Icon source={AppsMajor} color="base"/>*/}
                {/*        </ListItemIcon>*/}
                {/*        <ListItemText primary={t("sideMenu.integrationApps")}/>*/}
                {/*    </ListItem>*/}
                {/*</NavLink>*/}
                {/*<p className="general">{t("sideMenu.salesChannels")}</p>*/}
                {/*<NavLink activeClassName="sellerActiveLink" to="/online_store">*/}
                {/*    <ListItem button key={"Online store"}>*/}
                {/*        <ListItemIcon>*/}
                {/*            <Icon source={StoreMajor} color="base"/>*/}
                {/*        </ListItemIcon>*/}
                {/*        <ListItemText primary={t("sideMenu.onlineStore")}/>*/}
                {/*    </ListItem>*/}
                {/*</NavLink>*/}
            </div>
        </Sticky>
    );

    const marketerRoutes = useMarketerRoutes();
    const marketerDrawer = (
        <Sticky>
            <div
                style={{
                    backgroundColor: "white",
                    borderRight: "1px solid rgba(0,0,0,0.12)",
                    minHeight: "95vh",
                }}
                className="sidemenu"
            >
                <SingleRoute
                    path={"/dashboard"}
                    details={marketerRoutes.common["/dashboard"]}
                    setMobileOpen={setMobileOpen}
                   
                />
               
              
                <RouteGroup
                    key={"Wallets"}
                    meta={marketerRoutes.wallet.meta ?? {}}
                    pathsGroup={marketerRoutes.wallet}
                    paths={[
                        "/marketer_wallet",
                        "/settle_status",
                        "/marketer_balance",
                        

                    ]}
                    setMobileOpen={setMobileOpen}
                    
                />
                <SingleRoute
                    path={"/settings"}
                    className="settingsLink"
                    details={marketerRoutes.settings["/settings"]}
                    setMobileOpen={setMobileOpen}
                    
                />
     
            </div>
        </Sticky>
    );
    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    {/* <Typography variant="h6" noWrap component="div">
            Responsive drawer
          </Typography> */}
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                    anchor={currentLang === "ar" ? "right" : "left"}
                >
                     {user.role === "Seller"
                        ? sellerDrawer
                        : user.role === "Marketer"
                        ? marketerDrawer
                        : drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                    open
                    anchor={currentLang === "ar" ? "right" : "left"}
                >
                    
                    {user.role === "Seller"
                        ? sellerDrawer
                        : user.role === "Marketer"
                        ? marketerDrawer
                        : drawer}
                </Drawer>
                
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                    anchor={currentLang === "ar" ? "right" : "left"}
                >
                    
                    {user.role === "Seller"
                        ? sellerDrawer
                        : user.role === "Marketer"
                        ? marketerDrawer
                        : drawer}
                    
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                    open
                    anchor={currentLang === "ar" ? "right" : "left"}
                >
                    
                    {user.role === "Seller"
                        ? sellerDrawer
                        : user.role === "Marketer"
                        ? marketerDrawer
                        : drawer}
                    
                </Drawer>
            </Box>
        </Box>
    );
}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default ResponsiveDrawer;
