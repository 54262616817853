import React, { useEffect, useState } from "react";
import TablePro from "../../../../components/tables/TablePro";
import {
    useConfirmedRequestsColumns,
    useUnConfirmedRequestsColumns,
} from "./dashboardColumns";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import OrderDetails from "../../../../components/layout/OrderDetails";
import axiosInstance from "../../../../api/axios";
import { DatePicker, Input, Select } from "antd";
import _ from "lodash";

function EmptyPlaceholder({ currentStateTitle, onRefresh }) {
    const { t } = useTranslation();
    return (
        <div className="noDataDiv">
            <h6 className="noDataHead">
                {t("refreshTitle")} {currentStateTitle}
            </h6>
            <p>{t("refreshText")}</p>
            <button className="confirm" onClick={onRefresh}>
                {t("refresh")}
            </button>
        </div>
    );
}

export default function DashboardRequestsTable({
    currentState,
    setFilters,
    currentStateTitle,
    isUnConfirmedRequest,
    data,
    onSearch,
    onRefresh,
    loading,
    selected,
    setSelected,
    pagination,
    children,
}) {
    const { t } = useTranslation();
    const user = useSelector((state) => state.auth.user);
    const unConfirmedRequestsColumns = useUnConfirmedRequestsColumns();
    const confirmedRequestsColumns = useConfirmedRequestsColumns();
    const [details, setDetails] = useState();
    const [detailsLoading, setDetailsLoading] = useState(false);
    console.log({ details });

    async function fetchDetails(id) {
        console.log("clicked");
        setDetailsLoading(true);
        let result = await axiosInstance.get(`/Order/${id}`);
        if (result) {
            setDetails(result.data);
        }
        setDetailsLoading(false);
    }

    async function onChangeTickets(page) {
        setDetailsLoading(true);
        const newDetails = { ...details };
        newDetails.tickets.page = page;

        let result = await axiosInstance.get(`/Order/${details.id}`, {
            params: {
                page_size: 4,
                page: page,
            },
        });
        if (result) {
            newDetails.tickets = result.data.tickets;
            newDetails.tickets.page = page;
            setDetails(newDetails);
        }
        setDetailsLoading(false);
    }

    const onChange = _.debounce((callback) => setFilters(callback), 500);
    const [showConfirm, setShowConfirm] = useState(false);

    const showConfirmModal = () => {
        setShowConfirm(true);
    };
    const handleRowClick = (i, row) => {
        if (!(user.role === "Seller" && row.state?.type === 9)) {
            fetchDetails(row.order?.id ?? row.id);
        }
    };
    
    return (
        <div
            style={{
                position: "relative",
            }}
        >
            <div className="grid-form">
                <div>
                    <label>
                        {isUnConfirmedRequest ? t("orderId") : t("ticketId")}
                    </label>
                    <Input
                        onChange={(e) =>
                            onChange((prev) => ({
                                ...prev,
                                id: e.target.value,
                            }))
                        }
                    />
                </div>
                <div>
                    <label>{t("customerName")}</label>
                    <Input
                        onChange={(e) =>
                            onChange((prev) => ({
                                ...prev,
                                client_name: e.target.value,
                            }))
                        }
                    />
                </div>
                <div>
                    <label>{t("fromTo")}</label>
                    <DatePicker.RangePicker
                        onChange={(v) => {
                            setFilters((prev) => ({
                                ...prev,
                                start_date: v?.[0]?.format(
                                    "YYYY-MM-DDT12:00:00Z"
                                ),
                                end_date: v?.[1]?.format(
                                    "YYYY-MM-DDT12:00:00Z"
                                ),
                            }));
                            console.log({ v });
                        }}
                        style={{
                            width: "100%",
                        }}
                    />
                </div>
                {user.role === "Organization Admin" && (
                    <div>
                        <label>{t("trackNumber")}</label>
                        <Input
                            onChange={(e) =>
                                onChange((prev) => ({
                                    ...prev,
                                    track_number: e.target.value,
                                }))
                            }
                        />
                    </div>
                    )}

            </div>
            {children}
            <TablePro
                loading={loading}
                columns={
                    isUnConfirmedRequest
                        ? unConfirmedRequestsColumns
                        : confirmedRequestsColumns
                }
                headerExtra={<div>{/*<Sele*/}</div>}
                dataSource={loading ? [] : data}
                searchPlaceholder={t("searchByTicketOrCustomer")}
                selection={user.role !== "Seller" ? "checkbox" : undefined}
                emptyPlaceholder={
                    <EmptyPlaceholder
                        currentStateTitle={currentStateTitle}
                        onRefresh={onRefresh}
                    />
                }
                // onRowClick={(i, row) => fetchDetails(row.order?.id ?? row.id)}
                onRowClick={handleRowClick}
                selectedRows={selected}
                setSelectedRows={setSelected}
                pagination={pagination}
                preserveSelectedRowKeys
            />
            {details ? (
                <OrderDetails
                    pagination={onChangeTickets}
                    current={details.tickets.page ?? 1}
                    tickets={details.tickets}
                    ticketsCount={details.tickets.count}
                    show={details}
                    loading={detailsLoading}
                    setClose={() => setDetails(undefined)}
                    data={details}
                />
            ) : null}
        </div>
    );
}
