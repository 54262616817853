import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../style-sheets/cards.css";
import { Tooltip } from "reactstrap";
import { useTranslation } from "react-i18next";
import useLanguage from "../../../src/hooks/useLanguage";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const StatusCard = ({
    styleClass,
    style,
    background,
    number,
    type,
    selectedCard,
    description,
    onClick,
}) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const { t } = useTranslation();
    const lang = useLanguage();
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const borderbottom = lang === 'ar' ? 'borderBottomRightRadius' : 'borderBottomLeftRadius';
    const bordertop = lang === 'ar' ? 'borderTopRightRadius' : 'borderTopLeftRadius';
    return (
        <div className="my-4" style={{ overflow: "hidden" , 
                                     
                                     width:'250px'
                                    }}>
            <div style={style} className={styleClass}>
                <div
                    className="padding"
                    style={{
                        background:
                            selectedCard === type ? background : "#E0E0E0",
                            [borderbottom]:"50px",
                            [bordertop] :"50px",

                    }}
                >
                    <Row>
                    <Col></Col>
                        <Col
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                            xs="12"
                        >
                            {" "}
                            <h6 className="statusTitle" >{type}</h6>
                            <span>
                                {" "}
                                {description ? (
                                    <>
                                        {" "}
                                        <i
                                            style={{
                                                // position: "absolute",
                                                // top: "15px",
                                                // right: "15px",
                                                color:
                                                    selectedCard === type
                                                        ? "#1F0765"
                                                        : "#C0C0C0",
                                            }}
                                            id="TooltipExample"
                                            className="fas fa-info-circle ml-5"
                                        ></i>
                                        <Tooltip
                                            placement="right"
                                            isOpen={tooltipOpen}
                                            target="TooltipExample"
                                            toggle={toggle}
                                        >
                                            {description ?? "No Description"}
                                        </Tooltip>
                                    </>
                                ) : null}
                            </span>
                        </Col>
                        {/* <Col xs="2"> */} {/* </Col> */}
                    </Row>
                    <Row>
                        <Col xs="6">
                            <p className="statusNumber">{number}</p>
                        </Col>
                        <Col >
                           
                                <div onClick={onClick} className="tableID" style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end",
                                    height: "100%",
                                    flexDirection:"column",
                                    marginTop:"10px",
                                    color:"black"
                                }}>
                                    {t("show_all")}
                                    {lang.includes("en") ? (
                                            <ArrowForwardIcon  style={{fontSize:"20px" , marginRight:"10px"}}/>
                                        ) : (
                                            <KeyboardBackspaceIcon  style={{fontSize:"20px" , marginLeft:"10px"}}/>
                                        )}
                                    
                                </div>
                            
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default StatusCard;
