import { Input } from "antd";
import { TextField } from "../form/TextInputFields";
import { useTranslation } from "react-i18next";

export const pattern = /^(\+966)(5)[0-9]{8}$/;
export const smallPattern = /^(5)[0-9]{8}$/;
export const prefix = "+966";
export const useAntdPhoneRules = () => {
    const { t } = useTranslation();
    return [
        {
            pattern,
            message: t("phoneErr"),
        },
    ];
};

export default function PhoneInput(props) {
    const { t } = useTranslation();

    const rawValue = props.value ?? "";
    const value = rawValue.split(prefix)[1] ?? "";
    console.log({
        props: props.name,
    });
    return props.formType === "antd" ? (
        <Input
            {...props}
            addonBefore={prefix}
            value={value}
            onBlur={(e) => {
                e.target.value = `${prefix}${e.target.value}`;
                props.onBlur?.(e);
            }}
            onChange={(e) => props.onChange?.(`${prefix}${e.target.value}`)}
        />
    ) : (
        <TextField
            id="phone"
            note={t("phonePlaceholder")}
            className="formTextField text-area"
            title={t("phone")}
            name="phone"
            prefix="+966"
            backendErrors={
                props.errors !== ""
                    ? props.errors?.[props.name ?? "phone"]?.length > 0
                        ? props.errors?.[props.name ?? "phone"]?.[0]
                        : null
                    : null
            }
            disabled={false}
            validation={{
                length: 9,
                required: props.name === "phone",
                pattern: smallPattern,
            }}
            {...props}
        />
    );
}
