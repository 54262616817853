import React, { useContext, useEffect, useState, memo } from "react";
import { DatePicker } from "antd";
import { Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { userType } from "../../global-state/actions/userTypeAction";
import { addAccount } from "../../global-state/actions/addAccountAction";
import { useTranslation } from "react-i18next";

import { useFormValidation } from "../contexts/FormContext";
import { Select } from "antd";
import { Controller } from "react-hook-form";
// import { useTranslation } from "react-i18next";
import {
    getSeller,
    getClient,
} from "../../global-state/actions/getSelectedAction";

const lang = localStorage.getItem("lang");

function Error({ id, type, text }) {
    const { errors } = useFormValidation();
    const { t } = useTranslation();

    return (
        <p className="error">
            {errors[id]?.type === "required" && t("requiredField")}
            {errors[id]?.type === "maxLength" && lang === "en"
                ? "Number exceeded maximum length"
                : errors[id]?.type === "maxLength" && lang === "ar"
                ? "لقد تخطيت عدد الارقام المسموح به"
                : null}
            {errors[id]?.type === "max" && lang === "en"
                ? "Maximum value exceeded"
                : errors[id]?.type === "max" && lang === "ar"
                ? "لقد نخطيت القيمة المسموح بها"
                : null}
            {errors[id]?.type === "pattern" && `${t("phoneErr")}`}
        </p>
    );
}

export function TextField({
    className,
    id,
    title,
    value,
    validation,
    placeholder,
    type,
    name,
    disabled,
    shouldRegister,
    backendErrors,
    note,
    prefix,
    ...rest
}) {
    const { register, errors } = useFormValidation();
    return (
        <div className={className}>
            <label className="mt-2" for={id}>
                {title}
            </label>
            {note && <p className="note">{note}</p>}
            {shouldRegister ? (
                <>
                    <InputGroup>
                        {" "}
                        {prefix && (
                            <InputGroup.Text id="basic-addon1">
                                {prefix}
                            </InputGroup.Text>
                        )}
                        <Form.Control
                            {...register(id, validation)}
                            disabled={disabled}
                            placeholder={placeholder}
                            type={type ?? "text"}
                            name={name}
                            {...rest}
                        />
                    </InputGroup>
                    <Error id={id} />
                    <p className="error">
                        {backendErrors ? backendErrors : null}
                    </p>
                </>
            ) : (
                <InputGroup>
                    {prefix && (
                        <InputGroup.Text id="basic-addon1">
                            {prefix}
                        </InputGroup.Text>
                    )}
                    <Form.Control
                        defaultValue={value}
                        value={value}
                        disabled={disabled}
                        readOnly
                        placeholder={placeholder}
                        type={type ?? "text"}
                        name={name}
                        {...rest}
                    />
                </InputGroup>
            )}
        </div>
    );
}

export function CheckBox({ className, id, validation, title, children }) {
    const { register, errors } = useFormValidation();

    return (
        <div className={className}>
            <label for={id}>{title}</label>
            <input type="checkbox" {...register(id, validation)} />
            <Error id={id} />
        </div>
    );
}

export function Radio({
    className,
    id,
    validation,
    value,
    name,
    txt,
    onChange,
}) {
    const { register, errors } = useFormValidation();
    const user = useSelector((state) => state.auth.user);

    // const { control, register, handleSubmit, formState:{errors} } = useForm({mode: "onChange"});
    return (
        <div className={className}>
            <input
                type="radio"
                name={name}
                id={id}
                // value={value}
                defaultValue={value}
                // ref={register({ required: true })}
                className={
                    user.role == "Seller"
                        ? "form-check-seller"
                        : "form-check-input"
                }
                {...register(id, validation)}
            />
            <span className="radio-txt">{txt}</span>
            <Error id={id} />
        </div>
    );
}

export function TextArea({ id, className, validation, title }) {
    const { register, errors } = useFormValidation();

    return (
        <div className={className}>
            <label for={id}>{title}</label>
            <textarea rows="10" {...register(id, validation)}></textarea>
            <Error id={id} />
        </div>
    );
}

export function DateInput({ className, id, validation, title, children }) {
    const { register, setValue, control } = useFormValidation();

    return (
        <div className={className}>
            <label for={id}>{title}</label>
            <input
                {...register(id, validation)}
                defaultValue=""
                className="form-control"
                type="date"
            />
            <Error id={id} />
        </div>
    );
}

export function PaginatedSelect({
    search,
    ref,
    className,
    id,
    validation,
    title,
    children,
    onScroll,
    onClick,
    backendErrors,
    choice,
    disabled,
    defaultValue,
    allowClear,
}) {
    const { register, control, setValue } = useFormValidation();
    // const {setValue} = useForm();

    const [selected, setSelected] = useState(choice);
    const [nextUrl, setnextUrl] = useState();
    const dispatch = useDispatch();

    const onSearch = (value) => {
        console.log(value);
        search(value);
    };

    const getChangeHandlerWithValue = (name) => (value, obj) => {
        console.log(value);
        if (obj.sellerinfo) {
            console.log("key", obj.sellerinfo);
            dispatch(getSeller(obj.sellerinfo));
        }
        if (obj.clientinfo) {
            console.log(obj.clientinfo);
            dispatch(getClient(obj.clientinfo));
        }
        if (obj.type) {
            dispatch(userType(obj.type));
        }
        if (obj.branchname) {
            dispatch(addAccount({ branchName: obj.branchname }));
        }

        setValue(name, value);
        setSelected(value);
    };

    return (
        <div className={className}>
            <label className="mt-2">{title}</label>
            {search ? (
                <Select
                    disabled={disabled}
                    onSearch={onSearch}
                    filterOption={false}
                    showSearch
                    defaultValue={defaultValue ?? selected}
                    value={selected}
                    onChange={getChangeHandlerWithValue(id)}
                    allowClear={allowClear ?? false}
                    ref={ref}
                    onClick={!disabled ? onClick : undefined}
                    onPopupScroll={onScroll}
                    className="form-control paginated"
                >
                    {children}
                </Select>
            ) : (
                <Select
                    filterOption={false}
                    defaultValue={defaultValue ?? selected}
                    value={selected}
                    onChange={getChangeHandlerWithValue(id)}
                    allowClear
                    ref={ref}
                    disabled={disabled}
                    onClick={!disabled ? onClick : undefined}
                    onPopupScroll={onScroll}
                    className="form-control paginated"
                >
                    {children}
                </Select>
            )}
            <Error id={id} />
            <p className="error">{backendErrors ? backendErrors : null}</p>
        </div>
    );
}

export function PaginatedSelect2({ id, children, loadNext }) {
    const { register, control, setValue } = useFormValidation();

    return (
        <Controller
            {...register(id)}
            name={id}
            defaultValue=""
            control={control}
            render={({ ref }) => (
                <PaginatedSelect
                    setValue={setValue}
                    id={id}
                    loadNext={loadNext}
                    ref={ref}
                >
                    {children}
                </PaginatedSelect>
            )}
        />
    );
}
