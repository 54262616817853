import { Col, Row , Stack } from "react-bootstrap";
import sellerLoginImg from "../../../../images/sellerLoginImg.png";
import React from "react";
import "../../../../style-sheets/login.css";

export default function RegisterLayout({ title, children }) {
    return (

            <div style={{marginTop:'50px'}}>
                <h2
                    className="title"
                    
                >
                    {title}
                </h2>
                <div style={{display:'flex', justifyContent:'center' , alignItems:'center' ,flexDirection:'column' }}>
                    {children}
                </div>
           </div> 
        
    );
}
