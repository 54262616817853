import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
// import { useTranslation } from 'react-i18next';
import { FormContext } from "../contexts/FormContext";
// import "../../../stylesheets/detailsForm.css";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import "react-toastify/dist/ReactToastify.css";

export default function ModifiedForm({
    children,
    defaultValues,
    submitFunction,
    listenToValues,
}) {
    const dispatch = useDispatch();

    const selectedSeller = useSelector(
        (state) => state.selectedData.selectedSeller
    );

    const order = useSelector((state) => state.order);
    // const orderDispatch = useDispatch()
    const {
        register,
        formState,
        formState: { errors },
        handleSubmit,
        control,
        setValue,
        getValues,
    } = useForm({
        mode: "onChange",
        defaultValues: defaultValues ?? {
            seller: selectedSeller?.id,
            seller_address: order?.seller_address,
            client_address: order?.client_address,
            client: order?.client,
            tickets: order?.tickets,
        },
    });

    useEffect(() => {
        if (getValues) {
            listenToValues?.(getValues);
        }
    }, [getValues]);

    //   const { t } = useTranslation();

    async function onSubmit(data) {
        console.log(`Form Data = ${JSON.stringify(data)}`);
        // console.log(formState.isValid)
        // orderDispatch(addOrder(data))
        submitFunction(data);
    }

    // useEffect(()=>{
    //   dispatch(isValid(formState.isValid))

    // },[formState.isValid])

    return (
        <FormContext.Provider
            value={{ register, errors, control, formState, setValue }}
        >
            <form className="modifiedForm" onSubmit={handleSubmit(onSubmit)}>
                {/* {console.log(formState.isValid)} */}
                {children}
                <ToastContainer position="bottom-right" />
            </form>
        </FormContext.Provider>
    );
}
