import formattedError from "./errorFormatter";
import axiosInstance from "./axios";

const DimensionRepository = {
    get: async ({ page = 1, limit = 10 }) => {
        try {
            const res = await axiosInstance.get(`/DefaultDiemention/`, {
                params: {
                    page,
                    page_size: limit,
                },
            });
            if (res.data) {
                return {
                    success: true,
                    data: res.data.data,
                };
            } else return formattedError();
        } catch (e) {
            return formattedError(e);
        }
    },
    post: async (data) => {
        try {
            const res = await axiosInstance.post("/DefaultDiemention/", data);

            if (res.data) {
                return {
                    success: true,
                    data: res.data,
                };
            } else return formattedError();
        } catch (e) {
            return formattedError(e);
        }
    },
    put: async (data, id) => {
        try {
            const res = await axiosInstance.put(
                "/DefaultDiemention/" + id,
                data
            );

            if (res.data) {
                return {
                    success: true,
                    data: res.data,
                };
            } else return formattedError();
        } catch (e) {
            return formattedError(e);
        }
    },
};

export default DimensionRepository;
