import React, { useState, useEffect } from "react";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import {
    runsheetOrders,
    runsheetOrders_ar, usePostponedColumns,
    useRunSheetColumns,
} from "../layout/tableColumns";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    CreationConfirmation,
    SuccessfulRequest,
    UnSuccessfulRequest,
} from "../modals/confirmationModal";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";
import TablePro from "../tables/TablePro";

function PostponeRequest({ value, handleChange }) {
    const { t } = useTranslation();

    const [show, setShow] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState("");
    const [ID, setID] = useState("");

    const data = useSelector((state) => state.postponed.orders);
    const date = useSelector((state) => state.postponeDate.date);
    const ordersIds = useSelector((state) => state.postponedIds.orders);
    const history = useHistory();
    const hide = () => {
        setShow(false);
        setShowConfirm(false);
        setShowError(false);
        // history.push("/dashboard")
    };
    const sendRequest = () => {
        setShow(true);
    };

    const confirm = async () => {
        // const ordersText = object.orders.map(order => order.toString())
        let data = {
            orders: ordersIds,
            new_date: date,
            state: "postponed",
        };
        let result = await axiosInstance
            .post("/MoveTicket/state", data)
            .catch((err) => {
                setError(err.response.data);
                console.log(err.response);
            });

        if (result) {
            console.log(result);
            hide();
            setShowConfirm(true);

            // setID(result.data.data.id)
        }
    };
    useEffect(() => {
        if (error !== "") {
            setShowError(true);
        }
    }, [error]);
    useEffect(() => {
        if (ID !== "") {
            setShowConfirm(true);
        }
    }, [ID]);
    const lang = useLanguage();

    return (
        <>
            <Row className="mt-3">
                <Col sm="12" md="6">
                    <div className="position-relative text-area-form">
                        <label className="bold-label">
                            {t("postponedDate")}
                        </label>
                        <Form.Control
                            defaultValue={date}
                            disabled
                            type="date"
                        />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col className="text-end buttons-margin mb-3" sm="12">
                    <button
                        className="cancel"
                        onClick={() => handleChange(null, 0)}
                    >
                        {t("discard")}
                    </button>
                    <button onClick={sendRequest} className="confirm">
                        {t("sendRequest")}
                    </button>
                </Col>
            </Row>
            <TablePro dataSource={data} columns={usePostponedColumns()} />
            <Row>
                <CreationConfirmation
                    sendRequest={confirm}
                    show={show}
                    hide={hide}
                    body={t("postponeConfirmText")}
                    title={t("postponeConfirmation")}
                />
                <SuccessfulRequest
                    show={showConfirm}
                    hide={() => {
                        setShowConfirm(false);
                        setTimeout(() => history.push("/dashboard"), 500);
                    }}
                    title={t("postponeConfirmation")}
                    body={t("postponeSuccessText")}
                />
                <UnSuccessfulRequest
                    show={showError}
                    hide={hide}
                    title={t("postponeConfirmation")}
                    body={error}
                />
            </Row>
        </>
    );
}

export default ErrorHandler(PostponeRequest, axiosInstance);
