import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../../../style-sheets/form.css";
import { Select } from "antd";
import { PaginatedSelect } from "../../../../components/form/TextInputFields";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../../../../api/axios";
import ModifiedForm from "../../../../components/form/ModifiedForm";
import { addAccount } from "../../../../global-state/actions/addAccountAction";
import { useTranslation } from "react-i18next";
import PermissionsList from "../../../../components/forms/PermissionsList";

export default function AccountTypeForm({ accountTypes, value, handleChange }) {
    const dispatch = useDispatch();

    const [branches, setBranches] = useState([]);
    const [nextLink, setNextLink] = useState("");
    const [loadMore, setLoadMore] = useState(true);
    const [selectedUser, setSelected] = useState(null);
    const [branchErr, setBranchErr] = useState("");
    const { t, i18n } = useTranslation();
    const [permissions, setPermissions] = useState(undefined);
    const selectedType = useSelector((state) => state.userType.userType);
    const [permLoading, setPermLoading] = useState(false);
    const getBranches = async () => {
        let result = await axiosInstance.get(`/Branch/`);
        if (result) {
            setBranches(result.data.data);
            setNextLink(result.data.next);
        }
    };

    const getUserPermissions = async () => {
        if (
            selectedUser === null ||
            selectedUser === undefined ||
            selectedUser === accountTypes.seller.index
        ) {
            setPermissions(undefined);
            return;
        }

        setPermLoading(true);
        const result = await axiosInstance.get(
            `/permissions?user_type=${selectedUser}`
        );

        if (result) setPermissions(result.data);

        setPermLoading(false);
    };

    const updatePermission = (i, v) => {
        const copy = [...permissions];
        permissions[i].active = v;
        setPermissions(copy);
    };
    const handleUser = (e) => {
        setSelected(e);
    };

    useEffect(() => getUserPermissions(), [selectedUser]);

    const onScroll = async (event, link) => {
        var target = event.target;
        let result;
        if (
            loadMore &&
            target.scrollTop + target.offsetHeight === target.scrollHeight
        ) {
            result = await axiosInstance
                .get(`${nextLink}`)
                .catch((err) => console.log(err));

            if (result) {
                setBranches([...branches, ...result.data.data]);
                setNextLink(result.data.next);
                if (result.data.next === null) {
                    setLoadMore(false);
                }
            }
        }
    };
    const validate = (data) => {
        console.log(`Form Data = `, data);
        if (!data.branch && selectedUser !== accountTypes.seller.index) {
            setBranchErr(t("branchErr"));
        } else {
            setBranchErr("");
            data.type = selectedUser;
            data.permissions = permissions;
            if (selectedUser !== null) {
                handleChange(null, value + 1);
                dispatch(addAccount(data));
            }
        }
    };

    useEffect(() => {
        getBranches();
        dispatch(addAccount(""));
    }, []);
    return (
        <ModifiedForm submitFunction={validate}>
            <Row>
                <Col sm="12" md="6">
                    <div className="text-area-form">
                        <label className="mt-2">{t("selectUser")}</label>
                        <Select
                            onChange={handleUser}
                            value={selectedUser}
                            style={{ width: "100%" }}
                            placeholder={t("userType")}
                        >
                            {Object.values(accountTypes).map((it) => (
                                <Select.Option
                                    key={it.index}
                                    type={it.index}
                                    value={it.index}
                                >
                                    {it.title}
                                </Select.Option>
                            ))}
                        </Select>
                        <></>
                    </div>
                </Col>
                {selectedUser === -1 || selectedUser === null ? null : (
                    <Col sm="12" md="6">
                        {/* <div className="text-area-form"> */}
                        <PaginatedSelect
                            id="branch"
                            title={t("sideMenu.branches")}
                            onScroll={(e) => onScroll(e, nextLink)}
                        >
                            {
                                //return sellers[index]
                                branches.map((branch) => (
                                    <Select.Option
                                        key={branch.id}
                                        branchname={branch.name}
                                        value={branch.id}
                                    >
                                        {branch.name}
                                    </Select.Option>
                                ))
                            }
                        </PaginatedSelect>

                        <p className="error">{branchErr}</p>
                        {/* </div> */}
                    </Col>
                )}
            </Row>

            {permissions && (
                <PermissionsList
                    permissions={permissions}
                    updatePermission={updatePermission}
                    loading={permLoading}
                />
            )}

            <hr className="line-margin" />
            <Row>
                <Col className="text-end buttons-margin" sm="12">
                    <button className="cancel" disabled>
                        {t("discard")}
                    </button>
                    <input
                        className="confirm"
                        value={t("proceed")}
                        type="submit"
                    />
                </Col>
            </Row>
        </ModifiedForm>
    );
}
