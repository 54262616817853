import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../../style-sheets/form.css";
import { Select, Form, Input } from "antd";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import axiosInstance from "../../../api/axios";
import { SuccessfulRequest } from "../../modals/confirmationModal";
// import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../../contexts/LanguageContext";
import { useTranslation } from "react-i18next";

const LanguageCurrency = () => {
    const [selectedLang, setSelectedLang] = useState(null);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const { t, i18n } = useTranslation();

    const handleLang = async (e) => {
        console.log(e);
        setSelectedLang(e);
        await i18n.changeLanguage(e);
        localStorage.setItem("lang", e);
        window.location.reload(false);
    };
    const handleCurrency = (e) => {
        setSelectedCurrency(e);
    };
    return (
        <div>
            <Container className="px-5 py-3">
                <Row className="mb-4">
                    <Col sm="12">
                        <span className="title1">{t("sideMenu.settings")}</span>
                        <i class="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">{t("language")}</span>
                    </Col>
                    <Col sm="12">
                        <h2 className="pageTitle">{t("lang")}</h2>
                    </Col>
                </Row>

                <Row className="justify-content-center">
                    <Col sm={12} md={8} className="updatePassDiv">
                        <Row>
                            <Col sm={12} md={12}>
                                <label>{t("language")}</label>
                                <Select
                                    onChange={handleLang}
                                    value={selectedLang}
                                    style={{ width: "100%" }}
                                    placeholder={t("selectLang")}
                                >
                                    <Select.Option name="English" value="en">
                                        English
                                    </Select.Option>
                                    <Select.Option name="Arabic" value="ar">
                                        Arabic
                                    </Select.Option>
                                </Select>
                            </Col>
                            <Col sm={12} md={2}></Col>
                            {/* <Col sm={12} md={5}>
                <label>{t("currency")}</label>

                <Select
                  onChange={handleCurrency}
                  value={selectedCurrency}
                  style={{ width: "100%" }}
                  placeholder={t("selectCurrency")}
                >
                  <Select.Option name="egy" value="egy">
                    Egypt(EGP)
                  </Select.Option>
                </Select>
              </Col> */}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ErrorHandler(LanguageCurrency, axiosInstance);
