import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { DatePicker, Select } from "antd";
import { DataGrid } from "@mui/x-data-grid";
import "../../../../style-sheets/table.css";
import axiosInstance from "../../../../api/axios";
import ErrorHandler from "../../../../error-handler/ErrorHandler";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Permissions = (props) => {

    const { t} = useTranslation();

  const [data, setData] = useState([]);
  const [selectionModel, setSelectionModel] = useState("");
  const [selectedUserID, setSelectedUserID] = useState("");

  useEffect(() => {
    setSelectedUserID(selectionModel);
  }, [selectionModel]);

  const getUserPermission = async () => {
    console.log(props.selectedUser[0])
    let result = await axiosInstance.get(
      `/permissions?user_id=${props.selectedUser[0].user.id}`
    );
    console.log(result);
    setData(result.data);
  };
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    getUserPermission();
  }, []);

  
  const addPermission = async (e) => {
    let data = {
      user_id: props.selectedUser[0].user.id,
      permissions: e.target.id
    };
    let result = await axiosInstance
      .post(`/add_permissions`, data)
      .catch((err) => {
        console.log(err.response);
      });
    if (result) {
      console.log(result);
      getUserPermission();
    }
  };

  const removePermission = async (e) => {
    let data = {
      user_id: props.selectedUser[0].user.id,
      permissions: e.target.id
    };
    let result = await axiosInstance
      .post(`/remove_permissions`, data)
      .catch((err) => {
        console.log(err.response);
      });
    if (result) {
      console.log(result);
      getUserPermission();
    }
  };

  useEffect(()=>{
    console.log(props.selectedUser)
  },[])

  return (
    <div>
      <Container>
        <Row>
          <Col lg="6">
            <label>{t("managerName")}</label>
            <Form.Control
              value={props.selectedUser[0].user.name}
              disabled
              type="text"
            />
          </Col>
          <Col lg="6">
            <label>{t("branch")}</label>
            <Form.Control
              value={props.selectedUser[0].branch.name}
              disabled
              type="text"
            />
          </Col>
        </Row>
        <Row>
          <Col lg="7">
            {data.map((data) => {
              return (
                <>
                  <div key={data.id} className="permission">
                    <p>
                      {data.name} 
                       <strong>
                        {data.active ? `${t("activated")}` : `${t("deactivated")}`}
                      </strong>
                    </p>
                    {data.active ? (
                      <button onClick={removePermission} id={data.id} className="deactivate">
                        {t("deactivate")}
                      </button>
                    ) : (
                      <button
                        onClick={addPermission}
                        id={data.id}
                        className="activate"
                      >
                        {t("activate")}
                      </button>
                    )}
                  </div>
                </>
              );
            })}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ErrorHandler(Permissions, axiosInstance);
