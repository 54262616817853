import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Row, Col, InputGroup  , Stack, Container} from "react-bootstrap";
import img from "../../images/signup.svg";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Image from "react-bootstrap/Image";
import { useHistory } from "react-router";
import {
    SuccessfulRequest,
    UnSuccessfulRequest,
} from "../modals/confirmationModal";
import { couriers } from "../layout/tableColumns";

function compare(a, b) {
    if (a.name.common < b.name.common) {
        return -1;
    }
    if (a.name.common > b.name.common) {
        return 1;
    }
    return 0;
}
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function Registeration() {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [errorArray, setErrorArray] = useState([]);
    const [domain, setDomain] = useState("");
    const [country, setCountry] = useState({});
    const [countries2, setCountries2] = useState([]);

    const [showFailed, setShowFailed] = useState(false);
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        email: Yup.string().required("Email is required"),
        phone: Yup.number()
            .typeError("Phone is required")
            .required("Phone is required"),
        company_name: Yup.string().required("Company Name is required"),
        app_id: Yup.string().required("Domain is required"),
        password: Yup.string()
            .required("Password is required")
            .min(6, "Password must be at least 6 characters"),
        confirmPassword: Yup.string()
            .required("Confirm Password is required")
            .oneOf([Yup.ref("password")], "Passwords must match"),
        terms: Yup.boolean().oneOf(
            [true],
            "You must agree to the terms and conditions"
        ),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const changeCountry = (e) => {
        console.log(country);
        let c = countries2.find((country) => country.cca2 === e.target.value);
        setCountry({
            country: c.name.common,
            currency: Object.keys(c.currencies)[0],
            iso: c.cca2,
        });
    };

    useEffect(() => {
        const getCountries = async () => {
            let result = await (
                await axios.get("https://restcountries.com/v3.1/all")
            ).data;
            result.sort(compare);
            setCountries2(result);
            console.log(result);
        };
        getCountries();
    }, []);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm(formOptions);

    const validate = async (data) => {
        setErrorArray([]);
        setLoading(true);

        let result = await axios
            .post(`https://public-mybox.treecode.website/client_create?lang=en`, {
                ...data,
                ...country,
            })
            .catch((err) => {
                let errArray = [];

                for (const value of Object.values(err.response.data)) {
                    console.log(value);
                    let errText = value[0];
                    errArray.push(errText);
                    console.log(errArray);
                }
                setErrorArray(errArray);

                console.log(Object.values(err.response.data));
                setShowFailed(true);
                setLoading(false);
            });

        if (result) {
            gtag_report_conversion();
            setDomain(data.app_id);
            setShow(true);
            setLoading(false);
        }

        console.log(data);
    };

    const hide = (created) => {
        setShow(false);
        setShowFailed(false);
        localStorage.setItem("lang", "en");
        // testing
        if (created)
            window.location.href = `https://${domain}.vialines.net/login/en`;

        // production
        // if (created) window.location.href = `https://$mybox.treecode.io/login/en`;
    };

    window.dataLayer = window.dataLayer || [];
    function gtag() {
        window.dataLayer.push(arguments);
    }

    function gtag_report_conversion(url) {
        // var callback = function () {
        //   if (typeof url != "undefined") {
        //     window.location = url;
        //   }
        // };
        gtag("js", new Date());
        gtag("config", "AW-587206414");
        gtag("event", "conversion", {
            send_to: "AW-587206414/H5QZCIjV4pADEI6egJgC",
            // event_callback: callback,
        });
        return false;
    }

    useEffect(() => {
        localStorage.setItem("lang", "en");
    }, []);
    return (
        <div className="loginContainer">

            <Container className="pageContainer">
                <Stack gap={1} className="col-md-10 mx-auto">
                    <form className="text-center" onSubmit={handleSubmit(validate)}>
                        
                        <p className="form-title">{t("signUp")}</p>
                            <div className="field-wrapper">
                                <i class="fas fa-lg fa-user"></i>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        {...register("name")}
                                        placeholder="Your Name"
                                        type="text"
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        tooltip
                                    >
                                        {errors.name?.message}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </div>
                            <div className="field-wrapper">
                                <i class="fas fa-envelope"></i>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        {...register("email")}
                                        placeholder="Your Email"
                                        type="email"
                                        isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        tooltip
                                    >
                                        {errors.email?.message}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </div>
                            <div className="field-wrapper">
                                <i class="fas fa-phone"></i>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        {...register("phone")}
                                        placeholder="Your Phone"
                                        className="number-input"
                                        type="number"
                                        isInvalid={!!errors.phone}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        tooltip
                                    >
                                        {errors.phone?.message}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </div>
                            <div className="field-wrapper">
                                <i class="fas fa-building"></i>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        {...register("company_name")}
                                        placeholder="Your Company Name"
                                        type="text"
                                        isInvalid={!!errors.company_name}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        tooltip
                                    >
                                        {errors.company_name?.message}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </div>
                            <div className="field-wrapper">
                                <i class="fas fa-building"></i>
                                <Form.Select
                                    onChange={changeCountry}
                                    defaultValue="0"
                                    aria-label="Default select example"
                                >
                                    <option disabled value="0">
                                        Choose Your country
                                    </option>
                                    {countries2.map((country) => {
                                        return (
                                            <option value={country.cca2}>
                                                {country.name.common}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </div>
                            <div className="field-wrapper">
                                <i class="fas fa-link"></i>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        {...register("app_id")}
                                        placeholder="Username"
                                        type="text"
                                        isInvalid={!!errors.app_id}
                                    />
                                    <InputGroup.Text id="basic-addon2">
                                        .treecode.io
                                    </InputGroup.Text>
                                    <Form.Control.Feedback
                                        type="invalid"
                                        tooltip
                                    >
                                        {errors.app_id?.message}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </div>
                            <div className="field-wrapper">
                                <i class="fas fa-lock"></i>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        {...register("password")}
                                        placeholder="Password"
                                        type="password"
                                        isInvalid={!!errors.password}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        tooltip
                                    >
                                        {errors.password?.message}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </div>
                            <div className="field-wrapper">
                                <i class="fas fa-key"></i>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        {...register("confirmPassword")}
                                        placeholder="Repeat your password"
                                        type="password"
                                        isInvalid={!!errors.confirmPassword}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        tooltip
                                    >
                                        {errors.confirmPassword?.message}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </div>

                            <div className="text-center field-wrapper">
                                <input
                                    {...register("terms")}
                                    id="terms"
                                    type="checkbox"
                                />
                                <label className="ms-2" for="terms">
                                    I agree to all statements in{" "}
                                </label>
                                {/* testing */}
                                <a href="https://test.tree-code.com/en/terms">
                                    Terms of service
                                </a>

                                {/* production */}
                                {/* <a href="https://tree-code.com/en/terms">Terms of service</a> */}
                            </div>
                            <p className="error">{errors.terms?.message}</p>
                            <button
                                disabled={loading}
                                className="confirm mt-4"
                                type="submit"
                            >
                                {t("register")}
                            </button>
                        {/* 
                            <Col
                                sm="6"
                                md="6"
                                className="order-first order-md-last"
                            >
                                <img src={img} alt="Sign up photo" />
                            </Col>
                        */}
                    </form>
                </Stack>
            </Container>
            <SuccessfulRequest
                show={show}
                title="Setup Your Account"
                body={
                    <p>
                        Your <strong>Password & Sub-Domain</strong> has been
                        created successfully
                    </p>
                }
                hide={() => hide(true)}
            />
            <UnSuccessfulRequest
                show={showFailed}
                title="Setup Your Account"
                body={errorArray[0]}
                hide={() => hide(false)}
            />
            {/* <CSSTransitionGroup  transitionName="overlay"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}> */}
            {loading ? (
                <div className="overlayModal">
                    <Spin
                        className="registration-spinner"
                        indicator={antIcon}
                    />
                    <p className="title fs-2">{t("orgConfigure")}</p>
                    <p className="fs-5">{t("orgAlert")}</p>
                </div>
            ) : null}
            {/* </CSSTransitionGroup> */}
        </div>
    );
}

export default Registeration;
