import { Col, Row } from "react-bootstrap";
import { Form, Select, Input, Button, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../../../api/axios";
import { addOrder } from "../../../../../global-state/actions/addOrderAction";

export default function SellerAddressInfo({ value, handleChange }) {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [addresses, setAddresses] = useState([]);
    const [areas, setAreas] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [selectedArea, setSelectedArea] = useState(null); // Track selected area
    const order = useSelector((state) => state.order);
    const user = useSelector((state) => state.auth.user);
    const currentLanguage = i18n.language; 
    const [form] = Form.useForm(); // Initialize form instance

    useEffect(() => {
        getSellerAddresses();
        getAreas();
    }, []);

    const getSellerAddresses = async () => {
        try {
            const result = await axiosInstance.get(`/Seller/${user.user.user_id}`);
            setAddresses(result.data.saved_address);
        } catch (error) {
            console.error("Error fetching seller addresses:", error);
        }
    };

    const getAreas = async () => {
        try {
            const areasData = await axiosInstance.get(`/FilterAreas/?page_size=10000`);
            setAreas(areasData.data.data);
        } catch (err) {
            console.error("Error fetching areas:", err);
        }
    };

    const handleAddressSelect = (value) => {
        const selected = addresses.find((address) => address.id === value);
        setSelectedAddress(selected);
        setSelectedArea(null); // Reset selected area when a new address is selected

        // Set form fields manually without resetting the entire form
        form.setFieldsValue({
            address: selected.address,
            area: selected.area.id,
            special_name: selected.special_name,
            store_phone: selected.store_phone,
            sender_name: selected.sender_name, // Add sender_name field here
        });
    };

    const handleEdit = async () => {
        try {
            await axiosInstance.put(`/SellerAddress/${selectedAddress.id}`, {
                address: selectedAddress.address,
                area: selectedArea ? selectedArea : selectedAddress.area.id,
                special_name: selectedAddress.special_name,
                store_phone: selectedAddress.store_phone,
                sender_name: selectedAddress.sender_name, // Include sender_name in the update
            });
            notification.success({
                message: t("success"),
                description: t("addressUpdatedSuccessfully"),
            });
            getSellerAddresses();
            setSelectedAddress(null);
        } catch (error) {
            const errorMessages = [];
            if (error.response?.data) {
                for (const [field, messages] of Object.entries(error.response.data)) {
                    messages.forEach((msg) => {
                        errorMessages.push(`${t(field)}: ${msg}`);
                    });
                }
            }
            notification.error({
                message: t("error"),
                description: errorMessages.length > 0 ? errorMessages.join("\n") : t("updateFailed"),
            });
            console.error(error);
        }
    };

    const onFinish = (data) => {
        data.seller = user.user.user_id;
        dispatch(
            addOrder({
                ...data,
            })
        );
        handleChange(null, parseInt(value) + 1);
    };

    return (
        <Form
            form={form} // Pass form instance to Form component
            initialValues={{
                seller_address: order.seller_address,
            }}
            layout="vertical"
            onFinish={onFinish}
        >
            <Row>
                <Col sm={6}>
                    <Form.Item
                        name={"seller_address"}
                        rules={[
                            {
                                required: true,
                                message: t("requiredField"),
                            },
                        ]}
                        label={t("chooseSellerArea")}
                    >
                        <Select
                            id="seller_address"
                            style={{ width: "100%" }}
                            showSearch
                            optionFilterProp="children"
                            onChange={handleAddressSelect}
                        >
                            {addresses.map((address) => (
                                <Select.Option key={address.id} value={address.id}>
                                    {/* {address.special_name} */}
                                    {
                                        address.special_name
                                        ? address.special_name
                                        : currentLanguage === 'en'
                                        ? address.area.name
                                        : address.area.name_ar
                                    }
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            {selectedAddress && (
                <Row className="mt-4">
                    <Col sm={6}>
                        <Form.Item label={t("address")} name="address">
                            <Input
                                value={selectedAddress.address}
                                onChange={(e) =>
                                    setSelectedAddress({
                                        ...selectedAddress,
                                        address: e.target.value,
                                    })
                                }
                            />
                        </Form.Item>
                    </Col>

                    <Col sm={6}>
                        <Form.Item label={t("area")} name="area">
                            <Select
                                value={selectedArea ? selectedArea : selectedAddress.area.id}
                                onChange={(value) => setSelectedArea(value)}
                            >
                                {areas.map((area) => (
                                    <Select.Option key={area.id} value={area.id}>
                                        {i18n.language === "en" ? area.name : area.name_ar}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col sm={6}>
                        <Form.Item label={t("specialName")} name="special_name">
                            <Input
                                value={selectedAddress.special_name}
                                onChange={(e) =>
                                    setSelectedAddress({
                                        ...selectedAddress,
                                        special_name: e.target.value,
                                    })
                                }
                            />
                        </Form.Item>
                    </Col>

                    <Col sm={6}>
                        <Form.Item label={t("senderName")} name="sender_name">
                            <Input
                                value={selectedAddress.sender_name}
                                onChange={(e) =>
                                    setSelectedAddress({
                                        ...selectedAddress,
                                        sender_name: e.target.value,
                                    })
                                }
                            />
                        </Form.Item>
                    </Col>

                    <Col sm={6}>
                        <Form.Item label={t("phone")} name="store_phone">
                            <Input
                                value={selectedAddress.store_phone}
                                onChange={(e) =>
                                    setSelectedAddress({
                                        ...selectedAddress,
                                        store_phone: e.target.value,
                                    })
                                }
                            />
                        </Form.Item>
                    </Col>

                    <Col sm={12}>
                        <Button type="primary" className="confirm" onClick={handleEdit}>
                            {t("edit")}
                        </Button>
                    </Col>
                </Row>
            )}

            <Row className="mt-4">
                <Col className="text-end" sm="12">
                    <input className="confirm" value={t("proceed")} type="submit" />
                </Col>
            </Row>
        </Form>
    );
}
