import React, { useState } from "react";
import styles from "./ConfirmPayment.module.css";
import { Input, Modal } from "antd";
import WMButton from "../../../../../components/pages/AllWorkModels/WMButton";
import { useTranslation } from "react-i18next";
import axiosInstance, { BASE_URL } from "../../../../../api/axios";
import { useSelector } from "react-redux";
import ToastContext from "react-bootstrap/ToastContext";
import { toast, ToastContainer } from "react-toastify";
import useLanguage from "../../../../../hooks/useLanguage";

export default function ConfirmPayment({}) {
    const { t } = useTranslation();
    const [isCommenting, setIsCommenting] = useState(false);
    const [comment, setComment] = useState(undefined);
    const [open, setOpen] = useState(false);
    const paymentMethod = useSelector((state) => state.rsOrders.orders.payment);
    console.log({ paymentMethod });

    async function onSubmit() {
        const res = await axiosInstance
            .post(
                "SellerConfirmPayment/" + paymentMethod.id,
                !isCommenting && !comment
                    ? {
                          seller_confirmed: true,
                      }
                    : {
                          seller_confirmed: false,
                          seller_note: comment,
                      }
            )
            .catch((err) => toast.error(t("server_error")), {
                toastId: paymentMethod.id,
            });

        if (res.data) {
            toast.success(t("paymentMethodConfirm"), {
                toastId: paymentMethod.id,
            });
        }
    }

    const lang = useLanguage();
    return (
        <div>
            <ToastContainer
                position={lang.includes("en") ? "bottom-right" : "bottom-left"}
            />
            <WMButton onClick={() => setOpen(true)}>
                {t("confirmPayment")}
            </WMButton>
            <Modal open={open} onCancel={() => setOpen(false)}>
                <div
                    className={[
                        styles.container,
                        isCommenting ? styles.commenting : "",
                    ].join(" ")}
                >
                    <img
                        className={styles.invoiceImage}
                        src={paymentMethod?.payment_proof}
                    />
                    {isCommenting && (
                        <div className={styles.commentSection}>
                            <Input.TextArea
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            />
                        </div>
                    )}
                    <div className={styles.actions}>
                        {isCommenting ? (
                            <WMButton
                                className={styles.secondaryButton}
                                onClick={() => {
                                    setIsCommenting(false);
                                    setComment(undefined);
                                }}
                            >
                                {t("cancelComment")}
                            </WMButton>
                        ) : (
                            <WMButton
                                className={styles.secondaryButton}
                                onClick={() => setIsCommenting(true)}
                            >
                                {t("leaveComment")}
                            </WMButton>
                        )}

                        <WMButton
                            className={styles.confirmButton}
                            onClick={onSubmit}
                        >
                            {t("confirmPayment")}
                        </WMButton>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
