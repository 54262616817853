import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { Select, Form as AntForm, Input } from "antd";
import axiosInstance from "../../../../../api/axios";
import { useSelector, useDispatch } from "react-redux";
import { addOrder } from "../../../../../global-state/actions/addOrderAction";
import CreateNewProduct from "../../../../../components/modals/CreateNewProduct";
import { useTranslation } from "react-i18next";
import useLanguage from "../../../../../hooks/useLanguage";

export default function ProductInfoForm({ value, handleChange }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const order = useSelector((state) => state.order);
    const workModelFormItems =
        order.work_model_type === "1"
            ? [
                  {
                      label: t("weight"),
                      name: "weight",
                      getValue: (data) => data.weight,
                  },
              ]
            : [
                  {
                      label: t("length"),
                      name: "length",
                      getValue: (data) => data.length,
                  },
                  {
                      label: t("width"),
                      name: "width",
                      getValue: (data) => data.width,
                  },
                  {
                      label: t("height"),
                      name: "height",
                      getValue: (data) => data.height,
                  },
              ];
    const [products, setProducts] = useState([]);
    const [nextProducts, setNextProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(
        order?.tickets?.map((ticket) => parseInt(ticket.product)) ?? []
    );
    const [selectedLabel, setSelectedLabel] = useState(
        order?.tickets?.map((ticket) => parseInt(ticket.special_label)) ?? []
    );
    const [labels, setLabels] = useState([]);
    const [errMsg, setErrMsg] = useState("");
    const [labelErr, setLabelErr] = useState("");
    const [showCreate, setShowCreate] = useState(false);
    const [productPrice, setProductPrice] = useState([]);
    const [created, setCreated] = useState(true);

    console.log({ order });
    const [ticketData, setTicket] = useState(
        order?.tickets ?? [
            {
                width: undefined,
                height: undefined,
                weight: undefined,
                product: "",
                quantity: 1,
                special_label: "",
                price: undefined,
            },
        ]
    );

    useEffect(() => {
        console.log({ orderrrrrr: order.tickets });
        if (order?.tickets) setTicket([...order.tickets]);
    }, [value]);

    useEffect(() => {
        console.log(value);
    }, []);
    const user = useSelector((state) => state.auth.user);

    const selectedSeller = useSelector(
        (state) => state.selectedData.selectedSeller
    );
    const handleInput = (e, index) => {
        const { name, value } = e.target;
        const list = [...ticketData];
        list[index][name] = isNaN(value) ? value : +value;
        setTicket(list);
    };

    const handleSelectedProduct = (e, v, i) => {
        // const productPriceList = [...productPrice]
        // productPriceList[i] = v.price
        // setProductPrice(productPriceList)
        console.log(v);
        console.log(e);
        const list = [...ticketData];
        list[i].product = e;
        list[i].price = v?.price;
        let copy = [...selectedProduct];
        copy[i] = e;
        setSelectedProduct(copy);
    };

    useEffect(() => {
        console.log(productPrice);
        console.log(ticketData);
    }, [productPrice, ticketData]);

    const handleSelectedLabel = (e, i) => {
        const list = [...ticketData];
        list[i].special_label = e;
        let copy = [...selectedLabel];
        copy[i] = e;
        setSelectedLabel(copy);
    };

    const addAnotherProduct = () => {
        let productList = [...ticketData];
        setTicket([
            ...productList,
            {
                width: undefined,
                height: undefined,
                weight: undefined,
                product: "",
                quantity: 1,
                special_label: "",
                price: undefined,
            },
        ]);
    };

    const getProducts = async () => {
        let result = await axiosInstance.get(
            `/FilterProducts/?seller=${
                user.role === "Seller" ? user.user.user_id : order.seller?.id
            }`
        );
        if (result) {
            setProducts(result.data.data);
            setNextProducts(result.data.next);
        }
    };

    const getLabels = async () => {
        let result = await axiosInstance.get(`SpecialLabel/`);
        if (result) {
            setLabels(result.data.data);
        }
    };

    const validate = () => {
        if (selectedProduct !== "" && selectedLabel !== "") {
            // console.log(formState.isValid)
            const tickets = { tickets: ticketData };
            dispatch(addOrder(tickets));
            handleChange(null, parseInt(value) + 1);
        } else if (selectedProduct === "") {
            setErrMsg("This field is required");
        } else if (selectedLabel === "") {
            setLabelErr("This field is required");
        }
    };
    useEffect(() => {
        setErrMsg("");
    }, [selectedProduct]);
    useEffect(() => {
        setLabelErr("");
    }, [selectedLabel]);

    const previous = () => {
        handleChange(null, parseInt(value) - 1);
    };

    const cancelTicket = (e, i) => {
        console.log(i);
        console.log(ticketData);
        let ticketList = [...ticketData];

        ticketList.splice(i, 1);
        setTicket(ticketList);

        // let priceList = [...productPrice]

        // let newPrice = priceList.splice(i , 1)
        // setProductPrice(newPrice)
    };

    const openCreateModal = () => {
        setShowCreate(true);
        setCreated(false);
    };

    const hideCreateModal = (e) => {
        setShowCreate(false);
    };

    useEffect(() => {
        if (created) getProducts();
    }, [created]);
    // useEffect(() => {
    //     getLabels();
    // }, []);
    const lang = useLanguage();

    return (
        <>
            {ticketData.map((data, i) => {
                return (
                    <>
                        <Row>
                            <Col className="btnPosition" sm-12>
                                <button
                                    className="cancelBtn"
                                    disabled={ticketData.length === 1}
                                    onClick={(e) => cancelTicket(e, i)}
                                >
                                    {t("cancelTicket")}
                                </button>
                            </Col>
                        </Row>
                        <AntForm
                            layout="vertical"
                            className="grid-form small-gap"
                        >
                            <div className="position-relative text-area-form">
                                {user.role === "Seller" && (
                                    <a
                                        className={
                                            lang.includes("en")
                                                ? "over-select-link-en"
                                                : "over-select-link-ar"
                                        }
                                        onClick={openCreateModal}
                                    >
                                        {t("createNewProduct")}
                                    </a>
                                )}
                                <div>
                                    <AntForm.Item
                                        label={t("selectProduct")}
                                        name="product"
                                    >
                                        {<></>}
                                        <Select
                                            value={
                                                data.product ??
                                                selectedProduct[i]
                                            }
                                            onChange={(e, v) =>
                                                handleSelectedProduct(e, v, i)
                                            }
                                            allowClear
                                            className="form-control paginated"
                                            name="product"
                                        >
                                            <Select.Option
                                                selected
                                                hidden
                                                disabled
                                            >
                                                {lang === "en"
                                                    ? "Choose a product"
                                                    : "اختر المنتج"}
                                            </Select.Option>
                                            {products.map((product) => (
                                                <Select.Option
                                                    key={product.id}
                                                    name="product"
                                                    value={product.id}
                                                    price={product.price}
                                                >
                                                    {product.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </AntForm.Item>
                                </div>
                                <p className="error">
                                    {errMsg !== "" ? errMsg : null}
                                </p>
                            </div>
                            <div>
                                <AntForm.Item
                                    label={t("specialLabel")}
                                    name="label"
                                >
                                    {<></>}

                                    <Input
                                        onChange={(e) => handleInput(e, i)}
                                        value={data.special_label ?? ""}
                                        name="special_label"
                                    />
                                </AntForm.Item>
                                <p className="error">
                                    {labelErr !== "" ? labelErr : null}
                                </p>
                            </div>
                            <AntForm.Item label={t("quantity")} name="quantity">
                                {<></>}
                                <Input
                                    min={1}
                                    defaultValue={1}
                                    type="number"
                                    name="quantity"
                                    onChange={(e) => handleInput(e, i)}
                                    value={data.quantity ?? 1}
                                    className="formTextField text-area"
                                />
                            </AntForm.Item>

                            {workModelFormItems.map((it) => (
                                <AntForm.Item
                                    label={it.label}
                                    key={it.name}
                                    name={it.name}
                                >
                                    {<></>}
                                    <Input
                                        value={it.getValue(data)}
                                        type="number"
                                        name={it.name}
                                        onChange={(e) => handleInput(e, i)}
                                    />
                                </AntForm.Item>
                            ))}

                            <AntForm.Item label={t("price")} name="price">
                                {<></>}
                                <Input
                                    name="price"
                                    // onChange={(e) => handleInput(e, i)}
                                    readOnly
                                    value={data.price}
                                    className="formTextField text-area"
                                />
                            </AntForm.Item>
                        </AntForm>
                    </>
                );
            })}

            <Row className="mt-4">
                <Col sm="12">
                    <p onClick={addAnotherProduct} className="addBtn">
                        <i className="fas fa-plus-circle mx-2"></i>
                        {t("addAnotherProduct")}
                    </p>
                </Col>
            </Row>
            <Row>
                <Col className="text-end buttons-margin" sm="12">
                    <button className="previous" onClick={previous}>
                        {t("previous")}
                    </button>

                    <button className="confirm" onClick={validate}>
                        {t("proceed")}
                    </button>
                </Col>
            </Row>
            <CreateNewProduct
                setCreated={setCreated}
                showModal={showCreate}
                hideModal={hideCreateModal}
                useModal={true}
            />
        </>
    );
}
