import React from "react";
import PageHeader from "../../../../components/layout/PageHeader";
import { useTranslation } from "react-i18next";
import useLanguage from "../../../../hooks/useLanguage";

export default function Header({ courier }) {
    const { t } = useTranslation();
    const language = useLanguage();
    return (
        <PageHeader
            group={t("sideMenu.ticketScan")}
            title={t("sideMenu.ticketScanSession")}
            subLocations={
                courier
                    ? [
                          t("sideMenu.ticketScanSession"),
                          language.includes("en")
                              ? `${courier} RunSheet`
                              : `خط سير ${courier}`,
                      ]
                    : undefined
            }
        />
    );
}
