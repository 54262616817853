import React, { useState, useEffect, useCallback, useRef } from "react";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Row, Col, Dropdown } from "react-bootstrap";
import { Select } from "antd";
import { DataGrid } from "@mui/x-data-grid";
import {
    runsheetOrders,
    runsheetOrders_ar, usePostponedColumns,
    useRunSheetColumns,
} from "../layout/tableColumns";
import { postponedIds } from "../../global-state/actions/postponedOrdersIds";
import { postponedOrders } from "../../global-state/actions/postponedOrders";
import { Pagination } from "antd";
import { useDispatch } from "react-redux";
import { search as SearchAction } from "../../global-state/actions/searchAction";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";
import TablePro from "../tables/TablePro";

function PostponedOrders({ value, handleChange }) {
    const searchDispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(1);
    const [data, setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [changed, setChanged] = useState(false);
    const [orders, setOrders] = useState([]);
    const { t } = useTranslation();
    const columns = usePostponedColumns();
    const lang = useLanguage();
    const dispatch = useDispatch();
    const dispatchOrders = useDispatch();

    const searching = (e) => {
        //get the value of the search input copilot
        e.preventDefault();
        const v = e.target.elements.search?.value;
        console.log({ v });
        setSearch(v);
        // searchDispatch(SearchAction(v));
    };

    useEffect(() => {
        dispatchOrders(postponedOrders(selectedRecords));
        dispatch(postponedIds(selectedRows));
    }, [selectedRows]);

    async function fetchData() {
        let result = await axiosInstance.get(`/FilterOrder/`, {
            params: {
                page,
                search,
            },
        });
        if (result) {
            setData(result.data.data);
            setCount(result.data.count);
        }
    }

    useEffect(() => {
        if (search) fetchData();
    }, [page, search]);

    const proceed = () => {
        handleChange(null, value + 1);
    };

    return (
        <>
            <Row className="mt-3">
                <Col sm="12" md="6">
                    <div>
                        <form onSubmit={(e) => searching(e)}>
                            <div className="input-icons">
                                <i className="fa fa-search icon"></i>
                                <input
                                    name="search"
                                    className="searchInput"
                                    placeholder={t("searchOrderID")}
                                    type="text"
                                />
                            </div>
                        </form>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="text-end buttons-margin mb-3" sm="12">
                    <button
                        disabled={selectedRows.length === 0}
                        onClick={proceed}
                        className="confirm"
                    >
                        {t("next")}
                    </button>
                </Col>
            </Row>
            {data.length !== 0 ? (
                <TablePro
                    dataSource={data}
                    columns={columns}
                    selection={"checkbox"}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    setSelectedRecords={setSelectedRecords}
                    pagination={{
                        current: page,
                        total: count,
                        onChange: (page) => setPage(page),
                    }}
                />
            ) : (
                <div className="noDataDiv1">
                    <h6 className="noDataHead">{t("postponedTxt1")}</h6>
                    <p>{t("postponedTxt2")}</p>
                </div>
            )}
        </>
    );
}

export default ErrorHandler(PostponedOrders, axiosInstance);
