import React from "react";
import { Row } from "react-bootstrap";
import { Button } from "antd";
import RegisterLayout from "./register_layout";

const SellerType = ({ nextStep }) => {
    return (
        <RegisterLayout>
            <Row className="mb-3 mt-3" style={{ display: "flex" }}>
                <Button className="confirm" onClick={() => nextStep({ flag: 1 })} style={{ flex: 1, minWidth: "350px", height: "auto", padding: "15px", fontSize: "18px"  }}>
                    بائع / تاجر<br />( امتلك متجر إلكتروني ، مؤسسة ، شركة )
                </Button>
            </Row>
            <Row className="mb-5" style={{ display: "flex" }}>
                <Button className="confirm" onClick={() => nextStep({ flag: 2 })} style={{ flex: 1, minWidth: "350px", height: "auto", padding: "15px"  , fontSize: "18px" }}>
                    فرد ( شحن شخصي )
                </Button>
            </Row>
        </RegisterLayout>
    );
};

export default SellerType;
