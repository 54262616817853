import React, { useState, useEffect } from "react";
import { UploadMajor } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import {BASE_URL} from "../../api/axios"
function Test() {
  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);
  const [errors , setErrors] = useState([])

  const fileHandler = async(event) => {
    let fileObj = event.target.files[0];
    //just pass the fileObj as parameter
    let formdata = new FormData()
    formdata.append("file",fileObj)
    let result = await axiosInstance.post("/ImportOrders/" , formdata).catch(err => {
      console.log(Object.entries(err.response.data))
      console.log(Object.values(err.response.data))

      setErrors(Object.entries(err.response.data))

    })
    if(result){
     console.log("good")
    }
    event.target.value=null
  };

  const getTemplate = async ()=>{
      let result = await axiosInstance.get("/media/order_bulk.xlsx")
      
  }
  return (
    <>
        <a className="confirm" href={`${BASE_URL}/media/order_bulk.xlsx`} target="_blank">Get Tmplate</a>
      <div className="uploaderCard">
        <Icon source={UploadMajor} color="base" />
        <h6>
          {"Drop your file here\n or Click to "}
          <span>Browse</span>
        </h6>
        <input type="file" className="uploaderInput" onChange={fileHandler} />
      </div>
      {errors.length > 0 ?  <table>
        <tr>
         {errors.map(error => (
           <>
           <th>Order Number</th>
           {error.map(header => (
             <th></th>
           ))}
           </>
         ))}
        </tr>
      </table> : null}
     
     
    </>
  );
}

export default ErrorHandler(Test,axiosInstance);
