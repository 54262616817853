import { mdiDotsHorizontal } from "@mdi/js";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@mdi/react";
import ErrorHandler from "../../error-handler/ErrorHandler";
import React, { useState, useEffect } from "react";
import axiosInstance from "../../api/axios";
import { ConfirmDelete } from "../modals/confirmationModal";
import { useTranslation } from "react-i18next";
import { UnSuccessfulRequest } from "../modals/confirmationModal";
function ThreeDotsMenu({
    data,
    deleted,
    setDeleted,
    setEdited,
    onClick,
    link,
    getData,
}) {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState(null);
    const [showDelete, setShowDelete] = useState(false);
    const [error, setError] = useState("");
    const [showFailed, setShowFailed] = useState(false);
    const [loading, setLoading] = useState(false);

    const deleteEntity = async () => {
        setLoading(true);
        if (data.id === 1 && link === "WorkModel") {
            setError(`${t("deleteMain")}`);
            setShowFailed(true);
            setLoading(false);
        } else {
            let result = await axiosInstance
                .delete(`/${link}/${data.id}`)
                .catch((err) => {
                    setError(err.response.data);
                    setShowFailed(true);
                    setShowDelete(false);
                    setLoading(false);
                });
            if (result) {
                setDeleted(!deleted);
                setShowDelete(false);
                setLoading(false);
                getData();
            }
        }
    };

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
        if (onClick) {
            onClick();
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const hide = () => {
        setShowDelete(false);
        setShowFailed(false);
    };

    return (
        <div className="actionMenu">
            <Button onClick={handleClick}>
                <Icon path={mdiDotsHorizontal} size={1.2} />
            </Button>
            <Menu
                id="card-actions-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        setEdited(true);
                        handleClose();
                    }}
                >
                    <i className="fas fa-pen my-3 mx-2"></i>
                    {t("edit")}
                </MenuItem>
                {/*{deleted && (*/}
                {/*    <MenuItem*/}
                {/*        onClick={() => {*/}
                {/*            setShowDelete(true);*/}
                {/*            handleClose();*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <i className="fas fa-trash my-3 mx-2"></i>*/}
                {/*        {t("delete")}*/}
                {/*    </MenuItem>*/}
                {/*)}*/}
            </Menu>
            <ConfirmDelete
                hide={hide}
                deleteEntity={deleteEntity}
                title={t("deleteProcess")}
                body={t("deleteText")}
                show={showDelete}
                loading={loading}
            />
            <UnSuccessfulRequest
                title={t("deleteProcess")}
                show={showFailed}
                hide={hide}
                body={error}
            />
        </div>
    );
}

export default ErrorHandler(ThreeDotsMenu, axiosInstance);
