import { Input } from "antd";

export default function DetailsTab({ items }) {
    console.log({ items });
    return (
        <div className={"grid-form"}>
            {items.map((it, i) =>
                it.hidden ? null : (
                    <div key={i}>
                        <label
                            style={{
                                marginBottom: "8px",
                            }}
                        >
                            {it.label}
                        </label>
                        <Input value={it.value} disabled />
                    </div>
                )
            )}
        </div>
    );
}
