import React, {useEffect, useState} from "react";
import styles from "./StoreReportsPage.module.css";
import CommonReportsPage from "../CommonReportsPage";
import {
    branchReportsTypes,
    storeReportsTypes,
} from "../../../../api/ReportsRepository";
import axiosInstance from "../../../../api/axios";
import {useTranslation} from "react-i18next";
import { useSelector } from "react-redux";

export default function StoreReportsPage({}) {
    const user = useSelector((state) => state.auth);
    const [selectedType, setSelectedType] = useState();
    const [sellers, setSellers] = useState([]);
    const {t} = useTranslation();

    // Filter the storeReportsTypes based on the user's role
let filteredReportTypes = Object.values(storeReportsTypes);
if (user && user.user.role === "Accountant" || user.user.role === "Operation Team Member") {
    filteredReportTypes = filteredReportTypes.filter((reportType, index) => index === 2 || index === 3);
}
    const fields = [
        // {
        //     multiple: selectedType?.title !== "financial",
        //     name: "seller",
        //     label: t("reports.store_name"),
        //     type: "select",
        //     search: true,
        //     placeholder: t("reports.store_name_ph"),
        //     rules: [{required: true, message: t("reports.store_name_req")}],
        //     options: sellers.map((it) => (
        //         <option key={it.id} value={it.id}>
        //             {it.store_name}
        //         </option>
        //     )),
        // },
        // {
        //     name: "type",
        //     label: t("reports.report_type"),
        //     type: "select",
        //     placeholder: t("reports.report_type_ph"),
        //     rules: [{required: true, message: t("reports.report_type_req")}],
        //     onChange: (v) => setSelectedType(storeReportsTypes[v]),
        //     options: filteredReportTypes.map((reportType, index) => (
        //         <option key={index}>
        //             {t(`reports.store_list.${reportType.title}`)}
        //         </option>
        //     )),
        // },

        {
            multiple: selectedType?.title !== "financial",
            name: "seller",
            label: t("reports.store_name"),
            type: "select",
            search: true,
            placeholder: t("reports.store_name_ph"),
            rules: [{ required: true, message: t("reports.store_name_req") }],
            options: sellers.map((it) => (
                <option key={it.id} value={it.id}>
                    {it.store_name}
                </option>
            )),
        },
        {
            name: "type",
            label: t("reports.report_type"),
            type: "select",
            placeholder: t("reports.report_type_ph"),
            rules: [{ required: true, message: t("reports.report_type_req") }],
            onChange: (v) => setSelectedType(storeReportsTypes[v]),
            options: filteredReportTypes.map((reportType) => (
                <option key={reportType.title} value={reportType.title}>
                    {t(`reports.store_list.${reportType.title}`)}
                </option>
            )),
        },
    ];

    async function fetchSellers() {
        const res = await axiosInstance
            .get("/Seller/?page_size=10000")
            .catch((err) => console.log({err}));
        if (res?.data) {
            setSellers(res.data?.data ?? []);
        }
    }

    useEffect(() => {
        fetchSellers();
    }, []);
    return (
        <CommonReportsPage
            formFields={fields}
            selectedType={selectedType}
            section={t("reports.store_reports")}
        />
    );
}
