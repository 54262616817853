import React, { useState, useEffect, useCallback, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import "../../../../../style-sheets/table.css";
import axiosInstance from "../../../../../api/axios";
import ErrorHandler from "../../../../../error-handler/ErrorHandler";
import {
    productInfo,
    productInfo_ar,
    useTicketsColumns,
} from "../../../../../components/layout/tableColumns";
import { useDispatch, useSelector } from "react-redux";
import {
    getSeller,
    getTicketsReturned,
} from "../../../../../global-state/actions/getSelectedAction";
import { useTranslation } from "react-i18next";
import useLanguage from "../../../../../hooks/useLanguage";
import TablePro from "../../../../../components/tables/TablePro";
import { addOrder } from "../../../../../global-state/actions/addOrderAction";

const ReplacedTickets = (props) => {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [keyword, setKeyword] = useState("");
    const [isSending, setIsSending] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [changed, setChanged] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState([]);
    const [page, setPage] = useState(1);
    const [tickets, setTickets] = useState([]);
    const [orderIDS, setOrderids] = useState([]);
    const [loading, setLoading] = useState(false);
    const prevSelectionModel = useRef(selectionModel);
    const prevTickets = useRef(tickets);
    const orderSellerDispatch = useDispatch();
    const selectedTickets = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const [order, setOrder] = useState({});
    const getData = async (id) => {
        setLoading(true);
        const result = await axiosInstance
            .get(`/Order/${id}?page_size=1000000`)
            .catch((err) => {
                setLoading(false);
            });

        if (result) {
            setOrder(result.data);
            setData(result.data?.tickets?.data ?? []);
        }
        setLoading(false);
    };

    const searching = (e) => {
        setKeyword(e.target.value);
    };
    const sendRequest = useCallback(
        async (e) => {
            if (e.key == "Enter") {
                getData();
            }
        },
        [isSending, keyword]
    );

    const proceed = () => {
        // console.log(selectedTicket);
        if (selectedTicket.length !== 0) {
            const tickets = data.filter((it) => selectedTicket.includes(it.id));
            orderSellerDispatch(
                addOrder({
                    id: order.id,
                    seller: tickets[0].order.seller,
                    return_tickets: tickets,
                })
            );
            props.handleChange(null, props.value + 1);
            // orderSellerDispatch(getSeller(selectedTicket[0].order.seller));
        }
    };
    const { t } = useTranslation();
    const lang = useLanguage();

    const columns = useTicketsColumns().splice(1);
    return (
        <div>
            <Container>
                <Row>
                    <div>
                        <TablePro
                            loading={loading}
                            searchPlaceholder={t("replaceTxt1")}
                            emptyPlaceholder={
                                <div className="noDataDiv">
                                    <h6 className="noDataHead">
                                        {t("replaceTxt2")}
                                    </h6>
                                    <p>{t("replaceTxt3")}</p>
                                </div>
                            }
                            dataSource={data}
                            columns={columns}
                            selectedRows={selectedTicket}
                            setSelectedRows={setSelectedTicket}
                            onSearch={(v) => getData(v)}
                            selection={"checkbox"}
                        />
                    </div>
                </Row>
                <Row>
                    <Col className="text-end mt-2" sm="12">
                        <button
                            disabled={
                                selectedTicket.length !== 0 ? false : true
                            }
                            className={
                                user.role == "Seller"
                                    ? "confirmSeller"
                                    : "confirm"
                            }
                            onClick={proceed}
                            type="submit"
                        >
                            {t("proceed")}
                        </button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ErrorHandler(ReplacedTickets, axiosInstance);
