import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
//redux
import allReducers from "./global-state/reducers/rootReducer";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import promiseMiddleware from "redux-promise";
/* LocalStorage Functions */
import { loadState } from "./global-state/LocalStorage";
import { saveState } from "./global-state/LocalStorage";
import i18n from "./resourses/i18n";
import "./resourses/Localization";
import "./i18next";
import { ConfigProvider } from "antd";

const persistedState = loadState();
const middleware = [thunk];
const store = createStore(
    allReducers,

    persistedState,
    composeWithDevTools(applyMiddleware(promiseMiddleware, ...middleware))
);

store.subscribe(() => {
    saveState({
        auth: store.getState().auth,
        reAssignedRs: store.getState().reAssignedRs,
        isReassigned: store.getState().isReassigned,
        selectedUser: store.getState().selectedUser,
        ticketPrintedData: store.getState().ticketPrintedData,
        plan: store.getState().plan,
        dimention: store.getState().dimention,
    });
});

i18n.on("initialized", (options) => {
    console.log({ options: options.lang });
    ReactDOM.render(
        <React.StrictMode>
            {/* <Suspense fallback="loading"> */}
            <Provider store={store}>
                <App />
            </Provider>
            {/* </Suspense> */}
        </React.StrictMode>,
        document.getElementById("root")
    );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
