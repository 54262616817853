import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {  Input, Button, Modal, notification } from "antd";
import { Row, Col } from "react-bootstrap";
import axiosInstance from "../../../api/axios";
import { useState, useEffect } from "react";
function WalletSummary({ summary  , id , is_superuser , fetchWallet}) {
    const { t } = useTranslation();
    const [amount, setAmount] = useState(0);
    const [reason, setReason] = useState('');
    const [confirmVisible, setConfirmVisible] = useState(false);
    const handleDeductBalance = async () => {
        setConfirmVisible(true); // Show the confirmation popup
    };
    const cancelProceed = () => {
        setConfirmVisible(false); // Close the confirmation popup
    };
    const currency = useSelector(
        (state) => state.auth.user?.user?.organization?.country?.currency
    );
    const confirmProceed  = async () => {
        try {
            const response = await axiosInstance.post(`/deduct-balance/${id}/`, { amount, deduct_reason: reason });
            notification.success({
                message: 'Success',
                description: response.data.message,
            });
            fetchWallet();
            setConfirmVisible(false); // Close the confirmation popup
        } catch (error) {
            console.error('Error deducting balance:', error.response.data.error);
            notification.error({
                message: 'Error',
                description: error.response.data.error || 'Failed to deduct balance.',
            });
            setConfirmVisible(false); // Close the confirmation popup
        }
    };
    return (
        <div className="px-3 rounded">
            <div className="d-flex justify-content-around align-items-center flex-wrap">
            <div className="d-flex flex-column my-2 shadow px-5 py-4 rounded">
    <div className="d-flex flex-row align-items-center mb-3">
        <div className="bg-primary rounded-circle p-3 me-4">
            <i className="fas fa-wallet text-white"></i>
        </div>
        <div className="flex-grow-1">
            <h6 className="text-muted">
                {t("sideMenu.totalBalance")}
            </h6>
            <h3 className="font-weight-bold m-0">
                {t(currency)} {summary?.balance ?? 0}
            </h3>
        </div>
    </div>
    <hr className="w-100" />
    {is_superuser && (
        <div className="ms-4 mt-3">
            <Row className="g-2">
                <Col>
                    <Input
                        type="number"
                        placeholder={t('deduct_placeholder')}
                        value={amount}
                        onChange={(e) => setAmount(parseFloat(e.target.value))}
                    />
                    <Input
                        type="text"
                        placeholder={t('deduct_reason_placeholder')}
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        style={{ marginTop: '3px' }}
                        />
                </Col>
                <Col>
                    <Button type="primary" onClick={handleDeductBalance}>{t('deduct_btn')}</Button>
                </Col>
            </Row>
        </div>
    )}
</div>

                <div className="d-flex my-2 flex-column align-items-center justify-content-around shadow px-5 py-4 rounded">
                    <div className="d-flex align-items-center">
                        <div className="bg-success rounded-circle p-3 me-5">
                            <i className="fas fa-money-bill-alt text-white"></i>
                        </div>
                        <div>
                            <h6 className="text-muted">
                                {t("sideMenu.rechargeTotal")}
                            </h6>
                            <h3 className="font-weight-bold">
                                {t(currency)} {summary?.total_transactions ?? 0}
                            </h3>
                        </div>
                        
                    </div>
                    <hr className="w-100" />
                    <div>
                        <h6 className="text-muted">
                            {t("sideMenu.rechargeCount")}
                        </h6>
                        <h3 className="font-weight-bold mb-0 text-center">
                            {summary?.count_transactions ?? 0}
                        </h3>
                    </div>
                </div>
                <div className="d-flex my-2 flex-column align-items-center shadow px-5 py-4 rounded">
                    <div className="d-flex align-items-center">
                        <div className="bg-danger rounded-circle p-3 me-5">
                            <i className="fas fa-money-bill-alt text-white"></i>
                        </div>
                        <div>
                            <h6 className="text-muted">
                                {t("sideMenu.shipmentTotal")}
                            </h6>
                            <h3 className="font-weight-bold">
                                {t(currency)} {summary?.total_orders ?? 0}
                            </h3>
                        </div>
                    </div>
                    <hr className="w-100" />
                    <div>
                        <h6 className="text-muted">
                            {t("sideMenu.shipmentCount")}
                        </h6>
                        <h3 className="font-weight-bold text-center mb-0">
                            {summary?.count_orders ?? 0}
                        </h3>
                    </div>
                </div>
                <div className="d-flex my-2 flex-column align-items-center shadow px-5 py-4 rounded">
                    <div className="d-flex align-items-center">
                        <div className="bg-info rounded-circle p-3 me-5">
                            <i className="fas fa-money-bill-alt text-white"></i>
                        </div>
                        <div>
                            <h6 className="text-muted">
                                {t("sideMenu.refundTotal")}
                            </h6>
                            <h3 className="font-weight-bold">
                                {t(currency)} {summary?.total_refund_requests ?? 0}
                            </h3>
                        </div>
                    </div>
                    <hr className="w-100" />
                    <div>
                        <h6 className="text-muted">
                            {t("sideMenu.refundsCount")}
                        </h6>
                        <h3 className="font-weight-bold text-center mb-0">
                            {summary?.count_refund_requests ?? 0}
                        </h3>
                    </div>
                </div>
            </div>
            <Modal
                title={t('deduct_confirm')}
                visible={confirmVisible}
                onOk={confirmProceed}
                onCancel={cancelProceed}
               
            >
                <p style={{ marginTop: '20px', marginBottom: '50px' , textAlign: 'center'}}>{`هل تريد خصم مبلغ ${amount} ؟`}</p>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Button type="primary" onClick={confirmProceed}>{t('deduct_btn')}</Button>
                    <Button type="primary" danger onClick={cancelProceed}>{t('cancel')}</Button>
                </div>
            </Modal>
        </div>
    );
}

export default WalletSummary;
