import { POSTPONE_DATE } from './actionTypes'

export const postponeDate = (info) => {
    return {
        type: POSTPONE_DATE,
        payload: info
    }

}

