import React from "react";
import { Col, Row } from "react-bootstrap";
import { Slider, Spin } from "antd";
import StatusCard from "../../../../components/layout/StatusCard";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import useLanguage from "../../../../hooks/useLanguage";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import axiosInstance from "../../../../api/axios";
export default function CardsHeader({
    loading,
    status,
    currentStateTitle,
    selectState,
    plan,
}) {
    const { t } = useTranslation();
    const lang = useLanguage();
    const user = useSelector((state) => state.auth.user);

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const contactSupport = () => {
        document
            .getElementsByClassName("b24-widget-button-inner-block")[0]
            .click();
    };

    const borderStyle = lang === 'ar' ? 'borderLeft' : 'borderRight';
    const marketerID = useSelector((state) => state.auth.user.user.id);
    const role = useSelector((state) => state.auth.user.role);
    const [clients, setClients] = useState([]);
    const [orders, setOrders] = useState([]);

    const getCommissionSellers = async () => {
        try {
          let result = await axiosInstance.get(`/marketer-sellers/${marketerID}/`);
          if (result && result.data) {
            setClients(result.data); // Set the response data to the state variable
            console.log("clients", clients);
            console.log(marketerID);
          }
        } catch (err) {
          console.log(err);
        }
      };
      const getCommissionOrders = async () => {
        try {
          let result = await axiosInstance.get(`/marketer-orders/${marketerID}/`);
          if (result && result.data) {
            setOrders(result.data); // Set the response data to the state variable
            console.log("orders", orders);
            console.log(marketerID);
          }
        } catch (err) {
          console.log(err);
        }
      };


 

      useEffect(() => {
        getCommissionSellers();
        getCommissionOrders();
      console.log("@@@@@@@@@@", orders)
      }, [marketerID]);


      const history = useHistory();

      const handleOnClick = () => {
        
        console.log("@@@@@@@@@@", orders)
        history.push('/sellers-stores');
      };

      const handleOnClick1 = () => {
    
        // Navigate to the new component and pass the seller stores data
        history.push('/marketer_wallet');
      };
    // customize order for status card mapping
    const orderedIds = [3, 4, 5,8,7, 1 , 9]; // Define the order of state.state.id values

    const sortedStatus = status.sort((a, b) => {
    const idA = a.state.id;// Get the state ID of the first object
    const idB = b.state.id;// Get the state ID of the second object
     // Find the index of the state IDs in the orderedIds array
    const indexA = orderedIds.indexOf(idA);
    const indexB = orderedIds.indexOf(idB);
    // Compare the indices to sort the objects based on the custom order
    return indexA - indexB;
    });

    return (
        <header>
            <Row style={{marginTop:"50px" , marginBottom:'100px'}}>
                <Col className="first-step" lg="12">
                    {role === "Marketer" ? 
                        <Row>
                        {loading ? (
                            <div className="spinnerAnt">
                                <Spin indicator={antIcon} />
                            </div>
                        ) :   
                        <Col sm="12"md="4">
                        <StatusCard
                            
                            
                            description={
                                t("clients")
                            }
                            onClick={ handleOnClick}
                            type={
                                t("clients")
                            }
                            number={clients.sellers_with_same_code}
                            styleClass={"statusCard"}
                            background={"lightgrey"}
                            style={{
                               [borderStyle]: `solid 20px Black`,
                            }}
                        />
                        <StatusCard
                            
                            
                            description={
                               
                                t("orderStep")
                            }
                            onClick={ handleOnClick1}
                            type={
                                t("orderStep")
                            }
                            number={orders.number_of_orders}
                            styleClass={"statusCard"}
                            background={"lightgrey"}
                            style={{
                               [borderStyle]: `solid 20px Black`,
                            }}
                        />
                    </Col>
                        
                    
                    }
                    </Row>
                    
                    : 

                    <Row>
                        {loading ? (
                            <div className="spinnerAnt">
                                <Spin indicator={antIcon} />
                            </div>
                        ) : status.length > 0 ? (
                            sortedStatus.filter((state) => ![2, 6, ].includes(state.state.id)).map((state, index) => {
                                return (
                                    <Col
                                        key={state.state.id}
                                        // onClick={() =>
                                        // }
                                        sm="12"
                                        md="4"
                                    >
                                        <StatusCard
                                            key={state.state.id}
                                            // selectedCard={currentStateTitle}
                                            description={
                                                state.state.description
                                            }
                                            onClick={() =>{
                                                selectState(
                                                    state,
                                                    status[index + 1]
                                                )

                                            }}
                                            type={
                                                lang === "ar"
                                                    ? state.state.title_ar
                                                    : state.state.title
                                            }
                                            number={state.count}
                                            styleClass={"statusCard"}
                                            background={`${state.state.accent_color}`}
                                            style={{
                                               [borderStyle]: `solid 20px ${state.state.color}`,
                                            }}
                                        />
                                    </Col>
                                );
                            })
                        ) : (
                            <div className="d-flex flex-row justify-content-center align-items-center text-center my-4">
                                <h3>
                                    <strong>{t("noOrders")}</strong>
                                </h3>
                            </div>
                        )}
                    </Row>}
                </Col>
                {/*<Col lg="4">*/}
                    {/*{user.role !== "Seller" ? (*/}
                    {/*    <Row className="statusCard p-3 my-4">*/}
                    {/*        <p className="plan-header">{t("packageUsage")}</p>*/}
                    {/*        <p className="plan-name">*/}
                    {/*            {plan?.plan?.name} {t("plan")}*/}
                    {/*        </p>*/}
                    {/*        <Row className="orders-row">*/}
                    {/*            <Col className="orders-count" sm="12">*/}
                    {/*                <p>{t("orderPackage")}</p>*/}
                    {/*                <span>*/}
                    {/*                    {plan?.orders_count} {t("outOf")}{" "}*/}
                    {/*                    {plan?.orders_remaining}*/}
                    {/*                </span>*/}
                    {/*            </Col>*/}
                    {/*        </Row>*/}
                    {/*        <Slider*/}
                    {/*            value={plan?.orders_count}*/}
                    {/*            disabled*/}
                    {/*            max={plan?.orders_remaining}*/}
                    {/*        />*/}
                    {/*        <Link style={{ padding: "0px" }} to="/payments">*/}
                    {/*            <p style={{ marginBottom: "0px" }}>*/}
                    {/*                {t("payments")}*/}
                    {/*            </p>*/}
                    {/*        </Link>*/}
                    {/*    </Row>*/}
                    {/*) : null}*/}

                    {/*<Row*/}
                    {/*    className="statusCard p-3 my-4"*/}
                    {/*    style={{ cursor: "unset" }}*/}
                    {/*>*/}
                    {/*    <Row>*/}
                    {/*        <h3>*/}
                    {/*            <strong>{t("needHelp")}</strong>*/}
                    {/*        </h3>*/}
                    {/*    </Row>*/}

                    {/*    <Row className="mt-4">*/}
                    {/*        <Col lg="1" className="me-2">*/}
                    {/*            <i className="fas fa-comment-dots fs-4 side-icon"></i>*/}
                    {/*        </Col>*/}

                    {/*        <Col>*/}
                    {/*            <a*/}
                    {/*                style={{ color: "black" }}*/}
                    {/*                target="_blank"*/}
                    {/*                href="https://box.sa/en/contactus"*/}
                    {/*                rel="noreferrer"*/}
                    {/*            >*/}
                    {/*                <Col*/}
                    {/*                    // onClick={contactSupport}*/}
                    {/*                    style={{ cursor: "pointer" }}*/}
                    {/*                >*/}
                    {/*                    <strong>{t("contactSupport1")}</strong>*/}
                    {/*                    <div className="desc-sm my-2">*/}
                    {/*                        {t("contactSupport2")}*/}
                    {/*                    </div>*/}
                    {/*                </Col>*/}
                    {/*            </a>*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}

                    {/*    <Row className="my-2">*/}
                    {/*        <hr />*/}
                    {/*    </Row>*/}
                    {/*    <Row>*/}
                    {/*        <Col lg="1" className="me-2">*/}
                    {/*            <i className="fas fa-question-circle fs-4 side-icon"></i>*/}
                    {/*        </Col>*/}

                    {/*        <Col>*/}
                    {/*            <a*/}
                    {/*                style={{ color: "black" }}*/}
                    {/*                target="_blank"*/}
                    {/*                href="https://lace-booth-6cf.notion.site/427d045aa04a4b28ae4aad271700c2bb"*/}
                    {/*                rel="noreferrer"*/}
                    {/*            >*/}
                    {/*                <strong>{t("helpCenter1")}</strong>*/}
                    {/*            </a>*/}
                    {/*            <div className="desc-sm my-2">*/}
                    {/*                {t("helpCenter2")}*/}
                    {/*            </div>*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*</Row>*/}
               {/* </Col> */}
            </Row>
        </header>
    );
}
