import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import {
    Select,
    Form as AntForm,
    Input,
    AutoComplete,
    InputNumber,
    Checkbox
} from "antd";
import axiosInstance from "../../../../../api/axios";
import { useSelector, useDispatch } from "react-redux";
import { addOrder } from "../../../../../global-state/actions/addOrderAction";
import CreateNewProduct from "../../../../../components/modals/CreateNewProduct";
import { useTranslation } from "react-i18next";
import useLanguage from "../../../../../hooks/useLanguage";

export default function ProductInfoForm({ value, handleChange }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const order = useSelector((state) => state.order);
    console.log({ order });
    const workModelFormItems =
        order.work_model_type === "1"
            ? [
                  {
                      label: t("weight"),
                      name: "weight",
                      getValue: (data) => data.weight,
                  },
              ]
            : [
                  {
                      label: t("weight"),
                      name: "weight",
                      max: 15,
                      min: 1,
                      getValue: (data) => data.weight,
                  },
                  // {
                  //     label: t("length"),
                  //     name: "length",
                  //     getValue: (data) => data.length,
                  // },
                  // {
                  //     label: t("width"),
                  //     name: "width",
                  //     getValue: (data) => data.width,
                  // },
                  // {
                  //     label: t("height"),
                  //     name: "height",
                  //     getValue: (data) => data.height,
                  // },
              ];
    const [products, setProducts] = useState([]);
    const [nextProducts, setNextProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(
        order?.tickets?.map((ticket) => parseInt(ticket.product)) ?? []
    );
    const [selectedLabel, setSelectedLabel] = useState(
        order?.tickets?.map((ticket) => parseInt(ticket.special_label)) ?? []
    );

    const [labels, setLabels] = useState([]);
    const [errMsg, setErrMsg] = useState("");
    const [labelErr, setLabelErr] = useState("");
    const [showCreate, setShowCreate] = useState(false);
    const [productPrice, setProductPrice] = useState([]);
    const [created, setCreated] = useState(true);
    console.log({ order });
    const [ticketData, setTicket] = useState(
        order?.tickets?.length > 0
            ? order?.tickets
            : [
                  {
                      width: 1,
                      height: 1,
                      length: 1,
                      weight: undefined,
                      product: "",
                      quantity: 1,
                      special_label: "false",
                      price: undefined,
                  },
              ]
    );
    const [checked, setChecked] = useState(false); // State to track checkbox status

    useEffect(() => {
        console.log(value);
    }, []);
    const user = useSelector((state) => state.auth.user);

    const selectedSeller = useSelector(
        (state) => state.selectedData.selectedSeller
    );
    const handleInput = (e, index) => {
        const { name, value } = e.target;
        const list = [...ticketData];

        list[index][name] = isNaN(value) ? value : +value;
        setTicket(list);
    };

    const createProduct = async (name) => {
        let data = {
            seller: order.seller,
            price: 0,
            name,
            description: " ",
        };
        console.log(data);
        let result = await axiosInstance.post(`/SavedProduct/`, data);
        if (result.data) return result.data.data;
        else return {};
    };

    const [selectedProductName, setSelectedProductName] = useState(
        order.tickets?.[0]?.product_name ?? ""
    );
    const handleSelectedProduct = async () => {
        let product = products.find(
            (it) => it.name.toLowerCase() === selectedProductName.toLowerCase()
        );
        console.log({ product });
        if (!product) {
            product = await createProduct(selectedProductName);
        }
        return product.id;
    };

    useEffect(() => {
        console.log(productPrice);
        console.log(ticketData);
    }, [productPrice, ticketData]);

    const handleSelectedLabel = (e, i) => {
        const list = [...ticketData];
        list[i].special_label = e;
        let copy = [...selectedLabel];
        copy[i] = e;
        setSelectedLabel(copy);
    };

    const addAnotherProduct = () => {
        let productList = [...ticketData];
        setTicket([
            ...productList,
            {
                width: 1,
                height: 1,
                length: 1,

                weight: undefined,
                product: "",
                quantity: 1,
                special_label: "false",
                price: undefined,
            },
        ]);
    };

    const getProducts = async (name = null) => {
        let result = await axiosInstance.get(
            `/FilterProducts/?seller=${
                user.role === "Seller" ? user.user.user_id : selectedSeller.id
            }&name=${name ?? ""}`
        );
        if (result) {
            setProducts(result.data.data);
            setNextProducts(result.data.next);
        }
    };

    const getLabels = async () => {
        let result = await axiosInstance.get(`SpecialLabel/`);
        if (result) {
            setLabels(result.data.data);
        }
    };

    const validate = async () => {
        if (!checked) {
            setErrMsg("يجب الموافقة على شروط الوزن والأبعاد");
            return;
        }
        if (selectedProduct !== "" && selectedLabel !== "" ) {
            // console.log(formState.isValid)
            const id = await handleSelectedProduct();
            const tickets = {
                tickets: [
                    {
                        ...ticketData[0],
                        product: id,
                        product_name: selectedProductName,
                    },
                ],
            };
            dispatch(addOrder(tickets));
            handleChange(null, parseInt(value) + 1);
        } else if (selectedProduct === "") {
            setErrMsg("This field is required");
        } else if (selectedLabel === "") {
            setLabelErr("This field is required");
        }
    };
    useEffect(() => {
        setErrMsg("");
    }, [selectedProduct]);
    useEffect(() => {
        setLabelErr("");
    }, [selectedLabel]);

    const previous = () => {
        handleChange(null, parseInt(value) - 1);
    };

    const cancelTicket = (e, i) => {
        console.log(i);
        console.log(ticketData);
        let ticketList = [...ticketData];

        ticketList.splice(i, 1);
        setTicket(ticketList);

        // let priceList = [...productPrice]

        // let newPrice = priceList.splice(i , 1)
        // setProductPrice(newPrice)
    };

    const openCreateModal = () => {
        setShowCreate(true);
        setCreated(false);
    };

    const hideCreateModal = (e) => {
        setShowCreate(false);
    };

    useEffect(() => {
        if (created) getProducts();
    }, [created]);

    const lang = useLanguage();
    const options = products.map((product) => ({
        value: product.name,
        label: product.name,
    }));
    const [form] = AntForm.useForm();

    useEffect(() => {
        const data = ticketData[0] ?? {};
        form.setFieldsValue({
            product: selectedProductName,
            special_label: data.special_label,
            quantity: data.quantity,
            width: data.width,
            length: data.length,
            height: data.height,
            weight: data.weight,
        });
    }, []);
    return (
        <>
            {ticketData.map((data, i) => {
                return (
                    <>
                        {/*<Row>*/}
                        {/*    <Col className="btnPosition" sm-12>*/}
                        {/*        <button*/}
                        {/*            className="cancelBtn"*/}
                        {/*            disabled={ticketData.length === 1}*/}
                        {/*            onClick={(e) => cancelTicket(e, i)}*/}
                        {/*        >*/}
                        {/*            {t("cancelTicket")}*/}
                        {/*        </button>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        <AntForm
                            form={form}
                            onFinish={validate}
                            layout="vertical"
                            className="grid-form small-gap"
                        >
                            <div className="position-relative text-area-form">
                                {/* {user.role === "Seller" && ( */}
                                {/*<a*/}
                                {/*    className={*/}
                                {/*        lang.includes("en")*/}
                                {/*            ? "over-select-link-en"*/}
                                {/*            : "over-select-link-ar"*/}
                                {/*    }*/}
                                {/*    onClick={openCreateModal}*/}
                                {/*>*/}
                                {/*    {t("createNewProduct")}*/}
                                {/*</a>*/}
                                {/*/!* )} *!/*/}
                                <div>
                                    <AntForm.Item
                                        label={t("selectProduct")}
                                        name="product"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("requiredField"),
                                            },
                                        ]}
                                    >
                                        <AutoComplete
                                            value={selectedProductName}
                                            onChange={(v) =>
                                                setSelectedProductName(v)
                                            }
                                            allowClear
                                            className="form-control paginated"
                                            name="product"
                                            showSearch
                                            filterOption={(input, option) =>
                                                option?.value
                                                    .toLowerCase()
                                                    .includes(
                                                        input.toLowerCase()
                                                    )
                                            }
                                            options={options}
                                        >
                                            {/*<Select.Option*/}
                                            {/*    selected*/}
                                            {/*    hidden*/}
                                            {/*    disabled*/}
                                            {/*>*/}
                                            {/*    {lang === "en"*/}
                                            {/*        ? "Choose a product"*/}
                                            {/*        : "اختر المنتج"}*/}
                                            {/*</Select.Option>*/}
                                            {}
                                        </AutoComplete>
                                    </AntForm.Item>
                                </div>
                                <p className="error">
                                    {errMsg !== "" ? errMsg : null}
                                </p>
                            </div>
                            <div>
                                <AntForm.Item
                                    label={t("specialLabel")}
                                    name="special_label"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("requiredField"),
                                        },
                                    ]}
                                >
                                    <Select
                                        onChange={(v) => {
                                            const e = {
                                                target: {
                                                    value: v,
                                                    name: "special_label",
                                                },
                                            };
                                            handleInput(e, i);
                                        }}
                                        value={data.special_label ?? ""}
                                    >
                                        <Select.Option value={"true"}>
                                            {t("true")}
                                        </Select.Option>
                                        <Select.Option value={"false"}>
                                            {t("false")}
                                        </Select.Option>
                                    </Select>
                                </AntForm.Item>
                                <p className="error">
                                    {labelErr !== "" ? labelErr : null}
                                </p>
                            </div>
                            <AntForm.Item
                                rules={[
                                    {
                                        required: true,
                                        message: t("requiredField"),
                                    },
                                ]}
                                label={t("quantity")}
                                name="quantity"
                            >
                                <Select
                                    defaultValue={1}
                                    type="number"
                                    name="quantity"
                                    onChange={(v) => {
                                        console.log({ v });
                                        const e = {
                                            target: {
                                                value: v,
                                                name: "quantity",
                                            },
                                        };
                                        handleInput(e, i);
                                    }}
                                    value={data.quantity ?? 1}
                                    className="formTextField text-area"
                                >
                                    <Select.Option value={1}>1</Select.Option>
                                    <Select.Option value={2}>2</Select.Option>
                                </Select>
                            </AntForm.Item>

                            {workModelFormItems.map((it) => (
                                <AntForm.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: t("requiredField"),
                                        },
                                    ]}
                                    label={it.label}
                                    key={it.name}
                                    name={it.name}
                                >
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        value={it.getValue(data)}
                                        type="number"
                                        name={it.name}
                                        max={it.max}
                                        min={it.min}
                                        onChange={(v) => {
                                            const e = {
                                                target: {
                                                    name: it.name,
                                                    value: v,
                                                },
                                            };
                                            handleInput(e, i, it.max);
                                        }}
                                    />
                                </AntForm.Item>

                            ))}

                            <AntForm.Item>
                                <Checkbox
                                    checked={checked}
                                    onChange={(e) => setChecked(e.target.checked)}
                                >
                                    <span style={{ color: 'red' , fontWeight: 'bold'}}>الوزن المسموح 15 كيلو - 120 مجموع ابعاد الشحنة و بعده يحتسب وزن زائد</span>
                                </Checkbox>
                            </AntForm.Item>
                            {console.log(data.price)}
                            {/* <AntForm.Item label={t("price")} name="price"> */}
                            {/*<div>*/}
                            {/*    <label htmlFor="price" className="mb-2">*/}
                            {/*        {t("price")}*/}
                            {/*    </label>*/}
                            {/*    <Input*/}
                            {/*        name="price"*/}
                            {/*        // onChange={(e) => handleInput(e, i)}*/}
                            {/*        // defaultValue={0}*/}
                            {/*        // disabled*/}
                            {/*        readOnly*/}
                            {/*        value={data.price}*/}
                            {/*        className="formTextField text-area"*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/* </AntForm.Item> */}
                        </AntForm>
                    </>
                );
            })}

            {/*<Row className="mt-4">*/}
            {/*    <Col sm="12">*/}
            {/*        <p onClick={addAnotherProduct} className="addBtn">*/}
            {/*            <i className="fas fa-plus-circle mx-2"></i>*/}
            {/*            {t("addAnotherProduct")}*/}
            {/*        </p>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <Row>
                <Col className="text-end buttons-margin" sm="12">
                    <button className="previous" onClick={previous}>
                        {t("previous")}
                    </button>

                    <button className="confirm" onClick={() => form.submit()}>
                        {t("proceed")}
                    </button>
                </Col>
            </Row>
            <CreateNewProduct
                setCreated={setCreated}
                showModal={showCreate}
                hideModal={hideCreateModal}
                useModal={true}
            />
        </>
    );
}
