import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Row, Col, Modal } from "react-bootstrap";
import InformationCard from "../layout/informationCard";
import ThreeDotsMenu from "../layout/threeDotsMenu";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { reduxBranch } from "../../global-state/actions/branchAction";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";

function DeliveryRanges() {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [range, setRange] = useState("");
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);
  const [deleted, setDeleted] = useState(false);
  const [show, setShow] = useState(false);
  const [type, setType] = useState("");
  const [editedRange, setEditedRange] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const [columns, setColumns] = useState([
    {
      field: "ranges",
      headerName: `${t("rangeOverAll")}`,
      flex: 2,
      minWidth: 150,
      valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin">
          {row.row?.min} - {row.row?.max}
        </p>
      ),
    },
    {
      field: "actions",
      headerName: `${t("actions")}`,
      flex: 1,
      minWidth: 150,
      renderCell: (row) => (
        <ThreeDotsMenu
          onClick={() => settingEditedData(row.row)}
          link="DeliveryRange"
          setEdited={setShow}
          getData={getRanges}
          setDeleted={setDeleted}
          data={row.row}
        />
      ),
    },
  ]);

  const settingEditedData = (data) => {
    setType("edit");
    console.log(data);
    let range = data.min + "-" + data.max;
    setRange(range);
    setEditedRange(data);
  };

  const handleInput = (e, setFuntion) => {
    setFuntion(e.target.value);
  };
  const getRanges = async () => {
    const result = await axiosInstance.get("/DeliveryRange/");
    console.log(result);
    if (result) {
      setData(result.data.data);
      setCount(result.data.count);
    }
  };
  useEffect(() => {
    getRanges();
  }, [deleted]);
  const createRange = () => {
    setType("create");
    setShow(true);
  };
  const confirmRequest = async (type) => {
    setLoading(true);
    let data = {
      min: range.split("-")[0],
      max: range.split("-")[1],
    };
    console.log(data);
    let result;
    if (type === "create") {
      result = await axiosInstance.post("/DeliveryRange/", data);
    } else {
      result = await axiosInstance.put(
        `/DeliveryRange/${editedRange.id}`,
        data
      );
    }
    if (result) {
      hide();
      setLoading(false);
      getRanges();
    }
  };
  const hide = () => {
    setRange("");
    setShow(false);
    getRanges()
  };
  useEffect(() => {
    getRanges();
  }, []);
  const lang = useLanguage();

  return (
    <div>
      <Row>
        <Col sm="12">
          <span className="title1 text-uppercase">
            {t("sideMenu.workModels")}
          </span>
          <i class="fas fa-chevron-right px-3 title2"></i>
          <span className="title2">{t("deliveryRange")}</span>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <h6 className="reqTypeTitle">{t("deliveryRange")}</h6>
        </Col>
      </Row>
      <InformationCard
        title={t("workmodelInfoTitle")}
        subTitle={t("workmodelInfoSub")}
        list={[
          <p>
            {t("select")} <strong>{t("sideMenu.branches")}</strong>
          </p>,
          <p>
            {t("workModelCreateTxt1")}{" "}
            <strong>{t("workModelCreateTxt2")}</strong>{" "}
            {t("workModelCreateTxt3")}
          </p>,
          <p>
            {t("workModelCreateTxt4")}{" "}
            <strong>{t("workModelCreateTxt5")}</strong>{" "}
            {t("workModelCreateTxt6")}
          </p>,
          <p>
            {t("workModelCreateTxt7")}{" "}
            <strong>{t("workModelCreateTxt8")}</strong>.
          </p>,
          <p>
            {t("workModelCreateTxt9")}{" "}
            <strong>{t("workModelCreateTxt10")}</strong>{" "}
            {t("workModelCreateTxt11")}
          </p>,
        ]}
      />
      <Row>
        <Col sm="12" className="btnPosition">
          <button onClick={createRange}>
            <i className="fas fa-plus-circle"></i> {t("createDeliveryRange")}
          </button>
        </Col>
      </Row>
      <div className="requestTable rs">
        <DataGrid
          rowHeight="80"
          rows={data}
          columns={columns}
          disableSelectionOnClick
          columnsPanel
          pagination
          paginationMode="server"
          rowCount={count}
          pageSize={10}
          onPageChange={async (page) => {
            setPage(page);

            let result = await axiosInstance.get(
              `/DeliveryRanges/?page=${page + 1}`
            );
            setData(result.data.data);
            setCount(result.data.count);
          }}
        />
      </div>
      <Modal dir={lang == "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>
            {type === "create" ? t("createDeliveryRange") : t("editRange")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm="12">
              {" "}
              <Form.Control
                value={range}
                onChange={(e) => handleInput(e, setRange)}
                type="text"
                placeholder="Ex: 2-3"
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={hide} className="cancel">
            {t("close")}
          </button>
          <button
            // onClick={}
            className="confirm"
            disabled={loading}
            onClick={() => confirmRequest(type)}
          >
            {type === "create" ? t("addRange") : t("edit")}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ErrorHandler(DeliveryRanges, axiosInstance);
