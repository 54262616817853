import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import axiosInstance from "../../api/axios";
import "../../style-sheets/modal.css";
import { userType } from "../../global-state/actions/userTypeAction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useLanguage from "../../hooks/useLanguage";
import { prefix } from "../phone-input";

const confirmRequest = async (
    selectedOrders,
    verdict,
    hide,
    currentState,
    nextState,
    setLoading,
    onError,
    isUnConfirmedRequest
) => {
    setLoading(true);
    console.log({ selectedOrders });
    const errorHandler = (err) => {
        const errorData = err.response.data;
        onError?.(
            typeof errorData === "string"
                ? errorData
                : Array.isArray(errorData)
                ? errorData[0]
                : Object.values(errorData)[0]
        );
    };
    // const idsList = selectedOrders.map((order) => order.id);
    const idsList = selectedOrders;
    let result;
    if (isUnConfirmedRequest) {
        result = await axiosInstance
            .post("/AcceptOrders/", { orders: idsList, verdict: verdict })
            .catch(errorHandler);
    } else {
        result = await axiosInstance
            .post("/MoveTicket/state", {
                state: nextState.id,
                tickets: idsList,
            })
            .catch(errorHandler);
    }

    if (result) {
        setLoading(false);
        hide();
    }
};

const createAccount = async (
    account,
    showConfirm,
    hide,
    setID,
    type,
    getErrors,
    setPassword,
    setLoading,
    onError,
    lang
) => {
    setLoading(true);
    let result;
    if (!account.fullPhone) {
        account.email = account.email.toLowerCase();
        account.phone = prefix + account.phone;
        account.phone_alt = account.phone_alt
            ? prefix + account.phone_alt
            : undefined;
    }
    if (type === -1) {
        result = await axiosInstance
            .post(
                "/Seller/",
                account,
                lang
                    ? {
                          headers: {
                              "Accept-Language": lang,
                          },
                      }
                    : undefined
            )
            .catch((err) => {
                onError();
                setLoading(false);
                getErrors(err.response?.data);
            });
    }
    else if (type === 9) {
        result = await axiosInstance
            .post("/ThirdPartyCompany/", account , lang
            ? {
                  headers: {
                      "Accept-Language": lang,
                  },
              }
            : undefined)
            .catch((err) => {
                onError();
                setLoading(false);
                getErrors(err.response.data);
            });
    }
    else {
        const formattedAccount = {
            ...account,
            type: account.type.toString(),
            permissions: account.permissions
                ? account.permissions
                      .filter((it) => it.active)
                      .map((it) => it.id)
                : undefined,
        };
        console.log({ formattedAccount });
        result = await axiosInstance
            .post("/OrgEmployee/", formattedAccount)
            .catch((err) => {
                onError();
                setLoading(false);
                getErrors(err.response.data);
            });
    }

    if (result) {
        setLoading(false);
        getErrors("");
        setID(result.data.data.id);
        setPassword(result.data.extras.password);
        hide();
        showConfirm();
    }
};

export function ConfirmationModal({
    show,
    hide,
    selectedCount,
    currentState,
    selectedOrders,
    nextState,
    color,
    background,
    onError,
    isUnConfirmedRequest,
}) {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const lang = useLanguage();

    return (
        <div dir={lang === "en" ? "ltr" : "rtl"}>
            <Modal show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("changeStatusConfirmation")}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ lineHeight: "2.3" }}>
                    {lang === "en" ? (
                        <>
                            {" "}
                            Do you want to confirm that {selectedCount} items
                            will be changed from{" "}
                            <strong>{currentState.state.title}</strong> to{" "}
                            <strong>
                                <span
                                    className="nextState"
                                    style={{
                                        background: isUnConfirmedRequest
                                            ? "#126AFC"
                                            : nextState.background,
                                        color: isUnConfirmedRequest
                                            ? "white"
                                            : color,
                                    }}
                                >
                                    {isUnConfirmedRequest
                                        ? "Pickup Confirmed"
                                        : nextState.name}
                                </span>
                            </strong>
                        </>
                    ) : (
                        <>
                            اتريد تأكيد ان عدد {selectedCount} شحنات سيتم
                            تغييرهم من{" "}
                            <strong>{currentState.state.title_ar}</strong> إلى{" "}
                            <strong>
                                <span
                                    className="nextState"
                                    style={{
                                        background: isUnConfirmedRequest
                                            ? "#126AFC"
                                            : nextState.background,
                                        color: isUnConfirmedRequest
                                            ? "white"
                                            : color,
                                    }}
                                >
                                    {isUnConfirmedRequest
                                        ? "إستلام من المتجر"
                                        : nextState.name}
                                </span>
                            </strong>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={hide} className="cancel">
                        {t("cancel")}
                    </button>
                    <button
                        // onClick={}
                        className="confirm"
                        disabled={loading}
                        onClick={() =>
                            confirmRequest(
                                selectedOrders,
                                true,
                                hide,
                                currentState,
                                nextState,
                                setLoading,
                                (err) => {
                                    onError?.(err);
                                    setLoading(false);
                                },
                                isUnConfirmedRequest
                            )
                        }
                    >
                        {t("confirm")}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export function DeleteModal({
    show,
    hide,
    selectedCount,
    selectedOrders,
    currentState,
    nextState,
}) {
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();
    const lang = useLanguage();
    // console.log(currentState)
    return (
        <div>
            <Modal show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("archiveConfirmation")}</Modal.Title>
                </Modal.Header>
                {lang === "en" ? (
                    <>
                        <Modal.Body>
                            Do you want to confirm that {selectedCount}{" "}
                            {selectedCount === 1 ? "item" : "items"} will be
                            changed from{" "}
                            <strong>{currentState.state.title}</strong> to{" "}
                            <span className="archive">Archive</span>
                        </Modal.Body>
                    </>
                ) : (
                    <>
                        <Modal.Body>
                            اتريد تأكيد ان تغيير عدد {selectedCount} شحنات سيتم
                            تغييرهم من
                            <strong>
                                {currentState.state.title_ar}
                            </strong> إلي{" "}
                            <span className="archive">الأرشيف</span>
                        </Modal.Body>
                    </>
                )}

                <Modal.Footer>
                    <button onClick={hide} className="cancel">
                        {t("cancel")}
                    </button>
                    <button
                        // onClick={}
                        className="confirm"
                        onClick={() =>
                            confirmRequest(
                                selectedOrders,
                                false,
                                hide,
                                currentState,
                                nextState,
                                setLoading
                            )
                        }
                    >
                        {t("confirm")}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export function OrderConfirmationModal({ show, hide, id }) {
    const lang = useLanguage();
    const { t } = useTranslation();

    return (
        <div dir={lang === "en" ? "ltr" : "rtl"}>
            <Modal
                dir={lang === "en" ? "ltr" : "rtl"}
                show={show}
                onHide={hide}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("createOrder")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {lang === "en"
                            ? `Your order with ID#${id} has been created succesfully`
                            : `تم إنشاء طلبك برقم ${id} بنجاح`}
                    </p>
                    <svg
                        className="confirm-check"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 130.2 130.2"
                    >
                        <circle
                            class="path circle"
                            fill="#57BE6C"
                            stroke="#fff"
                            stroke-width="10"
                            stroke-miterlimit="10"
                            cx="65.1"
                            cy="65.1"
                            r="62.1"
                        />
                        <polyline
                            class="path check"
                            fill="none"
                            stroke="#fff"
                            stroke-width="10"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            points="100.2,40.2 51.5,88.8 29.8,67.5 "
                        />
                    </svg>{" "}
                </Modal.Body>
            </Modal>
        </div>
    );
}

export function AccountConfirmationModal({
    show,
    hide,
    id,
    password,
    account,
}) {
    const { t } = useTranslation();
    const lang = useLanguage();
    return (
        <div>
            <Modal dir={lang == "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("accountConfirmation")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {lang == "ar" ? (
                        <p>
                            {" "}
                            تم انشاء الحساب بنجاح وكلمة السر هي{" "}
                            <strong>{password}</strong>
                        </p>
                    ) : (
                        <p>
                            {" "}
                            Your new <strong>{account.type}</strong> account
                            with ID <strong>#{id}</strong> and password{" "}
                            <strong>{password}</strong> has been created
                            succesfully{" "}
                        </p>
                    )}
                    <svg
                        className="confirm-check"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 130.2 130.2"
                    >
                        <circle
                            class="path circle"
                            fill="#57BE6C"
                            stroke="#fff"
                            stroke-width="10"
                            stroke-miterlimit="10"
                            cx="65.1"
                            cy="65.1"
                            r="62.1"
                        />
                        <polyline
                            class="path check"
                            fill="none"
                            stroke="#fff"
                            stroke-width="10"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            points="100.2,40.2 51.5,88.8 29.8,67.5 "
                        />
                    </svg>{" "}
                </Modal.Body>
            </Modal>
        </div>
    );
}

export function AccountRegisterationSuccessModel({
    show,
    hide,
    id,
    password,
    account,
}) {
    const { t } = useTranslation();
    const lang = useLanguage();
    return (
        <div>
            <Modal dir={lang == "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("accountConfirmation")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {lang == "ar" ? (
                        <p>تم انشاء الحساب بنجاح</p>
                    ) : (
                        <p>Account created successfully</p>
                    )}
                    <svg
                        className="confirm-check"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 130.2 130.2"
                    >
                        <circle
                            class="path circle"
                            fill="#57BE6C"
                            stroke="#fff"
                            stroke-width="10"
                            stroke-miterlimit="10"
                            cx="65.1"
                            cy="65.1"
                            r="62.1"
                        />
                        <polyline
                            class="path check"
                            fill="none"
                            stroke="#fff"
                            stroke-width="10"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            points="100.2,40.2 51.5,88.8 29.8,67.5 "
                        />
                    </svg>{" "}
                </Modal.Body>
            </Modal>
        </div>
    );
}

export function CreationConfirmationModal({
    title,
    show,
    hide,
    showConfirm,
    account,
    setID,
    getErrors,
    setPassword,
    onBack,
}) {
    const type = useSelector((state) => state.account.type);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const lang = useLanguage();
    console.log(title);
    return (
        <div dir={lang == "ar" ? "rtl" : "ltr"}>
            <Modal dir={lang == "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("accountCreateTxt1")}</Modal.Body>
                <Modal.Footer>
                    <button onClick={hide} className="cancel">
                        {t("cancel")}
                    </button>
                    <button
                        // onClick={}
                        className="confirm"
                        disabled={loading}
                        onClick={() => {
                            createAccount(
                                account,
                                showConfirm,
                                hide,
                                setID,
                                type,
                                getErrors,
                                setPassword,
                                setLoading,
                                () => {
                                    onBack();
                                    hide();
                                },
                                lang
                            );
                        }}
                    >
                        {t("confirm")}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export function OrderDeleteModal({ show, hide, id }) {
    const lang = useLanguage();
    const { t } = useTranslation();

    return (
        <div dir={lang == "ar" ? "rtl" : "ltr"}>
            <Modal show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("deleteOrder")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {lang === "en" ? (
                            <>
                                Your order with ID <strong>#{id}</strong> has
                                been deleted successfully
                            </>
                        ) : (
                            `تم مسح طلبك رقم ${id} بنجاح`
                        )}
                    </p>
                    <svg
                        className="confirm-check"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 130.2 130.2"
                    >
                        <circle
                            className="path circle"
                            fill="none"
                            stroke="#D06079"
                            strokeWidth="6"
                            strokeMiterlimit="10"
                            cx="65.1"
                            cy="65.1"
                            r="62.1"
                        />
                        <line
                            className="path line"
                            fill="none"
                            stroke="#D06079"
                            strokeWidth="6"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            x1="34.4"
                            y1="37.9"
                            x2="95.8"
                            y2="92.3"
                        />
                        <line
                            className="path line"
                            fill="none"
                            stroke="#D06079"
                            strokeWidth="6"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            x1="95.8"
                            y1="38"
                            x2="34.4"
                            y2="92.2"
                        />
                    </svg>{" "}
                </Modal.Body>
            </Modal>
        </div>
    );
}

export function CreationConfirmation({
    title,
    sendRequest,
    body,
    show,
    hide,
    loading,
}) {
    const type = useSelector((state) => state.auth.user);
    const { t } = useTranslation();
    const lang = useLanguage();
    return (
        <div>
            <Modal dir={lang == "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
                <Modal.Footer>
                    <button onClick={hide} className="cancel">
                        {t("cancel")}
                    </button>
                    <button
                        disabled={loading}
                        // onClick={}
                        className={
                            type.role == "Seller" ? "confirmSeller" : "confirm"
                        }
                        onClick={() => {
                            sendRequest();
                            // createAccount(account, showConfirm, hide , setID , type , getErrors , setPassword)
                        }}
                    >
                        {t("confirm")}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export function SuccessfulRequest({ show, hide, title, body }) {
    const lang = useLanguage();
    return (
        <div dir={lang == "ar" ? "rtl" : "ltr"}>
            <Modal dir={lang == "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
                <Modal.Header
                    className={
                        title === "Setup Your Account"
                            ? "justify-content-center"
                            : null
                    }
                    closeButton
                >
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body
                    className={
                        title === "Setup Your Account"
                            ? "justify-content-center"
                            : null
                    }
                >
                    <p>{body}</p>
                    <svg
                        className="confirm-check"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 130.2 130.2"
                    >
                        <circle
                            className="path circle"
                            fill="#57BE6C"
                            stroke="#fff"
                            strokeWidth="10"
                            strokeMiterlimit="10"
                            cx="65.1"
                            cy="65.1"
                            r="62.1"
                        />
                        <polyline
                            className="path check"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="10"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            points="100.2,40.2 51.5,88.8 29.8,67.5 "
                        />
                    </svg>{" "}
                </Modal.Body>
                {title === "Setup Your Account" ? (
                    <Modal.Footer className="justify-content-center">
                        <button onClick={hide} className="confirm">
                            Done
                        </button>
                    </Modal.Footer>
                ) : null}
            </Modal>
        </div>
    );
}

export function SuccessfulPayment({ show, setShow, body }) {
    const lang = useLanguage();
    const { t } = useTranslation();
    const history = useHistory();

    const hide = () => {
        setShow(false);
        history.push("/dashboard");
    };
    return (
        <div dir={lang == "ar" ? "rtl" : "ltr"}>
            <Modal dir={lang == "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("paymentState")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-capitalize">{t("paymentSuccess")}</p>
                    <svg
                        className="confirm-check"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 130.2 130.2"
                    >
                        <circle
                            className="path circle"
                            fill="#57BE6C"
                            stroke="#fff"
                            strokeWidth="10"
                            strokeMiterlimit="10"
                            cx="65.1"
                            cy="65.1"
                            r="62.1"
                        />
                        <polyline
                            className="path check"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="10"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            points="100.2,40.2 51.5,88.8 29.8,67.5 "
                        />
                    </svg>{" "}
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <button onClick={hide} className="confirm">
                        {t("done")}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export function UnSuccessfulRequest({ show, hide, title, body, realError }) {
    const [showRealError, setShowRealError] = useState(false);
    const lang = useLanguage();

    const errorMessage = showRealError ? realError : body;

    return (
        <div dir={lang === "ar" ? "rtl" : "ltr"}>
            <Modal dir={lang === "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-capitalize">{errorMessage}</p>
     

                    <svg
                        className="confirm-check"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 130.2 130.2"
                    >
                        <circle
                            className="path circle"
                            fill="none"
                            stroke="#D06079"
                            strokeWidth="6"
                            strokeMiterlimit="10"
                            cx="65.1"
                            cy="65.1"
                            r="62.1"
                        />
                        <line
                            className="path line"
                            fill="none"
                            stroke="#D06079"
                            strokeWidth="6"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            x1="34.4"
                            y1="37.9"
                            x2="95.8"
                            y2="92.3"
                        />
                        <line
                            className="path line"
                            fill="none"
                            stroke="#D06079"
                            strokeWidth="6"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            x1="95.8"
                            y1="38"
                            x2="34.4"
                            y2="92.2"
                        />
                    </svg>{" "}
                    {realError && (
                    <button
                        onClick={() => setShowRealError(!showRealError)}
                        style={{
                            padding: '8px 16px',
                            backgroundColor: '#58519e',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            outline: 'none', // Remove default outline
                            transition: 'background-color 0.3s',
                            display: 'block', // Make button a block element
                            margin: 'auto', // Center horizontally
                            marginTop: '1rem',
                        }}
                    >
                        {showRealError ? 'Back' : 'Show Real Error'}
                    </button>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
}

export function UnSuccessfulPayment({ show, setShow, body }) {
    const lang = useLanguage();
    const { t } = useTranslation();
    const history = useHistory();

    const hide = () => {
        setShow(false);
        history.push("/payments");
    };
    return (
        <div dir={lang == "ar" ? "rtl" : "ltr"}>
            <Modal
                className="preline"
                dir={lang == "ar" ? "rtl" : "ltr"}
                show={show}
                onHide={hide}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("paymentState")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-capitalize">
                        {t("paymentFailed")}{" "}
                        <span className="text-capitalize fw-bold">{body}</span>
                    </p>
                    <svg
                        className="confirm-check"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 130.2 130.2"
                    >
                        <circle
                            className="path circle"
                            fill="none"
                            stroke="#D06079"
                            strokeWidth="6"
                            strokeMiterlimit="10"
                            cx="65.1"
                            cy="65.1"
                            r="62.1"
                        />
                        <line
                            className="path line"
                            fill="none"
                            stroke="#D06079"
                            strokeWidth="6"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            x1="34.4"
                            y1="37.9"
                            x2="95.8"
                            y2="92.3"
                        />
                        <line
                            className="path line"
                            fill="none"
                            stroke="#D06079"
                            strokeWidth="6"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            x1="95.8"
                            y1="38"
                            x2="34.4"
                            y2="92.2"
                        />
                    </svg>{" "}
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <button onClick={hide} className="cancel">
                        {t("retry")}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export function ConfirmDelete({
    title,
    show,
    hide,
    deleteEntity,
    body,
    loading,
}) {
    const lang = useLanguage();
    const { t } = useTranslation();

    return (
        <div dir={lang == "ar" ? "rtl" : "ltr"}>
            <Modal dir={lang == "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
                <Modal.Footer>
                    <button onClick={hide} className="cancel">
                        {t("cancel")}
                    </button>
                    <button
                        // onClick={}
                        className="confirm"
                        disabled={loading}
                        onClick={() => {
                            deleteEntity();
                        }}
                    >
                        {t("confirm")}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export function PaymentAlert({ title, show, setShow }) {
    const lang = useLanguage();
    const { t } = useTranslation();
    const history = useHistory();
    const hide = () => {
        setShow(false);
        history.push("/dashboard");
    };
    return (
        <div dir={lang == "ar" ? "rtl" : "ltr"}>
            <Modal dir={lang == "ar" ? "rtl" : "ltr"} show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("packageExpired")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("packageExpiredText")}</Modal.Body>
                <Modal.Footer>
                    <button onClick={hide} className="cancel">
                        {t("cancel")}
                    </button>
                    <button
                        className="confirm"
                        onClick={() => history.push("/payments")}
                    >
                        {t("confirm")}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export function WMDeleteConfirmation({
    title,
    deleteRequest,
    assignRequest,
    body,
    show,
    hide,
    loading,
}) {
    const { t } = useTranslation();
    const lang = useLanguage();
    return (
        <div>
            <Modal
                dir={lang === "ar" ? "rtl" : "ltr"}
                show={show}
                onHide={hide}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
                <Modal.Footer>
                    <button onClick={hide} className="cancel">
                        {t("cancel")}
                    </button>
                    <button
                        disabled={loading}
                        className={"cancel filled"}
                        onClick={() => {
                            deleteRequest();
                        }}
                    >
                        {t("delete")}
                    </button>
                    {/*<button*/}
                    {/*    disabled={loading}*/}
                    {/*    className={"confirm"}*/}
                    {/*    onClick={() => {*/}
                    {/*        assignRequest?.();*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    {t("assign")}*/}
                    {/*</button>*/}
                </Modal.Footer>
            </Modal>
        </div>
    );
}
