import { useShipmentOrderColumns } from "../../../layout/tableColumns";
import axiosInstance from "../../../../api/axios";
import { useState, useEffect } from "react";
import TablePro from "../../../tables/TablePro";
import { useTranslation } from "react-i18next";
import useQuery from "../../../../hooks/useQuery";

function OrderStep() {
    const columns = useShipmentOrderColumns();
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(1);
    const [search, setSearch] = useState();   
    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    // const [selectedOrder, setSelectedOrder] = useState([]);
    const { appendQuery } = useQuery();

    const fetchOrders = async () => {
        setLoading(true);
        const result = await axiosInstance.get(prepareFetchUrlQuery());
        if (result) {
            setCount(result.data.count);
            setData(result.data.data);
        }
        setLoading(false);
    };

    const prepareFetchUrlQuery = () => {
        let url = "/FilterShipmentOrder/?";
        if (search) {
            url += `search=${search}&`;
        }
        url += `page=${page}`;
        return url;
    };


    useEffect(() => {
        fetchOrders();
        
    }, [search, page]);
    return ( 
        <div>
        <div>
            <h1>{t("orderStep")}</h1>
        </div>
        <div>
        <TablePro
            loading={loading}
            // headerExtra={
            //     <div className="row-centered-horizontal gap-1">
            //         {Object.keys(props.filters ?? {}).map((key) => (
            //             <Select
            //                 key={key}
            //                 onChange={(v) => {
            //                     setFilters({
            //                         ...filters,
            //                         [key]: v,
            //                     });
            //                 }}
            //                 value={
            //                     props.filters[key]?.selectedValue
            //                 }
            //                 allowClear
            //                 style={{ minWidth: "150px" }}
            //                 placeholder={props.filters[key].title}
            //             >
            //                 {props.filters[key].values.map((it) => (
            //                     <Select.Option
            //                         key={it.id}
            //                         value={it.id}
            //                     >
            //                         {it.name}
            //                     </Select.Option>
            //                 ))}
            //             </Select>
            //         ))}
            //     </div>
            // }
            selection={"radio"}
            columns={columns}
            dataSource={data}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            pagination={{
                current: page,
                total: count,
                onChange: (page) => setPage(page),
            }}
            // onRowClick={(i, record) => {
            //     setSelectedOrder([record]);
            // }}
            searchPlaceholder={t("search")}
            onSearch={setSearch}
        />
    </div>
        <div className="w-100 d-flex">
        <button
            className="confirm mt-4 ms-auto"
            onClick={() => {
                appendQuery("order", selectedRows[0]);
            }}
            disabled={selectedRows.length !== 1}
        >
            {t("next")}
        </button>
        </div>
    </div>
     );
}

export default OrderStep;