import { PLAN } from "../actions/actionTypes";
const initialState = {};

export default function (state = initialState, action) {
  //  (action.payload);
  switch (action.type) {
    case PLAN:
      return {
        ...state, 
        plan : action.payload
      };

    
    default:
      return state;
  }
}