import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SeeMoreButton from "../../../../components/SeeMoreButton";
import useLanguage from "../../../../hooks/useLanguage";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../api/axios";
import { Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons'
import { useRef } from 'react';
const useCommonRequestColumns = () => {
    const { t } = useTranslation();

    const dateTimeSorter = (a, b) => {
        const dateA = new Date(a.create_date);
        const dateB = new Date(b.create_date);
        return dateA - dateB;
    };

    return [
        {
            title: t("createDate"),
            dataIndex: "create_date",
            render: (date) => {
                const dateParts = date?.split("T") ?? [];
                return (
                    <>
                        <span>{dateParts[0]}</span>{" "}
                        <span>{dateParts[1].substr(0, 5)}</span>
                    </>
                );
            },
            sorter: (a, b) => dateTimeSorter(a, b),
            sortDirections: ['ascend', 'descend'], 
            
        },
    ];
};


export const useUnConfirmedRequestsColumns = () => {
    const { t } = useTranslation();

    return [
        {
            title: t("orderId"),
            dataIndex: "id",
            render: (_, row) => <span className="tableID">#{row.id}</span>,
        },
        ...useCommonRequestColumns(),
        {
            title: t("shipmentProvider"),
            dataIndex: ["shipment_order", "provider_name"],
        },
        //حالة الطلب
        {
            title: t("shipmentType"),
            dataIndex: "type",
            render: () => <span>{t("uncompleted")}</span>,
        },
        //اسم المستلم
        {
            title: t("customerName"),
            dataIndex: ["client", "name"],
        },
        {
            title: t("store"),
            dataIndex: "store_name",
            render: (_, record) => (
                <span>
                    {record.seller_address?.sender_name
                        ? record.seller_address.sender_name
                        : record.seller?.store_name || "No Name"}
                </span>
            ),
        },

        {
            title: t("sellerAddress"),
            dataIndex: [ "seller_address" ,"address"],
        },
        
        
       /* {
            title: t("itemQuantity"),
            dataIndex: "ticket_count",
        },
        {
            title: t("orderValue"),
            dataIndex: "sub_total",
        },
        */
/*
        {
            title: t("paymentMethod"),
            dataIndex: "payment_method",
            render: (_, row) => (
                <strong>
                    {row.payment_method == "Online" ? (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className="onlineBtn"
                        >
                            {row.payment_method}
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className="cashBtn"
                        >
                            {row.payment_method}
                        </Button>
                    )}
                </strong>
            ),
        },*/
        {
            title: t("orderDetails"),
            show: true,
            render: (_, row) => (
                <NavLink to={`/order_chain_details/${row.order?.id ?? row.id}`}>
                    <SeeMoreButton />
                </NavLink>
            ),
        },
    ];
};

// export const useConfirmedRequestsColumns = () => {
//     const { t } = useTranslation();
//     const lang = useLanguage();
//     const cur = useSelector(
//         (state) => state.auth.user.user?.organization.country.currency
//     );
//     const user = useSelector((state) => state.auth.user);

//     const paymentMethodSorter = (a, b) => {
//         const paymentMethods = { Cash: 1, Online: 2 }; // Assign numeric values

//         const methodA = a.order.payment_method === "1" ? "Online" : "Cash";
//         const methodB = b.order.payment_method === "2" ? "Cash" : "Online";

//         return paymentMethods[methodA] - paymentMethods[methodB];
//     };
//     const searchInput = useRef(null);

//     const getColumnSearchProps = (dataIndex) => ({
//         filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
//             <div style={{ padding: 8 }}>
//                 <Input
//                     ref={searchInput}
//                     placeholder={`Search ${t(dataIndex)}`}
//                     value={selectedKeys[0]}
//                     onChange={(e) =>
//                         setSelectedKeys(e.target.value ? [e.target.value] : [])
//                     }
//                     onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
//                     style={{ width: 188, marginBottom: 8, display: 'block' }}
//                 />
//                 <Space>
//                     <Button
//                         type="primary"
//                         onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
//                         icon={<SearchOutlined />}
//                         size="small"
//                         style={{ width: 90 }}
//                     >
//                         {t('search')}
//                     </Button>
//                     <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
//                         {t('reset')}
//                     </Button>
//                 </Space>
//             </div>
//         ),
//         filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
//         onFilter: (value, record) => {
//             const { shipment_order } = record.order || {};
//             if (shipment_order) {
//                 const { waybill_number, shipment_id } = shipment_order;
//                 return (
//                     (waybill_number && waybill_number.toLowerCase().includes(value.toLowerCase())) ||
//                     (shipment_id && shipment_id.toLowerCase().includes(value.toLowerCase()))
//                 );
//             }
//             return false;
//         },
//         onFilterDropdownVisibleChange: (visible) => {
//             if (visible) {
//                 setTimeout(() => searchInput.current.select(), 100);
//             }
//         }
//     });

//     const handleSearch = (selectedKeys, confirm, dataIndex) => {
//         confirm();
//     };
//     const handleReset = (clearFilters) => {
//         clearFilters();
//     };
    

//     return [
//         {
//             title: t("orderId"),
//             dataIndex: ["order", "id"],
//             render: (_, row) => (
//                 <span className="tableID">#{row.order?.id}</span>
//             ),
//         },
//         {
//             title: t("ticketId"),
//             dataIndex: "id",
//             headerName: "Ticket ID",
//             width: 200,
//             render: (_, row) => (
//                 <a
//                     href={row.order?.shipment_order?.label_url}
//                     target={"_blank"}
//                     className="tableID"
//                 >
//                     #{row.id}
//                 </a>
//             ),
//             sorter: (a, b) => a.id.localeCompare(b.id), // Add sorter function for ticketId column

//         },
//         {
//             title: t("trackNumber"),
//             dataIndex: ["order", "shipment_order" , "waybill_number"],
//             ...getColumnSearchProps("trackNumber"), // Add search filter for trackNumber
//             render: (_, row) => {
//                 const { shipment_order } = row.order || {};
//                 if (shipment_order && shipment_order.provider_name === "Aramex") {
//                     return <span className="tableID">#{shipment_order.shipment_id}</span>;
//                 } else if (shipment_order) {
//                     return <span className="tableID">#{shipment_order.waybill_number}</span>;
//                 } else {
//                     return null; // or any other fallback value as needed
//                 }
//             },
//         },
        
        
      
//         ...useCommonRequestColumns(),
        
      
//         {
//             title:t("shipmentProvider"),
//             dataIndex: ["order","shipment_order" , "provider_name"]
//         },
//         {
//             title: t("shipState"),
//             dataIndex: ["order", "shipment_order" , "state"],
//         },
//         {
//             title: t("orderValue"),
//             dataIndex: ["order", "sub_total"],
//             render: (_, row) => (
//                 <>
//                     {row.order?.sub_total} {cur}
//                 </>
//             ),
//         },
    
//         {
//             title: t("paymentMethod"),
//            dataIndex: [ "payment_method"],
//            render: (_, row) => (
               
//                     <strong>
//                     {t("withdraw")}
//                 </strong>
               
//            ),
//            sorter: paymentMethodSorter,
//        },

//        {
//         title: t("customerName"),
//         dataIndex: ["order", "client", "name"],
//         },
        
//         {
//             title: t("store"),
//             dataIndex: ["order", "seller", "store_name"],
//         },
//         {
//             title: t("sellerAddress"),
//             dataIndex: ["order", "seller_address", "address"],
//         },
        
        
//         {
//             show: true,
//             field: "order details",
//             headerName: "Action",
//             title: t("orderDetails"),
//             render: (_, row) => (
//                 <NavLink to={`/order_chain_details/${row.order?.id ?? row.id}`}>
//                     <SeeMoreButton />
//                 </NavLink>
//             ),
//         },
//     ];
// };


export const useConfirmedRequestsColumns = () => {
    const { t } = useTranslation();
    const lang = useLanguage();
    const cur = useSelector(
        (state) => state.auth.user.user?.organization.country.currency
    );
    const user = useSelector((state) => state.auth.user);

    const paymentMethodSorter = (a, b) => {
        const paymentMethods = { Cash: 1, Online: 2 }; // Assign numeric values

        const methodA = a.order.payment_method === "1" ? "Online" : "Cash";
        const methodB = b.order.payment_method === "2" ? "Cash" : "Online";

        return paymentMethods[methodA] - paymentMethods[methodB];
    };

    const searchInput = useRef(null);

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${t(dataIndex)}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => {
            const { shipment_order } = record.order || {};
            if (shipment_order) {
                const { waybill_number, shipment_id } = shipment_order;
                return (
                    (waybill_number && waybill_number.toLowerCase().includes(value.toLowerCase())) ||
                    (shipment_id && shipment_id.toLowerCase().includes(value.toLowerCase()))
                );
            }
            return false;
        },
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        }
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const handleReset = (clearFilters) => {
        clearFilters();
    };

    const columns = [
        {
            title: t("orderId"),
            dataIndex: ["order", "id"],
            render: (_, row) => (
                <span className="tableID">#{row.order?.id}</span>
            ),
        },
       
        {
            title: t("ticketId"),
            dataIndex: "id",
            headerName: "Ticket ID",
            width: 200,
            render: (_, row) => {
                if (row.state.type === 9 && user.role === "Seller" ) {
                    
                    return (
                        <span className="tableID">#{row.id}</span>
                        
                    );
                } else {
                    return (
                        <a
                            href={row.order?.shipment_order?.label_url}
                            target="_blank"
                            className="tableID"
                        >
                            #{row.id}
                        </a>
                    );
                }
            },
            sorter: (a, b) => a.id.localeCompare(b.id), 
        },
        {
            title: t("trackNumber"),
            dataIndex: ["order", "shipment_order", "waybill_number"],
            ...getColumnSearchProps("trackNumber"), 
            render: (_, row) => {
                if (user.role === "Seller" && row.state?.type === 9) {
                    return null;
                }
        
                const { shipment_order } = row.order || {};
                if (shipment_order) {
                    const trackNumber = shipment_order.provider_name === "Aramex" 
                        ? shipment_order.shipment_id 
                        : shipment_order.waybill_number;
                    return <span className="tableID">#{trackNumber}</span>;
                }
                return null; 
            },
        },        
        ...useCommonRequestColumns(),
        {
            title: t("shipmentProvider"),
            dataIndex: ["order","shipment_order" , "provider_name"]
        },
        {
            title: t("shipState"),
            dataIndex: ["order", "shipment_order" , "state"],
        },
        {
            title: t("orderValue"),
            dataIndex: ["order", "sub_total"],
            render: (_, row) => (
                <>
                    {row.order?.sub_total} {cur}
                </>
            ),
        },
        {
            title: t("paymentMethod"),
            dataIndex: [ "payment_method"],
            render: (_, row) => (
                <strong>
                    {t("withdraw")}
                </strong>
            ),
            sorter: paymentMethodSorter,
        },
        {
            title: t("customerName"),
            dataIndex: ["order", "client", "name"],
        },
        {
            title: t("store"),
            dataIndex: ["order", "seller", "store_name"],
        },
        {
            title: t("sellerAddress"),
            dataIndex: ["order", "seller_address", "address"],
        },
        {
            show: true,
            field: "order details",
            headerName: "Action",
            title: t("orderDetails"),
            render: (_, row) => (
                <NavLink to={`/order_chain_details/${row.order?.id ?? row.id}`}>
                    <SeeMoreButton />
                </NavLink>
            ),
        },
    ];

 

    return columns;
};

