import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Row, Col } from "react-bootstrap";
import "../../../../../style-sheets/form.css";
import ErrorHandler from "../../../../../error-handler/ErrorHandler";
import axiosInstance from "../../../../../api/axios";
import {useDispatch, useSelector} from "react-redux";
import SellerInfoForm from "../../../../../components/forms/sellerInfoForm";
import { useTranslation } from "react-i18next";
import ProductInfoTable from "../../../../../components/layout/TicketsReturns/ProductInfoTable";
import ReviewReturnRequest from "../../../../../components/layout/TicketsReturns/ReviewReturnRequest";
import ReplacedTickets from "./ReplacedTickets";
import NewTickets from "./NewTickets";
import ReplacementSellerAddress from "./ReplacementSellerAddress";
import ReviewReplacementRequest from "./ReviewReplacementRequest";
import {addOrder} from "../../../../../global-state/actions/addOrderAction";
import {getSeller, getTicketsReturned} from "../../../../../global-state/actions/getSelectedAction";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function TicketsReplacement() {
    const [value, setValue] = useState(0);
    const [showCreate, setShowCreate] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [returnedObj, setReturnedTicketsObj] = useState("");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const selectedSeller = useSelector(
        (state) => state.selectedData.selectedSeller
    );
    const user = useSelector((state) => state.auth.user);

    useEffect(() => {
        //  console.log(selectedProduct)
    }, [selectedProduct]);

    const getReturnedTicketsObject = (e) => {
        console.log(e);
        setReturnedTicketsObj(e);
    };
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(addOrder({}));
    }, []);

    const order = useSelector((state) => state.order);
    useEffect(() => {
        console.log({ order });
    }, [order]);

    useEffect(() => {
        dispatch(addOrder(""));
        dispatch(getTicketsReturned(""));
        dispatch(getSeller(""));
    }, []);

    return (
        <div>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.tickets")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("sideMenu.replace")}</span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("createReplaceRequest")}</h2>
                </Col>
            </Row>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={value}
                        // onChange={handleChange}
                        aria-label="basic tabs example"
                        className={`${
                            user.role == "Seller" ? "seller" : "admin"
                        }`}
                    >
                        <Tab label={t("selectTickets")} {...a11yProps(0)} />
                        <Tab label={t("sellerInfo")} {...a11yProps(1)} />
                        <Tab label={t("productInfo")} {...a11yProps(2)} />
                        <Tab label={t("reviewRequest")} {...a11yProps(3)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <ReplacedTickets
                        getReturnedTicketsObject={getReturnedTicketsObject}
                        value={value}
                        handleChange={handleChange}
                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ReplacementSellerAddress
                        value={value}
                        handleChange={handleChange}
                    />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <NewTickets value={value} handleChange={handleChange} />
                </TabPanel>

                <TabPanel value={value} index={3}>
                    <ReviewReplacementRequest
                        value={value}
                        handleChange={handleChange}
                    />
                </TabPanel>
            </Box>
        </div>
    );
}

export default ErrorHandler(TicketsReplacement, axiosInstance);
