import React, {useEffect, useState} from "react";
import ReportsRepository, {
    branchReportsTypes,
} from "../../../../api/ReportsRepository";
import axiosInstance from "../../../../api/axios";
import CommonReportsPage from "../CommonReportsPage";
import {useTranslation} from "react-i18next";

export default function BranchReportsPage({}) {
    const [selectedType, setSelectedType] = useState();
    const [branches, setBranches] = useState([]);
    const [couriers, setCouriers] = useState([]);
    const [courierSearch, setCourierSearch] = useState();
    const [marketers, setMarketers] = useState([]);
    const [marketerSearch, setMarketerSearch] = useState();
    const {t} = useTranslation();
    const defaultBranchId = branches.length > 0 ? branches[0].id : null;
    const defaultMarketerName = marketers.length > 0 ? marketers[0].user.name : "";
    const fields = [
        // {
        //     name: "branch",
        //     label: t("reports.branch_name"),
        //     multiple: true,
        //     type: "select",
        //     placeholder: t("reports.branch_name_ph"),
        //     rules: [{required: true, message: t("reports.branch_name_req")}],
        //     options: branches.map((it) => (
        //         <option key={it.id} value={it.id}>
        //             {it.name}
        //         </option>
        //     )),
        // },
        {
            name: "branch",
            label: t("reports.branch_name"),
            initialValue: defaultBranchId,
            hidden: true,
        },
        {
            name: "type",
            label: t("reports.report_type"),
            type: "select",
            placeholder: t("reports.report_type_ph"),
            rules: [{required: true, message: t("reports.report_type_req")}],
            onChange: (v) => setSelectedType(branchReportsTypes[v]),
            options: Object.keys(branchReportsTypes).map((key) => (
                <option key={key}>{t(`reports.branch_list.${branchReportsTypes[key].title}`)}</option>
            )),
        },
        


        selectedType === branchReportsTypes.marketer
            ? {
                name: "marketers",
                label: t("reports.marketer_name"),
                type: "select",
                multiple: true,
                placeholder: t("reports.marketer_name_ph"),
                search: true,
                onClick: () => setMarketerSearch(undefined),
                onSearch: (input) => {
                    setMarketerSearch(input);
                    return false;
                },
        
                rules: [{required: true, message: t("reports.marketer_name_req")}],
                options: marketers.map((it) => (
                    <option key={it.id} value={it.id}>
                        {it.user.name}
                    </option>
                )),
            }
            : undefined,

            selectedType === branchReportsTypes.marketer2
            ? {
                name: "marketers",
                label: t("reports.marketer_name"),
                type: "select",
                multiple: true,
                placeholder: t("reports.marketer_name_ph"),
                search: true,
                onClick: () => setMarketerSearch(undefined),
                onSearch: (input) => {
                    setMarketerSearch(input);
                    return false;
                },
        
                rules: [{required: true, message: t("reports.marketer_name_req")}],
                options: marketers.map((it) => (
                    <option key={it.id} value={it.id}>
                        {it.user.name}
                    </option>
                )),
            }
            : undefined,
    ];

    async function fetchBranches() {
        const res = await axiosInstance
            .get("/Branch/?page_size=10000")
            .catch((err) => console.log({err}));
        if (res?.data) {
            setBranches(res.data?.data ?? []);
        }
    }

    


    async function fetchMarketers() {
        const res = await axiosInstance
            .get("/FilterOrgEmployee/?type=3", {
                params: {
                    // page_size: 1000,
                    search: marketerSearch,
                },
            })
            .catch((err) => console.log({err}));
    
        if (res.data) setMarketers(res.data.data ?? []);
    }

    useEffect(() => {
        fetchBranches();
        
    }, []);

    useEffect(() => {
        fetchMarketers();
    }, [marketerSearch]);
    return (
        <CommonReportsPage
            formFields={fields}
            selectedType={selectedType}
            section={t("reports.branch_reports")}
        />
    );
}
