import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    useMemo,
} from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../../style-sheets/table.css";
import { Row, Col, Dropdown } from "react-bootstrap";
import OrderDetails from "./OrderDetails";
import { useDispatch, useSelector } from "react-redux";
import { selectedState } from "../../global-state/actions/stateAction";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Pagination } from "antd";
import { search } from "../../global-state/actions/searchAction";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";

const RequestsTable = ({
    searchFunc,
    current,
    count,
    pagination,
    columns,
    data,
    currentState,
    pageSize,
    setPageSize,
}) => {
    const user = useSelector((state) => state.auth.user);
    const lang = useLanguage();
    const [searchTerm, setSearchTerm] = useState("");
    const [details, setDetails] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const [ticketsCurrent, setTicketsCurent] = useState(1);
    const [ticketCount, setTicketCount] = useState(1);
    const [show, setShow] = useState(false);
    const [showTicket, setShowTicket] = useState(false);
    const [order, setOrder] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const searchDispatch = useDispatch();

    const isMounted = useRef(true);
    const { t } = useTranslation();

    const onChangeTickets = async (page) => {
        setTicketsCurent(page);
        let result = await axiosInstance.get(
            `/Order/${order}?page_size=4&page=${page}`
        );
        if (result) {
            setTickets(result.data.tickets);
            setTicketCount(result.data.tickets.count);
        }
    };

    const sendRequest = useCallback(
        async (e) => {
            if (e.key == "Enter") {
                console.log(currentState);
                searchFunc(currentState);
            }
        },
        [isSending, searchTerm]
    );

    const onClose = (e) => {
        setShow(e);
        setShowTicket(e);
    };

    const searching = (e) => {
        setSearchTerm(e.target.value);
        searchDispatch(search(e.target.value));
    };
    // useEffect(()=>{
    //  searchDispatch(search(searchTerm))
    // },[searchTerm])
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        console.log(data);
        console.log(currentState);
    }, []);

    return (
        <div className="reqTableContainer">
            <Row dir="ltr">
                <Col sm="12" md="6" className="p-4">
                    <div>
                        <div class="input-icons">
                            <i class="fa fa-search icon"></i>
                            <input
                                onChange={searching}
                                onKeyPress={sendRequest}
                                class="searchInput"
                                placeholder={t("runsheetorderSearch")}
                                type="text"
                            />
                        </div>
                    </div>
                </Col>
                <Col sm="12" md="6" className="p-4">
                    {/* <Dropdown className="tableFilters px-1">
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="btn1"
            >
             {t("shipmentType")}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="">{t("complete")}</Dropdown.Item>
              <Dropdown.Item href="">{t("partial")}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="tableFilters px-1">
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="btn2"
            >
             {t("paymentMethod")}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="">{t("online")}</Dropdown.Item>
              <Dropdown.Item href="">{t("cash")}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
                    <Pagination
                        simple
                        pageSize={pageSize}
                        onChange={pagination}
                        defaultCurrent={current}
                        total={count}
                        current={current}
                    />
                </Col>
            </Row>
            <div dir="ltr" className="requestTable">
                <DataGrid
                    rowHeight="80"
                    rows={data}
                    columns={columns}
                    pagination
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10, 50, 100]}
                    disableSelectionOnClick
                    checkboxSelection={user.role !== "Seller" ? true : false}
                    onSelectionModelChange={(ids) => {
                        const selectedIDs = new Set(ids);
                        const selectedRowData = data.filter((row) =>
                            selectedIDs.has(row.id)
                        );
                        console.log("onSelectionModelChange:", selectedRowData);
                        dispatch(selectedState(selectedRowData));
                    }}
                    onCellClick={async (p, e) => {
                        console.log(p.field);
                        let result;
                        if (p.field == "id") {
                            console.log(p.row.order);
                            setLoading(true);
                            setOrder(p.row.id);
                            currentState.state.title === "Pickup Request"
                                ? (result = await axiosInstance
                                      .get(`/Order/${p.row.id}?page_size=4`)
                                      .catch((err) => {
                                          console.log(err);
                                      }))
                                : (result = await axiosInstance
                                      .get(`/Order/${p.row.order.id}`)
                                      .catch((err) => {
                                          console.log(err);
                                      }));
                            if (result) {
                                setLoading(false);
                                console.log(result.data);
                                setDetails(result.data);
                                setTickets(result.data.tickets);
                            }
                            setShow(true);

                            // currentState.title === "Pickup Request" ? setDetails(p.row) : setDetails(p.row.order);
                        }

                        e.defaultMuiPrevented = true;
                    }}
                />{" "}
                {data.length !== 0 ? null : (
                    <div className="noDataDiv">
                        <h6 className="noDataHead">
                            {t("refreshTitle")}{" "}
                            {lang === "en"
                                ? currentState?.state.title
                                : currentState?.state.title_ar}
                        </h6>
                        <p>{t("refreshText")}</p>
                        <button className="confirm">{t("refresh")}</button>
                    </div>
                )}
                {show ? (
                    <OrderDetails
                        pagination={onChangeTickets}
                        current={ticketsCurrent}
                        tickets={tickets}
                        ticketsCount={details.tickets.count}
                        show={show}
                        loading={loading}
                        setClose={onClose}
                        data={details}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default ErrorHandler(RequestsTable, axiosInstance);
