import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../api/axios";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import WalletSummary from "./WalletSummary";
import WalletHistory from "./WalletHistory";
import OrderDetails from "../../layout/OrderDetails";
import { notification } from "antd";
import {useOrdersColumns, useRefundColumns} from "../../layout/tableColumns";
import { usePaymentTransactionColumns } from "../../layout/tableColumns";
import errorHandler from "../../../error-handler/ErrorHandler";
import { Spin } from "antd";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import useLanguage from "../../../hooks/useLanguage";
import TablePro from "../../tables/TablePro";
import {  Input, Button, Modal } from "antd";
import 'antd/dist/antd.css'; // Import Ant Design styles

function SellerWallet() {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [details, setDetails] = useState();
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [view, setView] = useState("shipment");
    const id = useParams().id ?? "";
    const is_superuser = useSelector((state) => state.auth.user.role === "Organization Admin");
    const [loading, setLoading] = useState(true);
    const orderColumns = useOrdersColumns();
    const paymentTransactionColumns = usePaymentTransactionColumns();
    const refundsColumns = useRefundColumns();
    const { language } = useLanguage();
    const lang = useLanguage();
  

    const fetchWallet = async () => {
        setLoading(true);
        const result = await axiosInstance
            .get(`/SellerWallet/${id}`)
            .catch((err) => {
                if (err.response.data) {
                    Object.entries(err.response.data).forEach(
                        ([key, value]) => {
                            notification.error({
                                message: key,
                                description: value,
                                placement: "bottomRight",
                            });
                        }
                    );
                } else {
                    notification.error({
                        message: "Error",
                        description: "Something went wrong",
                        placement: "bottomRight",
                    });
                }
            });
        if (result) {
            setData(result.data);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchWallet();
        setCount(data.length);
        setPage(1);
    }, []);

      // Combine all columns into a single array
      const combinedColumns = [
        ...orderColumns,
        ...paymentTransactionColumns,
        // ...refundsColumns,
        {
            title:t("headerCredit"),
            dataIndex: 'balance',
            key: 'balance',
            render: () => <span>{data.summary ? data.summary.balance : 0}</span>, // Display balance from data.summary.balance for each row
          },
      ];

  // Combine all data into a single array
  const combinedData = [
    ...(data.orders || []),
    ...(data.payment_transactions || []),
    ...(data.refund_requests || []),
  ];
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(data.length);




async function fetchDetails(id) {
  try {
    setDetailsLoading(true);
    let result = await axiosInstance.get(`/Order/${id}`);
    if (result && result.data) {
      setDetails(result.data);
    }
    setDetailsLoading(false);
  } catch (error) {
    setDetailsLoading(false);
    if (lang === 'ar') {
      // Display Arabic message for order not found
      notification.error({
        message: 'لم يتم العثور على الطلب',
        description: 'الطلب المطلوب غير موجود.',
      });
    } else {
      // Display English message for order not found
      notification.error({
        message: 'Order Not Found',
        description: 'The requested order was not found.',
      });
    }
    console.error('Error fetching order details:', error);
  }
}

async function onChangeTickets(page) {
    setDetailsLoading(true);
    const newDetails = { ...details };
    newDetails.tickets.page = page;

    let result = await axiosInstance.get(`/Order/${details.id}`, {
        params: {
            page_size: 4,
            page: page,
        },
    });
    if (result) {
        newDetails.tickets = result.data.tickets;
        newDetails.tickets.page = page;
        setDetails(newDetails);
    }
    setDetailsLoading(false);
}
    return (
        <div>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.wallet")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("sideMenu.selletWallet")}</span>
                </Col>
                <Col className="titleDisplay" sm="12">
                    <h2 className="pageTitle mt-2">
                        {t("sideMenu.selletWallet")}
                    </h2>
                </Col>
            </Row>
            <div dir={language === "a" ? "rtl" : "ltr"}>
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center">
                        <Spin
                            indicator={
                                <LoadingOutlined
                                    style={{ fontSize: 24 }}
                                    spin
                                />
                            }
                        />
                    </div>
                ) : (
                    <div>
                        <div>
                            <WalletSummary summary={data.summary} id = {id} is_superuser = {is_superuser}  fetchWallet={fetchWallet}/>
                        </div>
                       
                        <div className="mt-4 position-relative"> 
                            <WalletHistory
                                data={
                                    {
                                        shipment: data.orders,
                                        recharge: data.payment_transactions,
                                        refund: data.refund_requests,
                                    }[view]
                                }
                                columns={
                                    {
                                        shipment: orderColumns,
                                        recharge: paymentTransactionColumns,
                                        refund: refundsColumns,
                                    }[view]
                                }
                                view={view}
                                setView={setView}
                                loading={loading}
                            />


                            {/* <TablePro
                             loading={loading}
                             selection={"radio"}
                             columns={combinedColumns}
                             dataSource={combinedData}
                             selectedRows={selectedRows}
                             setSelectedRows={setSelectedRows}
                             onRowClick={
                                combinedColumns[0].title === t("orderId")
                                     ? (index, record) => {
                                           fetchDetails(record.id);
                                       }
                                     : combinedColumns[0].title === t("requestId")
                                     ? (index, record) => {
                                           fetchDetails(record.order);
                                       }
                                     : undefined
                             }
                             pagination={{
                                 current: page,
                                 total: count,
                                 onChange: (page) => setPage(page),
                             }}
                            /> */}
                            {details ? (
                    <OrderDetails
                        pagination={onChangeTickets}
                        current={details.tickets.page ?? 1}
                        tickets={details.tickets}
                        ticketsCount={details.tickets.count}
                        show={details}
                        loading={detailsLoading}
                        setClose={() => setDetails(undefined)}
                        data={details}
                    />
                ) : null}
                        </div>
                    </div>
                )}
            </div>
            
        </div>
    );
}

export default errorHandler(SellerWallet, axiosInstance);
