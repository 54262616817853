import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../../../../style-sheets/form.css";
import { Select } from "antd";
import {
    PaginatedSelect,
    Radio,
    TextField,
} from "../../../../../components/form/TextInputFields";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../../../../../api/axios";
import ModifiedForm from "../../../../../components/form/ModifiedForm";
import { addOrder } from "../../../../../global-state/actions/addOrderAction";
import { useTranslation } from "react-i18next";
import { PaymentAlert } from "../../../../../components/modals/confirmationModal";

export default function ReplacementSellerAddress({ value, handleChange }) {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation();

    const [sellers, setSellers] = useState([]);
    const [nextLink, setNextLink] = useState("");
    const [loadMore, setLoadMore] = useState(true);
    const [sellersNext, setSellersNext] = useState([]);
    const [errMsg, setErrMsg] = useState("");
    const [showPaymentAlert, setPaymentAlert] = useState(false);

    const selectedSeller = useSelector((state) => state.order.seller);
    console.log({ selectedSeller });

    const order = useSelector((state) => state.order);

    const plan = useSelector((state) => state.plan.plan);

    const returnedTickets = useSelector(
        (state) => state.returnedTickets.returnedTickets
    );
    const getSellers = async () => {
        let result = await axiosInstance.get(`/FilterSeller/?search=`);
        if (result) {
            setSellers(result.data.data);
            setNextLink(result.data.next);
        }
    };
    const validate = (data) => {
        // console.log(formState.isValid)
        if (data.return_address == undefined) {
            setErrMsg(t("requiredField"));
        } else {
            console.log({ data, s: order.seller });
            dispatch(
                addOrder({
                    ...order,
                    work_model_type: order.seller?.saved_address?.filter(
                        (it) => it.id == data.return_address
                    )[0]?.new_work_model.model_type,
                    return_address: data.return_address,
                    seller_address: data.seller_address,
                })
            );
            setErrMsg("");
            handleChange(null, value + 1);
        }
    };

    useEffect(() => {
        getSellers();
    }, []);
    useEffect(() => {
        setErrMsg("");
    }, [selectedSeller]);

    useEffect(() => {
        if (plan?.orders_count >= plan?.orders_remaining) {
            setPaymentAlert(true);
        }
    }, [plan]);
    return (
        <ModifiedForm submitFunction={validate}>
            <>
                <Row>
                    <Col sm="12" md="6">
                        <div className="text-area-form">
                            <TextField
                                id="dq"
                                className="formTextField text-area"
                                title={t("sellername")}
                                value={
                                    selectedSeller !== ""
                                        ? selectedSeller.user.name
                                        : ""
                                }
                                name={
                                    selectedSeller !== ""
                                        ? selectedSeller.user.name
                                        : ""
                                }
                            />
                            <p className="error">
                                {errMsg !== "" ? errMsg : null}
                            </p>
                            <TextField
                                id="dq"
                                className="formTextField text-area"
                                title={t("storename")}
                                value={
                                    selectedSeller !== ""
                                        ? selectedSeller.store_name
                                        : ""
                                }
                                name={
                                    selectedSeller !== ""
                                        ? selectedSeller.store_name
                                        : ""
                                }
                            />
                            <>
                                <TextField
                                    id="dq"
                                    className="formTextField text-area"
                                    title={t("phone")}
                                    value={
                                        selectedSeller !== ""
                                            ? `${selectedSeller?.user?.phone}`
                                            : ""
                                    }
                                    name={
                                        selectedSeller !== ""
                                            ? `${selectedSeller?.user?.phone}`
                                            : ""
                                    }
                                />{" "}
                                <TextField
                                    id="dqw"
                                    className="formTextField text-area"
                                    title={t("phone")}
                                    value={
                                        selectedSeller !== ""
                                            ? selectedSeller?.user?.phone_alt ??
                                              ""
                                            : ""
                                    }
                                    name={
                                        selectedSeller !== ""
                                            ? `${selectedSeller?.user?.phone_alt}`
                                            : ""
                                    }
                                />
                            </>
                        </div>
                    </Col>
                    <Col sm="12" md="6">
                        <div className="addressCard">
                            <label> {t("chooseSellerReturnAddress")}</label>
                            {selectedSeller !== {}
                                ? selectedSeller?.saved_address?.map(
                                      (address) => {
                                          return (
                                              <>
                                                  <Radio
                                                      value={
                                                          order.return_address ??
                                                          address.id
                                                      }
                                                      id="return_address"
                                                      validation={{
                                                          required: true,
                                                      }}
                                                      name="address"
                                                      className="radio-btn"
                                                      txt={address.address}
                                                  />
                                              </>
                                          );
                                      }
                                  )
                                : null}
                        </div>
                        <div className="addressCard mt-3">
                            <label> {t("chooseSellerAddress")}</label>
                            {selectedSeller !== {}
                                ? selectedSeller?.saved_address?.map(
                                      (address) => {
                                          return (
                                              <>
                                                  <Radio
                                                      value={
                                                          order.seller_address ??
                                                          address.id
                                                      }
                                                      id="seller_address"
                                                      validation={{
                                                          required: true,
                                                      }}
                                                      name="address"
                                                      className="radio-btn"
                                                      txt={address.address}
                                                  />
                                              </>
                                          );
                                      }
                                  )
                                : null}
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col className="text-end mt-2" sm="12">
                        <button
                            onClick={() => handleChange(null, value - 1)}
                            className="previous"
                        >
                            {t("previous")}
                        </button>

                        <input
                            className={
                                user.role == "Seller"
                                    ? "confirmSeller"
                                    : "confirm"
                            }
                            value={t("proceed")}
                            type="submit"
                        />
                    </Col>
                </Row>
            </>
            <PaymentAlert
                body="dqwd"
                title="fewff"
                setShow={setPaymentAlert}
                show={showPaymentAlert}
            />
        </ModifiedForm>
    );
}
