import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../style-sheets/form.css";
import { Select } from "antd";
import { PaginatedSelect, Radio, TextField } from "../form/TextInputFields";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../../api/axios";
import ModifiedForm from "../form/ModifiedForm";
import { addOrder } from "../../global-state/actions/addOrderAction";
import { useTranslation } from "react-i18next";
import { PaymentAlert } from "../modals/confirmationModal";

export default function SellerInfoForm({ value, handleChange, returnedObj }) {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation();

    const [sellers, setSellers] = useState([]);
    const [nextLink, setNextLink] = useState("");
    const [loadMore, setLoadMore] = useState(true);
    const [sellersNext, setSellersNext] = useState([]);
    const [errMsg, setErrMsg] = useState("");
    const [showPaymentAlert, setPaymentAlert] = useState(false);

    const selectedSeller = useSelector(
        (state) => state.selectedData.selectedSeller
    );
    const x = useSelector(
        (state) => state.selectedData
    );

    const order = useSelector((state) => state.order);

    const plan = useSelector((state) => state.plan.plan);

    const returnedTickets = returnedObj ?? [];

    console.log({ returnedTickets });

    const getSellers = async () => {
        let result = await axiosInstance.get(`/FilterSeller/?search=`);
        if (result) {
            setSellers(result.data.data);
            setNextLink(result.data.next);
        }
    };

    const search = async (value) => {
        // setSearchTerm(value)
        let result = await axiosInstance.get(`/FilterSeller/?search=${value}`);
        if (result) {
            console.log(result.data.data);
            setSellers(result.data.data);
            setNextLink(result.data.next);
        }
        console.log(value);
    };
    const onScroll = async (event, link) => {
        console.log("ee");
        var target = event.target;
        let result;
        if (
            loadMore &&
            target.scrollTop + target.offsetHeight === target.scrollHeight
        ) {
            result = await axiosInstance
                .get(`${nextLink}`)
                .catch((err) => console.log(err));

            if (result) {
                setSellers([...sellers, ...result.data.data]);
                setSellersNext(result.data.next);
                if (result.data.next === null) {
                    setLoadMore(false);
                }
            }
        }
    };
    const validate = (data) => {
        // console.log(formState.isValid)
        console.log(data);
        console.log(selectedSeller);
        if (
            data.seller == undefined &&
            selectedSeller == "" &&
            returnedTickets.length == 0
        ) {
            setErrMsg(t("requiredField"));
        } else {
            dispatch(addOrder(data));
            setErrMsg("");
            handleChange(null, value + 1);
        }
    };

    useEffect(() => {
        getSellers();
        console.log( returnedObj)
        console.log( x)
    }, []);
    useEffect(() => {
        setErrMsg("");
    }, [selectedSeller]);

    useEffect(() => {
        if (plan?.orders_count >= plan?.orders_remaining) {
            setPaymentAlert(true);
        }
    }, [plan]);
    return (
        <ModifiedForm submitFunction={validate}>
            <>
                <Row>

 {/* client info form */}

 <Col sm="12" md="4">
                         <p>{t('sender')} :</p>
                        <div className="text-area-form">
                        
                                <TextField
                                    className="formTextField text-area"
                                    title={t("fullname")}
                                    value={
                                        returnedTickets[0].order.client.name
                                    }
                                />
                        
                            
                            <>
                                <TextField
                                    className="formTextField text-area"
                                    title={t("phone")}
                                    value={
                                        returnedTickets[0].order.client.phone 
                                    }
                                    
                                />{" "}
                                <TextField
                                    className="formTextField text-area"
                                    title={t("altPhone")}
                                    value={
                                        returnedTickets[0].order.client.phone_alt
                                    }
                                />
                            </>
                            <TextField
                                    className="formTextField text-area"
                                    title={t("area")}
                                    value={
                                        returnedTickets[0].order.client.client_addresses[0].area.name
                                    }
                                />

                            <TextField
                                    className="formTextField text-area"
                                    title={t("address")}
                                    value={
                                        returnedTickets[0].order.client.client_addresses[0].address
                                    }
                                />
                        </div>
                    </Col>

                    {/* Merchant Info Form  */}
                    {/* <Col sm="12" md="4">
                    <p>{t('reciever')} :</p>     
                        <div className="text-area-form">
                            {returnedTickets.length == 0 ? (
                                <PaginatedSelect
                                    id="seller"
                                    title={t("sellername")}
                                    search={search}
                                    onScroll={(e) => onScroll(e, nextLink)}
                                    choice={selectedSeller?.id ?? null}
                                >
                                    {
                                        //return sellers[index]
                                        sellers.map((seller) => (
                                            <Select.Option
                                                key={seller.id}
                                                sellerinfo={seller}
                                                value={seller.id}
                                            >
                                                {seller.user.name}
                                            </Select.Option>
                                        ))
                                    }
                                </PaginatedSelect>
                            ) : (
                                <TextField
                                    id="dq"
                                    className="formTextField text-area"
                                    title={t("sellername")}
                                    value={
                                        selectedSeller !== ""
                                            ? selectedSeller.user.name
                                            : ""
                                    }
                                    name={
                                        selectedSeller !== ""
                                            ? selectedSeller.user.name
                                            : ""
                                    }
                                />
                            )}

                            <p className="error">
                                {errMsg !== "" ? errMsg : null}
                            </p>
                            <TextField
                                id="dq"
                                className="formTextField text-area"
                                title={t("storename")}
                                value={
                                    selectedSeller !== ""
                                        ? selectedSeller.store_name
                                        : ""
                                }
                                name={
                                    selectedSeller !== ""
                                        ? selectedSeller.store_name
                                        : ""
                                }
                            />
                            <>
                                <TextField
                                    id="dq"
                                    className="formTextField text-area"
                                    title={t("phone")}
                                    value={
                                        selectedSeller !== ""
                                            ? `${selectedSeller?.user?.phone}`
                                            : ""
                                    }
                                    name={
                                        selectedSeller !== ""
                                            ? `${selectedSeller?.user?.phone}`
                                            : ""
                                    }
                                />{" "}
                                <TextField
                                    id="dqw"
                                    className="formTextField text-area"
                                    title={t("altPhone")}
                                    value={
                                        selectedSeller !== ""
                                            ? selectedSeller?.user?.phone_alt ??
                                              ""
                                            : ""
                                    }
                                    name={
                                        selectedSeller !== ""
                                            ? `${selectedSeller?.user?.phone_alt}`
                                            : ""
                                    }
                                />
                            </>

                                <div className="addressCard mt-4" >
                                    <label> {t("chooseSellerAddress")}</label> 
                                    <label> {t("sellerAddress")}</label>
                                    {selectedSeller && selectedSeller.id
                                        ? selectedSeller?.saved_address?.map(
                                            (address) => {
                                                return (
                                                    <>
                                                    
                                                        <Radio
                                                            value={
                                                                order.seller_address ??
                                                                address.id
                                                                
                                                            }
                                                            id="seller_address"
                                                            validation={{
                                                                required: true,
                                                            }}
                                                            name="address"
                                                            className="radio-btn"
                                                            txt={address.address}
                                                        />
                                                    </>
                                                    
                                                );
                                            }
                                        )
                                        : null}
                                </div>
                                </div>
                      
                        
                    </Col> */}
                        <Col sm="12" md="4">
                    <p>{t('reciever')} :</p>     
                        <div className="text-area-form">
                           
                                <TextField
                                    id="dq"
                                    className="formTextField text-area"
                                    title={t("sellername")}
                                    value={
                                        returnedTickets[0].order.seller.user.name
                                    }
                                    name={
                                        returnedTickets[0].order.seller.user.name
                                    }
                                />
                            

                            <TextField
                                id="dq"
                                className="formTextField text-area"
                                title={t("storename")}
                                value={
                                    returnedTickets[0].order.seller.store_name
                                        
                                }
                                name={
                                    returnedTickets[0].order.seller.store_name
                                }
                            />
                            <>
                                <TextField
                                    id="dq"
                                    className="formTextField text-area"
                                    title={t("phone")}
                                    value={
                                        returnedTickets[0].order.seller.user.phone
                                    }
                                    name={
                                        returnedTickets[0].order.seller.user.phone
                                    }
                                />{" "}
                                <TextField
                                    id="dqw"
                                    className="formTextField text-area"
                                    title={t("altPhone")}
                                    value={
                                        returnedTickets[0].order.seller.user.phone_alt
                                    }
                                    name={
                                        returnedTickets[0].order.seller.user.phone_alt
                                    }
                                />
                            </>
                           
                            <TextField
                                    id="dqw"
                                    className="formTextField text-area"
                                    title={t("sellerAddress")}
                                    value={
                                        returnedTickets[0].order.seller_address.address
                                    }
                                    name={
                                        returnedTickets[0].order.seller_address.address
                                    }
                                />
                                
                        </div>
                      
                        
                    </Col>
                   
                    

                     {/* Product info form */}

                     <Col sm="12" md="4">
                     <p>{t('productInfo')} :</p>
                        <div className="text-area-form">
                           
                            <TextField
                                className="formTextField text-area"
                                title={t("product")}
                                value={
                                    returnedTickets[0].product.name
                                }
                            />
                            <>
                                <TextField

                                    className="formTextField text-area"
                                    title={t("quantity")}
                                    value={
                                        returnedTickets[0].quantity
                                    }
                                   
                                />{" "}
                                <TextField
                                    className="formTextField text-area"
                                    title={t("weight")}
                                    value={
                                        returnedTickets[0].weight
                                    }
                                />
                                <TextField
                                    className="formTextField text-area"
                                    title={t("specialLabel")}
                                    value={
                                        t(returnedTickets[0].special_label)
                                    }
                                />
                            </>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col className="text-end mt-2" sm="12">
                        <button
                            className="previous"
                            onClick={() => handleChange(null, value - 1)}
                        >
                            {t("previous")}
                        </button>

                        <input
                            className={
                                user.role == "Seller"
                                    ? "confirmSeller"
                                    : "confirm"
                            }
                            value={t("proceed")}
                            type="submit"
                        />
                    </Col>
                </Row>
            </>
            <PaymentAlert
                body="dqwd"
                title="fewff"
                setShow={setPaymentAlert}
                show={showPaymentAlert}
            />
        </ModifiedForm>
    );
}
