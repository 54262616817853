import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { Select } from "antd";
import axiosInstance from "../../api/axios";
import { useSelector, useDispatch } from "react-redux";
import { addOrder } from "../../global-state/actions/addOrderAction";
import CreateNewProduct from "../modals/CreateNewProduct";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";

export default function ProductInfoForm({ value, handleChange }) {
    const dispatch = useDispatch();
    const order = useSelector((state) => state.order);
    console.log({ order });
    const [products, setProducts] = useState([]);
    const [nextProducts, setNextProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(
        order?.tickets?.map((ticket) => parseInt(ticket.product)) ?? []
    );
    const [selectedLabel, setSelectedLabel] = useState(
        order?.tickets?.map((ticket) => parseInt(ticket.special_label)) ?? []
    );
    const [labels, setLabels] = useState([]);
    const [errMsg, setErrMsg] = useState("");
    const [labelErr, setLabelErr] = useState("");
    const [showCreate, setShowCreate] = useState(false);
    const [productPrice, setProductPrice] = useState([]);
    const [created, setCreated] = useState(true);
    const { t } = useTranslation();

    const [ticketData, setTicket] = useState(
        order?.tickets ?? [
            {
                width: "",
                height: "",
                weight: "",
                product: "",
                quantity: "",
                special_label: "",
                price: "",
            },
        ]
    );

    useEffect(() => {
        console.log(value);
    }, []);
    const user = useSelector((state) => state.auth.user);

    const selectedSeller = useSelector(
        (state) => state.selectedData.selectedSeller
    );
    const handleInput = (e, index) => {
        const { name, value } = e.target;
        const list = [...ticketData];
        list[index][name] = value;
        setTicket(list);
    };

    const handleSelectedProduct = (e, v, i) => {
        // const productPriceList = [...productPrice]
        // productPriceList[i] = v.price
        // setProductPrice(productPriceList)
        console.log(v);
        console.log(e);
        const list = [...ticketData];
        list[i].product = e;
        list[i].price = v.price;
        let copy = [...selectedProduct];
        copy[i] = e;
        setSelectedProduct(copy);
    };

    useEffect(() => {
        console.log(productPrice);
        console.log(ticketData);
    }, [productPrice, ticketData]);

    const handleSelectedLabel = (e, i) => {
        const list = [...ticketData];
        list[i].special_label = e;
        let copy = [...selectedLabel];
        copy[i] = e;
        setSelectedLabel(copy);
    };

    const addAnotherProduct = () => {
        let productList = [...ticketData];
        setTicket([
            ...productList,
            {
                width: "",
                height: "",
                weight: "",
                product: "",
                quantity: "",
                special_label: "",
                price: "",
            },
        ]);
    };

    const getProducts = async () => {
        let result = await axiosInstance.get(
            `/FilterProducts/?seller=${
                user.role === "Seller" ? user.user.user_id : selectedSeller.id
            }`
        );
        if (result) {
            setProducts(result.data.data);
            setNextProducts(result.data.next);
        }
    };


    const validate = () => {
        if (selectedProduct !== "" && selectedLabel !== "") {
            // console.log(formState.isValid)
            const tickets = { tickets: ticketData };
            dispatch(addOrder(tickets));
            handleChange(null, parseInt(value) + 1);
        } else if (selectedProduct === "") {
            setErrMsg("This field is required");
        } else if (selectedLabel === "") {
            setLabelErr("This field is required");
        }
    };
    useEffect(() => {
        setErrMsg("");
    }, [selectedProduct]);
    useEffect(() => {
        setLabelErr("");
    }, [selectedLabel]);

    const previous = () => {
        handleChange(null, parseInt(value) - 1);
    };

    const cancelTicket = (e, i) => {
        console.log(i);
        console.log(ticketData);
        let ticketList = [...ticketData];

        ticketList.splice(i, 1);
        setTicket(ticketList);

        // let priceList = [...productPrice]

        // let newPrice = priceList.splice(i , 1)
        // setProductPrice(newPrice)
    };

    const openCreateModal = () => {
        setShowCreate(true);
        setCreated(false);
    };

    const hideCreateModal = (e) => {
        setShowCreate(false);
    };

    useEffect(() => {
        if (created) getProducts();
    }, [created]);

    const lang = useLanguage();

    return (
        <>
            {ticketData.map((data, i) => {
                return (
                    <>
                        <Row>
                            <Col className="btnPosition" sm-12>
                                <button
                                    className="cancelBtn"
                                    disabled={ticketData.length === 1}
                                    onClick={(e) => cancelTicket(e, i)}
                                >
                                    {t("cancelTicket")}
                                </button>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col sm="12" md="6">
                                <div className="position-relative text-area-form">
                                    <a
                                        className={
                                            lang == "en"
                                                ? "over-select-link-en"
                                                : "over-select-link-ar"
                                        }
                                        onClick={openCreateModal}
                                    >
                                        {t("createNewProduct")}
                                    </a>
                                    <label>{t("selectProduct")}</label>

                                    <Select
                                        value={selectedProduct[i]}
                                        onChange={(e, v) =>
                                            handleSelectedProduct(e, v, i)
                                        }
                                        allowClear
                                        className="form-control paginated"
                                        name="product"
                                    >
                                        <Select.Option selected hidden disabled>
                                            {lang === "en"
                                                ? "Choose a product"
                                                : "اختر المنتج"}
                                        </Select.Option>
                                        {products.map((product) => (
                                            <Select.Option
                                                key={product.id}
                                                name="product"
                                                value={product.id}
                                                price={product.price}
                                            >
                                                {product.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                    <p className="error">
                                        {errMsg !== "" ? errMsg : null}
                                    </p>

                                    <label>{t("weight")}</label>
                                    <Form.Control
                                        className="formTextField text-area"
                                        name="weight"
                                        value={data.weight}
                                        onChange={(e) => handleInput(e, i)}
                                    />

                                    <label>{t("selectLabel")}</label>

                                    <Select
                                        value={
                                            order?.tickets
                                                ? order?.tickets[i]
                                                      ?.special_label
                                                : selectedLabel[i]
                                        }
                                        onChange={(e) =>
                                            handleSelectedLabel(e, i)
                                        }
                                        allowClear
                                        // ref={ref}
                                        // onPopupScroll={onScroll}
                                        className="form-control paginated"
                                        name="label"
                                    >
                                        {labels.map((label) => (
                                            <Select.Option
                                                key={label.id}
                                                name="label"
                                                value={label.id}
                                            >
                                                {label.en} - {label.ar}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                    <p className="error">
                                        {labelErr !== "" ? labelErr : null}
                                    </p>
                                </div>
                            </Col>
                            <Col sm="12" md="6">
                                <label> {t("quantity")}</label>
                                <Form.Control
                                    name="quantity"
                                    onChange={(e) => handleInput(e, i)}
                                    value={data.quantity}
                                    className="formTextField text-area"
                                />{" "}
                                <label>{t("height")} </label>
                                <Form.Control
                                    name="height"
                                    onChange={(e) => handleInput(e, i)}
                                    value={data.height}
                                    className="formTextField text-area"
                                />
                                <label>{t("width")} </label>
                                <Form.Control
                                    name="width"
                                    onChange={(e) => handleInput(e, i)}
                                    value={data.width}
                                    className="formTextField text-area"
                                />
                                <label>{t("price")}</label>
                                <Form.Control
                                    name="price"
                                    // onChange={(e) => handleInput(e, i)}
                                    disabled
                                    value={data.price}
                                    className="formTextField text-area"
                                />
                            </Col>
                        </Row>
                    </>
                );
            })}

            <Row className="mt-4">
                <Col sm="12">
                    <p onClick={addAnotherProduct} className="addBtn">
                        <i className="fas fa-plus-circle mx-2"></i>
                        {t("addAnotherProduct")}
                    </p>
                </Col>
            </Row>
            <Row>
                <Col className="text-end buttons-margin" sm="12">
                    <button className="previous" onClick={previous}>
                        {t("previous")}
                    </button>

                    <button className="confirm" onClick={validate}>
                        {t("proceed")}
                    </button>
                </Col>
            </Row>
            <CreateNewProduct
                setCreated={setCreated}
                showModal={showCreate}
                hideModal={hideCreateModal}
                useModal={true}
            />
        </>
    );
}
