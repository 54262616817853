import { USER_TYPE } from "../actions/actionTypes";
const initialState = {
  userType : ""
};

export default function (state = initialState, action) {
  //  (action.payload);
  switch (action.type) {
    case USER_TYPE:
      return {
        ...state,
        userType : action.payload
      };
    default:
      return state;
  }
}