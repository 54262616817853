import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../../api/axios";
import ErrorHandler from "../../../../error-handler/ErrorHandler";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useLanguage from "../../../../hooks/useLanguage";
import { useCommissionColumns } from "../../../layout/tableColumns"
import TablePro from "../../../tables/TablePro";

function AdminCommissionOrders(props) {
  const { t } = useTranslation();
  const lang = useLanguage();
  const { id } = useParams();
  const cur = useSelector((state) => state.auth.user.user.organization.country.currency);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const commissionColumns = useCommissionColumns();

  // get unsettled commissionOrders
  const getCommissionOrders = async () => {
    setLoading(true);
    try {
      const result = await axiosInstance.get(`/commission/${id}/`, {
        params: {
          page_size: 10,
          page: page,
        },
      });

      if (result) {
        setData(result.data.data);
        setCount(result.data.count);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCommissionOrders();
  }, [id, page]);

  useEffect(() => {
    console.log("Data:", data);
  }, [data]);
  const columns = commissionColumns.map((column) => ({
    ...column,
    dataIndex: column.field,
    key: column.field,
  }));
  return (
    <>
      <Row className="mb-4">
        <Col sm="12">
          <span className="title1">{t("sideMenu.wallet")}</span>
          <i className="fas fa-chevron-right px-3 title2"></i>
          <span className="title2">{t("commissionOrders")}</span>
        </Col>
        <Col sm="12">
          <h2 className="pageTitle">{t("commissionOrders")}</h2>
        </Col>
      </Row>

      <div className="reqTableContainer">
        <TablePro
          columns={commissionColumns}  
          dataSource={data}
          pagination={{
            current: page,
            total: count,
            onChange: (page) => setPage(page),
          }}
        />
      </div>
    </>
  );
}

export default ErrorHandler(AdminCommissionOrders, axiosInstance);