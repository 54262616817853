import React from "react";
import { useTranslation } from "react-i18next";

export function NoPermission() {
    const { i18n } = useTranslation();
    return (
        <ErrorPage>
            {i18n.language === "ar"
                ? "غير مصرح لك بالدخول لهذه الصفحة"
                : "You don't have permission to access this page."}
        </ErrorPage>
    );
}

export function NotFound() {
    const { i18n } = useTranslation();

    return (
        <ErrorPage>
            <div
                style={{
                    fontSize: "180px",
                    letterSpacing: "22px",
                }}
            >
                404
            </div>
            <div
                style={{
                    fontSize: "50px",
                }}
            >
                {i18n.language === "ar"
                    ? "لا يمكن العثور على الوصلة"
                    : "Page Not Found"}
            </div>
        </ErrorPage>
    );
}

export default function ErrorPage({ children }) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                color: "var(--primaryColor)",
                fontWeight: "bold",
                fontSize: "30px",
                textAlign: "center",
                minHeight: "50vh",
            }}
            className="unselectable"
        >
            {children}
        </div>
    );
}
