import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TablePro from "../../../tables/TablePro";
import useLanguage from "../../../../hooks/useLanguage";
import OrderDetails from "../../../layout/OrderDetails";
import axiosInstance from "../../../../api/axios";
import { Select } from 'antd';
function ViewSelector({ view, setView, loading }) {
    const { t } = useTranslation();

    return (
        <div className="d-flex justify-content-center">
            <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label
                    className={`btn btn-outline-success ${
                        view === "shipment" ? "active" : ""
                    }`}
                    style={{
                        borderEndStartRadius: "6px",
                        borderStartStartRadius: "6px",
                        borderStartEndRadius: "0",
                        borderEndEndRadius: "0",
                    }}
                >
                    <input
                        type="radio"
                        name="options"
                        id="option2"
                        autoComplete="off"
                        onClick={() => setView("shipment")}
                    />{" "}
                    {t("sideMenu.shipment")}
                </label>
                <label
                    className={`btn btn-outline-success ${
                        view === "recharge" ? "active" : ""
                    }`}
                >
                    <input
                        type="radio"
                        name="options"
                        id="option1"
                        autoComplete="off"
                        onClick={() => setView("recharge")}
                    />{" "}
                    {t("sideMenu.recharge")}
                </label>
                <label
                    className={`btn btn-outline-success ${
                        view === "refund" ? "active" : ""
                    }`}
                    style={{
                        borderEndStartRadius: "0",
                        borderStartStartRadius: "0",
                        borderStartEndRadius: "6px",
                        borderEndEndRadius: "6px",
                    }}
                >
                    <input
                        type="radio"
                        name="options"
                        id="option3"
                        autoComplete="off"
                        onClick={() => setView("refund")}
                    />{" "}
                    {t("sideMenu.refund")}
                </label>
            </div>
        </div>
    );
}

function WalletHistory({ data, view, setView, loading, columns }) {
    const { t } = useTranslation();
    const [displayedData, setDisplayedData] = useState(data.slice(0, 10));
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(displayedData.length);
    const [selectedRows, setSelectedRows] = useState([]);
    const [details, setDetails] = useState();
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [language] = useLanguage();

    const onChangePage = (page) => {
        setPage(page);
        setDisplayedData(data.slice((page - 1) * 10, page * 10));
    };

    useEffect(() => {
        console.log(data);
        setDisplayedData(data.slice(0, 10));
        setCount(data.length);
        setPage(1);
    }, [data]);

    async function fetchDetails(id) {
        console.log("clicked");
        setDetailsLoading(true);
        let result = await axiosInstance.get(`/Order/${id}`);
        if (result) {
            setDetails(result.data);
        }
        setDetailsLoading(false);
    }

    async function onChangeTickets(page) {
        setDetailsLoading(true);
        const newDetails = { ...details };
        newDetails.tickets.page = page;

        let result = await axiosInstance.get(`/Order/${details.id}`, {
            params: {
                page_size: 4,
                page: page,
            },
        });
        if (result) {
            newDetails.tickets = result.data.tickets;
            newDetails.tickets.page = page;
            setDetails(newDetails);
        }
        setDetailsLoading(false);
    }

    return (
        <div dir={language === "a" ? "rtl" : "ltr"}>
            <div className="d-flex justify-content-between align-items-center">
                <h3 className="font-weight-bold">{t(`sideMenu.${view}`)}</h3>
                {/* <ViewSelector view={view} setView={setView} /> */}
                <select value={view} onChange={(e) => setView(e.target.value)}>
                    <option value="shipment">{t("sideMenu.shipment")}</option>
                    <option value="recharge">{t("sideMenu.recharge")}</option>
                    <option value="refund">{t("sideMenu.refund")}</option>
                </select>
            </div>
            <div className="position-relative">
                <TablePro
                    loading={loading}
                    selection={"radio"}
                    columns={columns}
                    dataSource={displayedData}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    onRowClick={
                        columns[0].title === t("orderId")
                            ? (index, record) => {
                                  fetchDetails(record.id);
                              }
                            : columns[0].title === t("requestId")
                            ? (index, record) => {
                                  fetchDetails(record.order);
                              }
                            : undefined
                    }
                    pagination={{
                        current: page,
                        total: count,
                        onChange: (page) => onChangePage(page),
                    }}
                />
                {details ? (
                    <OrderDetails
                        pagination={onChangeTickets}
                        current={details.tickets.page ?? 1}
                        tickets={details.tickets}
                        ticketsCount={details.tickets.count}
                        show={details}
                        loading={detailsLoading}
                        setClose={() => setDetails(undefined)}
                        data={details}
                    />
                ) : null}
            </div>
        </div>
    );
}

export default WalletHistory;
