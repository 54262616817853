import errorHandler from "../../../error-handler/ErrorHandler"
import axiosInstance from "../../../api/axios";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Steps from "./Steps";

function CreateSupportTicket() {
    const { t } = useTranslation();
    return ( 
        <div>
        <Row className="mb-4">
            <Col sm="12">
                <span className="title1">{t("sideMenu.supportTickets")}</span>
                <i class="fas fa-chevron-right px-3 title2"></i>
                <span className="title2">{t("sideMenu.createSupportTicket")}</span>
            </Col>
            <Col className="titleDisplay" sm="12">
                <h2 className="pageTitle mt-2">
                    {t("sideMenu.createSupportTicket")}
                </h2>
            </Col>
        </Row>
        <div>
            <Steps/>
        </div>
    </div>
     );
}

export default errorHandler(CreateSupportTicket, axiosInstance);