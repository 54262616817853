import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../../api/axios';
import { useSelector } from 'react-redux';
import { Input, Checkbox, Button, Form, Row, Col } from 'antd';

const BankAccountForm = () => {
  const [form] = Form.useForm();
  const [bankAccount, setBankAccount] = useState({
    full_name: '',
    IBAN: '',
    bank_account_number: '',
    bank_name: '',
    accept_terms: false,
  });

  const handleChange = (changedValues) => {
    setBankAccount({ ...bankAccount, ...changedValues });
  };

  const marketerID = useSelector((state) => state.auth.user.user.org_id);

  const onFinish = async () => {
    try {
      await axiosInstance.put(`/bank_acc/${marketerID}/`, bankAccount);
      console.log('Bank account updated successfully!');
    } catch (error) {
      console.error('Error updating bank account:', error);
    }
  };

  useEffect(() => {
    const fetchBankData = async () => {
      try {
        const response = await axiosInstance.get(`/bank_acc/${marketerID}/`);
        setBankAccount(response.data);
        form.setFieldsValue(response.data);
      } catch (error) {
        console.error('Error fetching bank data:', error);
      }
    };

    fetchBankData();
  }, [form,marketerID]);

  return (
    <Form
      form={form}
      initialValues={bankAccount}
      onFinish={onFinish}
      onValuesChange={(_, values) => handleChange(values)}
      layout="vertical"
    >
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Full Name" name="full_name">
            <Input placeholder="Full Name" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="IBAN" name="IBAN">
            <Input placeholder="IBAN" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Bank Account Number" name="bank_account_number">
            <Input placeholder="Bank Account Number" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Bank Name" name="bank_name">
            <Input placeholder="Bank Name" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="accept_terms" valuePropName="checked">
            <Checkbox>Accept Terms</Checkbox>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Button className='confirm' htmlType="submit">
              Update Bank Account
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default BankAccountForm;
