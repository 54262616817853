import React, {useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import "../../../style-sheets/form.css";
import {Select, Form, Input} from "antd";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import axiosInstance from "../../../api/axios";
import {SuccessfulRequest} from "../../modals/confirmationModal";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {LogOut} from "../../../global-state/actions/authAction";
import {useDispatch} from "react-redux";
import useLanguage from "../../../hooks/useLanguage";

const UpdatePassword = () => {

    const {t, i18n} = useTranslation();

    const [oldPass, setOldPassword] = useState("");
    const [newPass, setNewPass] = useState("");
    const [err, setErr] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const lang = useLanguage();
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const update = async (values) => {
        console.log(values)
        let data = {
            old_password: oldPass,
            new_password: newPass
        };


        console.log(data);
        let result = await axiosInstance
            .post("/update_password", data)
            .catch((err) => {
                console.log(err.response);
                setErr(err.response.data);
            });
        if (result) {
            console.log(result);
            if (result.status === 200) {
                setShowConfirm(true);
                setErr("");
                setTimeout(() => {
                    setShowConfirm(false);
                    dispatch(LogOut());
                    history.push("/login");
                }, 2500);
            }
        }
    };
    return (
        <div>
            <Container className="px-5 py-3">
                <Row className="mb-4">
                    <Col sm="12">
                        <span className="title1">{t("sideMenu.settings")}</span>
                        <i class="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">{t("security")}</span>
                        <i class="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">{t("updatePassword")}</span>
                    </Col>
                    <Col sm="12">
                        <h2 className="pageTitle">{t("security")}</h2>
                    </Col>
                </Row>

                <Row className="justify-content-center">

                    <Col className="updatePassDiv" sm={12} md={6}>
                        <Form name="change_password" onFinish={update} onFinishFailed={onFinishFailed}
                              layout="vertical">
                            <h6 className="pageTitle mb-5">{t("updatePassword")}</h6>
                            <Form.Item
                                name="oldPass"
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("inputOldPassword")}`
                                    }
                                ]}
                                label={t("oldPassword")}
                            >
                                <Input.Password
                                    placeholder={t("oldPassword")}
                                    name="oldPass"
                                    value={oldPass}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                />
                            </Form.Item>
                            {err !== "" ? <p className="error">{err}</p> : null}
                            <Form.Item
                                name="newPass"
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("inputNewPassword")}`
                                    }
                                ]}
                                label={t("newPassword")}
                            >
                                <Input.Password
                                    placeholder={t("newPassword")}
                                    name="newPass"
                                    value={newPass}
                                    onChange={(e) => setNewPass(e.target.value)}
                                />
                            </Form.Item>
                            <Form.Item
                                name="confirm"
                                label={t("confirmPasswordLabel")}
                                dependencies={["password"]}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("confirmPassword")}`
                                    },
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue("newPass") === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    `${t("updatePasswordError")}`
                                                )
                                            );
                                        }
                                    })
                                ]}
                            >
                                <Input.Password placeholder={t("newPassword")}/>
                            </Form.Item>
                            <button className="update-password mt-3" htmlType="submit">
                                {t("updatePassword")}
                            </button>
                        </Form>
                    </Col>
                </Row>
            </Container>

            <SuccessfulRequest
                show={showConfirm}
                // hide={hideSuccess}
                title={t("updatePassword")}
                body={t("passwordUpdated")}
            />
        </div>
    );
};

export default ErrorHandler(UpdatePassword, axiosInstance);
