import React, { useState } from "react";
import { useSelector } from "react-redux";
import styles from "./ReportsTypeSelection.module.css";
import { useHistory } from "react-router-dom";
import WorkModelCard from "../../AllWorkModels/WorkModelCard";
import SecondaryWMButton from "../../AllWorkModels/WMButton/SecondaryWMButton";
import WMButton from "../../AllWorkModels/WMButton";
import { useTranslation } from "react-i18next";

export default function ReportsTypeSelection({}) {
    const user = useSelector((state) => state.auth);
    const [selectedType, setSelectedType] = useState(undefined);
    const history = useHistory();
    const { t } = useTranslation();

    let storeList = Object.values(t("reports.store_list", { returnObjects: true })) ?? {};

    if (user && user.user.role === "Accountant" || user.user.role === "Operation Team Member") {
        // Filter out the 2nd and 4th elements from storeList if user.role is "accountant"
        storeList = storeList.filter((item, index) => index === 2 || index === 3);
    }
    const types = [
        {
            path: "branch_reports",
            list: Object.values(
                t("reports.branch_list", { returnObjects: true }) ?? {}
            ),
        },
        {
            path: "store_reports",
            list: storeList,
        },
    ];

    return (
        <div className={styles.container}>
            <WorkModelCard
                title={t("reports.report_type_select")}
                actions={
                    <>
                        <WMButton
                            disabled={!selectedType}
                            onClick={() => {
                                history.push(`/${selectedType}`);
                            }}
                        >
                            {t("choose")}
                        </WMButton>
                    </>
                }
            >
                <div className={styles.centeredCard}>
                    {types.map((it) => (
                        <div
                            key={it.path}
                            className={[
                                styles.typeCard,
                                it.path === selectedType ? styles.selected : "",
                            ].join(" ")}
                            onClick={() => setSelectedType(it.path)}
                        >
                            <header>{t(`reports.${it.path}`)}</header>
                            <ul>
                                {it.list.map((listItem) => (
                                    <li key={listItem}>{listItem}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </WorkModelCard>
        </div>
    );
}
