import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../style-sheets/form.css";
import { Modal, Form, Input, Button } from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axiosInstance from "../../api/axios";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";

const Content = ({ hideModal, setCreated, useModal } = { useModal: true }) => {
    const [pname, setPname] = useState("");
    const [desc, setDesc] = useState("");
    const [price, setPrice] = useState("");
    const { t } = useTranslation();

    const selectedSeller = useSelector(
        (state) => state.selectedData.selectedSeller
    );

    const create = async () => {
        let data = {
            seller: selectedSeller.id,
            price: price,
            name: pname,
            description: desc,
        };
        console.log(data);
        let result = await axiosInstance.post(`/SavedProduct/`, data);
        if (result) {
            console.log(result);
            if (result.status == 201) {
                setCreated(true);
                hideModal?.();
            }
        }
    };
    const lang = useLanguage();
    return (
        <>
            <Container
                dir={lang == "ar" ? "rtl" : "ltr"}
                className={useModal ? "pt-5" : ""}
            >
                <Row>
                    <Col sm="12">
                        <span className="title1">{t("sideMenu.tickets")}</span>
                        <i class="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">{t("addNewProduct")}</span>
                    </Col>
                    <Col sm="12">
                        <h2 className="pageTitle">{t("addNewProduct")}</h2>
                    </Col>
                </Row>
                <Row
                    className={useModal ? "customercreateCard" : ""}
                    style={{ marginTop: "32px" }}
                >
                    <Form
                        name="create_customer"
                        className=""
                        layout="vertical"

                        //   onFinish={onFinish}
                    >
                        {" "}
                        <Row>
                            <Col sm={12} md={6}>
                                <div className="text-area-form">
                                    <Form.Item
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please input product Name!",
                                            },
                                        ]}
                                        label={t("name")}
                                    >
                                        <Input
                                            placeholder={t("name")}
                                            name="pname"
                                            value={pname}
                                            onChange={(e) =>
                                                setPname(e.target.value)
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="desc"
                                        label={t("description")}
                                    >
                                        <Input
                                            placeholder={t("description")}
                                            name="desc"
                                            value={desc}
                                            onChange={(e) =>
                                                setDesc(e.target.value)
                                            }
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col>
                                <div className="text-area-form">
                                    <Form.Item
                                        name="price"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input price!",
                                            },
                                        ]}
                                        label={t("price")}
                                    >
                                        <Input
                                            placeholder={t("price")}
                                            name="price"
                                            value={price}
                                            onChange={(e) =>
                                                setPrice(e.target.value)
                                            }
                                        />
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col className="text-end" sm="12">
                                {useModal && (
                                    <Button
                                        onClick={hideModal}
                                        className="cancel"
                                    >
                                        {t("discard")}
                                    </Button>
                                )}
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="confirm"
                                    onClick={create}
                                >
                                    {t("save")}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Row>
            </Container>
        </>
    );
};
const CreateNewProduct = (
    { showModal, hideModal, setCreated, useModal } = { useModal: true }
) => {
    return (
        <div>
            {useModal ? (
                <Modal
                    destroyOnClose={true}
                    width={700}
                    visible={showModal}
                    onCancel={hideModal}
                >
                    <Content
                        setCreated={setCreated}
                        hideModal={hideModal}
                        useModal={useModal}
                    />
                </Modal>
            ) : (
                <Content
                    setCreated={setCreated}
                    hideModal={hideModal}
                    useModal={useModal}
                />
            )}
        </div>
    );
};

export default ErrorHandler(CreateNewProduct, axiosInstance);
