import React, { useTransition } from "react";
import styles from "./PermissionsList.module.css";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";

function PermissionCard({ permission, onUpdate }) {
    const onClick = (e) => {
        e.preventDefault();
        onUpdate(!permission.active);
    };
    const { t } = useTranslation();

    return (
        <li>
            <div className={styles.card}>
                <div>{permission.name}</div>
                {!permission.active ? (
                    <button onClick={onClick} className={styles.activate}>
                        {t("activate")}
                    </button>
                ) : (
                    <button onClick={onClick} className={styles.deactivate}>
                        {t("deactivate")}
                    </button>
                )}
            </div>
        </li>
    );
}

export default function PermissionsList({
    permissions,
    loading,
    updatePermission,
}) {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <h1>{t("permissions")}</h1>

            {loading ? (
                <div>
                    <Spin />
                </div>
            ) : (
                <ul>
                    {permissions?.map((permission, i) => (
                        <PermissionCard
                            onUpdate={(v) => updatePermission(i, v)}
                            key={permission.id}
                            permission={permission}
                        />
                    ))}
                </ul>
            )}
        </div>
    );
}
