import {LOGIN, LOGOUT, REFRESH_ORG, REGISTER} from "../actions/actionTypes";

const initialState = {
    isAuth: false,
    user: {},
    accessToken: "",
};

export default function (state = initialState, action) {
    //  (action.payload);
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                isAuth: true,
                user: action.payload,
            };
        case REGISTER:
            return {
                ...state,
                // isAuth: false,
            };

        case REFRESH_ORG:
            return {
                ...state,
                user: {
                    ...state.user,
                    org: action.payload,
                },
            };
        case LOGOUT:
            return { ...state, isAuth: false, user: {} };

        default:
            return state;
    }
}
