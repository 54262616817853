import Settings from "../../components/pages/settings/settings";
import { SettingsMajor } from "@shopify/polaris-icons";
import Organization from "../../components/pages/settings/organization";
import Security from "../../components/pages/settings/security";

import { useTranslation } from "react-i18next";
import Areas from "../../components/pages/areas";
import BranchesPage from "../../components/pages/Branch";
import Dimentions from "../../components/pages/dimentions";
import AllPreferences from "../../components/pages/settings/Preferences/AllPreferences";
import TrakingBoard from "../../components/pages/settings/Preferences/TrakingBoard";
import SystemTheme from "../../components/pages/settings/Preferences/systemTheme";
import TableHeaders from "../../components/pages/settings/Preferences/TableHeaders";
import UpdatePassword from "../../components/pages/settings/UpdatePassword";
import LanguageCurrency from "../../components/pages/settings/LanguageCurrency";
import Test from "../../components/pages/test";
import SellerDetails from "../../components/pages/settings/Preferences/SellerDetails";
import SellerTax from "../../components/pages/settings/Preferences/sellerTax";
import MarketerInvitation from "../../components/pages/wallets/marketerWallet/marketerInvitation"
import BankAccountForm from "../../components/pages/wallets/marketerWallet/bankAccount"
import SellerTypeToggle from "../../features/seller/SellerTypeToggle";
export default function useSettingsRoutes() {
    const { t } = useTranslation();
    return {
        "/settings": {
            component: Settings,
            icon: SettingsMajor,
            title: t("sideMenu.settings"),
        },
        "/organization": {
            component: Organization,
        },

        "/areas/:branch/:id": {
            component: Areas,
        },
        "/areas": {
            component: Areas,
        },
        "/branches": {
            component: BranchesPage,
        },
        "/dimensions": {
            component: Dimentions,
        },
        "/preferences": {
            component: AllPreferences,
        },
        "/traking": {
            component: TrakingBoard,
        },
        "/theme": {
            component: SystemTheme,
        },
        "/create_headers": {
            component: TableHeaders,
        },
        "/update_password": {
            component: UpdatePassword,
        },
        "/lang_settings": {
            component: LanguageCurrency,
        },
        "/test": {
            component: Test,
        },
        "/seller_details": {
            component: SellerDetails,
        },
        "/seller_tax": {
            component: SellerTax,
        },
        "/invitation_code": {
            component: MarketerInvitation,
        },
        "/bank_acc": {
            component: BankAccountForm,
        },
        // "/seller_type": {
        //     component: SellerTypeToggle,
        // },
        
    };
}
