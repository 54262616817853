import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import PhoneIcon from "@mui/icons-material/Phone";
import TicketCard from "./TicketCard";
import { Spin, Pagination } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";
import { useSelector } from "react-redux";
import moment from "moment/moment";

const antIcon = <LoadingOutlined style={{ fontSize: 115 }} spin />;

const OrderDetails = ({
    tickets,
    current,
    pagination,
    ticketsCount,
    loading,
    show,
    data,
    setClose,
}) => {
    const cur = useSelector(
        (state) => state.auth.user.user.organization.country.currency
    );
    const { t } = useTranslation();
    const lang = useLanguage();

    const close = () => {
        setClose(false);
    };

    useEffect(() => {
        console.log(data);
        console.log(data.tickets);
        console.log(ticketsCount);
    }, []);

    const cssClasses = [
        "Modal detailsCard",
        show ? "ModalOpen" : "ModalClosed",
    ];

    return (
        <>
            <div
                dir={lang === "en" ? "ltr" : "rtl"}
                style={{
                    zIndex: "100",
                }}
                className={cssClasses.join(" ")}
            >
                {loading ? (
                    <Spin indicator={antIcon} />
                ) : (
                    <>
                        <div className="sectionPadding">
                            <p>
                                <i onClick={close} className="fas fa-times" />
                            </p>
                            <h3>
                                {lang === "en"
                                    ? ` Order ID#${data.id}`
                                    : `رقم الشحنة ${data.id}`}
                            </h3>
                            <span>{data.type}</span>
                            {/* <i className="fas fa-expand iconPadding"></i> */}
                        </div>
                        <div className="costSection">
                            <table className="costTable">
                                <thead>
                                    <tr className="my-2">
                                        <th></th>
                                        <th>{t("shipment")}</th>
                                        <th>{t("products")}</th>
                                        <th>{t("total")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p className="paymentMethod">
                                                {data.payment_method}
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                {t(cur)}
                                                <span className="priceSpan">
                                                    {data.delivery_fees}
                                                </span>
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                {" "}
                                                {t(cur)}{" "}
                                                <span className="priceSpan">
                                                    {data.sub_total}
                                                </span>
                                            </p>
                                        </td>
                                        <td>
                                            <p>
                                                {" "}
                                                {t(cur)}
                                                <span>
                                                    {parseFloat(
                                                        data.delivery_fees
                                                    ) +
                                                        parseFloat(
                                                            data.sub_total
                                                        )}
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="section">
                            <h5>{t("receiverInfo")}</h5>
                            <Row>
                                <Col sm="6">
                                    <p>
                                        <i className="fas fa-user userIcons" />
                                        {data.client?.name}
                                    </p>
                                </Col>
                                <Col sm="6">
                                    <p>
                                        <PhoneIcon className="phoneIcon" />{" "}
                                        {data.client?.phone}
                                    </p>
                                </Col>
                                <Col sm="6">
                                    {" "}
                                    <p>
                                        <i className="fas fa-map-marker-alt userIcons"></i>{" "}
                                        {data.client_address.address}
                                    </p>
                                </Col>
                                <Col sm="6">
                                    <p>
                                        <PhoneIcon className="phoneIcon" />{" "}
                                        {data.client?.phone}
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        <div className="section">
                            <Row>
                                <Col dir="ltr" sm={12}>
                                    <h5>{t("productDetails")}</h5>
                                    <Pagination
                                        pageSize="4"
                                        onChange={pagination}
                                        defaultCurrent={current}
                                        total={ticketsCount}
                                        current={current}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                {tickets.data.map((ticket) => {
                                    console.log(ticket);
                                    return (
                                        <Col key={ticket.id} sm={6}>
                                            {" "}
                                            <TicketCard data={ticket} />{" "}
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default ErrorHandler(OrderDetails, axiosInstance);
