import useQuery from "../../../../hooks/useQuery";
import ComplainStep from "./ComplainStep";
import OrderStep from "./OrderStep";


function StepNavigator() {
    const { query } = useQuery();
    return ( 
        isNaN(query.order) ? <OrderStep /> : <ComplainStep />
     );
}

export default StepNavigator;