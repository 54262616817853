import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../../../api/axios";
import ErrorHandler from "../../../../error-handler/ErrorHandler";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Input, Button, Modal , Tag } from "antd";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useLanguage from "../../../../hooks/useLanguage";
import TablePro from "../../../tables/TablePro";
import { DatePicker } from 'antd';
function CommissionOrders(props) {
  const { t } = useTranslation();
  const lang = useLanguage();
  const marketerID = useSelector((state) => state.auth.user.user.org_id);
  const history = useHistory();
  const branch = useSelector((state) => state.branch.branch);
  const cur = useSelector(state => state.auth.user.user.organization.country.currency)
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [columns, setColumns] = useState([
    {
      title: t('orderId'),
      dataIndex: ['order','id'],
      key: 'id',
      render: (id) => (
          <span className="tableID">
              {id}
          </span>
      ),
  },
  {
      title: t('createDate'),
      dataIndex: ['order', 'create_date'],
      key: 'create_date',
      render: (text, record) => (
          <span>{new Date(record.order.create_date).toLocaleDateString()}</span>
      ),
  },
  {
      title: t('status'),
      dataIndex: ['setteled'],
      key: 'setteled',
      render: (setteled) => (
          setteled ? <Tag color="green">{t('true')}</Tag> : <Tag color="red">{t('false')}</Tag>
      ),
  },
  {
    title: t('seller'),
    dataIndex: [ "order",'seller_name'],
    key: 'seller_name',
},
  {
      title: t('store'),
      dataIndex: [ "order",'seller_store'],
      key: 'store_name',
  },
  {
      title: t('commission'),
      dataIndex: [ 'commission_value'],
      key: 'commission_value',
  },
    {
      field: "payment_method",
      headerName: `${t("paymentMethod")}`,
      width: 240,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => (
        <p className="paragraphMargin">{row.row?.order.payment_method}</p>
      )
    }
  ]);

  

  

  const getCommissionOrders = async () => {
    setLoading(true);
    try {
      const result = await axiosInstance.get(`/commission/${marketerID}/?setteled=False`, {
        params: {
          page_size: 10,
          page: page,
        },
      });

      if (result) {
        setData(result.data.data);
        setCount(result.data.count);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };


const [approval_img , setApproval] = useState("");
const [isModalVisible, setIsModalVisible] = useState(false);


  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [imagePreview, setImagePreview] = useState(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setApproval(file);
       // Set image preview for the uploaded file
       const reader = new FileReader();
       reader.onload = (e) => {
         setImagePreview(e.target.result);
       };
       reader.readAsDataURL(file);
    } else {
      setApproval(""); 
    }
       
  };


const handleCreateSettleRequest = (start_date, end_date) => {
    // if (!approval_img) {
    //   console.error('Approval image not provided');
    //   return;
    // }
  
    const formData = new FormData();
    formData.append('marketer_id', marketerID);
    formData.append('start_date', start_date);
    formData.append('end_date', end_date);
    // formData.append('approval_img', approval_img);
  
    axiosInstance.post(`/settle-requests/${marketerID}/`, formData)
      .then(response => {
        console.log('SettleRequest created:', response.data);
        toast.success("تم تسجيل طلبك بنجاح");
        setApproval(""); // Reset approval_img state if needed
        setIsModalVisible(false); // Close the modal after successful creation
      })
      .catch(error => {
        if (error.response.data.message === "already has a Pendding SettleRequest ") {
          toast.error("لديك طلب معلق بالفعل");
        } else {
          toast.error(error.response.data.message);
        }
       
        setIsModalVisible(false);
      });
  };
  


  
 

  
  useEffect(() => {
    console.log('start date',selectedMonth && selectedMonth.startOf("month").format("DD-MM-YYYY") )
    console.log('end date',selectedMonth && selectedMonth.endOf("month").format("DD-MM-YYYY") )
  }, [selectedMonth]);

  useEffect(() => {
    getCommissionOrders();
  }, [marketerID, page]);
  return (
    <>
      <Row className="mb-4">
        <Col sm="12">
          <span className="title1">{t("sideMenu.wallet")}</span>
          <i class="fas fa-chevron-right px-3 title2"></i>
      
          <span className="title2">{t("commissionOrders")}</span>
        </Col>
        <Col sm="12">
          <h2 className="pageTitle">{t("commissionOrders")}</h2>
        </Col>
        <Col sm="12" className="text-start mt-3">
        <DatePicker.MonthPicker
            placeholder="اختر الشهر"
            onChange={(date, dateString) => setSelectedMonth(date)}
          />
            </Col>
        <Col sm="12" className="text-end mt-3">
        <Button type="primary" className="confirm" onClick={() => handleCreateSettleRequest(
              selectedMonth && selectedMonth.startOf("month").format("DD-MM-YYYY"),
              selectedMonth && selectedMonth.endOf("month").format("DD-MM-YYYY"))}>{t("createSettleRequest")}</Button>
        </Col>
       

        <ToastContainer />
      </Row>
      <Row>
      
      
      </Row>
    
      <div className="reqTableContainer">
        <div className="tableHeader">
          <h6>{branch} {t("orderDetails")}</h6>
        </div>

        <div className="requestTable" dir={lang === "en" ? 'ltr' : 'rtl'}>
        <TablePro
          columns={columns}  
          dataSource={data}
          pagination={{
            current: page,
            total: count,
            onChange: (page) => setPage(page),
          }}
        />
        </div>
      </div>
      <Modal
        title="Upload Image"
        visible={isModalVisible}
        onCancel={handleCancel}
    
        centered // To center the modal
      >
        <div style={{ textAlign: 'center' }}>
          {/* Image preview */}
          {imagePreview && (
            <img src={imagePreview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '300px' }} />
          )}

          {/* Input for file upload */}
          <Row>
          <Col>
          <Input type="file" onChange={handleImageUpload} />
          </Col>
          <Col>
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleCreateSettleRequest}>
            Submit
          </Button>,
          </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}

export default ErrorHandler(CommissionOrders, axiosInstance);
