import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../../api/axios";
import { SuccessfulRequest, UnSuccessfulRequest } from "../../../../../components/modals/confirmationModal";
import PageHeader from "../../../../../components/layout/PageHeader";
import TablePro from "../../../../../components/tables/TablePro";
import WMButton from "../../../../../components/pages/AllWorkModels/WMButton";
import useLanguage from "../../../../../hooks/useLanguage";
import OrderDetails from "../../../../../components/layout/OrderDetails";
import { useTranslation } from "react-i18next";
import { useRefundColumns } from "../../../../../components/layout/tableColumns";
import Input from "antd/lib/input/Input";
export default function ReviewRefunds() {
    const { t } = useTranslation();

    // State variables
    const [loading, setLoading] = useState(false);
    const [requests, setRequests] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [error, setError] = useState();
    const [successful, setSuccessful] = useState(false);
    const lang = useLanguage();
    const [details, setDetails] = useState();
    const [detailsLoading, setDetailsLoading] = useState(false);

    // Fetch order details
    async function fetchDetails(id) {
        console.log("clicked");
        setDetailsLoading(true);
        let result = await axiosInstance.get(`/Order/${id}`);
        if (result) {
            setDetails(result.data);
        }
        setDetailsLoading(false);
    }

    // Fetch order tickets on page change
    async function onChangeTickets(page) {
        setDetailsLoading(true);
        const newDetails = { ...details };
        newDetails.tickets.page = page;

        let result = await axiosInstance.get(`/Order/${details.id}`, {
            params: {
                page_size: 4,
                page: page,
            },
        });
        if (result) {
            newDetails.tickets = result.data.tickets;
            newDetails.tickets.page = page;
            setDetails(newDetails);
        }
        setDetailsLoading(false);
    }

    // Fetch refund requests on page change
    useEffect(() => {
        fetchRequests();
    }, [page]);

    // Handle decision on refund request
    async function onDecide(id, isApproved, adminNote) {
        try {
            setLoading(true);
            const res = await axiosInstance.patch(
                `/WalletRefundRequest/${id}`,
                {
                    state: isApproved ? 2 : 3,
                    admin_note: adminNote, // Include updated admin note
                }
            );
            setSuccessful(true);
            fetchRequests();
            setRequests(prevRequests => prevRequests.map(req => req.id === id ? {...req, admin_note: adminNote} : req)); // Update the admin note of the specific item
        } catch (e) {
            setError(e.response.data);
        } finally {
            setLoading(false);
        }
    }

    // Handle decision on refund request
    async function onUpdateNote(id,  adminNote) {
        try {
            setLoading(true);
            const res = await axiosInstance.patch(
                `/WalletRefundRequest/${id}`,
                {
                    admin_note: adminNote, // Include updated admin note
                }
            );
            setSuccessful(true);
            fetchRequests();
            setRequests(prevRequests => prevRequests.map(req => req.id === id ? {...req, admin_note: adminNote} : req)); // Update the admin note of the specific item
        } catch (e) {
            setError(e.response.data);
        } finally {
            setLoading(false);
        }
    }
    // Fetch refund requests
    async function fetchRequests() {
        try {
            setLoading(true);
            const res = await axiosInstance.get("/WalletRefundRequest/", {
                params: {
                    page,
                    state: 1,
                },
            });
            setRequests(res.data.data);
            setCount(res.data.count);
        } catch (e) {
        } finally {
            setLoading(false);
        }
    }

    // Table columns definition
    const columns = [
        ...useRefundColumns().filter((it) => it.dataIndex !== "state"),
        {
            title: t("adminNote"),
            dataIndex: "admin_note",
            render: (adminNote, item) => (
                <Input
                    type="text"
                    value={adminNote}
                    style={{ minWidth: "150px", width: `${Math.max(adminNote.length * 5, 50)}px` }} // Set minimum width to 15px
                    onChange={(e) => onAdminNoteChange(item.id, e.target.value)}
                />
                
            ),
        },
        {
            title: t("actions"),
            render: (_, item) => (
                <div
                    style={{
                        display: "flex",
                        gap: "8px",
                    }}
                >
                    <WMButton onClick={() => onUpdateNote(item.id,item.admin_note)}>
                        {t("update")}
                    </WMButton>
                    <WMButton onClick={() => onDecide(item.id, true, item.admin_note)}
                     style={{
                        backgroundColor: "#10950c",
                    }}>
                        {t("approve")}
                    </WMButton>
                    <WMButton
                        onClick={() => onDecide(item.id, false, item.admin_note)}
                        style={{
                            backgroundColor: "#ba0000",
                        }}
                    >
                        {t("reject")}
                    </WMButton>
                </div>
            ),
        },
    ];

    // Handle admin note change
    async function onAdminNoteChange(id, adminNote) {
        setRequests(prevRequests => prevRequests.map(req => req.id === id ? {...req, admin_note: adminNote} : req));
    }

    return (
        <div>
            <PageHeader
                title={t("sideMenu.reviewRefunds")}
                group={t("sideMenu.wallet")}
            />
            <div className="position-relative">
                <TablePro
                    loading={loading}
                    dataSource={requests}
                    columns={columns}
                    onRowClick={(index, record) => {
                        fetchDetails(record.order);
                    }}
                    pagination={{
                        current: page,
                        total: count,
                        onChange: (page) => setPage(page),
                    }}
                />
                {details ? (
                    <OrderDetails
                        pagination={onChangeTickets}
                        current={details.tickets.page ?? 1}
                        tickets={details.tickets}
                        ticketsCount={details.tickets.count}
                        show={details}
                        loading={detailsLoading}
                        setClose={() => setDetails(undefined)}
                        data={details}
                    />
                ) : null}
            </div>
            <SuccessfulRequest
                title={t("successfulRequest")}
                description={
                    lang === "en"
                        ? "Decision has been made successfully"
                        : "تم حفظ القرار بنجاح"
                }
                show={successful}
                hide={() => setSuccessful(false)}
            />
            <UnSuccessfulRequest
                title={t("error")}
                description={error}
                show={error}
                hide={() => setError(undefined)}
            />
        </div>
    );
}
