import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ModifiedForm from "../form/ModifiedForm";
import "../../style-sheets/form.css";
import { Select, Modal, Form, Input, Button } from "antd";
import { useForm } from "react-hook-form";
import { PaginatedSelect, TextField } from "../form/TextInputFields";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axiosInstance from "../../api/axios";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";
import SelectSearch from "../select-search/SelectSearch";
import SelectAreas from "../select-search/AreasSelect";
import PhoneInput from "../phone-input";

const { Option } = Select;

const CreateNewCustomer = ({ showModal, hideModal, setCreated }) => {
    // const [ZonesNext, setZonesNext] = useState([]);
    const [nextLink, setNextLink] = useState("");
    const [loadMore, setLoadMore] = useState(true);
    const [areas, setareas] = useState([]);
    const [selectedArea, setselectedArea] = useState("");
    const [fullName, setFullName] = useState("");
    const [phone, setPhone] = useState("");
    const [phone2, setPhone2] = useState("");
    const [address, setaddress] = useState("");
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const selectedSeller = useSelector(
        (state) => state.selectedData.selectedSeller
    );
    const user = useSelector((state) => state.auth.user);

    const getAreas = async (search = "") => {
        console.log(search, "search");
        let result = await axiosInstance
            .get(`/FilterAreas/`, {
                params: {
                    search,
                },
            })
            .catch((err) => console.log(err));

        if (result) {
            setareas(result.data.data);
            setNextLink(result.data.next);
        }
    };

    const onScroll = async (event) => {
        console.log("ee");
        var target = event.target;
        let result;
        if (
            loadMore &&
            target.scrollTop + target.offsetHeight === target.scrollHeight
        ) {
            result = await axiosInstance
                .get(`${nextLink}`)
                .catch((err) => console.log(err));

            if (result) {
                setareas([...areas, ...result.data.data]);
                setNextLink(result.data.next);
                if (result.data.next === null) {
                    setLoadMore(false);
                }
            }
        }
    };
    const changeAreas = (e) => {
        setselectedArea(e);
    };

    const create = async () => {
        let data = {
            seller:
                user.role === "Seller" ? user.user.user_id : selectedSeller.id,
            phone: phone,
            phone_alt: phone2,
            name: fullName,
            address: address,
            area: selectedArea,
        };
        console.log(data);
        let result = await axiosInstance
            .post(`/SavedClient/`, data)
            .catch((err) => {
                const error = err.response.data;
                console.log({ error });
                if (!error) return;
                form.setFields([
                    ...Object.entries(error).map(([name, value]) => ({
                        name,
                        errors: value,
                    })),
                ]);
                // form.submit();
            });
        if (result) {
            console.log(result);
            if (result.status == 200) {
                setCreated(true);
                hideModal();
            }
        }
    };
    const lang = useLanguage();
    return (
        <div>
            <Modal
                destroyOnClose={true}
                width={1000}
                visible={showModal}
                onCancel={hideModal}
                // zIndex={10000}
                // style={{
                //     zIndex: "-1",
                //     isolation: "isolate",
                // }}
            >
                <Container dir={lang == "ar" ? "rtl" : "ltr"} className="pt-5">
                    <Row>
                        <Col sm="12">
                            <span className="title1">
                                {t("sideMenu.tickets")}
                            </span>
                            <i class="fas fa-chevron-right px-3 title2"></i>
                            <span className="title2">
                                {t("createNewOrder")}
                            </span>
                            <i class="fas fa-chevron-right px-3 title2"></i>
                            <span className="title2">
                                {t("addNewCustomer")}
                            </span>
                        </Col>
                        <Col sm="12">
                            <h2 className="pageTitle">{t("addNewCustomer")}</h2>
                        </Col>
                    </Row>
                    <Row className="customercreateCard">
                        <Form
                            name="create_customer"
                            className=""
                            layout="vertical"
                            dir={lang === "ar" ? "rtl" : "ltr"}
                            form={form}
                            //   onFinish={onFinish}
                        >
                            {" "}
                            <Row>
                                <Col sm={12} md={6}>
                                    <div className="text-area-form">
                                        <Form.Item
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please input your Name!",
                                                },
                                            ]}
                                            label={t("fullname")}
                                        >
                                            <Input
                                                placeholder={t("fullname")}
                                                name="fullName"
                                                value={fullName}
                                                onChange={(e) =>
                                                    setFullName(e.target.value)
                                                }
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="phone"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please input your Phone!",
                                                },
                                            ]}
                                            label={
                                                <div>
                                                    <label>{t("phone")}</label>
                                                    <p className={"note"}>
                                                        {t("phonePlaceholder")}
                                                    </p>
                                                </div>
                                            }
                                        >
                                            <PhoneInput
                                                formType={"antd"}
                                                placeholder={t("phone")}
                                                name="phone"
                                                value={phone}
                                                onChange={(e) => setPhone(e)}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="phone_alt"
                                            label={
                                                <div>
                                                    <label>
                                                        {t("altPhone")}
                                                    </label>
                                                    <p className={"note"}>
                                                        {t("phonePlaceholder")}
                                                    </p>
                                                </div>
                                            }
                                        >
                                            <PhoneInput
                                                formType={"antd"}
                                                placeholder={t("phone")}
                                                name="phone_alt"
                                                value={phone2}
                                                onChange={(e) => setPhone2(e)}
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="text-area-form">
                                        <Form.Item
                                            name="area"
                                            label={t("area")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please select zone!",
                                                },
                                            ]}
                                        >
                                            <SelectAreas
                                                value={selectedArea}
                                                style={{ width: "100%" }}
                                                className="formTextField text-area"
                                                onChange={changeAreas}
                                                filterOption={false}
                                                showSearch
                                                onPopupScroll={onScroll}
                                                onClick={() => getAreas("")}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="adress"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please input your Address!",
                                                },
                                            ]}
                                            label={t("address")}
                                        >
                                            <Input
                                                placeholder={t("address")}
                                                name="address"
                                                value={address}
                                                onChange={(e) =>
                                                    setaddress(e.target.value)
                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-5">
                                <Col className="text-end" sm="12">
                                    <Button
                                        onClick={hideModal}
                                        className="cancel"
                                    >
                                        {t("discard")}
                                    </Button>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="confirm"
                                        onClick={create}
                                    >
                                        {t("save")}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Row>
                </Container>
            </Modal>
        </div>
    );
};

export default ErrorHandler(CreateNewCustomer, axiosInstance);
