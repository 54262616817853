import React, { useState, useEffect } from "react";
import { Form, Input, Button, DatePicker, InputNumber  , notification} from "antd";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import moment from 'moment';
function CreateExtraOrders() {
  const { t } = useTranslation();
  const userRole = useSelector((state) => state.auth.user.role);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    
        const fetchOrders = async () => {
            setLoading(true);
            try {
              const result = await axiosInstance.get("/FilterOrder/", {
                params: {
                  page: 1,
                  track_number: searchQuery,
                },
              });
              if (result.data && result.data.data && result.data.data.length > 0) {
                setOrderData(result.data.data[0]);
              } else {
                console.error("No data found in the API response.");
              }
            } catch (error) {
              console.error("Error fetching orders:", error);
            } finally {
              setLoading(false);
            }
          };
          fetchOrders();  
      console.log('####',orderData)

  }, [searchQuery]);

  

  useEffect(() => {
    if (orderData) {
      form.setFieldsValue({
        id: orderData.id,
        seller_id: orderData.seller ? orderData.seller.id : "",
        store_name: orderData.seller?.store_name || "",
        provider_name: orderData.shipment_order?.provider_name || "",
        trackNumber:
          orderData.shipment_order?.provider_name === "Aramex"
            ? orderData.shipment_order?.shipment_id || ""
            : orderData.shipment_order?.waybill_number || "",
        user_added_date: null,
        weight: null,
        extra_weight: null,
        extra_weight_charge: null,
      });
    }
  }, [orderData, form]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
        // Formate Date 
        let formattedDate = null;
        if (values.user_added_date) {
        formattedDate = values.user_added_date.clone().startOf('month').format('YYYY-MM-DD');
        }

      await axiosInstance.post("/extra-orders/", {
        order: orderData.id,
        weight: values.weight,
        extra_weight: values.extra_weight,
        extra_weight_charge: values.extra_weight_charge,
        user_added_date : formattedDate
      });
      // Handle success
      notification.success({
        message: 'Success',
        description: 'Extra order created successfully.',
      });
    } catch (error) {
      console.error("Error creating extra order:", error);
      // Handle error
      notification.error({
        message: 'Error',
        description: 'Failed to create extra order. Please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row className="mb-4">
        <Col span={24}>
          <h2 className="pageTitle">{t("createExtraOrder")}</h2>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col span={8}>
          <Input
            placeholder={t("trackNumber")}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Col>
        <Col span={8}>
        </Col>
      </Row>
      {orderData && searchQuery && (
        <Form
          form={form}
          name="createExtraOrderForm"
          onFinish={onFinish}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Row>
            <Col span={12}>
            <Form.Item
                label="ID"
                name="id"
                style={{ display: 'none' }} // Add this line
                >
                <Input disabled />
            </Form.Item>
            <Form.Item label={t("seller_id")} name="seller_id">
                <Input disabled />
              </Form.Item>
              <Form.Item label={t("storename")} name="store_name">
                <Input disabled />
              </Form.Item>
              <Form.Item label={t("shipmentProvider")} name="provider_name">
                <Input disabled />
              </Form.Item>
              <Form.Item label={t("trackNumber")} name="trackNumber">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              {/* <Form.Item
                label="Date"
                name="Date"
                rules={[{ required: true, message: "Please select a date" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item> */}
              <Form.Item label={t("createDate")} name="user_added_date">
                <DatePicker picker="month"  format="YYYY-MM" />
              </Form.Item>
              <Form.Item
                label={t("weight")}
                name="weight"
                rules={[
                  { required: true, message: "Please input the weight" },
                  { type: "number", message: "Weight must be a number" },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                label={t("extraWeight")}
                name="extra_weight"
                rules={[
                    { required: true, message: "Please input the Extra weight" },
                    { type: "number", message: "Extra Weight must be a number" },
                  ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                label={t("extraWeightCharge")}
                name="extra_weight_charge"
                rules={[
                    { required: true, message: "Please input the Charge" },
                    { type: "number", message: "Extra Weight must be a number" },
                  ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
            <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'left', marginTop: '20px' }}>
            <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{ backgroundColor: '#eb2f96', borderColor: '#eb2f96' }}
            >
                {t("submit")}
            </Button>
            </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
}

export default ErrorHandler(CreateExtraOrders, axiosInstance);
