import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../../../../style-sheets/form.css";
import { useSelector, useDispatch } from "react-redux";

import OperationPersonalInfo from "./operationPersonalInfo";
import SellerPersonalInfo from "./sellerPersonalInfo";
import ThirdPartyInfo from './thirdPartyInfo'

export default function AccountPersonalInfoForm({
    value,
    handleChange,
    accountTypes,
}) {
    const dispatch = useDispatch();

    const onBack = (e) => {
        e?.preventDefault();
        handleChange(undefined, value - 1);
    };
    const selectedUser = useSelector((state) => state.account.type);
    console.log({ selectedUser });
    switch (selectedUser) {
        case accountTypes.seller.index:
            return (
                <SellerPersonalInfo
                    value={value}
                    handleChange={handleChange}
                    onBack={onBack}
                />
            );
        case accountTypes.ThirdParty.index:
            return (
                <ThirdPartyInfo
                    value={value}
                    handleChange={handleChange}
                    onBack={onBack}
                />
            );
        default:
            return (
                <OperationPersonalInfo
                    value={value}
                    handleChange={handleChange}
                    onBack={onBack}
                />
            );
    }
}
