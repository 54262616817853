import React, { useState, useEffect } from "react";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import {
    runsheetOrders,
    runsheetOrders_ar, usePostponedColumns,
    useRunSheetColumns,
} from "../layout/tableColumns";
import { useSelector, useDispatch } from "react-redux";
import { postponeDate } from "../../global-state/actions/postponeDate";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";
import TablePro from "../tables/TablePro";

function NewPostponeDate({ value, handleChange }) {
    const data = useSelector((state) => state.postponed.orders);
    const dispatch = useDispatch();
    const [date, setDate] = useState("");
    const { t } = useTranslation();

    const handleDate = (e) => {
        setDate(e.target.value);
        dispatch(postponeDate(e.target.value));
    };
    const proceed = () => {
        handleChange(null, value + 1);
    };
    const lang = useLanguage();
    return (
        <>
            <Row className="mt-3">
                <Col sm="12" md="6">
                    <div className="position-relative text-area-form">
                        <label className="bold-label">{t("newDate")}</label>
                        <Form.Control
                            value={date}
                            onChange={handleDate}
                            type="date"
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="text-end buttons-margin mb-3" sm="12">
                    <button className="cancel" onClick={() =>  handleChange(null,0)}>{t("discard")}</button>
                    <button
                        disabled={date === ""}
                        onClick={proceed}
                        className="confirm"
                    >
                        {t("next")}
                    </button>
                </Col>
            </Row>
            <TablePro dataSource={data} columns={usePostponedColumns()} />
        </>
    );
}

export default ErrorHandler(NewPostponeDate, axiosInstance);
