import React from "react";
import { Form, Input, Modal } from "antd";
import WMButton from "../AllWorkModels/WMButton";
import { useTranslation } from "react-i18next";
import styles from "./support-ticket.module.css";
import { SendMajor } from "@shopify/polaris-icons";
import { useSelector } from "react-redux";

function Message({ isMe, message }) {
    return (
        <div className={[styles.message, isMe ? styles.me : ""].join(" ")}>
            <div className={styles.messageArrow} />
            <p className={styles.messageContent}>{message}</p>
        </div>
    );
}

export default function SupportTicketCommentsModal({
    ticket,
    onSendComment,
    onClose,
}) {
    const { t } = useTranslation();
    const userType = useSelector((state) => state.auth.user.role);
    const userTypeId = userType === "Seller" ? 1 : 2;
    const [form] = Form.useForm();
    return (
        <Modal open={ticket} onCancel={onClose}>
            <div
                style={{
                    marginTop: "30px",
                }}
            >
                <h4
                    style={{
                        marginBottom: "16px",
                    }}
                >
                    {t("comments")}
                </h4>

                {ticket?.replies?.map((replyMessage) => (
                    <Message
                        message={replyMessage.reply}
                        isMe={replyMessage.reply_by === userTypeId}
                    />
                ))}

                <Form
                    form={form}
                    className={styles.messageSend}
                    onFinish={(data) => {
                        onSendComment(data.message, userTypeId);
                        form.resetFields();
                    }}
                >

                    <Form.Item name="message">
                        <Input.TextArea />
                    </Form.Item>
                    <WMButton type={"submit"} htmlType={"submit"}>
                        <SendMajor />
                    </WMButton>
                </Form>
            </div>
        </Modal>
    );
}
