import React, { useState } from "react";
import RegisterNumber from "./register_number";
import ConfirmNumber from "./confirm_number";
import RegisterInfo2 from "./RegisterInfo";
import SellerType from "./seller_type";
import MerchantRegistrationRepository from "../data/registration-repository";
import useLanguage from "../../../../hooks/useLanguage";
import useQuery from "../../../../hooks/useQuery";
import logoSeller from "../../../../images/box.png";
import logoSellerAr from "../../../../images/box.png";
import { useTranslation } from "react-i18next";
import { Container , Stack} from "react-bootstrap";
import "../../../../style-sheets/login.css";
export default function SMAXRegisterMerchant() {
    const language = useLanguage();
    const { i18n } = useTranslation();
    const languageChecker = (englishCallback, arabicCallback) =>
        language.includes("en") ? englishCallback : arabicCallback;

    const [payload, setPayload] = useState({});
    const [success, setSuccess] = useState(false);
    const { query, appendQuery, appendQueries } = useQuery();
    const step = query.step ? +query.step : 1;
    console.log({ step });
    const onRegister = async (values) => {
        console.log(values);
        const res = await MerchantRegistrationRepository.registerMerchant(
            values
        );
        setSuccess(res.success);
    };

    const nextStep = async (step, payload) => {
        await appendQueries({
            step,
            ...payload,
        });
    };

    const [phoneNumber, setPhoneNumber] = useState();
    const [flag, setFlag] = useState()

    return (
        <div className="loginContainer">

            
           

            <Container className="pageContainer">
                <Stack gap={2} className="col-md-8 mx-auto">
                        <div className=" login-header "  dir={languageChecker("ltr", "rtl")}>
                                <div className="logo">
                                    <img
                                        src={languageChecker(logoSeller, logoSellerAr)}
                                        className="img-fluid logo"
                                        style={{
                                            height: "140px",
                                        }}
                                    />
                                    <p>SHIPPING GATEWAY</p>
                                </div>
                                <button
                                    onClick={() => {
                                        const newLang = languageChecker("ar", "en");
                                        i18n.changeLanguage(newLang);
                                        // window.location.reload();
                                    }}
                                >
                                    {languageChecker("ع", "EN")}
                                </button>
                        </div>
                        {(() => {
                            switch (step) {
                                case 1: // New step before RegisterNumber
                                return (
                                    <SellerType
                                    nextStep={(payload) => {
                                        setFlag(payload.flag);
        
                                        nextStep(2);
                                    }}
                                    />
                                );
                                case 2:
                                    return (
                                        
                                        <RegisterNumber
                                            nextStep={(payload) => {
                                                setPhoneNumber(payload.phone);
                                                localStorage.setItem("registerPhone", payload.phone);
                                                nextStep(3);
                                            }}
                                        />
                                        
                                    );
                                case 3:
                                    return (
                                        <ConfirmNumber
                                            nextStep={(payload) => nextStep(4, payload)}
                                        />
                                    );
                                case 4:
                                    return (
                                        <RegisterInfo2
                                            phone={phoneNumber}
                                            payload={payload}
                                            nextStep={() => nextStep(5)}
                                            flag={flag}
                                        />
                                    );
                            }
                        })()}
                    </Stack>
            </Container>
        </div>
    );
}
