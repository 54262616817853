import React, { useEffect, useState } from "react";
import { notification } from "antd";
import TablePro from "../../../components/tables/TablePro";
import { OrderColumns } from "../../../components/layout/tableColumns";
import {useOrdersColumns2} from "../../../components/layout/tableColumns";
import { usePaymentTransactionColumns2} from "../../../components/layout/tableColumns";
import OrderDetails from "../../../components/layout/OrderDetails";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import axiosInstance from "../../../api/axios";
import useLanguage from "../../../hooks/useLanguage"
export default function AllOrderTable() {
  const { id } = useParams(); 
  const { t } = useTranslation();
  const lang = useLanguage();
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(1);
  const [page2, setPage2] = useState(1);
  const [count, setCount] = useState(data.length);
  const [count2, setCount2] = useState(data2.length);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [detailsLoading2, setDetailsLoading2] = useState(false);
  const [details, setDetails] = useState();
  const orderColumns = OrderColumns();
  const orderColumns2 = useOrdersColumns2();
  const paymentTransactionColumns = usePaymentTransactionColumns2();
  const fetchDetails = async () => {
    setDetailsLoading(true);
    try {
      const result = await axiosInstance.get(`/FilterOrder/?seller=${id}`, {
        params: {
            page_size: pageSize,
            //search: searchTerm,
        },
    });
      if (result.data) {
        setData(result.data.data);
        setCount(result.data.count);
        setDetailsLoading(true);
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
    setDetailsLoading(false);
  };

  useEffect(() => {
    fetchDetails();
    fetchWallet();
    setCount(data2.length);
    setPage2(1);
  }, []);


  const fetchWallet = async () => {
    setLoading2(true);
    const result = await axiosInstance
        .get(`/SellerWallet/${id}`)
        .catch((err) => {
            if (err.response.data) {
                Object.entries(err.response.data).forEach(
                    ([key, value]) => {
                        notification.error({
                            message: key,
                            description: value,
                            placement: "bottomRight",
                        });
                    }
                );
            } else {
                notification.error({
                    message: "Error",
                    description: "Something went wrong",
                    placement: "bottomRight",
                });
            }
        });
    if (result) {
        setData2(result.data);
    }
    setLoading2(false);
};

async function fetchDetails2(id) {
  try {
    setDetailsLoading2(true);
    let result = await axiosInstance.get(`/Order/${id}`);
    if (result && result.data) {
      setDetails(result.data);
    }
    setDetailsLoading2(false);
  } catch (error) {
    setDetailsLoading2(false);
    if (lang === 'ar') {
      // Display Arabic message for order not found
      notification.error({
        message: 'لم يتم العثور على الطلب',
        description: 'الطلب المطلوب غير موجود.',
      });
    } else {
      // Display English message for order not found
      notification.error({
        message: 'Order Not Found',
        description: 'The requested order was not found.',
      });
    }
    console.error('Error fetching order details:', error);
  }
}

async function onChangeTickets(page) {
    setDetailsLoading2(true);
    const newDetails = { ...details };
    newDetails.tickets.page = page;

    let result = await axiosInstance.get(`/Order/${details.id}`, {
        params: {
            page_size: 4,
            page: page,
        },
    });
    if (result) {
        newDetails.tickets = result.data.tickets;
        newDetails.tickets.page = page;
        setDetails(newDetails);
    }
    setDetailsLoading2(false);
}


   // Combine all columns into a single array
   const combinedColumns = [
    ...orderColumns2,
    ...paymentTransactionColumns,
    // ...refundsColumns,
    {
        title:t("headerCredit"),
        dataIndex: 'balance',
        key: 'balance',
        render: () => <span>{data.summary ? data.summary.balance : 0}</span>, // Display balance from data.summary.balance for each row
      },
  ];

// Combine all data into a single array
const combinedData = [
...(data2.orders || []),
...(data2.payment_transactions || []),
...(data2.refund_requests || []),
];
  return (
    <div style={{ position: "relative" }}>
      <TablePro
      loading={detailsLoading}
        columns={orderColumns}
        dataSource={data}
        pageSize={pageSize}
        count={count}
        setPageSize={setPageSize}
        pagination={{
          current: page,
          total: count,
          onChange: (page) => setPage(page),
      }}
      />
       <TablePro
                             loading={loading2}
                             selection={"radio"}
                             columns={combinedColumns}
                             dataSource={combinedData}
                             selectedRows={selectedRows}
                             setSelectedRows={setSelectedRows}
                             onRowClick={
                                combinedColumns[0].title === t("orderId")
                                     ? (index, record) => {
                                           fetchDetails2(record.id);
                                       }
                                     : combinedColumns[0].title === t("requestId")
                                     ? (index, record) => {
                                           fetchDetails2(record.order);
                                       }
                                     : undefined
                             }
                             pagination={{
                                 current: page2,
                                 total: count2,
                                 onChange: (page) => setPage2(page),
                             }}
                            />
                            {details ? (
                    <OrderDetails
                        pagination={onChangeTickets}
                        current={details.tickets.page ?? 1}
                        tickets={details.tickets}
                        ticketsCount={details.tickets.count}
                        show={details}
                        loading={detailsLoading}
                        setClose={() => setDetails(undefined)}
                        data={details}
                    />
                ) : null}
    </div>
  );
}
