import React, { useState, useMemo } from "react";
import { Container, Row, Col , Stack } from "react-bootstrap";
import { Form, Input, Button } from "antd";
import { MailFilled, LockFilled } from "@ant-design/icons";
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import sellerLoginImg from "../../images/box-cover.png";
import logoSeller from "../../images/box.png";
import logoAdmin from "../../images/box.png";
import logoAdminAr from "../../images/box.png";
import logoSellerAr from "../../images/box.png";
import { connect, useSelector } from "react-redux";
import { userLogin, LogOut } from "../../global-state/actions/authAction";
import { bindActionCreators } from "redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../style-sheets/login.css";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { LanguageContext } from "../contexts/LanguageContext";
import useLanguage from "../../hooks/useLanguage";
import { Checkbox } from "antd";



const Login = (props) => {
    const params = useParams();
    const isAuth = useSelector((state) => state.auth.isAuth);

    const history = useHistory();

    const { t, i18n } = useTranslation();

    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [emailErr, setEmailerr] = useState("");
    const [passErr, setpassErr] = useState("");
    const [loading, setLoading] = useState(false);
    const [rememberMe, setRememberMe] = useState(true);

    const language = useLanguage();
    const isAdmin = useMemo(
        () =>
            props.history.location.pathname === "/login" ||
            props.history.location.pathname === "/login/ar" ||
            props.history.location.pathname === "/login/en",

        [props.history.location.pathname]
    );

    const handleEmail = (e) => {
        setEmail(e.target.value);
        if (
            e.target.value.match(
                /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
            )
        ) {
            setEmail(e.target.value);
            setEmailerr("");
        } else {
        }
    };
    const handlePass = (e) => {
        setPassword(e.target.value);
        // if (
        //   e.target.value.match(
        //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/
        //   )
        // ) {
        //   setPassword(e.target.value);
        //   setpassErr("");
        // } else {
        //   setpassErr(
        //     "password minimum 8 characters with at least one upper case and one lower case and number and no special charcters"
        //   );
        // }
    };

    const submit = async (e) => {
        setLoading(true);

        let loginrequest = {};
        // const salt = bcrypt.genSaltSync(saltRounds);
        // const hash = bcrypt.hashSync(this.state.password, salt);
        loginrequest.email = email.toLowerCase();
        loginrequest.password = password;

        const userData = await props.userLogin(
            loginrequest,
            props.history,
            rememberMe
        );
        // console.log(userData);
        if (userData) {
            console.log(userData);
            // this.setState({ BeErr: userData });
            setTimeout(() => {
                toast.error(userData);
            }, 500);
        }
        setLoading(false);
    };

    // useEffect(() => {
    //     if (isAuth) {
    //         history.push("/dashboard");
    //     }
    // }, []);
    const languageChecker = (englishCallback, arabicCallback) =>
        language.includes("en") ? englishCallback : arabicCallback;
    console.log({ directionLOG: languageChecker("ltr", "rtl"), language });

    return (
        <div className="loginContainer" >

            <LanguageContext.Provider value={{ language }}>
               
                
                <Container className="pageContainer" >
                
                    <Stack gap={2} className="col-md-8 mx-auto">
                            
                                <div className=" login-header " dir={languageChecker("ltr", "rtl")}>

                                    <div className="logo">
                                        {isAdmin ? (
                                            <img
                                                src={languageChecker(logoAdmin, logoAdminAr)}
                                                className="img-fluid logo"
                                            />
                                        ) : (
                                            <img
                                                src={languageChecker(logoSeller, logoSellerAr)}
                                                className="img-fluid logo"
                                            />
                                        )}
                                        <p>SHIPPING GATEWAY</p>
                                    </div>

                                        <button
                                            onClick={() => {
                                                const newLang = languageChecker("ar", "en");
                                                i18n.changeLanguage(newLang);
                                                // window.location.reload();
                                            }}
                                            

                                        >
                                            {languageChecker("ع", "EN")}
                                        </button>

                                    
                                
                                    
                                    
                                </div>
                            
                        

                        
                            {/* 
                            <Col md="6" sm="12">
                                <img
                                    style={{
                                        maxHeight: "470px",
                                        aspectRatio: "1/1",
                                        position: "relative",
                                        top: "-30px",
                                    }}
                                    src={isAdmin ? sellerLoginImg : sellerLoginImg}
                                    className="img-fluid"
                                />
                            </Col>
                            */}
                        {/*<Col md="1" sm="12"></Col>*/}
                            
                                {/*<h3 className="title">{t("login")}</h3>*/}
                                
                                <Form
                                    name="normal_login"
                                    className="login-form mt-4"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    //   onFinish={onFinish}
                                >
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: `${t("inputEmailErr")}`,
                                            },
                                        ]}
                                    >
                                        <Input
                                            prefix={
                                                
                                                <EmailIcon className="site-form-item-icon form_icon" />
                                            }
                                        // placeholder={t("email")}
                                            style={{ borderRadius: '5px', marginBottom: '10px', borderColor:'#5c55a5' }}
                                            name="email"
                                            value={email}
                                            onChange={handleEmail}
                                        />
                                    </Form.Item>
                                    <p className="error">{emailErr}</p>

                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: `${t("inputPasswordErr")}`,
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            prefix={
                                                <LockIcon className="site-form-item-icon form_icon" />
                                            }
                                            type="password"
                                        // placeholder={t("password")}
                                            style={{ borderRadius: '5px', marginBottom: '10px',  borderColor:'#5c55a5'}}
                                            name="password"
                                            value={password}
                                            onChange={handlePass}
                                        />
                                    </Form.Item>
                                    <p className="error">{passErr}</p>

                                    <div>
                                        <Checkbox
                                            checked={rememberMe}
                                            onChange={(e) =>
                                                setRememberMe(e.target.checked)
                                            }
                                        >
                                            {t("rememberMe")}
                                        </Checkbox>
                                    </div>
                                    <Form.Item className="forgot">
                                        {isAdmin ? (
                                            <a
                                                className="login-form-forgot"
                                                href="/admin_forget_password"
                                            >
                                                {t("forgotPassword")}
                                            </a>
                                        ) : (
                                            <a
                                                className="login-form-forgot"
                                                href="/forget_password"
                                            >
                                                {t("forgotPassword")}
                                            </a>
                                        )}
                                    </Form.Item>

                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className={`login-form-button ${
                                                isAdmin ? "adminBtn" : "sellerBtn"
                                            }`}
                                            disabled={loading}
                                            onClick={submit}
                                        >
                                            {t("login")}
                                        </Button>
                                    </Form.Item>
                                    <p
                                        style={{
                                            textAlign: "end",
                                        }}
                                    >
                                        {t("newAccount")}{" "}
                                        <Link to={"/register"}>
                                            {t("newRegister")}
                                        </Link>
                                        
                                    </p>
                                    {/* <p
                                        style={{
                                            textAlign: "end",
                                        }}
                                    >                                    
                                        <Link to={"/marketer-registeration"}>
                                            {t("newMarketerRegister")}
                                        </Link>
                                    </p> */}
                                </Form>
                                
                        
                        <ToastContainer position="bottom-right" />
                        
                    </Stack>
                </Container>
                
                
            </LanguageContext.Provider>
        </div>
    );
};
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ userLogin, LogOut }, dispatch);
};
export default connect(null, mapDispatchToProps)(Login);
