import { ADD_ORDER } from './actionTypes'

export const addOrder = (info) => {
    return {
        type: ADD_ORDER,
        payload: info
    }

}

