import { PLAN } from './actionTypes'

export const plans = (info) => {
    return {
        type: PLAN,
        payload: info
    }

}

