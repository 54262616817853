import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../../../api/axios";
import PageHeader from "../../../../../components/layout/PageHeader";
import TablePro from "../../../../../components/tables/TablePro";
import PaymentsColumns from "../../../../../components/layout/tableColumns";
import useLanguage from "../../../../../hooks/useLanguage";
import { notification, InputNumber, Modal , Button } from 'antd';

const Payments = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); 
    const lang = useLanguage();
    const [reloadData, setReloadData] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [retryPaymentId, setRetryPaymentId] = useState(null);

    useEffect(() => {
        fetchPayments(page);
        setReloadData(false);
    }, [page, reloadData]);

    const fetchPayments = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get("/payment-transactions/", {
                params: {
                    page_size: pageSize,
                    page: page,
                },
            });
            setData(response.data.data);
            setCount(response.data.count);
        } catch (error) {
            console.error("Error fetching payments:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleRetryPayment = async (id) => {
        setRetryPaymentId(id);
        setConfirmVisible(true);
    };

    const confirmRetryPayment = async () => {
        try {
            await axiosInstance.put(`/payment-transactions/${retryPaymentId}/`);
            notification.success({
                message: 'تمت إعادة المحاولة للدفع بنجاح',
            });
            setReloadData(true);
        } catch (error) {
            notification.error({
                message: 'فشلت إعادة المحاولة للدفع',
            });
            console.error('Error retrying payment:', error);
        } finally {
            setConfirmVisible(false);
        }
    };

    const columns = PaymentsColumns(handleRetryPayment);

    return (
        <div>
            <PageHeader
                title={t("recharge")}
                group={t("sideMenu.wallet")}
            />
            <div className="position-relative">
                <TablePro
                    loading={loading}
                    dataSource={data}
                    columns={columns}
                    paginationMode="server"
                    pagination={{
                        current: page,
                        total: count,
                        onChange: (pageNumber) => setPage(pageNumber),
                    }}
                />
            </div>
            <Modal
                title="تأكيد الدفع"
                visible={confirmVisible}
                onOk={confirmRetryPayment}
                onCancel={() => setConfirmVisible(false)}
            >
                <p>هل تريد تفعيل هذه المعاملة ؟</p>
                <Button type="primary" onClick={confirmRetryPayment}>
                    تفعيل
                </Button>
                <Button onClick={() => setConfirmVisible(false)}>
                    الغاء
                </Button>
            </Modal>
        </div>
    );
};

export default Payments;
