import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import InformationCard from "../../../layout/informationCard";
import { Link } from "react-router-dom";
import img from "../../../../images/tableCol.png";
import { useTranslation } from "react-i18next";
import { ThemeEditMajor } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";

const AllPreferences = () => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Container>
        <Row>
          <Col sm="12">
            <span className="title1">{t("sideMenu.settings")}</span>
            <i className="fas fa-chevron-right px-3 title2"></i>
            <span className="title2">{t("preferences")}</span>
          </Col>
          <Col className="mt-3" sm="12">
            <h2 className="pageTitle">{t("preferences")}</h2>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3" sm="10">
            <InformationCard
              title={t("prefCardTitle")}
              subTitle={t("prefCardSubTitle1")}
              list={[
                <p>{t("prefCardStep1")}</p>,
                <p>{t("prefCardStep2")}</p>,
                <p>{t("prefCardStep3")}</p>,
                <p>{t("prefCardStep4")}</p>,
              ]}
              subTitle2={t("prefCardSubTitle2")}
              list2={[
                <p>{t("prefCardStep5")}</p>,
                <p>{t("prefCardStep6")}</p>,
                <p>{t("prefCardStep7")}</p>,
                <p>{t("prefCardStep8")}</p>,
                <p>{t("prefCardStep9")}</p>,
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="5">
            <div className="setting-card">
              <div className="setting-icon">
                <i style={{ color: "#5C5F62" }} className="fas fa-clone"></i>
              </div>
              <div>
                <Link to={"/traking"} className="setting-card-title">
                  {t("trackingBoard")}
                </Link>
                <p className="setting-card-description">
                  {t("trackingBoardDesc")}
                </p>
              </div>
            </div>
          </Col>
          <Col sm="5">
            <div className="setting-card">
              <div className="setting-icon">
                <img src={img} className="img-fluid" />
              </div>
              <div>
                <Link to={"/create_headers"} className="setting-card-title">
                  {t("tableHeaders")}
                </Link>
                <p className="setting-card-description">
                  {t("tableHeadersDesc")}
                </p>
              </div>
            </div>
          </Col>
          <Col sm="5">

          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AllPreferences;
