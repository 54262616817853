import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Form, InputNumber } from "antd";
import { useState } from "react";
import useLanguage from "../../../hooks/useLanguage";

function RechargeCards({ setRechargePrice }) {
    const { t } = useTranslation();
    const currency = useSelector(
        (state) => state.auth.user.user.organization.country.currency
    );
    const [customPrice, setCustomPrice] = useState();
    const [language] = useLanguage();

    return (
        <div>
            <h5 className="font-weight-bold">
                {t("sideMenu.chooseRechargeAmount")}
            </h5>

            <div className="pt-3 ps-3 rounded d-flex justify-content-center align-items-center flex-wrap">
                {[50, 200, 500, 1000, 3000].map((price) => (
                    <div
                        role="button"
                        className="px-5 me-3 mb-3 py-5 rounded shadow d-flex justify-content-center align-items-center"
                        onClick={() => setRechargePrice(price)}
                    >
                        <h3 className="font-weight-bold mb-0">
                            {price} {t(currency)}
                        </h3>
                    </div>
                ))}
            </div>
            <Form
                className="d-flex justify-content-center align-items-end mt-5"
                onFinish={() => setRechargePrice(customPrice)}
            >
                <Form.Item
                    className="w-50"
                    label={t("sideMenu.customPrice")}
                    name="customPrice"
                    rules={[
                        {
                            required: true,
                            validator: (rule, value, callback) => {
                                if (value >= 50) return callback();
                                else
                                    return callback(
                                        t("rechargeMinError")
                                    );
                            },
                        },
                    ]}
                >
                    <InputNumber
                        className="w-100"
                        min={1}
                        max={5000}
                        onChange={(value) => setCustomPrice(value)}
                    />
                </Form.Item>
                <button
                    className={`btn confirm  ${
                        language === "ar" ? "ms-2" : "me-2"
                    }`}
                    style={{
                        marginBottom: "9px"
                    }}
                    type="submit"
                >
                    {t("sideMenu.recharge")}
                </button>
            </Form>
        </div>
    );
}

export default RechargeCards;
