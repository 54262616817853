import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { EditMajor } from "@shopify/polaris-icons";

export default function OrderSummary({ orderDetails, onShipmentFeesChange }) {
    const { t } = useTranslation();
    const currency = useSelector(
        (state) => state.auth.user.user.organization.country.currency
    );

    const [shipmentFeesChange, setShipmentFeesChange] = useState(undefined);
    const [isEditing, setIsEditing] = useState(false);
    return (
        <Row>
            <ul className="calculationRow">
                <li>
                    <span
                        className="tabsTitles"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        {t("shipment")}
                    </span>
                    <span
                        style={{
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                        }}
                    >
                        <span className="tabsInfoPrice">
                            {t(currency)}
                            <span className="tabsCost">
                                {isEditing ? (
                                    <input
                                        onChange={(e) => {
                                            onShipmentFeesChange?.(
                                                e.target.value
                                            );
                                            setShipmentFeesChange(
                                                +e.target.value
                                            );
                                        }}
                                        type="number"
                                        min={0}
                                        style={{
                                            display: "inline-block",
                                            width: "100%",
                                            background: "transparent",
                                            border: "none",
                                            textAlign: "end",
                                        }}
                                        defaultValue={
                                            orderDetails.delivery_fees
                                        }
                                    />
                                ) : (
                                    <>{orderDetails.delivery_fees ?? "00.00"}</>
                                )}
                            </span>
                        </span>
                        {orderDetails && (
                            <span
                                style={{
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                }}
                                onClick={() => setIsEditing(true)}
                            >
                                <EditMajor color="#646464" fill="#646464" />
                            </span>
                        )}
                    </span>
                </li>
                <li>
                    <span className="tabsTitles">
                        <span className="">{t("totalProducts")}</span>
                    </span>
                    <span className="tabsInfoPrice">
                        {t(currency)}
                        <span className="tabsCost">
                            {orderDetails.sub_total ?? "00.00"}
                        </span>
                    </span>
                </li>
            </ul>
            <ul className="calculationRow">
                <li>
                    <span className="tabsTitles">
                        <span className="">{t("total")}</span>
                    </span>
                    <span className="tabsInfoPrice">
                        {t(currency)}
                        <span className="tabsCost">
                            {orderDetails.delivery_fees &&
                            orderDetails.sub_total
                                ? (shipmentFeesChange ?? parseInt(orderDetails.delivery_fees)) +
                                  parseInt(orderDetails.sub_total)
                                : "00.00"}
                        </span>
                    </span>
                </li>
            </ul>
        </Row>
    );
}
