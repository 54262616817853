import React, { useEffect, useMemo, useState } from "react";
import axiosInstance from "../../../../../api/axios";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Form, Input, Select } from "antd";
import { addOrder } from "../../../../../global-state/actions/addOrderAction";
import { UnSuccessfulRequest } from "../../../../../components/modals/confirmationModal";
import SelectSearch from "../../../../../components/select-search/SelectSearch";
import SelectAreas from "../../../../../components/select-search/AreasSelect";
import PhoneInput, {
    useAntdPhoneRules,
} from "../../../../../components/phone-input";

export default function AdminCustomerInfo({ value, handleChange }) {
    const { t } = useTranslation();
    const order = useSelector((state) => state.order);
    const [foundCustomer, setFoundCustomer] = useState(undefined);
    const adminAddresses = useSelector(
        (state) => state.selectedData?.selectedSeller?.saved_address
    );
    const user = useSelector((state) => state.auth.user);

    const adminSelectedAddress = useSelector(
        (state) => state.order.seller_address
    );
    const selectedSeller = useSelector(
        (state) => state.selectedData?.selectedSeller
    );
    const workModel = useMemo(
        () =>
            adminAddresses?.find((a) => a.id === adminSelectedAddress)
                ?.new_work_model,
        []
    );
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [areas, setAreas] = useState([]);
    const [currentArea, setCurrentArea] = useState();
    const [error, setError] = useState();
    const [isWithoutAddress, setIsWithoutAddress] = useState(false);
    const [loading, setLoading] = useState(false);
    const fields = [
        {
            label: t("phone"),
            name: "phone",
            note: t("phonePlaceholder"),
            required: true,
            type: "phone",
            innerProps: {
                onBlur: (e) => {
                    console.log(e.target.value);
                    getClients(e.target.value);
                },
            },
            extraRules: useAntdPhoneRules(),
        },
        {
            label: t("fullname"),
            name: "name",
            required: true,
        },
        {
            label: t("altPhone"),
            name: "phone_alt",
            type: "phone",
            note: t("phonePlaceholder"),
            extraRules: useAntdPhoneRules(),
        },
        {
            label: t("city"),
            name: "area",
            required: true,
            type: "search",
            options: areas,
            // innerProps: {
            //     showSearch: true,
            //     onSearch: (e) => getAreas(e),
            //     filterOption: false,
            //     notFoundContent: null,
            // },
        },
        {
            label: t("address"),
            name: "address",
            required: true,
            extraRules: [
                {
                    min: 2,
                    message: t("tooShort"),
                },
            ],
        },
    ];

    useEffect(() => {
        form.setFieldsValue(order.clientData);
        if (order.clientData?.phone) getClients(order.clientData.phone);
    }, []);
    useEffect(() => {
        // getAreas();
    }, [workModel]);
    const previous = () => {
        handleChange(null, parseInt(value) - 1);
    };
    const getAreas = async (search = "") => {
        let result = await axiosInstance
            .get(`/FilterAreas/`, {
                params: {
                    search,
                    page_size: 100000,
                },
            })
            .catch((err) => console.log(err));

        if (result) {
            setAreas(result.data.data);
        }
    };

    async function getClients(phone) {
        const res = await axiosInstance.get("/FilterClients/", {
            params: {
                seller:
                    user.role === "Seller"
                        ? user.user.user_id
                        : selectedSeller.id ?? undefined,
                phone,
            },
        });

        if (res) {
            const resFoundCustomer =
                phone.length > 0 && res.data.data?.[0]
                    ? res.data.data?.[0]
                    : undefined;
            const formattedFoundCustomer = resFoundCustomer
                ? {
                      id: resFoundCustomer.id,
                      phone: resFoundCustomer.phone,
                      name: resFoundCustomer.name,
                      phone_alt: resFoundCustomer.phone_alt,
                      area: resFoundCustomer.client_addresses[0]?.area.id,
                      address: resFoundCustomer.client_addresses[0]?.address,
                      client_address: resFoundCustomer.client_addresses[0],
                  }
                : undefined;
            setCurrentArea(
                resFoundCustomer
                    ? resFoundCustomer.client_addresses[0]?.area.id
                    : undefined
            );
            setIsWithoutAddress(
                resFoundCustomer
                    ? resFoundCustomer.client_addresses?.length === 0
                    : false
            );
            setFoundCustomer(formattedFoundCustomer ?? undefined);
            if (formattedFoundCustomer)
                form.setFieldsValue(formattedFoundCustomer);
            else {
                // form.resetFields();
                form.setFieldValue("phone", phone);
            }
        }
    }

    const create = async (values) => {
        if (loading) return;
        setLoading(true);
        let data = {
            seller:
                user.role === "Seller" ? user.user.user_id : selectedSeller.id,
            ...values,
        };
        console.log(data);
        const handleError = (err) => {
            setLoading(false);
            const errors = err.response.data;
            if (
                errors &&
                Object.hasOwn(err.response.data ?? {}, "non_field_errors")
            )
                setError(err.response.data?.non_field_errors?.[0]);
            else
                form.setFields([
                    ...Object.entries(errors).map(([name, value]) => ({
                        name,
                        errors: value,
                    })),
                ]);
        };

        let result;
        if (foundCustomer) {
            result = await axiosInstance
                .put(`/SavedClient/${foundCustomer.id}`, data)
                .catch(handleError);

            if (isWithoutAddress) {
                try {
                    await axiosInstance.post("/ClientAddress/", {
                        client: foundCustomer.id,
                        address: data.address,
                        area: data.area,
                    });
                    setIsWithoutAddress(false);
                } catch (e) {
                    setIsWithoutAddress(true);
                }
            } else
                await axiosInstance.put(
                    `/ClientAddress/${foundCustomer.client_address.id}`,
                    {
                        ...foundCustomer.client_address,
                        area: data.area,
                        address: data.address,
                    }
                );
        } else {
            result = await axiosInstance
                .post(`/SavedClient/`, data)
                .catch(handleError);
        }

        if (result) {
            dispatch(
                addOrder({
                    ...order,
                    clientData: data,
                    client: foundCustomer ? foundCustomer.id : result.data.id,
                    client_address: result.data.client_addresses[0]?.id,
                    seller_address: adminSelectedAddress,
                })
            );
            handleChange(null, parseInt(value) + 1);
        }
    };
    return (
        <div>
            <Row>
                <Col sm={12} md={10} lg={7}>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={create}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "16px",
                        }}
                    >
                        {fields.map((field) => {
                            const {
                                type,
                                options,
                                required,
                                extraRules,
                                innerProps,
                                ...fieldData
                            } = field;

                            return (
                                <Form.Item
                                    key={field.name}
                                    rules={[
                                        { required },
                                        ...(extraRules ?? []),
                                    ]}
                                    {...fieldData}
                                    label={
                                        fieldData.note ? (
                                            <div>
                                                <label>{fieldData.label}</label>
                                                <p className={"note"}>
                                                    {fieldData.note}
                                                </p>
                                            </div>
                                        ) : (
                                            fieldData.label
                                        )
                                    }
                                >
                                    {type === "phone" ? (
                                        <PhoneInput
                                            {...innerProps}
                                            formType="antd"
                                        />
                                    ) : type === "search" ? (
                                        <SelectAreas
                                            currentArea={currentArea}
                                        />
                                    ) : type === "select" ? (
                                        <Select {...innerProps}>
                                            {options.map((option) => (
                                                <Select.Option
                                                    key={option.id}
                                                    value={option.id}
                                                >
                                                    {option.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    ) : (
                                        <Input type={type} {...innerProps} />
                                    )}
                                </Form.Item>
                            );
                        })}
                    </Form>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col className="text-end" sm="12">
                    <button className="previous" onClick={previous}>
                        {t("previous")}
                    </button>
                    <input
                        disabled={loading}
                        onClick={() => form.submit()}
                        className="confirm"
                        value={t("proceed")}
                        type="submit"
                    />
                </Col>
            </Row>
            <UnSuccessfulRequest
                show={error}
                hide={() => setError(undefined)}
                title={t("createOrderError")}
                body={error}
            />
        </div>
    );
}
