import { IS_VALID } from "../actions/actionTypes";
const initialState = {
  isValid : false
};

export default function (state = initialState, action) {
  //  (action.payload);
  switch (action.type) {
    case IS_VALID:
      return {
        ...state,
        isValid : action.payload
      };

    
    default:
      return state;
  }
}