import React from "react";
import styles from "./CommonReportsPage.module.css";
import WMButton from "../../AllWorkModels/WMButton";
import StatusCard from "../../../layout/StatusCard";
import ReportsRepository, {
    ordersDetailsRequest,
} from "../../../../api/ReportsRepository";
import WMTable from "../../AllWorkModels/WMTable";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function ReportsTable({ table }) {

    async function onDownloadTable() {
        const { success, data } = await ReportsRepository.getTable(
            table.extra?.ticket,
            table.extra?.orders,
            table.extra?.params,
            true
        );

        if (success) {
            toast.success("File Downloaded Successfully");
        }
    }

    const { t, i18n } = useTranslation();

    return (
        <div className={styles.reportsContainer}>
            <div
                className="row-centered-horizontal"
                style={{ justifyContent: "flex-end" }}
            >
                {/* <WMButton onClick={onDownloadTable}>
                    {t("reports.download")}
                </WMButton> */}
            </div>
            <div className={styles.reportContainer}>
                <WMTable
                    loading={false}
                    columns={
                        Object.keys(table[0] ?? {})
                            .map((it) => {
                                // if (
                                //     i18n.exists(`reports.table.regular.${it}`)
                                // )
                                {
                                    return {
                                        title: t(`reports.table.regular.${it}`),
                                        key: it,
                                        dataIndex: it,
                                    };
                                }

                                return undefined;
                            })
                            .filter((it) => it != undefined) ?? []
                    }
                    dataSource={table}
                />
            </div>
        </div>
    );
}
