import React, { useState } from "react";
import { Container, Row, Col , Stack } from "react-bootstrap";
import { Form, Input, Button } from "antd";
import EmailIcon from '@mui/icons-material/Email';
import { MailOutlined } from "@ant-design/icons";
import loginImg from "../../images/forgetPass.png";
import forgetPassSeller from "../../images/forgetPassSeller.png";
import "../../style-sheets/login.css";
import logoSeller from "../../images/box.png";
import logoAdmin from "../../images/box.png";
import arabicLogo from "../../images/box.png";
import logoSellerAr from "../../images/box.png";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useDispatch } from "react-redux";
import { BASE_URL } from "../../api/axios";
import { code } from "../../global-state/actions/codeAction";
import useLanguage from "../../hooks/useLanguage";

const ForetPassword = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const [emailErr, setEmailerr] = useState("");
    const [loading, setLoading] = useState(false);

    const handleEmail = (e) => {
        const em = e.target.value.toLowerCase();

        setEmail(em);
        if (
            e.target.value.match(
                /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
            )
        ) {
            setEmail(em);
            setEmailerr("");
        } else {
        }
    };

    const [form] = Form.useForm();
    const lang = useLanguage();
    const submit = async () => {
        setLoading(true);
        let result = await axios
            .post(
                BASE_URL + "/forgot_password",
                {
                    email: email.toLowerCase(),
                },
                {
                    headers: {
                        "Accept-Language": lang,
                    },
                }
            )
            .catch((err) => {
                setLoading(false);
                console.log({
                    err: err.response.data,
                });
                if (typeof err.response.data === "string" || err.response.data instanceof String) {
                    form.setFields([
                        {
                            name: "email",
                            errors: [
                                err.response.data
                            ],
                        },
                    ]);
                } else {
                form.setFields([
                    {
                        name: "email",
                        errors: [
                            Object.values(err.response.data).join(", ")
                        ],
                    },
                ]);
            }
                // setEmailerr(typeof err === "string" ? err.response.data : "");
            });
        if (result) {
            dispatch(code(email));

            setLoading(false);
            props.history.location.pathname == "/admin_forget_password"
                ? props.history.push("/admin_confirm")
                : props.history.push("/confirm");
        }
    };
    return (
        <div className="loginContainer">
            
            <Container className="pageContainer">
                
                    {/*
                        <Col md="6" sm="12">
                            {props.history.location.pathname ==
                            "/admin_forget_password" ? (
                                <img
                                    src={forgetPassSeller}
                                    className="img-fluid "
                                />
                            ) : (
                                <img
                                    src={forgetPassSeller}
                                    className="img-fluid "
                                />
                            )}
                        </Col>
                        <Col md="1" sm="12"></Col>
                         <Col className="mt-5 p-5" md="4" sm="12">
                    */}
                   <Stack gap={2} className="col-md-8 mx-auto">

                   <div className=" login-header ">
                    <div className="logo">
                            {props.history.location.pathname == "/admin_forget_password" ? (
                                <img
                                    src={
                                        localStorage.getItem("lang") === "en"
                                            ? logoAdmin
                                            : arabicLogo
                                    }
                                    className="img-fluid logo"
                                />
                            ) : (
                                <img
                                    src={
                                        localStorage.getItem("lang") === "en"
                                            ? logoSeller
                                            : logoSellerAr
                                    }
                                    className="img-fluid logo"
                                />
                            )}
                            <p>SHIPPING GATEWAY</p>
                            </div>
                    </div>



                        <h3 className="title">{t("enterEmail")}</h3>
                        <p className="subTitle mt-2">
                            {t("enterEmail")} <br />{" "}
                            <span style={{ color: "black" }}>
                                example@gmail.com
                            </span>
                        </p>
                        <Form
                            name="normal_login"
                            className="login-form mt-5"
                            initialValues={{
                                remember: true,
                            }}
                            form={form}
                            onFinish={submit}
                        >
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: `${t("enterEmail")}`,
                                        // email regex
                                    },
                                    {
                                        pattern:
                                            /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
                                        message: `${t("enterValidEmail")}`,
                                    },
                                ]}
                            >
                                <Input
                                    prefix={
                                        <EmailIcon className="site-form-item-icon form_icon" />
                                    }
                                    placeholder={t("email")}
                                    name="email"
                                    style={{ borderRadius: '5px', marginBottom: '10px', borderColor:'#5c55a5' }}
                                    value={email}
                                    onChange={handleEmail}
                                />
                            </Form.Item>

                            <p className="error">{emailErr}</p>

                            <Form.Item>
                                {props.history.location.pathname ==
                                "/admin_forget_password" ? (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        disabled={loading}
                                        className="login-form-button adminBtn"
                                    >
                                        {t("done")}
                                    </Button>
                                ) : (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        disabled={loading}
                                        className="login-form-button sellerBtn"
                                    >
                                        {t("done")}
                                    </Button>
                                )}
                            </Form.Item>
                        </Form>
                    </Stack>
                
            </Container>
        </div>
    );
};

export default ForetPassword;
