import React from "react";
import { Col, Row } from "react-bootstrap";
import {useTranslation} from "react-i18next";

export default function TableTitle({ currentStateTitle }) {
    const { t } = useTranslation();

    return (
        <>
            <Row>
                <Col sm="12">
                    <span className="title1">{t("sideMenu.dashboard")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{currentStateTitle}</span>
                </Col>
            </Row>
            <Row>
                <Col sm="6">
                    <h6 className="reqTypeTitle">{currentStateTitle}</h6>
                </Col>
            </Row>
        </>
    );
}
