import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../api/axios";
import { useSelector } from "react-redux";
import TablePro from "../../../../components/tables/TablePro";
import ErrorHandler from "../../../../error-handler/ErrorHandler";
import { useTranslation } from "react-i18next";

function AllProducts() {
    const { t } = useTranslation();
    const [products, setProducts] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const user = useSelector((state) => state.auth.user);
    const getProducts = async () => {
        let result = await axiosInstance.get(`/FilterProducts/`, {
            params: {
                seller: user.user.user_id,
                page,
            },
        });
        if (result) {
            setProducts(result.data.data);
            setCount(result.data.count);
        }
    };

    const columns = [
        {
            title: t("id"),
            dataIndex: "id",
        },
        {
            title: t("name"),
            dataIndex: "name",
        },
        {
            title: t("price"),
            dataIndex: "price",
        },
    ];

    useEffect(() => {
        getProducts();
    }, []);
    return (
        <div>
            <div>
                <TablePro
                    columns={columns}
                    dataSource={products}
                    pagination={{
                        current: page,
                        total: count,
                        onChange: (page) => setPage(page),
                    }}
                />
            </div>
        </div>
    );
}

export default ErrorHandler(AllProducts, axiosInstance);
