import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import  { useState , useEffect} from "react";
import axiosInstance from "../../../../api/axios";
function WalletSummary({  }) {
    const { t } = useTranslation();
    const currency = useSelector(
        (state) => state.auth.user.user.organization.country.currency
    );
    const marketerID = useSelector((state) => state.auth.user.user.id);
    const [data, setData] = useState([]); 
    // get usetteled commissionOrders
  const getMarketerWallet = async () => {
    let result = await axiosInstance
      .get(`/commission-sum/${marketerID}/`)
      .catch((err) => console.log(err));

    if (result) {
      setData(result.data);
      
    }
 
  };
  useEffect(() => {
    getMarketerWallet();
      }, []);
    return (
        <div className="px-3 rounded">
            <div className="d-flex justify-content-around align-items-center flex-wrap">
                <div className="d-flex flex my-2 align-self-stretch align-items-center shadow px-5 py-4 rounded">
                    <div className="bg-primary rounded-circle p-3">
                        <i className="fas fa-wallet text-white"></i>
                    </div>
                    <div className="ms-5">
                        <h6 className="text-muted">
                            {t("totalcommissions")}
                        </h6>
                        <h3 className="font-weight-bold m-0">
                            {t(currency)} {data.total_unsetteled}
                        </h3>
                    </div>
                </div>
                <div className="d-flex my-2 flex-column align-items-center justify-content-around shadow px-5 py-4 rounded">
                    <div className="d-flex align-items-center">
                        <div className="bg-success rounded-circle p-3 me-5">
                            <i className="fas fa-money-bill-alt text-white"></i>
                        </div>
                        <div>
                            <h6 className="text-muted">
                                {t("settledcommissions")}
                            </h6>
                            <h3 className="font-weight-bold">
                                {t(currency)} {data.total_setteled}
                            </h3>
                        </div>
                    </div>
                </div>
               
                    
               
            </div>
        </div>
    );
}

export default WalletSummary;
