import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import "../../../../style-sheets/table.css";
import axiosInstance from "../../../../api/axios";
import ErrorHandler from "../../../../error-handler/ErrorHandler";
import StateHeadersModel from "../../../modals/StateHeadersModel";
import ThreeDotsMenu from "../../../layout/threeDotsMenu";
import CreateTableHeader from "../../../modals/CreateTableHeader";
import { useTranslation } from "react-i18next";
import { SuccessfulRequest } from "../../../modals/confirmationModal";
import useLanguage from "../../../../hooks/useLanguage";

const TableHeaders = () => {
  const lang = useLanguage()

  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [stateHeaders, setstateHeaders] = useState("");
  const [show, setShow] = useState(false);
  const [page, setPage] = useState([]);
  const [showSuccess , setShowSuccess] = useState(false)

  const [headersCol, setHeaderCol] = useState([
    {
      field: "cardID",
      headerName: `${t("headersTable.number")}`,
      width: 230,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => <p className="tableID">#{row.row?.id}</p>
    },
    {
      field: "Title",
      headerName: `${t("headersTable.title")}`,
      width: 260,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => <p className="paragraphMargin">{lang === "en" ? row.row?.title : row.row?.title_ar}</p>
    },
    {
      field: "Tabel Header",
      headerName: `${t("headersTable.tableHeader")}`,
      width: 270,
      // valueGetter: (row) => row.row?.id,
      renderCell: (row) => <p className="tableID">{t("stateTableHeaders")}</p>
    },
  
  ]);
  const getData = async () => {
    let result = await axiosInstance.get(`/State/?detailed=true&page=1`);
    if (result) {
      console.log(result);
      setData(result.data.data);
      setCount(result.data.count);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const close = () => {
    setShow(false);
  };

  const hide = ()=>{
    setShow(false)
    setShowSuccess(false)
  }
  
  const onpageChangeHandle = async (page) => {
    setPage(page);
    let result = await axiosInstance.get(`/State/?page=${page + 1}`);
    if (result) {
      console.log(result);
      setData(result.data.data);
      setCount(result.data.count);
    }
  };
  return (
    <div>
      <Container>
        <Row>
          <Col sm="12">
            <span className="title2">{t("sideMenu.settings")}</span>
            <i className="fas fa-chevron-right px-3 title2"></i>
            <span className="title2">{t("preferences")}</span>
            <i className="fas fa-chevron-right px-3 title2"></i>
            <span className="title1">{t("headersTable.tableHeader")}</span>
          </Col>
          <Col className="mt-3" sm="12">
            <h2 className="pageTitle">{t("headersTable.tableHeader")}</h2>
          </Col>
        </Row>

       
        <Row>
          <Col sm="12">
            <div dir="ltr" className="requestTable rs mt-2">
              <DataGrid
                rows={data}
                columns={headersCol}
                disableSelectionOnClick
                pagination
                paginationMode="server"
                rowCount={count}
                pageSize={10}
                rowsPerPageOptions={[10]}
                // // checkboxSelection
                // selectionModel={selectionModel}
                onPageChange={onpageChangeHandle}
                onCellClick={(p, e) => {
                  if (p.field == "Tabel Header") {
                    console.log(p.row);
                    setstateHeaders(p.row);
                    setShow(true);
                  }
                }}
              />{" "}
              {show ? (
                <StateHeadersModel
                  details={stateHeaders}
                  show={show}
                  close={close}
                  showSuccess={setShowSuccess}
                />
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
      <SuccessfulRequest hide={hide} show={showSuccess} body={t("successfulRequest")} title={t("tableHeaders")} />
    </div>
  );
};

export default ErrorHandler(TableHeaders, axiosInstance);
