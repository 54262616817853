import React from "react";
import InformationCard from "../../../../components/layout/informationCard";
import { useTranslation } from "react-i18next";

export default function ScanInformation() {
    const { t } = useTranslation();

    return (
        <InformationCard
            title={t("infoScanTitle")}
            subTitle={t("infoScanSub")}
            list={t("scanInfoTxt", { returnObjects: true })}
        />
    );
}
