import React, { useState, useEffect } from "react";
import axiosInstance from "../../../../api/axios";
import ErrorHandler from "../../../../error-handler/ErrorHandler";
import { Row, Col } from "react-bootstrap";
import {  Dialog, DialogContent } from '@material-ui/core';
import { Input, Button, Modal } from "antd";
import { useSelector } from "react-redux";
import useLanguage from "../../../../hooks/useLanguage";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSettleRequestColumns } from "../../../layout/tableColumns";
import TablePro from "../../../tables/TablePro";

function SettleRequests(props) {
  const { t } = useTranslation();
  const marketerID = useSelector((state) => state.auth.user.user.id);
  const user_role = useSelector((state) => state.auth.user.role);
  const branch = useSelector((state) => state.branch.branch);
  const lang = useLanguage();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [adminNotes, setAdminNote] = useState([]);
  const [marketerNotes, setMarketerNote] = useState([]);
  const [rid, setRid] = useState(null)
  const [loading, setLoading] = useState(false);
  const [openn, setOpenn] = useState(false)
  
// Function to handle opening the image preview dialog
const handleImagePreview = (imageURL) => {
    setSelectedImage(imageURL);
    setOpen(true);
  };
 
  const getSettleRequests = async () => {
    setLoading(true);
    let result = await axiosInstance
      .get(`/settle-requests/${marketerID}/`, {
        params: {
          page_size: 10,
          page: page,
          status: '2'
        },
      })
      .catch((err) => console.log(err));

    if (result) {
      setData(result.data.data);
      setCount(result.data.count);
    }
    setLoading(false);
  };

  const handleMarketerUpdateSettleRequest = (id, flag) => {
    const marketer_note = marketerNotes.find((note) => note.id === id)?.note;
    if (!marketer_note && !flag) {
      toast.error('Please enter a note');
      return;
    }
    
    const requestData = {
       
      marketer_note,
      flag
    };
  
    axiosInstance.put(`/settle-request/${id}/`, requestData)
      .then(response => {
        console.log('SettleRequest updated:', response.data);
        // Handle successful update
        toast.success("تم تعديل طلبك بنجاح")
      })
      .catch(error => {
        console.error('Error updating SettleRequest:', error.response.data);
        toast.error( error.response.data.message);
        // Handle error
      });
  };



  const [approval_img , setApproval] = useState("");
const [isModalVisible, setIsModalVisible] = useState(false);


  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [imagePreview, setImagePreview] = useState(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setApproval(file);
       // Set image preview for the uploaded file
       const reader = new FileReader();
       reader.onload = (e) => {
         setImagePreview(e.target.result);
       };
       reader.readAsDataURL(file);
    } else {
      setApproval(""); 
    }
       
  };


  const handleAdminUpdateSettleRequest = (id, flag) => {
    const admin_note = adminNotes.find((note) => note.id === id)?.note;
    // if (!admin_note && !flag) {
    //   toast.error('Please enter a note');
    //   return;
    // }

    

    let content = '';
    if (lang === 'en') {
      content = flag ? 'Are you sure you want to accept this settle request?' : 'Are you sure you want to reject this settle request?';
    } else if (lang === 'ar') {
      content = flag ? 'هل أنت متأكد أنك تريد قبول طلب التسوية هذا؟' : 'هل أنت متأكد أنك تريد رفض طلب التسوية هذا؟';
    } else {
      content = 'Language not supported';
      // Handle unsupported language case
    }

    
    showModal();

    if (!approval_img) {
      console.error('Approval image not provided');
      return;
    }
    Modal.confirm({
      title: 'Confirm',
      content: content,
      
      onOk() {
        const formData = new FormData();
        formData.append('admin_note', admin_note);
        formData.append('flag', flag);
        formData.append('approval_img', approval_img);
        // const requestData = {
        //   admin_note,
        //   flag,
        //   approval_img: approval_img,
        // };
  
        axiosInstance.put(`/settle-request/${id}/`, formData)
          .then(response => {
            console.log('SettleRequest updated:', response.data);
            // Handle successful update
            toast.success("تم تعديل طلبك بنجاح")
          })
          .catch(error => {
            console.error('Error updating SettleRequest:', error.response.data);
            if (error.response && error.response.data && error.response.data.message) {
              toast.error(error.response.data.message);
            } else {
              toast.error('لا يمكن تعديل طلب غير معلق');
            }
            // Handle error
          });
      },
      onCancel() {
        // Do nothing when canceled
      },
    });
  };
  
  const onClosee = () => {
    setOpenn(false);
    setRid(null);
    console.log("&&&&&", marketerNotes);
  };
  
  
  useEffect(() => {
    getSettleRequests();
  }, [page]);

  useEffect(() => {
    getSettleRequests();
    
  }, []);

  const handleViewBankAccount = (record) => {
    Modal.info({
        title: record.marketer_name +"  "+ t("bankaccount"),
        content: (
            <div>
                <p>{t("Full Name")}: {record.marketer_bank_account.full_name}</p>
                <p>{t("IBAN")}: {record.marketer_bank_account.IBAN}</p>
                <p>{t("bankAccountNumber")}: {record.marketer_bank_account.bank_account_number}</p>
                <p>{t("bankName")}: {record.marketer_bank_account.bank_name}</p>
                
            </div>
        ),
        onOk() {},
    });
};

  const tableColumns = useSettleRequestColumns(setOpenn, handleAdminUpdateSettleRequest, handleMarketerUpdateSettleRequest, handleImagePreview, setRid , handleViewBankAccount);
  return (
    <>
      <ToastContainer />
      <Row className="mb-4">
        <Col sm="12">
          <span className="title1">{t("sideMenu.wallet")}</span>
          <i class="fas fa-chevron-right px-3 title2"></i>
      
          <span className="title2">{t("settleRequest")}</span>
        </Col>
        <Col sm="12">
          <h2 className="pageTitle">{t("settleRequest")}</h2>
        </Col>
        
      </Row>
    
      <div className="reqTableContainer">
        <div className="tableHeader">
          <h6>{branch} {t("settleRequest")}</h6>
          
        </div>


        
        <TablePro
            loading={loading}
            columns={tableColumns}
            dataSource={data}
            pagination={{
                current: page,
                total: count,
                onChange: (page) => setPage(page),
            }}
        />
         
      </div>
      <MyModal rid={rid} 
        markteerNotes={marketerNotes}
        adminNotes={adminNotes}
        setAdminNotes={setAdminNote}
        user_role={user_role} 
        setMarkteerNotes={setMarketerNote}
        open={openn}
        onClose={onClosee}
       />

 

        <Dialog open={open} onClose={() => setOpen(false)}>
              <DialogContent>
                <img src={selectedImage} alt="Preview" style={{ maxWidth: '100%' }} />
              </DialogContent>
            </Dialog>


            <Modal
        title="Upload Image"
        visible={isModalVisible}
        onCancel={handleCancel}
    
        centered // To center the modal
      >
        <div style={{ textAlign: 'center' }}>
          {/* Image preview */}
          {imagePreview && (
            <img src={imagePreview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '300px' }} />
          )}

          {/* Input for file upload */}
          <Row>
          <Col>
          <Input type="file" onChange={handleImageUpload} />
          </Col>
          <Col>
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleCancel}>
            Submit
          </Button>,
          </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
const MyModal = ({rid, markteerNotes, setMarkteerNotes, adminNotes, setAdminNotes, open, onClose, user_role}) => {
  return(
  <Modal open={open} onCancel={onClose} onOk={onClose}>
    <input onChange={(e) => {
        let newNotes = user_role === "Organization Admin" ? [...adminNotes] : [...markteerNotes]
        const indx = newNotes.findIndex((note) => note.id === rid)
        if (indx > -1) {
          newNotes[indx].note = e.target.value
        } else {
          newNotes.push({
            id:rid,
            note:e.target.value
          })
        }
        user_role === "Organization Admin" ? setAdminNotes(newNotes) : setMarkteerNotes(newNotes)
      }}
      value={user_role === "Organization Admin" ? adminNotes.find((note) => note.id === rid)?.note : markteerNotes.find((note) => note.id === rid)?.note}
      />
  </Modal>
  );
}
export default ErrorHandler(SettleRequests, axiosInstance);
