import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { DatePicker, Select } from "antd";
import { DataGrid } from "@mui/x-data-grid";
import "../../../../style-sheets/table.css";
import axiosInstance from "../../../../api/axios";
import ErrorHandler from "../../../../error-handler/ErrorHandler";
// import { managerCol,sellerCol,courierCol } from "../../layout/tableColumns";
import { managerCol, managerCol_ar } from "../../../layout/tableColumns";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TablePro from "../../../tables/TablePro";
import useLanguage from "../../../../hooks/useLanguage";

const BranchManagers = (props) => {
    const { t, i18n } = useTranslation();

    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState("");
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectionModel, setSelectionModel] = useState("");
    const [selectedUser, setSelectedUser] = useState([]);
    const lang = useLanguage();
    const { Option } = Select;
    const user = useSelector((state) => state.auth.user);

    const handleBranch = (e) => {
        console.log(e);
        setSelectedBranch(e);
    };

    const getData = async () => {
        let result;

        result = await axiosInstance.get(
            `/FilterOrgEmployee/?page=1&branch=${
                selectedBranch !== null ? selectedBranch : ""
            }`,
            {
                params: {
                    branch: selectedBranch,
                    page,
                    search: keyword,
                },
            }
        );

        if (result) {
            console.log(result);
            setData(result.data.data);
            setCount(result.data.count);
        }
    };

    useEffect(() => {
        getData();
    }, [selectedBranch, page, keyword]);

    const getBranches = async () => {
        const result = await axiosInstance.get("/Branch/", {
            params: {
                page_size: 100000,
            },
        });
        if (result) {
            setBranches(result.data.data);
        }
    };

    const proceed = () => {
        props.handleChange(null, props.value + 1);
        props.getSelectedUser(selectedUser);
    };

    useEffect(() => {
        getBranches();
    }, []);
    return (
        <div>
            <Container>
                <Row className="my-3">
                    <Col className="text-end" sm="12">
                        <button
                            disabled={selectedUser.length !== 0 ? false : true}
                            className="confirm"
                            onClick={proceed}
                        >
                            {t("proceed")}
                        </button>
                    </Col>
                </Row>
                <TablePro
                    onSearch={setKeyword}
                    headerExtra={
                        <Select
                            onChange={handleBranch}
                            value={selectedBranch}
                            style={{ width: 140 }}
                            placeholder={t("branch")}
                        >
                            {branches.map((branch) => {
                                return (
                                    <Option
                                        name={branch.name}
                                        key={branch.id}
                                        value={branch.id}
                                    >
                                        {branch.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    }
                    dataSource={data}
                    columns={lang.includes("en") ? managerCol : managerCol_ar}
                    pagination={{
                        current: page,
                        total: count,
                        onChange: (page) => setPage(page),
                    }}
                    selection={"radio"}
                    selectedRows={selectionModel}
                    setSelectedRows={setSelectionModel}
                    setSelectedRecords={setSelectedUser}
                />
            </Container>
        </div>
    );
};

export default ErrorHandler(BranchManagers, axiosInstance);
