import RegisterLayout from "./register_layout";
import { useTranslation } from "react-i18next";
import ReactCodeInput from "react-verification-code-input";
import React, { useState } from "react";
import { Button } from "antd";
import axiosInstance from "../../../../api/axios";
import useLanguage from "../../../../hooks/useLanguage";
import "../../../../style-sheets/login.css";


export default function ConfirmNumber({ nextStep }) {
    const { t } = useTranslation();
    const [error, setError] = useState();
    const [codeState, setCode] = useState("");
    const [loading, setLoading] = useState(false);
    const lang = useLanguage();

    async function verifyOTP() {
        try {
            setLoading(true);
            const res = await axiosInstance.post(
                "/VerifyOtp/register",
                {
                    code: codeState,
                },
                {
                    headers: {
                        "Accept-Language": lang,
                    },
                }
            );

            if (res.data) {
                nextStep(res.data);
            } else {
                setError(t("invalid_code"));
            }
        } catch (e) {
            setError(t("invalid_code"));
        } finally {
            setLoading(false);
        }
    }

    return (
        <RegisterLayout title={t("confirmNumber")}>
            <p className="subTitle mt-2">{t("enterCodePhone")}</p>
            {/*<p className="mt-5">{t("confirmationCode")}</p>*/}
            <div dir="ltr" className="custom-styles mt-2">
                <ReactCodeInput
                    fields={6}
                    type="text"
                    value={codeState ?? ""}
                    onChange={setCode}
                />
                <p className="error">{error ?? ""}</p>
            </div>
            <div style={{}}>
                <Button
                    onClick={verifyOTP}
                    loading={loading}
                    htmlType="submit"
                    type="submit"
                    className="confirm"
                    style={{
                        marginTop: "24px",
                        marginInlineStart: "auto",
                        borderRadius:'20px',
                        width : '18rem',
                        height : '3rem'

                    }}
                >
                    {t("register")}
                </Button>
            </div>
        </RegisterLayout>
    );
}
