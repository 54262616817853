import { GET_SELLER , GET_CLIENT } from "../actions/actionTypes";
const initialState = {
  selectedSeller: "",
  selectedClient : ""
};

export default function (state = initialState, action) {
  //  (action.payload);
  switch (action.type) {
    case GET_SELLER:
      return {
        ...state,
        selectedSeller: action.payload,
      };
      case GET_CLIENT:
        return {
          ...state,
          selectedClient: action.payload,
        };

    default:
      return state;
  }
}
