export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER";
export const REFRESH_ORG = "REFRESH_ORG";
export const LOGOUT = "LOGOUT";
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const SHOW_NAV = "SHOW_NAV";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const GET_ROUND_ID = "GET_ROUND_ID";
export const SHOW_CALENDER = "SHOW_CALENDER";
export const IS_QUIZ = "IS_QUIZ";
export const IS_SUBJECT = "IS_SUBJECT";
export const STATE = "STATE";
export const GET_SELLER = "GET_SELLER";
export const GET_CLIENT = "GET_CLIENT";
export const SEARCH = "SEARCH";
export const ADD_ORDER = "ADD_ORDER";
export const IS_VALID = "IS_VALID";
export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const USER_TYPE = "USER_TYPE";
export const RUNSHEET = "RUNSHEET";
export const RUNSHEET_ORDERS = "RUNSHEET_ORDERS";
export const REASSIGNED_RUNSHEET = "REASSIGNED_RUNSHEET";
export const IS_REASSIGNED = "IS_REASSIGNED";
export const BRANCH = "BRANCH";
export const ZONE = "ZONE";
export const POSTPONED_ORDERS = "POSTPONED_ORDERS";
export const POSTPONED_ORDERS_IDS = "POSTPONED_ORDERS_IDS";
export const POSTPONE_DATE = "POSTPONE_DATE";
export const GET_TICKETS_RETURNED = "GET_TICKETS_RETURNED";
export const SELECTED_USER = "SELECTED_USER";
export const TICKET_PRINTED = "TICKET_PRINTED";
export const PLAN = "PLAN";
export const CODE = "CODE";

export const SHOW_ACCOUNT_CREATION_ERROR = "SHOW_ACCOUNT_CREATION_ERROR";
export const SET_COPY_WORK_MODEL = "SET_COPY_WORK_MODEL";
export const DROP_COPY_WORK_MODEL = "DROP_COPY_WORK_MODEL";
export const SET_DIMENTIONS = "SET_DIMENTIONS";
