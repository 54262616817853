import { POSTPONED_ORDERS } from './actionTypes'

export const postponedOrders = (info) => {
    return {
        type: POSTPONED_ORDERS,
        payload: info
    }

}

