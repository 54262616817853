import axiosInstance from "./axios";
import formattedError from "./errorFormatter";

const OrderChainRepository = {
    async getById(id) {
        try {
            const resOrder = await axiosInstance.get(`/Order/${id}`);
            const resSummary = await axiosInstance.get(
                `/OrderChainSummary/${id}`
            );

            if (resSummary.data && resOrder.data) {
                const order = resOrder.data;
                return {
                    success: true,
                    data: {
                        order: {
                            id: order.id,
                            paymentMethod: order.payment_method,
                            type: order.type,
                        },
                        seller: {
                            name: order.seller.user.name,
                            store: order.seller.store_name,
                            phone: order.seller.user.phone,
                            pickupAddress: order.seller_address.address,
                        },
                        customer: {
                            name: order.client.name,
                            phone: order.client.phone,
                            address: order.client_address.address,
                        },
                        summary: resSummary.data,
                    },
                };
            }
        } catch (e) {
            return formattedError(e);
        }
    },
};

export default OrderChainRepository;
