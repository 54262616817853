import PageHeader from "../../../../../../components/layout/PageHeader";
import TablePro from "../../../../../../components/tables/TablePro";
import { useOrdersColumns } from "../../../../../../components/layout/tableColumns";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../../../api/axios";
import ErrorHandler from "../../../../../../error-handler/ErrorHandler";
import { Form, Input, Select, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import WMButton from "../../../../../../components/pages/AllWorkModels/WMButton";
import { useHistory } from "react-router-dom";
import { SuccessfulRequest } from "../../../../../../components/modals/confirmationModal";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { InboxOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import useLanguage from "../../../../../../hooks/useLanguage";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
function RequestRefund() {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [file, setFile] = useState();
    const [form] = Form.useForm();
    const history = useHistory();
    const { t } = useTranslation();
    const lang = useLanguage();
    const user = useSelector((state) => state.auth.user);
    useEffect(() => {
        fetchOrder();
    }, []);

    async function fetchOrder() {
        const res = await axiosInstance.get("/FilterOrder/", {
            params: {
                page_size: 10000,
                state: 1,
                refund_available: true,
                seller: user.user.user_id,
            },
        });

        setOrders(res.data.data);
    }

    async function onRequest(value) {
        setLoading(true);

        const formData = Object.entries({
            order: value.order,
            refund_reason: value.refund_reason,
            seller: user.user.user_id,
            refund_proof: file,
        }).reduce((prev, cur) => {
            if (typeof cur[1] !== "undefined") prev.append(cur[0], cur[1]);
            return prev;
        }, new FormData());
        try {
            await axiosInstance.post("/WalletRefundRequest/", formData);

            setSuccess(true);
        } catch (e) {
            const errors = e.response.data;
            const errorMessage = e.response.data.message;
            toast.error(errorMessage);
            form.setFields(
                Object.entries(errors).map(([key, value]) => {
                    return {
                        name: key,
                        errors: value,
                    };
                })
            );
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <PageHeader
                title={t("sideMenu.requestRefund")}
                group={t("sideMenu.wallet")}
            />
            <ToastContainer />
            <Form form={form} layout="vertical" onFinish={onRequest}>
                <Form.Item required name="order" label={t("order")}>
                    <Select showSearch>
                        {orders.map((order) => (
                            <Select.Option value={order.id}>
                                #{order.id}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="refund_proof" label={t("refundProof")}>
                    <Dragger
                        onChange={(info) => {
                            const { status } = info.file;
                            console.log({
                                info: info.file,
                            });
                            if (status !== "uploading") {
                                setFile(info.file);
                            }
                        }}
                        multiple={false}
                        beforeUpload={() => false}
                        action={undefined}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">{t("drag")}</p>
                    </Dragger>{" "}
                </Form.Item>
                <Form.Item
                    required
                    name="refund_reason"
                    label={t("refundReason")}
                >
                    <TextArea />
                </Form.Item>
                <WMButton
                    disabled={loading}
                    loading={loading}
                    style={{
                        marginInlineStart: "auto",
                        marginTop: "24px",
                    }}
                    htmlType="submit"
                >
                    {t("request")}
                </WMButton>
            </Form>
            <SuccessfulRequest
                title={lang === "en" ? "Refund Request" : "طلب استرداد"}
                body={
                    lang === "en"
                        ? "Refund Request Sent Successfully"
                        : "طلب الاسترداد اُرسل بنجاح"
                }
                show={success}
                hide={() => {
                    setSuccess(false);
                    history.push("/seller_wallet");
                }}
            />
        </div>
    );
}

export default ErrorHandler(RequestRefund, axiosInstance);
