import React, { useState, useEffect } from "react";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { Row, Col } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import {
    productInfo,
    productInfo_ar,
    useTicketsColumns,
} from "../../layout/tableColumns";
import { useSelector, useDispatch } from "react-redux";
import {
    CreationConfirmation,
    SuccessfulRequest,
    UnSuccessfulRequest,
} from "../../modals/confirmationModal";
import {
    getSeller,
    getTicketsReturned,
} from "../../../global-state/actions/getSelectedAction";
import { addOrder } from "../../../global-state/actions/addOrderAction";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useLanguage from "../../../hooks/useLanguage";
import { Checkbox } from "antd";
import OrderSummary from "../../../features/common/tickets/order-summary/ui/OrderSummary";
import TablePro from "../../tables/TablePro";
import { toast, ToastContainer } from "react-toastify";
import { MobileAcceptMajor } from "@shopify/polaris-icons";
import Search from "antd/lib/input/Search";


function ReviewReturnRequest({ value, handleChange, returnedObj }) {
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [search, setSearch] = useState("");
    const [ID, setID] = useState("");
    const [oldShipping, setOldShipping] = useState(false);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState("");
    const [ordersDetails, setOrdersDetails] = useState(undefined);
    const [shipmentProviders, setShipmentProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState();
    const selectedSeller = useSelector(
        (state) => state.selectedData.selectedSeller
    );
    const returnedTicketsID = useSelector(
        (state) => state.returnedTickets.returnedTickets
    );
    const sellerAddress = useSelector((state) => state.order.seller_address);
    const user = useSelector((state) => state.auth.user);

    const selectedSellerDispatch = useDispatch();
    const selectedAddressDispatch = useDispatch();
    const selectedTicketsDispatch = useDispatch();
    const ticketsColumns = useTicketsColumns();

    const sendRequest = () => {
        setShow(true);
    };

    const hide = () => {
        setShow(false);
    };
    const hideShowConfirm = () => {
        setShowConfirm(false);
        history.push("/dashboard");
    };
    const confirm = async () => {
        const orders = new Set(returnedObj.map((it) => it.order.id));
        let data = {
            tickets: returnedObj.map((it) => it.id),
            orders: [...orders],
            return_address: returnedObj[0].order.seller_address.id,
            old_shipping_fees: oldShipping ?? false,
            shipment_provider : selectedProvider?.id
        };
        console.log(data);
        let result = await axiosInstance
            .post("/ReturnedOrder/", data)
            .catch((err) => {
                setShowError(true);
                setError(
                    [
                        Object.values(err.response.data.orders ?? {})[0],
                        Object.values(err.response.data.tickets ?? {})[0],
                    ]
                        .filter((it) => it)
                        .join(", ")
                );
            });
        if (result) {
            setShow(false);
            console.log(result.data);
            setOrdersDetails(result.data);
            // setID(result.data[0].id);
            selectedAddressDispatch(addOrder(""));
            selectedTicketsDispatch(getTicketsReturned(""));
            selectedSellerDispatch(getSeller(""));
        }
    };

    const { t } = useTranslation();
    const lang = useLanguage();

    async function updatePrice(id, newFees) {
        let result = await axiosInstance
            .post("/Order/" + id, {
                delivery_fees: newFees,
            })
            .catch((err) => {
                toast.error(
                    lang.includes("en")
                        ? "Can't Update shipping fees right now"
                        : "يتعذر تعديل تكلفة الشحن في الوقت الحالي"
                );
            });

        if (result?.data) {
            toast.success(
                lang.includes("en")
                    ? "Order Shipping Fees updated successfully"
                    : "تم تعديل تكلفة الشحنة بنجاح"
            );
        }
    }
     
    const fetchShipmentProviders = async () => {
        const client_address = returnedObj[0].order.client_address.id
        const seller_address = returnedObj[0].order.seller_address.id
        console.log({
            client_address,
            seller_address
        });

        let result = await axiosInstance
            .get(`/client_shipment_service_provider/`, {
                params: {
                    client_address,
                    search,
                    seller_address:seller_address,
                    seller_id :returnedObj[0].order.seller.id,
                },
            })

            
            .catch((err) => console.log(err));
        if (result) {
            setShipmentProviders(result.data.data);
        }
    };

    useEffect(() => {
        fetchShipmentProviders();
        console.log(returnedObj);
    }, []);

    return (
        <>
            <ToastContainer
                position={lang.includes("en") ? "bottom-right" : "bottom-left"}
            />
             <Row>
                        <Col className="mt-4" sm="12" md="12">
                            <Col sm={12} md={6}>
                                <Search onSearch={setSearch} />
                            </Col>
                            <label
                                style={{
                                    marginTop: "24px",
                                }}
                            >
                                {t("shipmentProvider")}
                            </label>
                            <div className="mt-2 position-relative providers-list">
                                {shipmentProviders.map((provider) => (
                                    <div
                                        className={`provider-card ${
                                            selectedProvider?.id === provider.id
                                                ? "provider-selected"
                                                : ""
                                        } ${
                                            !provider.available
                                                ? "provider-disabled"
                                                : ""
                                        }`}
                                        onClick={() =>
                                            provider.available &&
                                            setSelectedProvider(provider)
                                        }
                                    >
                                        <img
                                            src={
                                                provider.shipment_provider
                                                    .image ??
                                                "https://www.bonbonentertainment.nl/wp-content/uploads/2021/06/no-image.jpg"
                                            }
                                        />
                                        <span>
                                            {provider.shipment_provider.name}
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "12px",
                                                fontWeight: "400",
                                            }}
                                        >
                                            {!provider.available
                                                ? ` (${t("notAvailable")})`
                                                : null}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
            {/* <Row>
                <Col sm="12" md="6">
                    <Row className="mt-3">
                        <Col>
                            <div className="position-relative text-area-form">
                                <label className="bold-label">
                                    {t("orderID")}
                                </label>
                                <Form.Control
                                    defaultValue={
                                        returnedObj.length !== 0
                                            ? returnedObj[0].order.id
                                            : ""
                                    }
                                    disabled
                                    type="text"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <div className="position-relative text-area-form">
                                <label className="bold-label">
                                    {t("sellername")}
                                </label>
                                <Form.Control
                                    defaultValue={selectedSeller.user?.name}
                                    disabled
                                    type="text"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <div className="position-relative text-area-form">
                                <label className="bold-label">
                                    {t("phone")}
                                </label>
                                <Form.Control
                                    defaultValue={selectedSeller.user?.phone}
                                    disabled
                                    type="text"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Checkbox
                                checked={oldShipping}
                                onChange={(e) =>
                                    setOldShipping(e.target.checked)
                                }
                            >
                                {t("oldShippingFees")}
                            </Checkbox>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col sm="12">
                    {ordersDetails ? (
                        <div>
                            <TablePro
                                title={t("createdOrders")}
                                dataSource={ordersDetails}
                                columns={[
                                    {
                                        title: t("orderId"),
                                        dataIndex: "id",
                                    },
                                    {
                                        title: t("totalProducts"),
                                        dataIndex: "sub_total",
                                    },
                                    {
                                        title: t("shipment"),
                                        dataIndex: "delivery_fees",
                                        render(_, row, i) {
                                            return (
                                                <input
                                                    type="number"
                                                    style={{
                                                        border: "1px solid gray",
                                                        width: "50px",
                                                    }}
                                                    min={0}
                                                    value={
                                                        row.cal_delivery_fees ??
                                                        row.delivery_fees
                                                    }
                                                    onChange={(e) => {
                                                        const newOrdersDetails =
                                                            [...ordersDetails];
                                                        newOrdersDetails[
                                                            i
                                                        ].cal_delivery_fees =
                                                            e.target.value;
                                                        setOrdersDetails(
                                                            newOrdersDetails
                                                        );
                                                    }}
                                                />
                                            );
                                        },
                                    },
                                    {
                                        title: t("total"),
                                        render(_, row) {
                                            const total =
                                                row.delivery_fees &&
                                                row.sub_total
                                                    ? parseInt(
                                                          row.cal_delivery_fees ??
                                                              row.delivery_fees
                                                      ) +
                                                      parseInt(row.sub_total)
                                                    : "00.00";
                                            return <>{total}</>;
                                        },
                                    },
                                    {
                                        title: t("actions"),
                                        render(_, row) {
                                            return (
                                                <button
                                                    disabled={
                                                        row.delivery_fees ==
                                                        row.cal_delivery_fees
                                                    }
                                                    onClick={() =>
                                                        updatePrice(
                                                            row.id,
                                                            row.cal_delivery_fees
                                                        )
                                                    }
                                                    className={"tableID"}
                                                    style={{
                                                        background:
                                                            "transparent",
                                                        border: "none",
                                                        outline: "transparent",
                                                    }}
                                                >
                                                    {t("update")}
                                                </button>
                                            );
                                        },
                                    },
                                ]}
                            />
                        </div>
                    ) : (
                        <TablePro
                            rowHeight="80"
                            dataSource={returnedObj}
                            columns={ticketsColumns}
                        />
                    )}
                </Col>
            </Row> */}
            {/*<OrderSummary />*/}
            <Row>
                {ordersDetails ? (
                    <Col className="text-end buttons-margin mb-3" sm="12">
                        <button
                            onClick={() => setShowConfirm(true)}
                            className={
                                user.role == "Seller"
                                    ? "confitmSeller"
                                    : "confirm"
                            }
                        >
                            {t("confirm")}
                        </button>
                    </Col>
                ) : (
                    <Col className="text-end buttons-margin mb-3" sm="12">
                        <button
                            className="cancel"
                            type={"button"}
                            onClick={() => handleChange(null, value - 1)}
                        >
                            {t("previous")}
                        </button>
                        <button
                            onClick={sendRequest}
                            className={
                                user.role == "Seller"
                                    ? "confitmSeller"
                                    : "confirm"
                            }
                        >
                            {t("sendRequest")}
                        </button>
                    </Col>
                )}
            </Row>
            <CreationConfirmation
                sendRequest={confirm}
                show={show}
                hide={hide}
                body={
                    lang === "en"
                        ? `Are you sure that you want to Return ${returnedObj.length} Tickets From Order ID #${returnedObj[0].order.id}`
                        : `هل انت متأكد انك تريد إرجاع عدد ${returnedObj.length} بوليصة من الطلب رقم #${returnedObj[0].order.id}`
                }
                title={t("returnConfirmation")}
            />
            <SuccessfulRequest
                show={showConfirm}
                hide={hideShowConfirm}
                title={t("returnConfirmation")}
                body={t("returnConfirmText")}
            />
            <UnSuccessfulRequest
                title={t("returnConfirmation")}
                show={showError}
                body={error}
                hide={() => setShowError(false)}
            />
        </>
    );
}

export default ErrorHandler(ReviewReturnRequest, axiosInstance);
