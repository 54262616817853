import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect, useCallback } from "react";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { Row, Col, Modal } from "react-bootstrap";
import InformationCard from "../../layout/informationCard";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import BarcodeReader from "react-barcode-reader";
import { scan, scan_ar } from "../../layout/tableColumns";
import { Select } from "antd";
import {
    SuccessfulRequest,
    UnSuccessfulRequest,
} from "../../modals/confirmationModal";
import { useTranslation } from "react-i18next";
import useLanguage from "../../../hooks/useLanguage";

function TicketScan() {
    const reducer = (previousValue, currentValue) =>
        previousValue + currentValue;
    const { t } = useTranslation();

    let searchInput;
    const dispatch = useDispatch();
    const [ticketID, setTicketID] = useState("");
    const [ticketsData, setTicketsData] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState("");
    const [selectedCourier, setSelectedCourier] = useState("");
    const [selectedCourierName, setSelectedCourierName] = useState("");
    const [next, setNext] = useState("");
    const [loadMore, setLoadMore] = useState(true);
    const [branches, setBranches] = useState([]);
    const [couriers, setCouriers] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [count, setCount] = useState(1);
    const [show, setShow] = useState(false);
    const [showFailed, setShowFailed] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [error, setError] = useState("");

    const hide = () => {
        setShow(false);
        setShowFailed(false);
    };

    const hideSuccess = () => {
        setTicketsData([]);
        setShowSuccess(false);
    };

    const getTicket = async (data) => {
        let result = await axiosInstance
            .get(`/Ticket/${data ?? ""}`, {
                params: {
                    couriers: selectedCourier,
                    branch: selectedBranch,
                },
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response?.status === 404) {
                    setShow(true);
                }
            });
        if (result) {
            console.log(result.data);
            const tickets = Array.isArray(result.data?.data)
                ? [...result.data.data]
                : [...ticketsData, result.data];
            console.log({ tickets });
            setTicketsData(tickets);
            if (!Array.isArray(result.data?.data)) {
                let ids = tickets.map((ticket) => ticket.id);
                console.log(ids);
                setSelectionModel(ids);
            }
        }
    };

    useEffect(() => {
        getTicket();
    }, [selectedBranch, selectedCourier]);

    const onScroll = async (event, data, setData) => {
        var target = event.target;
        let result;
        if (
            loadMore &&
            target.scrollTop + target.offsetHeight === target.scrollHeight
        ) {
            result = await axiosInstance
                .get(`${next}`)
                .catch((err) => console.log(err));

            if (result) {
                setData([...data, ...result.data.data]);
                setNext(result.data.next);
                if (result.data.next === null) {
                    setLoadMore(false);
                }
            }
        }
    };

    const getCouriers = async () => {
        let result = await axiosInstance
            .get(`/FilterCourier/?branch=${selectedBranch}`)
            .catch((err) => console.log(err));
        if (result) {
            console.log(result.data.data);
            setCouriers(result.data.data);
            setCount(result.data.count);
        }
    };

    const getBranches = async () => {
        let result = await axiosInstance
            .get("/Branch/")
            .catch((err) => console.log(err));
        if (result) {
            setBranches(result.data.data);
            setNext(result.data.next);
        }
    };

    useEffect(() => {
        console.log(ticketsData);
    }, [ticketsData]);

    const search = () => {
        getTicket(ticketID);
    };
    const handleScan = (data) => {
        console.log(data);
        getTicket(data);
    };
    const handleError = (err) => {
        console.error(err);
    };
    const handleInput = (e) => {
        setTicketID(e.target.value);
    };
    const recieve = async () => {
        let ticketsMoney = ticketsData.map((ticket) =>
            parseFloat(ticket.price)
        );
        let totalMoney = ticketsMoney.reduce(reducer);
        console.log(totalMoney);
        let result = await axiosInstance
            .post(`/ReceiveFromCourier/${selectedCourier}`, {
                tickets_returned: selectionModel,
                money_returned: totalMoney,
            })
            .catch((err) => {
                setError(err.response.data);
                console.log(err.response);
                setTimeout(() => {
                    setShowFailed(true);
                }, 1000);
            });

        if (result) {
            setShowSuccess(true);
        }
    };

    const handleOptions = async (e, v, option) => {
        console.log(e);
        console.log(v);
        if (v?.type === "courier") {
            setSelectedCourierName(v?.name);
        }
        option(e);
    };
    useEffect(() => {
        console.log("ticket", ticketID);
    }, [ticketID]);

    //   useEffect(() => {
    //       searchInput.focus()
    //   }, []);
    const lang = useLanguage();

    return (
        <div>
            <Row>
                <Col sm="12">
                    <span className="title1 text-uppercase">{t("scan")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("sideMenu.ticketScan")}</span>
                </Col>
            </Row>
            <Row>
                <Col sm="6">
                    <h1 className="reqTypeTitle">{t("sideMenu.ticketScan")}</h1>
                </Col>
            </Row>
            <InformationCard
                title={t("infoScanTitle")}
                subTitle={t("infoScanSub")}
                list={t("scanInfoTxt", { returnObjects: true })}
            />

            <Row className="my-3">
                <Col sm="12" md="6">
                    <div className="position-relative text-area-form">
                        <label className="bold-label">
                            {t("chooseBranch")}
                        </label>

                        <Select
                            value={selectedBranch}
                            onChange={(e, v) =>
                                handleOptions(e, v, setSelectedBranch)
                            }
                            allowClear
                            onClick={getBranches}
                            onPopupScroll={(e) =>
                                onScroll(e, branches, setBranches)
                            }
                            className="form-control paginated"
                            name="branch"
                        >
                            {branches.map((branch) => (
                                <Select.Option
                                    key={branch.id}
                                    type="branch"
                                    name={branch.name}
                                    value={branch.id}
                                >
                                    {branch.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                </Col>
                <Col sm="12" md="6">
                    <label className="bold-label">{t("chooseCourier")}</label>

                    <Select
                        value={selectedCourier}
                        onChange={(e, v) =>
                            handleOptions(e, v, setSelectedCourier)
                        }
                        allowClear
                        disabled={selectedBranch === ""}
                        onClick={getCouriers}
                        onPopupScroll={(e) =>
                            onScroll(e, couriers, setCouriers)
                        }
                        className="form-control paginated"
                        name="courier"
                    >
                        {couriers.map((courier) => (
                            <Select.Option
                                key={courier.id}
                                type="courier"
                                name={courier.user.name}
                                value={courier.id}
                            >
                                {courier.user.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
            </Row>
            <Row>
                <Col sm="12" md="6" className="p-4" style={{ display: "flex" }}>
                    <div class="input-icons">
                        <i class="fa fa-search icon"></i>
                        <input
                            ref={(inputEl) => (searchInput = inputEl)}
                            value={ticketID}
                            onChange={handleInput}
                            // onKeyPress={sendRequest}
                            class="searchInput"
                            placeholder={t("searchTicket")}
                            type="text"
                        />
                    </div>
                    <button
                        style={{ height: "36px" }}
                        className="confirm mx-3"
                        onClick={search}
                        disabled={ticketID !== "" ? false : true}
                    >
                        {t("search")}
                    </button>
                </Col>
            </Row>
            <Row>
                <Col sm="12" className="btnPosition">
                    <button
                        className="confirm"
                        disabled={
                            selectedBranch === "" ||
                            selectedCourier === "" ||
                            selectionModel.length === 0
                        }
                        onClick={recieve}
                    >
                        {t("recieveInHub")}
                    </button>
                </Col>
            </Row>

            <div dir="ltr" className="requestTable">
                <DataGrid
                    rowHeight="80"
                    rows={ticketsData}
                    columns={lang == "ar" ? scan_ar : scan}
                    disableSelectionOnClick
                    columnsPanel
                    pagination
                    paginationMode="server"
                    rowCount={count}
                    pageSize={10}
                    checkboxSelection
                    selectionModel={selectionModel}
                    onSelectionModelChange={(ids) => {
                        setSelectionModel(ids);
                        console.log(ids);
                        // console.log("ids ", ids)
                        const selectedIDs = new Set(ids);
                        // // console.log([...selectedIDs])
                        const selectedRowData = ticketsData.filter((row) =>
                            selectedIDs.has(row.id)
                        );
                        setTickets(selectedRowData);
                        // console.log(selectionModel)
                        console.log(selectedRowData);
                        // if(selectedRowData.length !== 0){
                        //     console.log("here")

                        //     setSelectedOrders(selectedRowData)
                        // }
                    }}
                />
            </div>
            <BarcodeReader onError={handleError} onScan={handleScan} />
            <Modal
                dir={lang === "en" ? "ltr" : "rtl"}
                show={show}
                onHide={hide}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("scanning")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("ticketNotFound")}</Modal.Body>
                <Modal.Footer>
                    <button onClick={hide} className="cancel">
                        {t("cancel")}
                    </button>
                </Modal.Footer>
            </Modal>
            <SuccessfulRequest
                show={showSuccess}
                title={t("ticketScan")}
                body={
                    lang === "en"
                        ? `You have recieved ${selectionModel.length} tickets from courier ${selectedCourierName} with ID ${selectedCourier}`
                        : `لقد تم استلام عدد ${selectionModel.length} بوليصة من التاجر ${selectedCourierName} برقم ${selectedCourier}`
                }
                hide={hideSuccess}
            />
            <UnSuccessfulRequest
                show={showFailed}
                title={t("ticketScan")}
                body={error}
                hide={hide}
            />
        </div>
    );
}

export default ErrorHandler(TicketScan, axiosInstance);
