import { USER_TYPE } from './actionTypes'

export const userType = (info) => {
    return {
        type: USER_TYPE,
        payload: info
    }

}

