import DetailsTab from "./DetailsTab";
import { useTranslation } from "react-i18next";

export default function MerchantDetails({ data }) {
    const { t } = useTranslation();
    console.log({ data });
    return (
        <DetailsTab
            items={[
                {
                    label: t("name"),
                    value: data.seller?.user?.name,
                },
                {
                    label: t("store"),
                    value: data.seller_address?.sender_name || data.seller?.store_name || "No Name",
                },                                {
                    label: t("phone"),
                    value: data.seller?.user?.phone,
                },
                {
                    label: t("altPhone"),
                    value: data.seller?.user?.phone_alt,
                    hidden: !data.seller?.user?.phone_alt,
                },
                {
                    label: t("address"),
                    value: data.seller_address?.address,
                },
            ]}
        />
    );
}
