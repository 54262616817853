import { POSTPONED_ORDERS_IDS } from './actionTypes'

export const postponedIds = (info) => {
    return {
        type: POSTPONED_ORDERS_IDS,
        payload: info
    }

}

