import { RUNSHEET_ORDERS } from './actionTypes'

export const addRunsheetOrders = (info) => {
    return {
        type: RUNSHEET_ORDERS,
        payload: info
    }

}

