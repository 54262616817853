import { SET_COPY_WORK_MODEL, DROP_COPY_WORK_MODEL } from "../actions/actionTypes";
const initialState = {
  copyWorkModel: "",
};

export default function (state = initialState, action) {
  //  (action.payload);
  switch (action.type) {
    case SET_COPY_WORK_MODEL:
      return {
        ...state,
        copyWorkModel: action.payload,
      };
    case DROP_COPY_WORK_MODEL:
        return {
            ...state,
            copyWorkModel : ""
        }

    default:
      return state;
  }
}