import React, { useMemo, useState } from "react";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import { NavLink } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Icon } from "@shopify/polaris";
import { BalanceMajor } from "@shopify/polaris-icons";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { permissionGranted } from "../../../global-state/routes/generate-routes";

export default function RouteGroup(
    { key, meta, paths, pathsGroup, isSeller, setMobileOpen } = {
        meta: {},
        isSeller: false,
    }
) {
    const [open, setOpen] = useState(false);
    const user = useSelector((state) => state.auth.user);
    const authorizedPaths = useMemo(
        () =>
            paths.filter((path) => {
                if (paths.includes("/create_order") && +user?.role_id === 0)
                    return true;
                else return permissionGranted(pathsGroup[path]?.rules, user);
            }),
        [user?.role_id, user.role]
    );
    return (
        <>
            {authorizedPaths.length > 0 ? (
                <>
                    <ListItem
                        onClick={() => setOpen((prev) => !prev)}
                        button
                        key={key}
                    >
                        <ListItemIcon>
                            <Icon source={meta?.icon} color="base" />
                        </ListItemIcon>
                        <ListItemText primary={meta?.title} />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List
                            className="subList"
                            component="div"
                            disablePadding
                        >
                            {authorizedPaths.map((path) => (
                                <NavLink
                                    activeClassName={
                                        isSeller
                                            ? "sellerActiveLink"
                                            : "activeLink"
                                    }
                                    to={path}
                                    key={path}
                                    onClick={() => setMobileOpen?.(false)}
                                >
                                    <ListItem button key={path}>
                                        <ListItemText
                                            primary={pathsGroup[path]?.title}
                                        />
                                    </ListItem>
                                </NavLink>
                            ))}
                        </List>
                    </Collapse>
                </>
            ) : undefined}
        </>
    );
}
