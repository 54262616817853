import React from "react";
import { Row, Col } from "react-bootstrap";

export default function InformationCard({
    title,
    subTitle,
    list,
    list2,
    subTitle2,
}) {
    return (
        <div className={list2 ? "informationCard2" : "informationCard"}>
            {list2 ? (
                <>
                    <Row>
                        {" "}
                        <Col sm="1">
                            <i
                                id="TooltipExample"
                                className="fas fa-info-circle"
                            ></i>
                        </Col>
                        <Col sm="11">
                            <p className="informationCardTitle">{title}</p>
                            <p className="informationCardSubtitle">
                                {subTitle}
                            </p>
                            <ul>
                                {list.map((item) => {
                                    return <li className="item">{item}</li>;
                                })}
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="1"></Col>
                        <Col sm="11">
                            <p className="informationCardSubtitle">
                                {subTitle2}
                            </p>
                            <ul>
                                {list2.map((item) => {
                                    return <li className="item">{item}</li>;
                                })}
                            </ul>
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <div sm="1">
                        <i
                            id="TooltipExample"
                            className="fas fa-info-circle ml-5"
                        ></i>
                    </div>
                    <div sm="11">
                        <p className="informationCardTitle">{title}</p>
                        <p className="informationCardSubtitle">{subTitle}</p>
                        {list && (
                            <ul>
                                {list.map((item) => {
                                    return <li className="item">{item}</li>;
                                })}
                            </ul>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
