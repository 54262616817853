import React, { useEffect, useState } from "react";
import { Row, Container, Col } from "react-bootstrap";
import { Collapse, Input } from "antd";
import { Checkbox } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import axios from "axios";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import useLanguage from "../../hooks/useLanguage";

const { Panel } = Collapse;
const StateHeadersModel = (props) => {
  const lang = useLanguage()

  const { t, i18n } = useTranslation();

  const [customer, setCustomer] = useState([]);
  const [seller, setSeller] = useState([]);
  const [date, setDate] = useState([]);
  const [ticket, setTicket] = useState([]);
  const [courier, setCourier] = useState([]);
  const [headers , setHeaders] = useState(props.details.headers)

  useEffect(() => {
    console.log(headers)

    console.log(props.details)
    let customer = props.details.headers.filter(
      (val) => val.name.includes("Customer")
    );
    setCustomer(customer);
    let seller = props.details.headers.filter(
      (val) =>
        (val.name.includes("Seller") || val.name.includes("Store")) 
    );
    setSeller(seller);
    let courier = props.details.headers.filter(
      (val) => val.name.includes("Courier") || val.name.includes("Vehicle") || val.name.includes("Covered Zone")
    );
    setCourier(courier);
    let date = props.details.headers.filter(
      (val) => val.name.includes("Date")
    );
    setDate(date);
    let ticket = props.details.headers.filter(
      (val) =>
        (val.name.includes("Order") ||
          val.name.includes("Ticket") ||
          val.name.includes("Shipment") ||
          val.name.includes("Item") ||
          val.name.includes("Dimension") ||
          val.name.includes("Weight") ||
          val.name.includes("Special Label") ||
          val.name.includes("Feedback Comment") ||
          val.name.includes("Status") ||
          val.name.includes("Assign") ||
          val.name.includes("Fee") ||
          val.name.includes("Payment")) || 
          val.name.includes("Delivery Range")
    );
    setTicket(ticket);
  }, [props.details]);

  const onCheck = (e) =>{
    console.log(e)
    
    let copy = [...headers]
    let updatedCopy = copy.map(header => {
      if(header.id === e.target.id){
        header.header_status__active = e.target.checked
      }
      return header
    })

    setHeaders(updatedCopy)
  }

  const updateState = async () =>{

    let activated = headers.filter(header => header.header_status__active).map(active => active.id)

    let deActivated = headers.filter(header =>  !header.header_status__active).map(inActive => inActive.id)

    let result = await axiosInstance.post(`/StateTableHeader/${props.details.id}`,{
      headers_activate : activated,
      headers_deactivate : deActivated
    })

    if(result){
      props.showSuccess(true)
    }
  }

  useEffect(()=>{
    console.log(headers)
  },[headers])
  return (
    <div
      className={`Modal deliveryRangeDetails ${
        props.show ? "ModalOpen" : "ModalClosed"
      }`}
      style={{ padding: "10px 0px" }}
    >
      <h6 className="p-3">{t("stateTableHeaders")}</h6>

      {/* {props.details.headers.map((header) => {
        return (
          <div className="stateList">
            <p className="ps-3">{header.header_status__active?header.name:""}</p>
          </div>
        );
      })} */}


      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        defaultActiveKey={["0"]}
        // expandIconPosition="right"
        ghost
      >
        <Panel header={t("ticket")} key="1">
          {ticket.length !== 0 ? (
            ticket.map((name) => {
              return (
                <>
                
                  <p key={name.id} className="ps-3 headersItems">
                    <Checkbox id={name.id}  onChange={onCheck} checked={name.header_status__active}>{lang === "en" ? name.name : name.name_ar}</Checkbox>
                  </p>{" "}
                </>
              );
            })
          ) : (
            <p className="ps-3">No Headers</p>
          )}
        </Panel>
        <Panel header={t("date")} key="2">
          {date.length !== 0 ? (
            date.map((name) => {
              return (
                <>
                  {" "}
                  <p key={name.id} className="ps-3 headersItems">
                    <Checkbox id={name.id}  onChange={onCheck} checked={name.header_status__active}>{lang === "en" ? name.name : name.name_ar}</Checkbox>
                  </p>{" "}
                </>
              );
            })
          ) : (
            <p className="ps-3">No Headers</p>
          )}
        </Panel>
        <Panel header={t("customer")} key="3">
          {customer.length !== 0 ? (
            customer.map((name) => {
              return (
                <>
                  {" "}
                  <p key={name.id} className="ps-3 headersItems">
                    <Checkbox id={name.id}  onChange={onCheck} checked={name.header_status__active}>{lang === "en" ? name.name : name.name_ar}</Checkbox>
                  </p>{" "}
                </>
              );
            })
          ) : (
            <p className="ps-3">No Headers</p>
          )}
        </Panel>
        <Panel header={t("seller")} key="4">
          {seller.length !== 0 ? (
            seller.map((name) => {
              return (
                <>
                  {" "}
                  <p key={name.id} className="ps-3 headersItems">
                    <Checkbox id={name.id}  onChange={onCheck} checked={name.header_status__active}>{lang === "en" ? name.name : name.name_ar}</Checkbox>
                  </p>{" "}
                </>
              );
            })
          ) : (
            <p className="ps-3">No Headers</p>
          )}
        </Panel>
        <Panel header={t("courier")} key="5">
          {courier.length !== 0 ? (
            courier.map((name) => {
              return (
                <>
                  {" "}
                  <p key={name.id} className="ps-3 headersItems">
                    <Checkbox id={name.id}  onChange={onCheck} checked={name.header_status__active}>{lang === "en" ? name.name : name.name_ar}</Checkbox>
                  </p>{" "}
                </>
              );
            })
          ) : (
            <p className="ps-3">No Headers</p>
          )}
        </Panel>
      </Collapse>
      <Row>
        <Col className="px-4" sm="12">
          <label>{t("stateTitle")}</label>
          <Input value={props.details.title} disabled />
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: "end", padding: "12px", marginRight: "15px" }}>
          <button onClick={props.close} className="deactivate">
            {t("close")}
          </button>
          <button onClick={updateState} className="deactivate">{t("apply")}</button>
        </Col>
      </Row>
    </div>
  );
};

export default ErrorHandler(StateHeadersModel , axiosInstance);
