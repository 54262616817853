import React from "react";
import "./WMTable.css";
import {Empty, Spin, Table} from "antd";
import useLanguage from "../../../../hooks/useLanguage";
import {useTranslation} from "react-i18next";

export default function WMTable(props) {
    const {t} = useTranslation();
    return (
        <div className={"tableContainer"}>
            <Table {...props}
                   locale={{
                       emptyText: <div>
                           <Empty/>
                           <div>{t("empty")}</div>
                       </div>,
                   }}
                   loading={{
                       spinning: props.loading,
                       indicator: <div style={{
                           display: "flex",
                           justifyContent: "center",
                           alignItems: "center",

                       }}><Spin/></div>,
                   }}/>
        </div>
    );
}
