import { Form as AntdForm, Input } from "antd";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../../api/axios";
import useQuery from "../../../../hooks/useQuery";
import {notification} from "antd"
import { useState } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useHistory } from "react-router-dom";

function ComplainStep() {
    const { t } = useTranslation();
    const  shipment_order = useQuery().query?.order
    const [loading, setLoading] = useState(false);
    const [complaint, setComplaint] = useState();
    const history = useHistory();

    const onFinish = async () => {
        setLoading(true);
        const result = await axiosInstance.post("/SupportTicket/", {shipment_order, complaint})
        .catch((err) => {
            console.log(err.response.data);
            if (err.response?.data){
                Object.entries(err.response.data).forEach(([key, value]) => {
                    notification.error({
                        message: t(key),
                        description: t(value[0]),
                        placement:"bottomRight",    
                    });
                });
            }else{
                notification.error({
                    message: t("Error"),
                    description: t("Something went wrong"),
                    placement:"bottomRight",    
                });
            }
        });
        if (result?.status === 201) {
            history.push("/all_support_tickets");
        } 
        setLoading(false);
    };

    return ( 
        <div className="step">
            <div className="step__header">
                <h2 className="step__title">{t("sideMenu.complain")}</h2>
                <p className="step__description">{t("sideMenu.Please fill the form below to submit your complain")}</p>
            </div>
            <AntdForm
                layout="vertical"
                
            >
                <AntdForm.Item
                    name="complain"
                    label={t("sideMenu.complain")}
                    rules={[
                        {
                            required: true,
                            message: t("Please input your complain!"),
                        },
                    ]}
                >
                    <Input.TextArea
                        onChange={(e) => setComplaint(e.target.value)}
                        id="complain"
                        rows="8"
                    />
                </AntdForm.Item>
            </AntdForm>
            <div className="d-flex w-100">
                <button className="confirm ms-auto" 
                onClick={onFinish}
                >{
                    loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color:"#fff" }} spin />} /> : t("confirm")
                }</button>
            </div>

        </div>
     );
}

export default ComplainStep;