import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../../style-sheets/table.css";
import { Tag , Input , notification , Modal , Table  } from "antd";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { useSelector } from "react-redux";
 import {  useArchivedOrdersColumns  } from "../../../components/layout/tableColumns";
import { useTranslation } from "react-i18next";
import useLanguage from "../../../hooks/useLanguage";
import TablePro from "../../../components/tables/TablePro";

const ArchivedOrders = () => {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [page, setPage] = useState(1);
    const [recordDetails, setRecordDetails] = useState(null);
    const { t } = useTranslation();





    async function fetchData() {
        try {
            const result = await axiosInstance.get(`/archived/`);
            if (result && result.data) {
                setData(result.data);
                setCount(result.data);
            }
        } catch (error) {
            // Handle any errors occurred during fetching
            console.error("Error fetching data:", error);
        }
    }
   

   
    const sendRequest = async (record) => {
        const confirmed = await showConfirmation('Are you sure you want to send this request?');
        
        if (confirmed) {
            console.log(record);
            let result = await axiosInstance
                .post(`/Order/`, {
                    ...record.archived_data,
                    payment_method: "1",
                    type: "1",
                    including_fees: true,
                })
                .then(response => {
                    console.log('Request successful:', response.data);
                    delete_archive(record); //delete after successful post
                })
                .catch((err) => {
                  
                    let error;
                    if (typeof err.response?.data === "object") {
                        if (err.response?.data.error) {
                            error = err.response?.data.error;
                        } else {
                            const firstElement = Object.entries(
                                err.response?.data
                            )[0];
                            error = `${firstElement[1]}`;
                        }
                    } else {
                        error = err.response?.data;
                       
                    }
                    // Handle error if needed
                });
        } else {
            // Handle if user cancels the operation
            console.log('Request canceled by user');
        }
    };
    const showConfirmation = (text) => {
        return new Promise((resolve) => {
            Modal.confirm({
                title: 'Confirmation',
                content: text,
                onOk: () => resolve(true),
                onCancel: () => resolve(false),
            });
        });
    };
    const delete_archive = async (record) => {
        console.log('Action 2 clicked for record:', record);
    
        const orderIdToDelete = record.id;
        axiosInstance.delete(`/archived/${orderIdToDelete}`)
            .then(response => {
                console.log('Order deleted:', response.data);
                notification.success({
                    message: 'Order Deleted',
                    description: `Order ${orderIdToDelete} has been successfully deleted.`,
                });
                window.location.reload();
            })
            .catch(error => {
                console.error('Error deleting order:', error);
                notification.error({
                    message: 'Error Deleting Order',
                    description: `There was an error deleting order ${orderIdToDelete}. Please try again.`,
                });
            });
    };
    
    useEffect(() => {
        fetchData();
    }, []);

    
    const archived_columns = useArchivedOrdersColumns( sendRequest , delete_archive );
    return (
        <div>
            <Container>
                <Row className="mb-4">
                    <Col sm="12">
                        <span className="title1">{t("archivedOrders")}</span>
                        <i class="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">{t("archivedOrders")}</span>
                    </Col>
                    <Col sm="12">
                        <h2 className="pageTitle">{t("archivedOrders")}</h2>
                    </Col>
                </Row>
                <TablePro
                    dataSource={data}
                    columns={archived_columns}
                    pagination={{
                        current: page,
                        total: count,
                        onChange: (page) => setPage(page),
                    }}
                />
            </Container>
        </div>
    );
};

export default ErrorHandler(ArchivedOrders, axiosInstance);
