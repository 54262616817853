import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RegisterLayout from "./register_layout";
import axiosInstance from "../../../../api/axios";
import { Button, Form, Input } from "antd";
import PhoneInput, {
    useAntdPhoneRules,
} from "../../../../components/phone-input";

export default function RegisterNumber({ nextStep }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [form] = Form.useForm();
    const onRegister = async (values) => {
        try {
            setLoading(true);

            const res = await axiosInstance.post("/RequestOtp/", {
                phone: values.phone,
            });

            if (res.data) nextStep({ phone: values.phone });
        } catch (error) {
            const formError = Object.values(
                error?.response.data ?? {}
            )?.[0]?.[0];

            form.setFields([
                {
                    name: "phone",
                    errors: [formError],
                },
            ]);
        } finally {
            setLoading(false);
        }
    };
    return (
        <RegisterLayout title={t("register")}>
            <Form
                layout={"vertical"}
                form={form}
                className="mt-4"
                onFinish={onRegister}
            >
                <Form.Item
                    name="phone"
                    label={
                        <div>
                            <label>{t("phone")}</label>
                            <p className={"note"}>{t("phonePlaceholder")}</p>
                        </div>
                    }
                    rules={useAntdPhoneRules()}
                >
                    <PhoneInput formType={"antd"} />
                </Form.Item>

                <Button
                    loading={loading}
                    htmlType="submit"
                    type="submit"
                    className="w-100 confirm"
                    style={{
                        marginTop: "24px",
                        marginInlineStart: "auto",
                        borderRadius:'20px',
                        width : '18rem',
                        height : '3rem'

                    }}
                >
                    {t("register")}
                </Button>
            </Form>
        </RegisterLayout>
    );
}
