import React, { useMemo } from "react";
import { Col } from "react-bootstrap";
import styles from "./TicketsScan.module.css";
import { Progress } from "antd";
import TicketScanRepository from "../data";
import {useTranslation} from "react-i18next";

export default function ScanProgress({ runSheet, ticketsStates }) {

const {t} = useTranslation();
    const scanned = useMemo(
        () =>
            Object.values(ticketsStates).filter(
                (it) => it.state === TicketScanRepository.scanStates.scanned
            ).length,
        [Object.values(ticketsStates).length]
    );
    const percent = useMemo(
        () =>
            (scanned / runSheet.data.details.count) *
            100,
        [runSheet.data.details.count, scanned]
    );

    return (
        <Col md={6} sm={12}>
            <p className={styles.ticketsCounter}>
                <label>{t("ticketsScanned")}</label>
                {runSheet.data.details.count && (
                    <span>
                        {scanned}/
                        {runSheet.data.details.count}
                    </span>
                )}
            </p>
            <Progress
                percent={percent}
                showInfo={false}
                strokeColor={document.documentElement.style.getPropertyValue(
                    "--primaryColor"
                )}
            />
        </Col>
    );
}
