import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { Select } from "antd";
import { DataGrid } from "@mui/x-data-grid";
import "../../../style-sheets/table.css";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
// import { managerCol,sellerCol,courierCol } from "../../layout/tableColumns";
import { useSelector } from "react-redux";
import { SettingsCellOutlined } from "@mui/icons-material";
import axios from "axios";
import { CreationConfirmation } from "../../modals/confirmationModal";
import {
    archivedUsers,
    archivedUsers_ar,
    useArchivedUsers,
} from "../../layout/tableColumns";
import { useTranslation } from "react-i18next";
import TablePro from "../../tables/TablePro";
import useLanguage from "../../../hooks/useLanguage";

const ArchivedUsers = (props) => {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [selectedDate, setSelectedDate] = useState([]);
    const [actionType, setAction] = useState(null);
    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState();
    const [isSending, setIsSending] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [branchNextLink, setbranchNextLink] = useState("");
    const [loadMore, setLoadMore] = useState("");
    const [loading, setLoading] = useState(false);
    const [models, setModels] = useState([]);
    const [selectionRows, setSelectionRows] = useState([]);
    const [selectedUser, setSelectedUser] = useState("");
    const [changed, setChanged] = useState("");
    const [selectedUserID, setSelectedUserID] = useState("");
    const [show, setShow] = useState(false);
    const { t } = useTranslation();
    const [filters, setFilters] = useState({
        branch: {
            key: "branch",
            title: t("branch"),
            selectedValue: undefined,
            values: [],
        },
        type: {
            key: "type",
            title: t("userType"),
            selectedValue: undefined,
            values: [
                {
                    id: 2,
                    name: t("operation"),
                },
                {
                    id: 4,
                    name: t("accountant"),
                },
                {
                    id: 5,
                    name: t("inventoryManager"),
                },
                {
                    id: 6,
                    name: t("operationTeamMember"),
                },
            ],
        },
    });
    const { Option } = Select;
    const user = useSelector((state) => state.auth.user);
    const showModel = () => {
        setShow(true);
    };
    const hide = () => {
        setShow(false);
    };
    const handleBranch = (e) => {
        console.log(e);
        setSelectedBranch(e);
    };
    useEffect(() => {
        console.log(actionType);
    }, [actionType]);

    const getData = async () => {
        setLoading(true);
        let result = await axiosInstance.get(`/FilterHistoricalOrgEmployee/`, {
            params: {
                page,
                search: keyword,
                branch: filters.branch.selectedValue,
                type: filters.type.selectedValue,
            },
        });
        if (result) {
            setData(result.data.data);
            setCount(result.data.count);
        }
        setLoading(false);
    };

    useEffect(() => {
        getData();
    }, [page, keyword, filters]);

    useEffect(() => {
        getBranches();
    }, []);

    const getBranches = async () => {
        const result = await axiosInstance.get("/Branch/?page_size=10000");
        if (result) {
            setFilters({
                ...filters,
                branch: { ...filters.branch, values: result.data.data },
            });
            setCount(result.data.count);
            setbranchNextLink(result.data.next);
        }
    };

    useEffect(() => {
        console.log(selectionRows);
        setSelectedUserID(selectionRows[0]);
    }, [selectionRows]);

    const reActive = async () => {
        //req
        let result = await axiosInstance
            .post(`/HistoricalOrgEmployee/${selectedUserID}`)
            .catch((err) => {
                console.log(err.response);
            });
        if (result) {
            // console.log(result);
            getData();
            hide();
        }
    };
    const lang = useLanguage();

    return (
        <div>
            <Container>
                <Row className="mb-4">
                    <Col sm="12">
                        <span className="title1">{t("sideMenu.accounts")}</span>
                        <i class="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">{t("sideMenu.archived")}</span>
                    </Col>
                    <Col sm="12">
                        <h2 className="pageTitle">{t("sideMenu.archived")}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-end" sm="12">
                        <button
                            disabled={selectionRows.length === 0}
                            className="confirm"
                            onClick={showModel}
                            type="submit"
                        >
                            {t("reActive")}
                        </button>
                    </Col>
                </Row>
                <Row>
                    <div>
                        <div>
                            <TablePro
                                loading={loading}
                                headerExtra={
                                    <div className="row-centered-horizontal gap-1">
                                        {Object.keys(filters ?? {}).map(
                                            (key) => (
                                                <Select
                                                    key={key}
                                                    onChange={(v) => {
                                                        setFilters({
                                                            ...filters,
                                                            [key]: {
                                                                ...filters[key],
                                                                selectedValue:
                                                                    v,
                                                            },
                                                        });
                                                    }}
                                                    value={
                                                        filters[key]
                                                            ?.selectedValue
                                                    }
                                                    allowClear
                                                    style={{
                                                        minWidth: "150px",
                                                    }}
                                                    placeholder={
                                                        filters[key].title
                                                    }
                                                >
                                                    {filters[key].values.map(
                                                        (it) => (
                                                            <Select.Option
                                                                key={it.id}
                                                                value={it.id}
                                                            >
                                                                {it.name}
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            )
                                        )}
                                    </div>
                                }
                                dataSource={data}
                                columns={useArchivedUsers()}
                                onSearch={setKeyword}
                                selection={"radio"}
                                selectedRows={selectionRows}
                                setSelectedRows={setSelectionRows}
                                pagination={{
                                    current: page,
                                    total: count,
                                    onChange: (page) => setPage(page),
                                }}
                            />
                        </div>
                    </div>
                </Row>
            </Container>
            <CreationConfirmation
                sendRequest={reActive}
                show={show}
                hide={hide}
                body={
                    lang == "ar"
                        ? "هل تريد تفعيل هذا الحساب"
                        : `Are you sure that you want to Re-Active this user ?`
                }
                title={
                    lang == "ar" ? "أرشفة الحساب" : "Archive User Confirmation"
                }
            />
        </div>
    );
};

export default ErrorHandler(ArchivedUsers, axiosInstance);
