import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import "../../style-sheets/form.css";
import { Select, Button, Modal } from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axiosInstance from "../../api/axios";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";
const { Option } = Select;
let filteredIds;
const CreateStateModel = ({ show, hideModal, updatedStates, data }) => {
  const [titleAr, setTitleAr] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const [desc, setDesc] = useState("");
  const [validated, setValidated] = useState(false);
  const [primary, setPrimary] = useState("");
  const [second, setSecond] = useState("");
  const [prevStates, setPrevStates] = useState([]);
  const [selectedPrevState, setSelectedPrevState] = useState([]);
  const [selectedPrevStateID, setSelectedPrevStateID] = useState([]);
  const [selectedNextState, setSelectedNextState] = useState([]);
  const [selectedNextStateID, setSelectedNextStateID] = useState([]);
  const [selectedPossible, setSelectedPossible] = useState([]);
  const [selectedPossibleID, setSelectedPossibleID] = useState([]);
  const { t } = useTranslation();

  const [count, setCount] = useState(1);
  const handleSubmit = async (e) => {
    //req
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      edit();
    }
    setValidated(true);
    e.preventDefault();
  };

  const getStates = async () => {
    let result = await axiosInstance.get(`/State/`);
    if (result) {
      console.log(result);
      setPrevStates(result.data.data);
      setCount(result.data.count);
    }
  };
  const handlePrevState = (v,e) => {
    console.log(e)
    setSelectedPrevState(e.value);
    setSelectedPrevStateID(e.id);
  };
  const handleNextState = (v,e) => {
    console.log(e)
    setSelectedNextState(e.value);
    setSelectedNextStateID(e.id);
  };
  const handlePossible = (v, e) => {
    console.log(v, e);
    setSelectedPossible(v);
    filteredIds = e.map((state) => {
      return state.id;
    });
    console.log(filteredIds);
    setSelectedPossibleID(filteredIds);
  };

  const edit = async () => {
    let object = {
      title: titleEn,
      title_ar: titleAr,
      color: primary,
      accent_color: second,
      description: desc,
      next_state: selectedNextStateID,
      prev_state: selectedPrevStateID,
      accepted_states: selectedPossibleID
    };
    let result = await axiosInstance
      .put(`/State/${data.id}`, object)
      .catch((err) => {
        console.log(err.response);
      });
    if (result) {
      console.log(result);
      if (result.data) {
        hideModal();
        updatedStates(true);
      }
    }
  };
  useEffect(() => {
    console.log(data);
    setTitleEn(data.title);
    setTitleAr(data.title_ar);
    setDesc(data.description);
    setPrimary(data.color);
    setSecond(data.accent_color);
    setSelectedPrevState(data?.prev_state?.title);
    setSelectedNextState(data?.next_state?.title);
    setSelectedPrevStateID(data?.prev_state?.id);
    setSelectedNextStateID(data?.next_state?.id);
    // if (Object.keys(data).length>0) {
    console.log("hereee");
    // setSelectedPossible(data.accepted_states);
    let stateNames = data?.accepted_states?.map((state) => {
      console.log(state.title);
      return state.title;
    });
    let stateIDS = data?.accepted_states?.map((state) => {
      console.log(state.title);
      return state.id;
    });
    setSelectedPossible(stateNames);
    setSelectedPossibleID(stateIDS);

    // }
  }, [data]);
  const lang = useLanguage();

  return (
    <div dir={lang == "ar" ? "rtl" : "ltr"}>
      <Modal width={1000} visible={show} onCancel={hideModal}>
        <Container className="pt-3">
          <Row>
            <Col sm="12">
              <h6 className="pageTitle">Tracking Board Edit</h6>
            </Col>
          </Row>
          <Row className="customereditCard" style={{ boxShadow: "unset" }}>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              //   onFinish={onFinish}
            >
              {" "}
              <Row>
                <Col sm={12} md={6}>
                  <div className="text-area-form">
                    <Form.Group controlId="validationtitleEn">
                      <Form.Label>State Title(English)</Form.Label>

                      <Form.Control
                        placeholder="State Title(English)"
                        name="titleEn"
                        value={titleEn}
                        onChange={(e) => setTitleEn(e.target.value)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Required field!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </Col>
                <Col className="6">
                  <div className="text-area-form">
                    <Form.Group controlId="validationtitleAr">
                      <Form.Label>State Title(Arabic)</Form.Label>

                      <Form.Control
                        placeholder="State Title(Arabic)"
                        name="titleAr"
                        value={titleAr}
                        onChange={(e) => setTitleAr(e.target.value)}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Required field!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <Form.Group controlId="validationtitleEn">
                    <Form.Label>Description</Form.Label>

                    <Form.Control
                      placeholder="Description"
                      name="desc"
                      value={desc}
                      onChange={(e) => setDesc(e.target.value)}
                    />
                  </Form.Group>
                  <p style={{ color: "#6D7175", fontSize: "12px" }}>
                    We’ll use this description to be as a tip for other members
                    use this system.{" "}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="6">
                  <Form.Label htmlFor="exampleColorInput">
                    Primery Color
                  </Form.Label>
                  <Form.Control
                    type="color"
                    id="exampleColorInput"
                    value={primary}
                    title="Primery Color "
                    // value={color}
                    onChange={(e) => setPrimary(e.target.value)}
                  />
                </Col>
                <Col sm="12" md="6">
                  <Form.Label htmlFor="exampleColorInput">
                    Selection Color
                  </Form.Label>
                  <Form.Control
                    type="color"
                    id="exampleColorInput"
                    value={second}
                    title="Selection Color"
                    onChange={(e) => setSecond(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="4">
                  <Form.Label htmlFor="exampleColorInput">
                    Previous State
                  </Form.Label>
                  <Select
                    onChange={handlePrevState}
                    value={selectedPrevState}
                    style={{ width: 100 }}
                    placeholder="Previous State"
                    onClick={getStates}
                    style={{ display: "block" }}
                    //   onPopupScroll={onScrollStates}
                  >
                    {prevStates.map((state) => {
                      return (
                        <Option
                        value={state.title}
                        key={state.id}
                        id={state.id}
                        >
                          {state.title}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
                <Col sm="12" md="4">
                  {" "}
                  <Form.Label htmlFor="exampleColorInput">
                    Next State
                  </Form.Label>
                  <Select
                    onChange={handleNextState}
                    value={selectedNextState}
                    style={{ width: 100 }}
                    placeholder="Next State"
                    onClick={getStates}
                    style={{ display: "block" }}
                    //   onPopupScroll={onScrollStates}
                  >
                    {prevStates.map((state) => {
                      return (
                        <Option
                        value={state.title}
                        key={state.id}
                        id={state.id}
                        >
                          {state.title}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
                <Col sm="12" md="4">
                  {" "}
                  <Form.Label htmlFor="exampleColorInput">
                    Possible States
                  </Form.Label>
                  <Select
                    mode="multiple"
                    onChange={handlePossible}
                    value={selectedPossible}
                    style={{ width: 100 }}
                    placeholder="possible States"
                    onClick={getStates}
                    style={{ display: "block" }}
                    //   onPopupScroll={onScrollStates}
                  >
                    {prevStates.map((state) => {
                      return (
                        <Option
                          value={state.title}
                          key={state.id}
                          id={state.id}
                        >
                          {state.title}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col className="text-end" sm="12">
                  <Button onClick={hideModal} className="cancel">
                    Discard
                  </Button>
                  <Button
                    type="primary"
                    className="confirm"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Row>
        </Container>
      </Modal>
    </div>
  );
};

export default ErrorHandler(CreateStateModel, axiosInstance);
