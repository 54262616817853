import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Row, Col, Modal } from "react-bootstrap";
import InformationCard from "../layout/informationCard";
import ThreeDotsMenu from "../layout/threeDotsMenu";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import useLanguage from "../../hooks/useLanguage";
import TablePro from "../tables/TablePro";

function Areas(props) {
    const zone = useSelector((state) => state.zone.zone);
    const { t } = useTranslation();

    const [page, setPage] = useState(1);
    const [type, setType] = useState("");
    const [data, setData] = useState([]);
    const [area, setArea] = useState("");
    const [description, setDescription] = useState("");
    const [count, setCount] = useState(1);
    const [deleted, setDeleted] = useState();
    const [show, setShow] = useState(false);
    const [editedArea, setEditedArea] = useState("");
    const [loading, setLoading] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState("all");
    const [cityFilter, setCityFilter] = useState("");
    const [columns, setColumns] = useState([
        {
            dataIndex: "name",
            title: `${t("area")}`,
        },
        {
            dataIndex: ["city", "name"],
            title: `${t("city")}`,
        },
        {
            title: `${t("actions")}`,
            render: (_, row) => (
                <ThreeDotsMenu
                    onClick={() => settingEditedData(row)}
                    link="Area"
                    deleted
                    setEdited={setShow}
                    setDeleted={setDeleted}
                    showModal={setShowDelete}
                    data={row}
                />
            ),
        },
    ]);

    const handleFilter = (city) => {
        setCityFilter(city);
        setPage(1);
    };

    const settingEditedData = (data) => {
        setType("edit");
        setArea(data.name);
        setEditedArea(data);
        setSelectedCity(data.city.id);
    };
    useEffect(() => {
        getAreas();
    }, [deleted, cityFilter, page]);
    const handleArea = (e) => {
        setArea(e.target.value);
    };
    const getAreas = async () => {
        const result = await axiosInstance.get(
            `/FilterAreas/?${
                !["", "all"].includes(cityFilter) ? "city=" + cityFilter : ""
            }&page=${page}`
        );
        if (result) {
            setData(result.data.data);
            setCount(result.data.count);
        }
    };
    const getcities = async () => {
        const result = await axiosInstance.get("/City/?page_size=30");
        if (result) {
            setCities([{ id: "all", name: "All Cities" }, ...result.data.data]);
        }
    };
    const handleCity = (value) => {
        setSelectedCity(value);
    };
    const handleInput = (e, setFuntion) => {
        setFuntion(e.target.value);
    };
    const createArea = () => {
        setType("create");
        setShow(true);
    };
    const confirmRequest = async (type) => {
        let data = {
            name: area,
            city: selectedCity,
        };
        let result;
        setLoading(true);
        if (type === "create") {
            result = await axiosInstance.post("/Area/", data).catch((err) => {
                setLoading(false);
            });
        } else {
            result = await axiosInstance
                .put(`/Area/${editedArea.id}`, data)
                .catch((err) => {
                    setLoading(false);
                });
        }

        if (result) {
            hide();
            setLoading(false);
            getAreas();
            setArea("");
        }
    };
    const hide = () => {
        setShow(false);
        setArea("");
    };
    useEffect(() => {
        getAreas();
    }, [cityFilter]);
    useEffect(() => {
        getcities();
        setCityFilter("all");
    }, []);
    useEffect(() => {
        if (show) {
            setSelectedCity(cities[1].id);
            setCities([...cities.slice(1)]);
        } else setCities([{ id: "all", name: "All Cities" }, ...cities]);
    }, [show]);
    const lang = useLanguage();

    return (
        <>
            <style>
                {`
            @media (min-width: 600px) {
                .MuiToolbar-root .MuiButtonBase-root {
                  display: block;
                }
              }`}
            </style>
            <div>
                {/* <InformationCard
        title={t("workmodelInfoTitle")}
        subTitle={t("workmodelInfoSub")}
        list={[
          <p>
            {t("select")} <strong>{t("sideMenu.branches")}</strong>
          </p>,
          <p>
            {t("workModelCreateTxt1")} <strong>{t("workModelCreateTxt2")}</strong> {t("workModelCreateTxt3")}
          </p>,
          <p>
           {t("workModelCreateTxt4")} <strong>{t("workModelCreateTxt5")}</strong> {t("workModelCreateTxt6")}
          </p>,
          <p>
            {t("workModelCreateTxt7")} <strong>{t("workModelCreateTxt8")}</strong>.
          </p>,
          <p>
            {t("workModelCreateTxt9")} <strong>{t("workModelCreateTxt10")}</strong> {t("workModelCreateTxt11")}
          </p>
        ]}
      /> */}
                <Row>
                    <Col
                        sm="3"
                        className="btnPosition"
                        style={{ justifyContent: "flex-start" }}
                    >
                        {cities.length > 0 && (
                            <Select
                                onChange={handleFilter}
                                value={cityFilter}
                                style={{ width: "100%" }}
                                // defaultValue="all"
                            >
                                {cities.map((city) => (
                                    <Select.Option
                                        key={city.id}
                                        type={city.id}
                                        value={city.id}
                                        style={{
                                            position: "relative",
                                        }}
                                    >
                                        {city.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Col>
                    <Col sm="9" className="btnPosition">
                        <button onClick={createArea}>
                            <i className="fas fa-plus-circle"></i>{" "}
                            {t("addNewArea")}
                        </button>
                    </Col>
                </Row>
                <TablePro
                    dataSource={data}
                    columns={columns}
                    pagination={{
                        current: page,
                        total: count,
                        onChange: (page) => setPage(page),
                    }}
                />
                <Modal
                    dir={lang == "ar" ? "rtl" : "ltr"}
                    show={show}
                    onHide={hide}
                    style={{ zIndex: 1050 }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {type === "create"
                                ? t("addNewArea")
                                : t("editArea")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>{t("areaName")}</label>
                        <Form.Control
                            value={area}
                            onChange={(e) => handleInput(e, setArea)}
                            type="text"
                        />
                        {/* <p className="error">{area === "" ? "This Field is required" : ""}</p> */}

                        <label>{t("city")}</label>
                        {/* <Form.Control defaultValue={zone} disabled type="text" /> */}
                        <Select
                            onChange={handleCity}
                            value={selectedCity}
                            style={{ width: "100%" }}
                            placeholder={t("select_city")}
                        >
                            {cities.map((city) => (
                                <Select.Option
                                    key={city.id}
                                    type={city.id}
                                    value={city.id}
                                    style={{
                                        zIndex: 10000,
                                        position: "relative",
                                    }}
                                >
                                    {city.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={hide} className="cancel">
                            {t("close")}
                        </button>
                        <button
                            className="confirm"
                            disabled={loading}
                            onClick={() => confirmRequest(type)}
                        >
                            {type === "create" ? t("addArea") : t("edit")}
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}

export default ErrorHandler(Areas, axiosInstance);
