import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../api/axios";

function MarketerInvitation() {
    const { t } = useTranslation();
    const marketerID = useSelector((state) => state.auth.user.user.id);
    const [marketer, setMarketer] = useState(null);

    useEffect(() => {
        const getMarketer = async () => {
            try {
                let result = await axiosInstance.get(`/marketer-orders/${marketerID}/`);
                if (result && result.data) {
                    setMarketer(result.data);
                }
            } catch (err) {
                console.log(err);
            }
        };
        getMarketer();
    }, [marketerID]);

    return (
        <div className="px-3 rounded">
            <div className="d-flex justify-content-around align-items-center flex-wrap">
                {marketer && marketer.marketer_invitation_code ? (
                    <div className="d-flex flex my-2 align-self-stretch align-items-center shadow px-5 py-4 rounded">
                        <div className="bg-primary rounded-circle p-3">
                            {/* You can add content here if needed */}
                        </div>
                        <div className="ms-5">
                            <h6 className="text-muted">{t("invitationcode")}</h6>
                            <h3 className="font-weight-bold m-0">
                                {marketer.marketer_invitation_code}
                            </h3>
                        </div>
                    </div>
                ) : (
                    // Render a loader or placeholder while data is being fetched
                    <div>Loading...</div>
                )}
            </div>
        </div>
    );
}

export default MarketerInvitation;
