import React from "react";
import { Image } from "antd";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useLanguage from "../../../hooks/useLanguage";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import axiosInstance from "../../../api/axios";
import trader_price_list from '../../../images/trader_price_list.png';
import individual_price_list from '../../../images/individual_price_list.png';

function PriceList() {
  const { t } = useTranslation();
  const lang = useLanguage();
  const seller_type = useSelector((state) => state.auth.user.user.seller_type);

  return (
  
    <Container >
      <Row className="mb-4">
        <Col>
          <h2 className="pageTitle">{t("price_list")}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          {seller_type === '1' && (
            <img src={trader_price_list} alt="Trader Price List" style={{ width: "100%" }} />
          )}
          {seller_type === '2' && (
            <img src={individual_price_list} alt="Individual Price List" style={{ width: "100%" }} />
          )}
        </Col>
      </Row>
    </Container>
    
  );
}

export default ErrorHandler(PriceList, axiosInstance);
