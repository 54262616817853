import React, { Component } from "react";
import Modal from "./Modal/Modal";
import axios from "axios";
import { BASE_URL } from "../api/axios";
import { userRefresh } from "../global-state/actions/tokenAction";
import { LogOut } from "../global-state/actions/authAction";

import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Cookies from "js-cookie";
import axiosInstance from "../api/axios";
// import Aux from '../Auxilliary'
const axiosApiInstance = axios.create();

const ErrorHandler = (WrappedComponent, axios) => {
    return class extends Component {
        state = {
            accessToken: this.props.accessToken,
            error: null,
            show: false,
        };

        onLogout = (e) => {
            this.props.LogOut();
        };

        UNSAFE_componentWillMount() {
            const lang = localStorage.getItem("lang");
            const rememberMe = localStorage.getItem("rememberMe") === "true";
            const isLoggedIn = sessionStorage.getItem("isLoggedIn") === "true";

            console.log("RememberMe", {
                rememberMe,
                isLoggedIn,
                log: rememberMe || (!rememberMe && isLoggedIn),
            });
            const access =
                rememberMe || (!rememberMe && isLoggedIn)
                    ? Cookies.get("accessToken")
                    : null;

            if (!access) {
                this.props.LogOut();
                window.location.pathname = "/login";
                return;
            }
            this.reqInterceptor = axios.interceptors.request.use(
                async (config) => {
                    config.headers.Authorization = "Bearer " + access;
                    if (lang == "ar") {
                        config.headers.common["Accept-Language"] = "ar";
                    } else {
                        config.headers.common["Accept-Language"] = "en";
                    }
                    this.setState({ error: null });

                    return config;
                }
            );
            this.resInterceptor = axios.interceptors.response.use(
                (response) => {
                    return response;
                },
                async (error) => {
                    const originalRequest = error.config;
                    if (error.response && error.response.status === 401) {
                        console.log(originalRequest._retry);
                        originalRequest._retry = true;

                        console.log(error.response);
                        if (
                            error.response.config?.url.includes(
                                "/auth/jwt/refresh"
                            )
                        ) {
                            this.props.LogOut();
                            window.location.pathname = "/login";
                        } else {
                            let x = await axios
                                .post(BASE_URL + `/auth/jwt/refresh`, {
                                    refresh:
                                        localStorage.getItem("refreshToken"),
                                })
                                .catch((err) => {
                                    console.log("here");
                                    // console.log(err.response);
                                    if (
                                        err.response &&
                                        err.response.status === 401
                                    ) {
                                        console.log("here");
                                        this.props.LogOut();
                                        window.location.pathname = "/login";
                                    }
                                });

                            if (x) {
                                Cookies.set("accessToken", x.data.access);
                                console.log(x);
                            }
                        }

                        return axiosApiInstance(originalRequest);
                    } else if (error.response) {
                        if (
                            error.response.status === 400 &&
                            error.response.status
                        ) {
                            this.setState({ error: "", show: false });
                        }
                        // else if (error.response.status === 500) {
                        //   console.log(error.response);
                        //   let result = await axiosInstance.post(`/log_error`, {
                        //     domain_url: error.response.config.baseURL,
                        //     called_api: error.response.config.url,
                        //     method: error.response.config.method,
                        //   });
                        //   if (result) {
                        //     // console.log("here")
                        //     alert(
                        //       lang === "en"
                        //         ? `Something wrong happened, please try again later. \n If the problem persists contact customer support`
                        //         : `حدث خطأ ما و الرجاء المحاولة في وقت لاحق \n إذا استمرت المشكلة تواصل مع خدمة العملاء`
                        //     );
                        //     // this.setState(prevState=>({ error:{...prevState.error
                        //     // ,message :lang === "en" ? `Something wrong happened, please try again later. \n If the problem persists contact customer support` : `حدث خطأ ما و الرجاء المحاولة في وقت لاحق \n إذا استمرت المشكلة تواصل مع خدمة العملاء` } , show: true }));
                        //     return error;
                        //   }
                        // }
                    } else {
                        console.log(error);
                        this.setState({ error: error, show: true });
                        console.log(this.state);
                    }
                    return Promise.reject(error);
                }
            );
        }

        componentWillUnmount() {
            axios.interceptors.request.eject(this.reqInterceptor);
            axios.interceptors.response.eject(this.resInterceptor);
        }

        errorConfirmed = () => {
            this.setState({ error: null, show: false });
        };

        render() {
            return (
                <div>
                    <Modal show={this.state.show} clicked={this.errorConfirmed}>
                        {this.state.show ? this.state.error?.message : null}
                    </Modal>
                    <WrappedComponent {...this.props}></WrappedComponent>
                </div>
            );
        }
    };
};

const mapStateToProps = (state) => ({
    accessToken: state.accessToken.accessToken,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ userRefresh, LogOut }, dispatch);
};

const composedComponent = compose(
    connect(mapStateToProps, mapDispatchToProps),
    ErrorHandler
);
export default composedComponent;
