import { ADD_ORDER } from "../actions/actionTypes";
const initialState = {
  
};

export default function (state = initialState, action) {
  //  (action.payload);
  switch (action.type) {
    case ADD_ORDER:
      return action.payload === "" ? {} : {
        ...state, ...action.payload
      };

    
    default:
      return state;
  }
}