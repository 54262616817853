import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../../../api/axios';
import ErrorHandler from '../../../error-handler/ErrorHandler'
import { Row, Col } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector ,useDispatch} from "react-redux"
import {useHistory} from "react-router-dom"
import { useTranslation } from "react-i18next";


function PaidTickets(props) {
    const dispatchOrders = useDispatch();
    const { t } = useTranslation();

    const history = useHistory()
    const tickets = useSelector((state) => state.rsOrders.orders);
    const seller = useSelector((state) => state.selectedData.selectedSeller);
    const cur = useSelector(state => state.auth.user.user.organization.country.currency)

    const [count, setCount] = useState(1)
    const [page, setPage] = useState(1);
    const [selectionModel, setSelectionModel] = useState([]);
    const [orders, setOrders] = useState([]);
    const [columns, setColumns] = useState([
        {
            field: "id",
            headerName: "Ticket ID",
            width: 220,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => <p className="paragraphMargin tableID">#{row.row?.id}</p>
        },
        {
            field: "sfee",
            headerName: "Ship. Fee",
            width: 220,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => <p className="paragraphMargin credit">{row.row?.delivery_fees}</p>
        },
        {
            field: "rto",
            headerName: "RTO Fee",
            width: 180,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => <p className="paragraphMargin debit">{cur} {row.row?.return_fees}</p>
        },
        {
            field: "value",
            headerName: "Ticket Value",
            width: 200,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => <p className="paragraphMargin credit">{cur} {row.row?.price}</p>
        },
        {
            field: "method",
            headerName: "Payment Method",
            width: 220,
            // valueGetter: (row) => row.row?.id,
            renderCell: (row) => <p className="paragraphMargin">{row.row?.order.payment_method}</p>
        },
        
    ])

   
   


   
   

    useEffect(() => {
    }, [])
    return (
        <>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.wallets")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("paidRequests")}</span>
                  
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("sideMenu.wallets")}</h2>
                </Col>
            </Row>
          
            <div className="reqTableContainer">
                <div className="tableHeader">
                    <h6>{seller.user.name} Orders</h6>

                </div>

                <div dir='ltr' className="requestTable">
                    <DataGrid
                        rows={tickets}
                        columns={columns}
                        rowCount={count}
                        pagination
                        selectionModel={selectionModel}
                       

                    />
                </div>
            </div>
        </>
    );
}

export default ErrorHandler(PaidTickets, axiosInstance);