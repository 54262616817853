import { EnvelopeMajor } from "@shopify/polaris-icons";
import CreateOrderTabs from "../../components/layout/CreateOrderTabs";
import CreateOrderTabs2 from "../../features/common/tickets/create/ui/CreateOrderTabs";

import PostponeOrder from "../../components/layout/postponeOrder";
import TicketReturnRequestTabs from "../../components/layout/TicketsReturns/TicketReturnRequestTabs";
import ArchivedTickets from "../../components/pages/ArchivedTickets";
import OrderChainDetails from "../../components/pages/OrderChainDetails/OrderChain";
import TicketChainDetails from "../../components/pages/OrderChainDetails/TicketChain";
import ViewOrders from "../../components/pages/wallets/viewOrders";
import PaidTickets from "../../components/pages/wallets/paidTickets";
import TicketScan from "../../components/pages/TicketScan/ticketScan";
import React from "react";
import { useTranslation } from "react-i18next";
import TicketScanStart from "../../features/admin/ticket-scan/ui/TicketScanStart";
import TicketScanSession from "../../features/admin/ticket-scan/ui/TicketScanSession";
import CreateBulkOrder from "../../features/common/tickets/create/ui/CreateBulkOrder";
import AdminCreateParcel from "../../features/admin/tickets/create/ui/AdminCreateParcel";
import SellerCreateParcel from "../../features/seller/tickets/create/ui/SellerCreateParcel";
import TicketsReplacement from "../../features/common/tickets/replace/ui/TicketsReplacement";
import ArchivedOrders from '../../features/common/archived/archivedOrders';


export function useSellerOrdersRoute() {
    const { t } = useTranslation();

    return {
        "/create_order/parcel": {
            component: SellerCreateParcel,
            title: t("sideMenu.createNewTicket"),
        },
    };
}

export function useAdminOrdersRoute() {
    const { t } = useTranslation();
    return {
        "/create_order/parcel": {
            component: AdminCreateParcel,
            title: t("sideMenu.createNewTicket"),
            rules: [73, 121],
        },
    };
}

export default function useOrdersRoutes() {
    const { t } = useTranslation();
    return {
        meta: {
            title: t("sideMenu.tickets"),
            icon: EnvelopeMajor,
        },
        "/create_order/bulk": {
            component: CreateBulkOrder,
            title: t("sideMenu.createNewTicket"),
            rules: [73, 121],
        },

        "/create_order": {
            component: CreateOrderTabs2,
            title: t("sideMenu.createNewTicket"),
            rules: [73, 121],
        },
        "/postponed": {
            component: PostponeOrder,
            title: t("sideMenu.postpond"),
            rules: [76, 124],
        },
        "/return_ticket": {
            component: TicketReturnRequestTabs,
            title: t("sideMenu.returned"),
            rules: [76, 124],
        },
        "/replace_ticket": {
            component: TicketsReplacement,
            title: t("sideMenu.replace"),
            rules: [76, 124],
        },
        "/archived_tickets": {
            component: ArchivedTickets,
            title: t("sideMenu.archivedTickets"),
            rules: [76, 124],
        },
        "/ticket_scan": {
            component: TicketScanStart,
            icon: <span className="material-icons">document_scanner</span>,
            title: t("sideMenu.ticketScan"),
            rules: [391],
        },
        "/ticket_scan/:id": {
            component: TicketScanSession,
            rules: [391],
        },
        "/order_chain_details/:id": {
            component: (props) => <OrderChainDetails {...props} />,
        },
        "/ticket_details/:id": {
            component: (props) => <TicketChainDetails {...props} />,
        },
        "/view_orders": {
            component: ViewOrders,
            rules: [76, 124],
        },
        "/paid_tickets": {
            component: PaidTickets,
            rules: [76, 124],
        },
        "/archived_order": {
            component: ArchivedOrders,
            icon: <span className="material-icons">document_scanner</span>,
            title: t("archivedOrders"),
            rules: [76, 124],
        },
    };
}
