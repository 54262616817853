import {ADD_ACCOUNT, SHOW_ACCOUNT_CREATION_ERROR} from "../actions/actionTypes";

const initialState = {};

export default function (state = initialState, action) {
    //  (action.payload);
    switch (action.type) {
        case ADD_ACCOUNT:
            return action.payload === "" ? {} : {
                ...state, ...action.payload
            };
        case SHOW_ACCOUNT_CREATION_ERROR:
            return {
                ...state, errors: {
                    ...action.payload,
                }
            }
        default:
            return state;
    }
}