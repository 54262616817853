import axiosInstance from "../../../../api/axios";
import ErrorHandler from "../../../../error-handler/ErrorHandler";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useLanguage from "../../../../hooks/useLanguage";
import { Col, Row, Button, Form, Alert } from "react-bootstrap";

function SellerTax() {
  const user = useSelector((state) => state.auth.user.user);

  const [data, setData] = useState({});
  const [editableTaxNumber, setEditableTaxNumber] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [validationError, setValidationError] = useState("");

  const { t } = useTranslation();
  const lang = useLanguage();

  useEffect(() => {
    fetchSeller();
  }, []);

  async function fetchSeller() {
    const res = await axiosInstance.get(`/Seller/${user.user_id}`);
    if (res.data) setData(res.data);
  }

  function validateTaxNumber(taxNumber) {
    const isValid = /^\d{15}$/.test(taxNumber) && taxNumber.startsWith("3") && taxNumber.endsWith("3");
    return isValid;
  }

  async function handleUpdateTaxNumber() {
    if (!validateTaxNumber(editableTaxNumber)) {
      setValidationError(t("رقم التسجيل الضريبي يجب ان يكون 15 رقما و يبدأ و ينتهي ب 3"));
      return;
    }

    try {
      await axiosInstance.put(`/Seller/${user.user_id}`, {
        tax_registration_number: editableTaxNumber,
      });
      // Assuming the update is successful, you can update the local state or trigger a refetch.
      setIsEditing(false);
      fetchSeller();
    } catch (error) {
      console.error("Error updating tax number", error);
    }
  }

  return (
    <div>
      <Row className="mb-4">
        <Col sm="12">
          <span className="title1">{t("sideMenu.settings")}</span>
          <i className="fas fa-chevron-right px-3 title2" />
          <span className="title2">{t("tax_number")}</span>
        </Col>
        <div>
          <h2 className="pageTitle"> {t("tax_number")}</h2>
        </div>
      </Row>
      <Form>
        <Form.Group controlId="taxNumber">
          <Form.Label>{t("tax_number")}</Form.Label>
          {isEditing ? (
            <>
              <Form.Control
                type="text"
                value={editableTaxNumber}
                onChange={(e) => setEditableTaxNumber(e.target.value)}
              />
              {validationError && <Alert variant="danger">{validationError}</Alert>}
            </>
          ) : (
            <Form.Control plaintext readOnly defaultValue={data.tax_registration_number} />
          )}
        </Form.Group>
        {isEditing ? (
          <Button className="primary mt-4" onClick={handleUpdateTaxNumber}>
            {t("save")}
          </Button>
        ) : (
          <Button className="secondary mt-4" onClick={() => setIsEditing(true)}>
            {t("edit")}
          </Button>
        )}
      </Form>
    </div>
  );
}

export default ErrorHandler(SellerTax, axiosInstance);
