import { ADD_ACCOUNT } from './actionTypes'

export const addAccount = (info) => {
    return {
        type: ADD_ACCOUNT,
        payload: info
    }

}

