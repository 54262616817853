import { CODE } from './actionTypes'

export const code = (info) => {
    return {
        type: CODE,
        payload: info
    }

}

