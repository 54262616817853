import DetailsTab from "./DetailsTab";
import { useTranslation } from "react-i18next";

export default function ClientDetails({ data }) {
    const { t } = useTranslation();
    console.log({ data });
    return (
        <DetailsTab
            items={[
                {
                    label: t("name"),
                    value: data.client?.name,
                },

                {
                    label: t("phone"),
                    value: data.client?.phone,
                },
                {
                    label: t("altPhone"),
                    value: data.client?.phone_alt,
                    hidden: !data.seller?.user?.phone_alt,
                },
                {
                    label: t("address"),
                    value: data.client_address?.address,
                },
            ]}
        />
    );
}
