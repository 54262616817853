import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Row, Col } from "react-bootstrap";
import "../../../style-sheets/form.css";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import axiosInstance from "../../../api/axios";
import { useSelector } from "react-redux";
import UserTable from "./UserTable";
import {
  useOrgEmployeesColumns,   
} from "../tableColumns";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}



const NewMarketers = () => {
    const [value, setValue] = useState(0);
    const { t } = useTranslation();

    const [allFilters, setAllFilters] = useState({
        branch: {
            title: t("branch"),
            selectedValue: undefined,
            values: [],
        },
      
    });

    
    const getBranches = async () => {
        const result = await axiosInstance.get("/Branch/?page_size=100000");
        if (result) return result.data.data;
        else return [];
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const user = useSelector((state) => state.auth.user);

    useEffect(async () => {
        const branches = await getBranches();
        setAllFilters({
            ...allFilters,
            branch: { ...allFilters.branch, values: branches },
        });
    }, []);

    function onFilterChange(key, value) {
        console.log({ key, value });
        setAllFilters({
            ...allFilters,
            [key]: { ...allFilters[key], selectedValue: value },
        });
    }

    return (
        <div>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.accounts")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("sideMenu.newMarketer")}</span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("sideMenu.newMarketer")}</h2>
                </Col>
            </Row>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        className={`${
                            user.role === "Seller" ? "seller" : "admin"
                        }`}
                    >
                      
                       
                    </Tabs>
                </Box>
                
              
                <TabPanel value={value} index={0}>
                    <UserTable
                        columns={useOrgEmployeesColumns()}
                        userType="manager"
                        searchText={t("managerSearch")}
                        fetchEndpoint={"/FilterOrgEmployee/?type=3&approval_status=1"}
                        deleteEndpoint={"/OrgEmployee/"}
                        filters={{
                            branch: allFilters.branch,
                            
                        }}
                        onFilterChange={onFilterChange}
                    />
                </TabPanel>
                
                
            </Box>
        </div>
    );
};

export default ErrorHandler(NewMarketers, axiosInstance);
