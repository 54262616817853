import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { Row, Col, Form, Input, Button, Switch ,notification , DatePicker} from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useLanguage from "../../../hooks/useLanguage";
import moment from 'moment';
const { Item } = Form;

function ExtraOrderDetails(props) {
  const { t } = useTranslation();
  const lang = useLanguage();
  const { id } = useParams();
  const user_role = useSelector((state) => state.auth.user.role);
  const [extraOrder, setExtraOrder] = useState(null);
  const [loading, setLoading] = useState(false);


  const getExtraOrders = async () => {
    setLoading(true);
    try {
      const result = await axiosInstance.get(`/extra-orders/${id}/`);
      if (result && result.data) {
        setExtraOrder(result.data);
      }
    } catch (err) {
      console.error("Error fetching extra orders:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
  
    getExtraOrders();
  }, [id]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      values.user_added_date = moment(values.user_added_date).format("YYYY-MM-DD");
      const result = await axiosInstance.put(`/extra-orders/${id}/`, values);
      if (result && result.data) {
        setExtraOrder(result.data);
        notification.success({
          message: "Success",
          description: "تم تعديل الطلب بنجاح",
        });
        getExtraOrders();
      }
    } catch (err) {
      console.error("Error updating extra order:", err);
      notification.error({
        message: "Error",
        description: "فشل تعديل الطلب",
      });
    }finally {
        setLoading(false);
      }
  };

  return (
    <>
      <Row className="mb-4">
        <Col span={24}>
          <h2 className="pageTitle">{t("extra_orders")}</h2>
        </Col>
      </Row>
      {loading && <p>Loading...</p>}
      {extraOrder && (
        <Form
          layout="horizontal"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{
            ...extraOrder,
            user_added_date: moment(extraOrder.user_added_date),
          }}
          onFinish={onFinish}
          onFinishFailed={(errorInfo) => console.error(errorInfo)}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Item label={t("storename")} name="seller_store_name">
                <Input disabled />
              </Item>
              <Item label={t("shipmentProvider")} name="shipment_provider">
                <Input disabled />
              </Item>
              <Item label={t("trackNumber")} name="track_number">
                <Input disabled />
              </Item>
            </Col>
            <Col span={12} style={{ borderLeft: "1px solid #e8e8e8", paddingLeft: "24px" }}>
            <Item label={t("createDate")} name="user_added_date">
            <DatePicker picker="month" defaultValue={moment(extraOrder.user_added_date).startOf('month')} format="YYYY-MM" />
              </Item>
              <Item label={t("weight")} name="weight">
                <Input />
              </Item>
              <Item label={t("extraWeight")} name="extra_weight">
                <Input />
              </Item>
              <Item label={t("extraWeightCharge")} name="extra_weight_charge">
                <Input />
              </Item>
              {/* <Item label="Paid" name="paid" valuePropName="checked">
                <Switch />
              </Item>
              <Item label="Edited" name="edited" valuePropName="checked">
                <Switch />
              </Item> */}
              <Item wrapperCol={{ span: 24 }} style={{ textAlign: 'left', marginTop: '20px' }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{ backgroundColor: '#eb2f96', borderColor: '#eb2f96' }}
            >
                {t("edit")}
            </Button>
              </Item>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
}

export default ErrorHandler(ExtraOrderDetails, axiosInstance);
