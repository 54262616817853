import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../../../../style-sheets/form.css";
import { Select } from "antd";
import {
    PaginatedSelect,
    Radio,
    TextField,
   
} from "../../../../../components/form/TextInputFields";
import { Checkbox , Input} from "antd";

import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../../../../../api/axios";
import ModifiedForm from "../../../../../components/form/ModifiedForm";
import { addAccount } from "../../../../../global-state/actions/addAccountAction";
import {
    AccountConfirmationModal,
    CreationConfirmationModal,
} from "../../../../../components/modals/confirmationModal";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { SHOW_ACCOUNT_CREATION_ERROR } from "../../../../../global-state/actions/actionTypes";
import PhoneInput, { prefix } from "../../../../../components/phone-input";

export default function ThirdpartyInfo({ value, handleChange, onBack }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const account = useSelector((state) => state.account);
    const errors = useSelector((state) => state.account.errors) ?? "";

    const { t, i18n } = useTranslation();

    const [show, setShow] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [ID, setID] = useState("");
    const [password, setPassword] = useState("");
    const [isActive, setActive] = useState(false);
    const [logoFile, setLogoFile] = useState(null); // Step 1: Declare Logo File State

    const showModal = () => {
        setShow(true);
    };

    const showConfirmModal = () => {
        setShowConfirm(true);
    };

    const hide = () => {
        setShow(false);
    };

    const hideConfirm = () => {
        setShowConfirm(false);
        history.push("/all_accounts");
    };
    const handleActiveChange = (event) => {
        const isChecked = event.target.checked;
        setActive(isChecked);
    };
    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        setLogoFile(file);
    };
    
    
    const validate = (data) => {
        // Prepare form data
        const formData = {
            ...data,
            // active: isActive,
            // logo: logoFile,
        };
    
        // Dispatch the action to add the account with the modified form data
        dispatch(addAccount(formData));
    
        // Proceed with the form submission flow based on the value
        if (value === 1) {
            handleChange(undefined, value + 1);
            dispatch({ type: SHOW_ACCOUNT_CREATION_ERROR, payload: "" });
        } else {
            showModal();
        }
    };
    
    

    const getErrors = (e) => {
        dispatch({
            type: SHOW_ACCOUNT_CREATION_ERROR,
            payload: e,
        });
    };

    const formattedAccount = {
        ...account,
        phone: account.phone?.split(prefix)[1] ?? account.phone,
        phone_alt: account.phone_alt?.split(prefix)[1] ?? account.phone_alt,
        // active: isActive,
        // logo: logoFile, // Assuming logoFile contains the uploaded logo
    };
    

    return (
        <>
            <ModifiedForm
                submitFunction={validate}
                defaultValues={formattedAccount ?? undefined}
            >
                <fieldset disabled={value === 2}>
                    <Row>
                        <Col sm="12" md="6">
                            <div className="text-area-form">
                                <TextField
                                    id="name"
                                    className="formTextField text-area"
                                    title={t("Name")}
                                    disabled={false}
                                    name="name"
                                    errors={
                                        errors !== ""
                                            ? errors.name ?? [0]
                                            : null
                                    }
                                    validation={{ required: true }}
                                    shouldRegister
                                />
                                <TextField
                                    id="name_ar"
                                    className="formTextField text-area"
                                    title={t("Name (Arabic)")}
                                    disabled={false}
                                    name="name_ar"
                                    shouldRegister
                                />
                                <TextField
                                    id="email"
                                    className="formTextField text-area"
                                    title={t("Email")}
                                    shouldRegister
                                    type="email"
                                    disabled={false}
                                    backendErrors={
                                        errors !== ""
                                            ? errors.email?.length > 0
                                                ? errors?.email[0]
                                                : null
                                            : null
                                    }
                                    name="email"
                                    validation={{ required: true }}
                                />
                                <PhoneInput
                                    shouldRegister
                                    name="phone"
                                    errors={errors}
                                />
                                <PhoneInput
                                    id="phone_alt"
                                    title={t("Alternative Phone")}
                                    shouldRegister
                                    name="phone_alt"
                                    errors={errors}
                                />
                            </div>
                        </Col>
                        <Col sm="12" md="6">
                            <div className="text-area-form">
                                {/* <Select
                                    id="area"
                                    className="formSelect text-area"
                                    title={t("Area")}
                                    shouldRegister
                                    name="area"
                                /> */}
                                <TextField
                                    id="address"
                                    className="formTextField text-area"
                                    title={t("Address")}
                                    shouldRegister
                                    name="address"
                                />
                            {/* <Input
                                type="file"
                                id="logo"
                                name="logo"
                                onChange={handleLogoChange}
                            /> */}

                            {/* <Checkbox
                                id="active"
                                className="formCheckbox text-area"
                                title={t("Active")}
                                shouldRegister
                                name="active"
                                onChange={handleActiveChange}
                            /> */}

                                <TextField
                                    id="website"
                                    className="formTextField text-area"
                                    title={t("Website")}
                                    shouldRegister
                                    type="url"
                                    name="website"
                                />
                                <TextField
                                    id="facebook"
                                    className="formTextField text-area"
                                    title={t("Facebook")}
                                    shouldRegister
                                    type="url"
                                    name="facebook"
                                />
                                <TextField
                                    id="twitter"
                                    className="formTextField text-area"
                                    title={t("Twitter")}
                                    shouldRegister
                                    type="url"
                                    name="twitter"
                                />
                                <TextField
                                    id="instagram"
                                    className="formTextField text-area"
                                    title={t("Instagram")}
                                    shouldRegister
                                    type="url"
                                    name="instagram"
                                />
                            </div>
                        </Col>
                    </Row>
                </fieldset>

                <Row>
                    <Col className="text-end buttons-margin" sm="12">
                        <button
                            className="cancel"
                            onClick={onBack}
                            type="button"
                        >
                            {t("Discard")}
                        </button>
                        <input
                            className="confirm"
                            value={t("Proceed")}
                            type="submit"
                        />
                    </Col>
                </Row>
            </ModifiedForm>
            <CreationConfirmationModal
                getErrors={getErrors}
                setPassword={setPassword}
                setID={setID}
                title={t("Account Creation")}
                show={show}
                showConfirm={showConfirmModal}
                hide={hide}
                account={account}
                onBack={onBack}
            />
            <AccountConfirmationModal
                show={showConfirm}
                hide={hideConfirm}
                id={ID}
                password={password}
                account={account}
                onBack={onBack}
            />
        </>
    );
}
