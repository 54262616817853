import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Row, Col } from "react-bootstrap";
import { ListItemButton } from "@mui/material";
import ModifiedForm from "../../../../components/form/ModifiedForm";
import { useFormValidation } from "../../../../components/contexts/FormContext";
import "../../../../style-sheets/form.css";
import { Select } from "antd";
import {
    PaginatedSelect,
    Radio,
    TextField,
} from "../../../../components/form/TextInputFields";
import ErrorHandler from "../../../../error-handler/ErrorHandler";
import axiosInstance from "../../../../api/axios";
import { useSelector } from "react-redux";
import { ConfirmationModal } from "../../../../components/modals/confirmationModal";
import SellerInfoForm from "../../../../components/forms/sellerInfoForm";
import AccountTypeForm from "./accoutTypeForm";
import AccountPersonalInfoForm from "./personal-info/accountPersonalInfoForm";
import CustomerInfoForm from "../../../../components/forms/customerInfoForm";
import { Form } from "react-bootstrap";
import ProductInfoForm from "../../../../components/forms/productInfoForm";
import OrderInfoForm from "../../../../components/forms/orderInfoForm";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const CreateAccount = () => {
    const { t } = useTranslation();
    const user = useSelector((state) => state.auth.user);
    const [accountData, setAccountData] = useState({});

    const [tab, setTab] = useState(0);

    const accountTypes = {
        seller: {
            index: -1,
            title: t("seller"),
        },
        operation: {
            index: 2,
            title: t("operation"),
        },
        MARKETER: {
            index: 3,
            title: t("marketer"),
        },
        // accountant: {
        //     index: 4,
        //     title: t("accountant"),
        // },
        // inventoryManager: {
        //     index: 5,
        //     title: t("inventoryManager"),
        // },
        teamMember: {
            index: 6,
            title: t("operationTeamMember"),
        },
        ThirdParty: {
            index: 9,
            title: t("thirdparty"),
        },
    };

    const handleChange = (event, newTab) => {
        setTab(newTab ?? 0);
    };

    return (
        <div>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.accounts")}</span>
                    <i className="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("sideMenu.createNewAcc")}</span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("sideMenu.createNewAcc")}</h2>
                </Col>
            </Row>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={tab}
                        aria-label="basic tabs example"
                        className={`${
                            user.role_id === "Seller" ? "seller" : "admin"
                        }`}
                    >
                        <Tab label={t("accType")} {...a11yProps(0)} />
                        <Tab label={t("personalInfo")} {...a11yProps(1)} />
                        <Tab label={t("sendInvitation")} {...a11yProps(2)} />

                        {/* <Tab label="Send Request" {...a11yProps(2)} /> */}
                    </Tabs>
                </Box>

                <TabPanel value={tab} index={0}>
                    <AccountTypeForm
                        accountTypes={accountTypes}
                        value={tab}
                        account={accountData}
                        handleChange={handleChange}
                    />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <AccountPersonalInfoForm
                        accountTypes={accountTypes}
                        value={tab}
                        account={accountData}
                        handleChange={handleChange}
                    />
                </TabPanel>
                <TabPanel value={tab} index={2}>
                    <AccountPersonalInfoForm
                        accountTypes={accountTypes}
                        value={tab}
                        account={accountData}
                        handleChange={handleChange}
                    />
                </TabPanel>
                {/* <TabPanel value={value} index={2}>
          <ProductInfoForm value={value} handleChange={handleChange} />
        </TabPanel> */}
            </Box>
        </div>
    );
};

export default ErrorHandler(CreateAccount, axiosInstance);
