import React, { useState } from "react";
import CreateNewProduct from "../../../../components/modals/CreateNewProduct";
import { SuccessfulRequest } from "../../../../components/modals/confirmationModal";
import { useTranslation } from "react-i18next";
import useLanguage from "../../../../hooks/useLanguage";
import { useHistory } from "react-router-dom";

export default function CreateProduct() {
    const { t } = useTranslation();
    const language = useLanguage();
    const history = useHistory();
    const [created, setCreated] = useState(false);
    return (
        <>
            <CreateNewProduct useModal={false} setCreated={setCreated} />
            <SuccessfulRequest
                show={created}
                hide={() => {
                    setCreated(false);
                    setTimeout(() => history.push("/products"), 100);
                }}
                title={language.includes("en") ? "Add Product" : "اضافة منتج"}
                body={
                    language.includes("en")
                        ? "Product Created Successfully"
                        : "تم اضافة المنتج بنجاح"
                }
            />
        </>
    );
}
