import { SEARCH } from "../actions/actionTypes";
const initialState = {
  search : ''
};

export default function (state = initialState, action) {
  //  (action.payload);
  switch (action.type) {
    case SEARCH:
      return {
        ...state,
        search : action.payload
      };

    
    default:
      return state;
  }
}