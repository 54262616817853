import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../../../../style-sheets/form.css";
import { Select } from "antd";
import {
    PaginatedSelect,
    Radio,
    TextField,
} from "../../../../../components/form/TextInputFields";
import { notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../../../../../api/axios";
import ModifiedForm from "../../../../../components/form/ModifiedForm";
import { addOrder } from "../../../../../global-state/actions/addOrderAction";
import { getTicketPrintedData } from "../../../../../global-state/actions/getSelectedAction";
import {
    OrderConfirmationModal,
    OrderDeleteModal,
    UnSuccessfulRequest,
} from "../../../../../components/modals/confirmationModal";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    getSeller,
    getClient,
} from "../../../../../global-state/actions/getSelectedAction";
import { DataGrid } from "@mui/x-data-grid";
import { BASE_URL } from "../../../../../api/axios";
import useLanguage from "../../../../../hooks/useLanguage";
import OrderSummary from "../../order-summary/ui/OrderSummary";
import CreateOrderLoading from "../../../../../components/modals/CreateOrderLoading";

import "./ticket-create.css";
import Search from "antd/lib/input/Search";
import { useLanguageContext } from "../../../../../components/contexts/LanguageContext";

export default function OrderInfoForm({ value, handleChange }) {
    const { t } = useTranslation();
    const cur = useSelector(
        (state) => state.auth.user.user.organization.country.currency
    );
    const dispatch = useDispatch();
    const dispatchSeller = useDispatch();
    const dispatchClient = useDispatch();
    const clearDispatch = useDispatch();
    const dispatchPrinted = useDispatch();
    const [orderDetails, setOrderDetails] = useState("");
    const [deliveryFees, setDeliveryFees] = useState(0);
    const [calDeliveryFees, setCalDeliveryFees] = useState(deliveryFees);
    const [nextBranches, setNextBranches] = useState([]);
    const [branches, setBranches] = useState([]);
    const [loadMore, setLoadMore] = useState(true);
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [nextRanges, setNextRanges] = useState([]);
    const [ranges, setRanges] = useState([]);
    const [sellerInfo, setSellerInfo] = useState("");
    const [sellerInfo2, setSellerInfo2] = useState([]);
    const [loading, setLoading] = useState(false);
    const [send, setSend] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [shipmentProviders, setShipmentProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState();
    const [error, setError] = useState(undefined);
    const [realError, seRealerror] = useState(undefined);
    const [search, setSearch] = useState("");
    const { client_address, seller_address } = useSelector(
        (state) => state.order
    );

    let history = useHistory();

    const user = useSelector((state) => state.auth.user);
    const showConfirmationModal = () => {
        setShow(true);
        clearDispatch(addOrder(""));
    };
    const showDeleteModal = () => {
        setShowDelete(true);
    };

    const { refreshCredit } = useLanguageContext();
    const hide = () => {
        setShow(false);
        refreshCredit();
        history.push("/dashboard");
    };

    const hideDelete = () => {
        setShowDelete(false);
        setTimeout(() => {
            handleChange(null, 0);
        }, 1000);
    };
    const order = useSelector((state) => state.order);
    console.log({ order });
    const sellerID = useSelector(
        (state) => state.selectedData.selectedSeller.id
    );

    const validate = async () => {
        const data = {};
        data.delivery_fees = deliveryFees;
        data.shipment_provider = selectedProvider?.id;
        dispatch(addOrder(data));
        setSend(true);
        console.log(order);
    };
    const discard = () => {
        if (orderDetails !== "") {
            showDeleteModal();
            let result = axiosInstance.delete(`/Order/${orderDetails.id}`);
            
            clearDispatch(addOrder(""));
            dispatchSeller(getSeller({}));
            dispatchClient(getClient({}));
        } else {
            if (!selectedProvider || !selectedProvider.id) {
                // Show a notification or take action to notify the user that a provider needs to be selected
                console.error('Error: No selected provider');
                notification.error({
                    message: t('archiveError'),
                    description: t('archiveErrorDescribtion'),
                });
                return; // Prevent further execution if the provider is not selected
            }
            archive_order();
            dispatchSeller(getSeller({}));
            dispatchClient(getClient({}));
            clearDispatch(addOrder(""));
            handleChange(null, 0);
        }
    };

    const archive_order = async  () => {
        if (!selectedProvider || !selectedProvider.id) {
            // Show a notification or take action to notify the user that a provider needs to be selected
            console.error('Error: No selected provider');
            notification.error({
                message: 'Error Archiving Order',
                description: `No Shipment Provider Selected `,
            });
            return; // Prevent further execution if the provider is not selected
        }

        try {
            const sellerData = await getSellerInfo2(order.seller);
    
            const orderData = {
                ...order,
                delivery_fees: deliveryFees,
                shipment_provider: selectedProvider.id,
                sellerData: sellerData, // Use the fetched sellerData directly
            };
    
            // Proceed with posting the orderData
            const response = await axiosInstance.post(`/archived/`, { archived_data: orderData });
            console.log('Order Archived:', response.data);
            // Handle success, maybe update UI or show a message
        } catch (error) {
            console.error('Error archiving order:', error);
            // Handle error, show an error message or take appropriate action
        }
    };
    const getSellerInfo2 = async (id) => {
        try {
            const result = await axiosInstance.get(`/Seller/${id}`);
            if (result) {
                console.log(result.data);
                return result.data; // Return the fetched data directly
            }
        } catch (error) {
            console.error('Error fetching seller info:', error);
            throw error; // Handle the error or propagate it up
        }
    };

    useEffect(() => {
        console.log({ send });
        if (send) {
            console.log(order);

            try {
                sendRequest();
            } catch (err) {
                console.log(err);
            }
        }
    }, [send]);

    const updateRequest = async () => {
        setLoading(true);

        let result = await axiosInstance
            .put(`/Order/${orderDetails.id}`, {
                delivery_fees: calDeliveryFees,
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });

        if (result) {
            dispatchPrinted(getTicketPrintedData(result.data));
            setLoading(false);
            setShow(true);
            clearDispatch(addOrder(""));
        }
    };

    const sendRequest = async () => {
        setLoading(true);
        console.log(order);
        let result = await axiosInstance
            .post(`/Order/`, {
                ...order,
                payment_method: "1",
                type: "1",
                including_fees: true,
            })
            .catch((err) => {
                let error;
                if (typeof err.response?.data === "object") {
                    if (err.response?.data.error) {
                        error = err.response?.data.error;
                    } else {
                        const firstElement = Object.entries(err.response?.data)[0];
                        error = `${firstElement[1]}`;
                    }
                } else {
                    error = err.response?.data;
                }
            
                const provider_error = t('provider_err');
                // Check if the error message includes 'shipment_provider'
                if (error.includes('shipment_provider') || 
                    error.includes('Smsa') || 
                    error.includes('Aramex') || 
                    error.includes('Sa3y') || 
                    error.includes('Aymakan')) {
                    setError(provider_error);
                    seRealerror(error);
                } else {
                    setError(error);
                }
                setLoading(false);
                setSend(false);
            });
            
        if (result) {
            setOrderDetails(result.data);
            console.log(result.data.tickets);
            console.log(result.data);
            setTickets(result.data.tickets);
            setDeliveryFees(parseFloat(result.data.delivery_fees));
            setCalDeliveryFees(parseFloat(result.data.delivery_fees));
            // setShowCalculations(true);
            dispatchSeller(getSeller({}));
            dispatchClient(getClient({}));
            dispatchPrinted(getTicketPrintedData(result.data));
            setLoading(false);
            showConfirmationModal();
        }
    };

    const getBranches = async () => {
        let result = await axiosInstance.get(`/Branch/`);
        if (result) {
            setBranches(result.data.data);
            setNextBranches(result.data.next);
        }
    };

    const fetchShipmentProviders = async () => {
        console.log({
            client_address,
            addd: user.user.user_id,
        });
        let updatedSellerId = sellerID; // Assign the default value

    if (user.role === "Seller") {
        updatedSellerId = user.user.user_id; // Update sellerId if user's role is "Seller"
    }
    console.log(sellerID ,user.user.user_id , updatedSellerId );
        let result = await axiosInstance
            .get(`/client_shipment_service_provider/`, {
                params: {
                    client_address,
                    search,
                    seller_address:
                        seller_address ??
                        (user.role === "Seller"
                            ? user.user.user_id
                            : undefined),
                    seller_id :updatedSellerId,
                },
            })

            
            .catch((err) => console.log(err));
        if (result) {
            setShipmentProviders(result.data.data);
        }
    };

    const previous = () => {
        handleChange(null, parseInt(value) - 1);
        dispatch(addOrder({ ...order }));
    };

    const getSellerInfo = async () => {
        if (user.role === "Seller") {
            let result = await axiosInstance.get(
                `/Seller/${user.user.user_id}`
            );
            if (result) {
                console.log(result.data);
                setSellerInfo(result.data);
            }
        }
    };

    
    useEffect(() => {
        // getSellerInfo();
        // getBranches();
        fetchShipmentProviders();
    }, [search]);

    useEffect(() => {
        console.log(
            deliveryFees,
            calDeliveryFees,
            deliveryFees === calDeliveryFees
        );
    }, [deliveryFees, calDeliveryFees]);
    const lang = useLanguage();

    return (
        <>
            <div
            // submitFunction={validate}
            // listenToValues={(getValues) => {
            //     const v = getValues("payment_method");
            //     console.log({ v });
            // }}
            >
                <Row>
                    <Col sm="12">
                        {orderDetails !== "" ? (
                            <p className="orderID">
                                {t("orderID")}#{orderDetails.id}
                            </p>
                        ) : null}
                    </Col>
                </Row>
                <Row>
                    <Row>
                        <Col className="mt-4" sm="12" md="12">
                            <Col sm={12} md={6}>
                                <Search onSearch={setSearch} />
                            </Col>
                            <label
                                style={{
                                    marginTop: "24px",
                                }}
                            >
                                {t("shipmentProvider")}
                            </label>
                            <div className="mt-2 position-relative providers-list">
                                {shipmentProviders.map((provider) => (
                                    <div
                                        className={`provider-card ${
                                            selectedProvider?.id === provider.id
                                                ? "provider-selected"
                                                : ""
                                        } ${
                                            !provider.available
                                                ? "provider-disabled"
                                                : ""
                                        }`}
                                        onClick={() =>
                                            provider.available &&
                                            setSelectedProvider(provider)
                                        }
                                    >
                                        <img
                                            src={
                                                provider.shipment_provider
                                                    .image ??
                                                "https://www.bonbonentertainment.nl/wp-content/uploads/2021/06/no-image.jpg"
                                            }
                                        />
                                        <span>
                                            {provider.shipment_provider.name}
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "12px",
                                                fontWeight: "400",
                                            }}
                                        >
                                            {!provider.available
                                                ? ` (${t("notAvailable")})`
                                                : null}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Row>
                <div>
                    {orderDetails.delivery_fees && (
                        <ul className="calculationRow">
                            <li>
                                <span className="tabsTitles">
                                    <span className="">
                                        {t("sideMenu.delivery_fees")}
                                    </span>
                                </span>
                                <span className="tabsInfoPrice">
                                    {t(cur)}
                                    <span className="tabsCost">
                                        {orderDetails.delivery_fees
                                            ? parseInt(
                                                  orderDetails.delivery_fees
                                              )
                                            : "00.00"}
                                    </span>
                                </span>
                            </li>
                        </ul>
                    )}
                </div>
                <Row
                    style={{
                        marginInlineEnd: "-32px",
                    }}
                >
                    <Col className="text-end buttons-margin">
                        <button className="cancel" onClick={discard}>
                            {t("archive")}
                        </button>
                        {/* orderdetails != "" and deliveryFees===calDeliveryFees ? (scan):(deliveryFees === calDeliveryFees ?(calculate) : (update) ) */}
                        <>
                            <button className="previous" onClick={previous}>
                                {t("previous")}
                            </button>

                            <input
                                disabled={send}
                                onClick={validate}
                                className="calculateBtn mx-2"
                                value={t("submit")}
                                type="submit"
                            />
                        </>
                    </Col>
                </Row>
            </div>
            <OrderConfirmationModal
                show={show}
                hide={hide}
                id={orderDetails.id}
            />
            <OrderDeleteModal
                show={showDelete}
                hide={hideDelete}
                id={orderDetails.id}
            />
            <UnSuccessfulRequest
                show={error}
                title={t("ticket")}
                body={error}
                realError={realError}
                hide={() => setError(undefined)}
            />
            <CreateOrderLoading show={loading} />
        </>
    );
}
