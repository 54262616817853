import React from "react";
import { Row, Col } from "react-bootstrap";
import { Icon } from "@shopify/polaris";
import {
    InventoryMajor,
    PaymentsMajor,
    DesktopMajor,
    LockMajor,
    SecureMajor,
    LanguageMinor,
    QuestionMarkMajor,
    ThemeEditMajor,
    LocationMajor,
    ButtonCornerSquareMajor,
    StoreMajor,
    TaxIcon,
} from "@shopify/polaris-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useLanguage from "../../../hooks/useLanguage";

export default function Settings() {
    const user = useSelector((state) => state.auth.user);

    const { t, i18n } = useTranslation();
    const lang = useLanguage();
    console.log({ user });
    const cards =
        user.role === "Organization Admin"
            ? [
                  {
                      icon: InventoryMajor,
                      title: `${t("organization")}`,
                      link: "/organization",
                      description: `${t("orgSubTitle")}`,
                  },
                  // {
                  //   icon: PaymentsMajor,
                  //   title: `${t("billing")}`,
                  //   link: "/billing",
                  //   description: `${t("billingSubTitle")}`
                  // },
                  {
                      icon: ThemeEditMajor,
                      title: `${t("systemTheme")}`,
                      link: "/theme",
                      description: `${t("systemThemeSubtitle")}`,
                  },
                  {
                      icon: LockMajor,
                      title: `${t("security")}`,
                      link: "/security",

                      description: `${t("securitySubTitle")}`,
                  },
                  {
                      icon: SecureMajor,
                      title: `${t("permissions")}`,
                      link: "/permissions",

                      description: `${t("permissionsSubTiltle")}`,
                  },
                  {
                      icon: LanguageMinor,
                      title: `${t("lang")}`,
                      link: "/lang_settings",

                      description: `${t("langSubTitle")}`,
                  },
                  {
                      icon: QuestionMarkMajor,
                      title: `${t("FAQs")}`,
                      link: `https://box.sa/${lang}/-5`,

                      description: `${t("FAQsSubTitle")}`,
                  },
                  {
                      icon: StoreMajor,
                      title: `${t("sideMenu.branches")}`,
                      link: "/branches",
                      description: `${t("branchesSubtitle")}`,
                  },
                  {
                      icon: LocationMajor,
                      title: `${t("areas")}`,
                      link: "/areas",
                      description: `${t("areasSubTiltle")}`,
                  },
                  {
                      icon: ButtonCornerSquareMajor,
                      title: `${t("dimensions")}`,
                      link: "/dimensions",
                      description: `${t("dimensionsSubTiltle")}`,
                  },
              ] : user.role === "Marketer" ?
              [
                
                {
                    icon: LockMajor,
                    title: `${t("security")}`,
                    link: "/security",

                    description: `${t("securitySubTitle")}`,
                },
                {
                    icon: LanguageMinor,
                    title: `${t("lang")}`,
                    link: "/lang_settings",

                    description: `${t("langSubTitle")}`,
                },
                {
                    icon: LockMajor,
                    title: `${t("invitationcode")}`,
                    link: "/invitation_code",

                    description: `${t("invitationcode")}`,
                },
                {
                    icon: LockMajor,
                    title: `${t("bankaccount")}`,
                    link: "/bank_acc",

                    description: `${t("bankaccount")}`,
                },
              ]
            : [
                  {
                      icon: LockMajor,
                      title: `${t("security")}`,
                      link: "/security",

                      description: `${t("securitySubTitle")}`,
                  },
                  {
                      icon: LanguageMinor,
                      title: `${t("lang")}`,
                      link: "/lang_settings",

                      description: `${t("langSubTitle")}`,
                  },
                  {
                      icon: LanguageMinor,
                      title: `${t("sellerAddressTitle")}`,
                      link: "/seller_details",
                      description: `${t("sellerAddressSubTitle")}`,
                  },
                  {
                    icon: LanguageMinor,
                    title: `${t("tax_number")}`,
                    link: "/seller_tax",
                    // description: `${t("sellerAddressSubTitle")}`,
                },
                // {
                //     icon: LanguageMinor,
                //     title: `${t("seller_type")}`,
                //     link: "/seller_type",
                //     // description: `${t("sellerAddressSubTitle")}`,
                // },
                  {
                      icon: QuestionMarkMajor,
                      title: `${t("FAQs")}`,
                      link: `https://box.sa/${lang}/-5`,
                      description: `${t("FAQsSubTitle")}`,
                  },
              ];

    return (
        <div className="p-5">
            <Row>
                <Col sm="6">
                    <h6 className="reqTypeTitle">{t("sideMenu.settings")}</h6>
                </Col>
            </Row>
            <Row>
                {cards.map((card) => {
                    return (
                        <Col sm="6">
                            <div className="setting-card">
                                <div className="setting-icon">
                                    <Icon source={card.icon} color="base" />

                                    {/* <i className={card.icon}></i> */}
                                </div>
                                <div>
                                    {card.title === `${t("FAQs")}` ? (
                                        <a
                                            className="setting-card-title"
                                            target="_blank"
                                            href={card.link}
                                            rel="noreferrer"
                                        >
                                            {card.title}
                                        </a>
                                    ) : (
                                        <Link
                                            to={card.link}
                                            className="setting-card-title"
                                        >
                                            {card.title}
                                        </Link>
                                    )}

                                    <p className="setting-card-description">
                                        {card.description}
                                    </p>
                                </div>
                            </div>
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
}
