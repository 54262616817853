import axiosInstance from "../../../../api/axios";
import formattedError from "../../../../api/errorFormatter";

const scanStates = {
    unknown: "unknown",
    scanned: "scanned",
};
const TicketScanRepository = {
    scanStates,
    getCouriers: async (search) => {
        try {
            const res = await axiosInstance.get("/FilterCourier/", {
                params: {
                    search,
                    page_size: 10000,
                    unavailable: true,
                },
            });

            if (res.data) {
                return {
                    success: true,
                    data: res.data.data,
                };
            }
        } catch (e) {
            return formattedError(e);
        }
    },
    getActiveRunSheet: async (courierId) => {
        try {
            const resActiveRunSheet = await axiosInstance.get(
                `ActiveRunsheet/${courierId}`,
                {
                    params: {
                        page_size: 100000,
                    },
                }
            );
            const resRunSheetData = await axiosInstance.get(
                `${resActiveRunSheet.data.runsheet_type === "1" ? "Runsheet" : "InternalRunsheet"}/${resActiveRunSheet.data.runsheet_id}`
            );
            if (resActiveRunSheet.data && resRunSheetData.data) {
                return {
                    success: true,
                    data: {
                        found: true,
                        data: {
                            id: resRunSheetData.data.id,
                            details: {
                                count: resActiveRunSheet.data.count,
                                runsheet_type:
                                    resRunSheetData.data.runsheet_type,
                            },
                            courier: resRunSheetData.data.courier,
                            tickets: resActiveRunSheet.data.data?.map((it) => ({
                                ...it,
                                scan_status: scanStates.unknown,
                            })),
                        },
                    },
                };
            }
        } catch (e) {
            console.log({ e });
            if (e?.response?.data === "Runsheet not found") {
                return {
                    success: true,
                    data: {
                        found: false,
                    },
                };
            }
            return formattedError(e);
        }
    },
    confirmScan: async (id, ticketStates) => {
        try {
            const receivedTickets = Object.entries(ticketStates)
                .filter(([_, value]) => {
                    console.log({
                        value: value.state,
                        scanStates: scanStates.scanned,
                    });
                    return value.state === scanStates.scanned;
                })
                .map(([key]) => key);

            const res = await axiosInstance.post(`/ReceiveFromCourier/${id}`, {
                tickets_returned: receivedTickets,
            });

            if (res.data) {
                return {
                    success: true,
                };
            }
        } catch (e) {
            return formattedError(e);
        }
    },
};

export default TicketScanRepository;
