import useLanguage from "../hooks/useLanguage";
import React from "react";
import { ReactComponent as SeeMoreIcon } from "../images/see_more_arrow.svg";

export default function SeeMoreButton() {
    const lang = useLanguage();
    return (
        <div style={{ whiteSpace: "nowrap" }}>
            {lang.includes("ar") ? (
                <span>
                    عرض <SeeMoreIcon style={{ rotate: "180deg" }} />
                </span>
            ) : (
                <span>
                    See More <SeeMoreIcon />
                </span>
            )}
        </div>
    );
}
