import { Button } from "react-bootstrap";
import { Button as AntdButton ,Tag , Input , notification , Modal , Table ,  Menu, Dropdown , Space } from "antd";
import { SearchOutlined ,CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import ThreeDotsMenuRS from "./ThreeDotsMenuRS";
import { NavLink, useHistory } from "react-router-dom";
import { ReactComponent as SeeMoreIcon } from "../../images/see_more_arrow.svg";
import { useSelector } from "react-redux";
import useLanguage from "../../hooks/useLanguage";
import SeeMoreButton from "../SeeMoreButton";
import { Link } from 'react-router-dom';
import { Image } from "antd";
import { useState , useEffect } from "react";
import { useDispatch } from 'react-redux';
import { addOrder } from "../../global-state/actions/addOrderAction";
import axiosInstance from "../../api/axios";
import moment from "moment"; // Import moment.js library

const SeeMore = ({ arabic }) => <SeeMoreButton />;
export const useOrdersColumns = (onClick) => {
    const { t } = useTranslation();
    return [
        {
            title: t("orderId"),
            dataIndex: "id",
            render: (id) => (
                <span onClick={() => onClick?.(id)} className="tableID">
                    {id}
                </span>
            ),
        },
        {
            title: t("store"),
            dataIndex: ["seller", "store_name"],
            render: (_, row) => (
                <p className="paragraphMargin">
                    {row?.seller?.store_name
                        ? row.seller?.store_name
                        : "No Name"}
                </p>
            ),
        },
        {
            title: t("createDate"),
            dataIndex: "create_date",
            render: (_, row) => (
                <p className="paragraphMargin">
                    {/* {row.create_date?.split("T")[0]} */}
                    {new Date(row.create_date).toLocaleString()}
                </p>
            ),
        },
        {
            title: t("orderValue"),
            dataIndex: "sub_total",
        },
        {
            title: t("status"),
            width: 270,
            dataIndex: ["shipment_order" , "state"],
        },
    ];
};

export const useTicketsColumns = () => {
    const { t } = useTranslation();
    const lang = useLanguage();
    return [
        {
            title: t("orderId"),
            dataIndex: ["order", "id"],
        },
        {
            title: t("ticketId"),
            dataIndex: "id",
        },
        {
            title: t("store"),
            dataIndex: ["order", "seller", "store_name"],
        },
        {
            title: t("orderValue"),
            dataIndex: ["order", "sub_total"],
        },
        {
            title: t("weight"),
            dataIndex: "weight",
        },
        // {
        //     title: t("dimensions"),
        //     render: (_, row) => (
        //         <>
        //             {typeof row.length === "undefined" ||
        //             typeof row.width === "undefined" ||
        //             typeof row.height === "undefined" ? (
        //                 <></>
        //             ) : (
        //                 <>
        //                     {row.length}x{row.width}x{row.height}
        //                 </>
        //             )}
        //         </>
        //     ),
        // },
        {
            title: t("specialLabel"),
            dataIndex: "special_label",
        },
        {
            title: t("shipmentFees"),
            dataIndex: [ "delivery_fees"],
           
        },
        {
            title: t("price"),
            dataIndex: [ "price"],
            
        },
    ];
};

export const useRunSheetColumns = (
    { selectable } = {
        selectable: false,
    }
) => {
    const { t } = useTranslation();
    const cur = useSelector(
        (state) => state.auth.user.user.organization.country.currency
    );
    return [
        { title: t("id"), width: 190, dataIndex: "id" },
        {
            title: t("couriername"),
            width: 240,
            dataIndex: ["courier", "user", "name"],
            render: (_, row) => (
                <>
                    <span
                        style={
                            selectable
                                ? {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                  }
                                : {}
                        }
                    >
                        {row.courier?.user?.name}
                    </span>
                </>
            ),
        },

        {
            title: t("rsValue"),
            width: 240,
            dataIndex: "runsheet_value",
            render: (_, row) => (
                <>
                    {cur} {row.runsheet_value}
                </>
            ),
        },
        {
            title: t("status"),
            dataIndex: "status",
            width: 150,
            render: (_, row) =>
                row.state == "1" ? (
                    <button className="btn accepted">Accepted</button>
                ) : row.state == "2" ? (
                    <p className="btn rejected">Rejected</p>
                ) : row.state == "3" ? (
                    <p className="btn pending">Pending</p>
                ) : (
                    <p className="btn closed">Closed</p>
                ),
        },
        {
            dataIndex: "modify_date",
            title: t("lastUpdated"),
            width: 170,
            render: (_, row) => {
                const date = row.modify_date
                    ? row.modify_date.split("T")[0]
                    : "-";
                return <div>{date}</div>;
            },
        },
    ];
};

export const usePostponedColumns = (
    { selectable } = {
        selectable: false,
    }
) => {
    const { t } = useTranslation();
    const cur = useSelector(
        (state) => state.auth.user.user.organization.country.currency
    );
    return [
        { title: t("id"), width: 190, dataIndex: "id" },
        {
            title: t("branch"),
            width: 240,
            dataIndex: ["branch", "name"],
        },
        {
            title: t("rsValue"),
            width: 240,
            dataIndex: "runsheet_value",
            render: (_, row) => (
                <>
                    {cur} {row.runsheet_value}
                </>
            ),
        },
        {
            title: t("status"),
            dataIndex: "status",
            width: 150,
            render: (_, row) =>
                row.state == "1" ? (
                    <button className="btn accepted">Accepted</button>
                ) : row.state == "2" ? (
                    <p className="btn rejected">Rejected</p>
                ) : row.state == "3" ? (
                    <p className="btn pending">Pending</p>
                ) : (
                    <p className="btn closed">Closed</p>
                ),
        },
        {
            dataIndex: "modify_date",
            title: t("lastUpdated"),
            width: 170,
            render: (_, row) => {
                const date = row.modify_date
                    ? row.modify_date.split("T")[0]
                    : "-";
                return <div>{date}</div>;
            },
        },
    ];
};

export const usePersonColumns = () => {
    const { t } = useTranslation();

    return [
        {
            title: t("id"),
            dataIndex: "id",
            render: (_, row) => <span className="tableID">{row.id}</span>,
        },
        {
            title: t("name"),
            width: 290,
            dataIndex: ["user", "name"],
        },
        {
            title: t("phone"),
            width: 250,
            dataIndex: ["user", "phone"],
        },
    ];
};

export const useOrgEmployeesColumns = () => {
    const { t } = useTranslation();
    return [
        ...usePersonColumns(),
        {
            title: t("branch"),
            width: 190,
            dataIndex: ["branch", "name"],
        },
        {
            title: t("userType"),
            width: 190,
            dataIndex: "type",
        },
        {
            title: t("email"),
            width: 250,
            dataIndex: ["user", "email"],
        },
        {
            title: t("details"),
            dataIndex: ["user","id"],
            render: (_, row) => (
                <NavLink to={`/user_details/manager/${row.id}`}>
                    <SeeMoreButton />
                </NavLink>
            ),
        },
    ];
};

export const useMarketerColumns = () => {
    const { t } = useTranslation();
    return [
        ...usePersonColumns(),
        {
            title: t("branch"),
            width: 190,
            dataIndex: ["branch", "name"],
        },
        {
            title: t("userType"),
            width: 190,
            dataIndex: "type",
        },
        {
            title: t("email"),
            width: 250,
            dataIndex: ["user", "email"],
        },
        // {
        //     title: t("details"),
        //     dataIndex: ["user","id"],
        //     render: (_, row) => (
        //         <NavLink to={`/user_details/manager/${row.id}`}>
        //             <SeeMoreButton />
        //         </NavLink>
        //     ),
        // },
        {
            title: t("orders"),
            dataIndex: ["id"],
            render: (_, row) => (
                <NavLink to={`/marketer_commission/${row.id}`}>
                    <SeeMoreButton />
                </NavLink>
            ),
        },
    ];
};

export const useThirdpartyColumns = () => {
    const { t } = useTranslation();
    return [
        ...usePersonColumns(),
        
        {
            title: t("companyName"),
            width: 190,
            dataIndex: "name",
        },
        {
            title: t("email"),
            width: 250,
            dataIndex: ["user", "email"],
        },
        {
            title: t("address"),
            width: 250,
            dataIndex: ["address"],
        },
    ];
};


export const useSellerColumns = () => {
    const { t } = useTranslation();
    return [
        ...usePersonColumns(),
        {
            title: t("sideMenu.delivery_fees"),
            dataIndex: "delivery_fees",
        },
        {
            title:  t("history"),
            dataIndex: 'id',
            render: (id) => (
              <Link to={`/h-orders/${id}`}>{t("history")}</Link>
              // Assumes '/h-orders/:id' is the new route where you want to send the ID
            ),
          },
    ];
};

export const useArchivedUsers = () => {
    const { t } = useTranslation();
    return [
        ...usePersonColumns(),
        {
            title: t("archivedDate"),
            width: 220,
            dataIndex: "history_date",
            render: (_, row) => (
                <p className="paragraphMargin">
                    {row.history_date?.split("T")[0]}
                </p>
            ),
        },
        {
            title: t("archivedBy"),
            width: 220,
            // valueGetter: (row) => row.row?.id,
            dataIndex: ["changed_by", "type_name"],
            render: (_, row) => (
                <p className="paragraphMargin">{row.changed_by?.type_name}</p>
            ),
        },
    ];
};

/**
 * OLD
 * */

export const pickupRequests = [
    {
        field: "id",
        headerName: "Order ID",
        width: 170,
        renderCell: (row) => <p className="tableID">#{row.row.id}</p>,
    },
    {
        field: "store_name",
        headerName: "Store name",
        width: 200,
        valueGetter: (row) =>
            row.row.seller?.store_name ? row.row.seller.store_name : null,
        renderCell: (row) => (
            <p className="tableID">
                {row.row.seller?.store_name
                    ? row.row.seller.store_name
                    : "No Name"}
            </p>
        ),
    },
    {
        field: "type",
        headerName: "Shipment Type",
        width: 220,
        renderCell: (row) => <p className="paragraphMargin">{row.row.type}</p>,
    },
    {
        field: "ticket_count",
        headerName: "Item Quantity",
        width: 215,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.ticket_count}</p>
        ),
    },
    {
        field: "sub_total",
        headerName: "Order Value",
        width: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.sub_total}</p>
        ),
    },
    {
        field: "payment_method",
        headerName: "Payment method",
        width: 230,
        renderCell: (row) => (
            <strong>
                {/* {console.log(row)} */}
                {row.row.payment_method == "Online" ? (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="onlineBtn"
                    >
                        {row.row.payment_method}
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="cashBtn"
                    >
                        {row.row.payment_method}
                    </Button>
                )}
            </strong>
        ),
    },
    {
        field: "action",
        show: true,
        headerName: "Action",
        width: 230,
        renderCell: ({ row }) => (
            <NavLink to={`/order_chain_details/${row.order?.id ?? row.id}`}>
                <SeeMore />
            </NavLink>
        ),
    },
];

export const pickupRequestsArabic = [
    {
        field: "id",
        headerName: "رقم الشحنة",
        width: 190,
        renderCell: (row) => <p className="tableID">#{row.row.id}</p>,
    },
    {
        field: "store_name",
        headerName: "اسم المتجر",
        width: 200,
        valueGetter: (row) =>
            row.row.seller?.store_name ? row.row.seller.store_name : null,
        renderCell: (row) => (
            <p className="tableID">
                {row.row.seller?.store_name
                    ? row.row.seller.store_name
                    : "No Name"}
            </p>
        ),
    },
    {
        field: "type",
        headerName: "نوع الشحنة",
        width: 220,
        renderCell: (row) => <p className="paragraphMargin">{row.row.type}</p>,
    },
    {
        field: "ticket_count",
        headerName: "عدد القطع",
        width: 215,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.ticket_count}</p>
        ),
    },
    {
        field: "sub_total",
        headerName: "قيمة الطلب",
        width: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.sub_total}</p>
        ),
    },
    {
        field: "payment_method",
        headerName: "طريقة الدفع",
        width: 230,
        renderCell: (row) => (
            <strong>
                {/* {console.log(row)} */}
                {row.row.payment_method == "Online" ? (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="onlineBtn"
                    >
                        {row.row.payment_method}
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="cashBtn"
                    >
                        {row.row.payment_method}
                    </Button>
                )}
            </strong>
        ),
    },
    {
        show: true,
        field: "action",
        headerName: "الإجراءات",
        width: 230,
        renderCell: ({ row }) => (
            <NavLink to={`/order_chain_details/${row.order?.id ?? row.id}`}>
                <SeeMore arabic />
            </NavLink>
        ),
    },
];

export const pickupConfirmedArabic = (cur) => [
    {
        field: "order.id",
        key: "Order ID",
        headerName: "رقم الشحنة",
        width: 200,
        valueGetter: (row) => row.row.order?.id,
        renderCell: (row) => <p className="tableID">{row.row.order?.id}</p>,
    },

    {
        field: "id",
        key: "Ticket ID",
        headerName: "رقم البوليصة",
        width: 200,
        renderCell: (row) => <p className="tableID">{row.row.id}</p>,
    },
    {
        field: "seller.store_name",
        key: "Store Name",
        headerName: "اسم المتجر",
        width: 200,
        valueGetter: (row) =>
            row.row.order?.seller.store_name
                ? row.row.order.seller?.store_name
                : null,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.seller.store_name
                    ? row.row.order.seller?.store_name
                    : "No Name"}
            </p>
        ),
    },
    {
        field: "create_date",
        key: "Date Created",
        headerName: "تاريخ الانشاء",
        width: 170,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.create_date?.split("T")[0]}
            </p>
        ),
    },

    {
        field: "create_date",
        key: "Request Date",
        headerName: "موعد الطلب",
        width: 170,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.create_date?.split("T")[0]}
            </p>
        ),
    },

    {
        field: "order.promise_date",
        key: "Postponed Date",
        headerName: "موعد التاجيل",
        width: 220,
        valueGetter: (row) => row.row?.new_date,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.new_date}</p>
        ),
    },

    {
        field: "order.delivery_range",
        key: "Delivery Range",
        headerName: "نطاق التسليم",
        width: 220,
        valueGetter: (row) => row.row.order?.delivery_range,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.delivery_range?.min}-
                {row.row.order?.delivery_range?.max}
            </p>
        ),
    },

    {
        field: "type",
        key: "Shipment Type",
        headerName: "نوع الشحنة",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.type ? "Pickup" : "Return"}
            </p>
        ),
    },

    {
        field: "quantity",
        key: "Item Quantity",
        headerName: "عدد القطع",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.quantity}</p>
        ),
    },

    {
        field: "length",
        key: "Dimension",
        headerName: "الابعاد",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.length}x{row.row.width}x{row.row.height}
            </p>
        ),
    },

    {
        field: "weight",
        key: "Weight",
        headerName: "الوزن",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.weight}</p>
        ),
    },
    {
        field: "special_label",
        key: "Special Label",
        headerName: "ملصق خاص",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.special_label?.en}</p>
        ),
    },

    {
        field: "comment",
        key: "Feedback Comment",
        headerName: "تعليقات",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.comment?.text_ar}</p>
        ),
    },

    {
        field: "order.sub_total",
        key: "Order Value",
        headerName: "قيمة الطلب",
        width: 220,
        valueGetter: (row) => row.row.order?.sub_total,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.sub_total} {cur}
            </p>
        ),
    },

    {
        field: "order.delivery_fees",
        key: "Shipment Fee",
        headerName: "سعر الشحن",
        width: 220,
        valueGetter: (row) => row.row.order?.sub_total,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.delivery_fees} {cur}
            </p>
        ),
    },

    {
        field: "order.courier.id",
        key: "Courier ID",
        headerName: "رقم المندوب",
        width: 220,
        valueGetter: (row) => row.row.order?.courier?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">#{row.row.order?.courier?.id}</p>
        ),
    },

    {
        field: "order.courier.name",
        key: "Courier name",
        headerName: "اسم المندوب",
        width: 220,
        valueGetter: (row) => row.row.order?.courier?.user.name,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.courier?.user.name}
            </p>
        ),
    },

    {
        field: "order.courier.branch",
        key: "Courier Branch",
        headerName: "فرع المندوب",
        width: 220,
        valueGetter: (row) => row.row.order?.courier?.branch.name,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.courier?.branch.name}
            </p>
        ),
    },

    {
        field: "order.courier.vehicle",
        key: "Vehicle",
        headerName: "المركبة",
        width: 220,
        valueGetter: (row) => row.row.order?.courier?.vehicle,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.order?.courier?.vehicle}</p>
        ),
    },

    {
        field: "order.courier.phone",
        key: "Courier Phone",
        headerName: "رقم هاتف المندوب",
        width: 220,
        valueGetter: (row) => row.row.order?.courier?.user.phone,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.courier.user.phone}
            </p>
        ),
    },

    {
        field: "order.seller.id",
        key: "Seller ID",
        headerName: "رقم التاجر",
        width: 220,
        valueGetter: (row) => row.row.order?.seller?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">#{row.row.order?.seller.id}</p>
        ),
    },

    {
        field: "order.seller.name",
        key: "Store Name",
        headerName: "اسم المتجر",
        width: 220,
        valueGetter: (row) => row.row.order?.seller?.store_name,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.seller.store_name}
            </p>
        ),
    },

    {
        field: "order.seller_address",
        key: "Store Address",
        headerName: "عنوان المتجر",
        width: 220,
        valueGetter: (row) => row.row.order?.seller_address.address,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.seller_address.address}
            </p>
        ),
    },

    {
        field: "order.client.name",
        key: "Customer Name",
        headerName: "اسم العميل",
        width: 220,
        valueGetter: (row) => row.row.order?.client.name,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.order?.client.name}</p>
        ),
    },

    {
        field: "order.client.id",
        key: "Customer ID",
        headerName: "رقم العميل",
        width: 220,
        valueGetter: (row) => row.row.order?.client.id,
        renderCell: (row) => (
            <p className="paragraphMargin">#{row.row.order?.client.id}</p>
        ),
    },

    {
        field: "order.client.phone",
        key: "Customer Phone",
        headerName: "رقم هاتف العميل",
        width: 220,
        valueGetter: (row) => row.row.order?.client.phone,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.order?.client.phone}</p>
        ),
    },

    {
        field: "order.client_address",
        key: "Customer Address",
        headerName: "عنوان العميل",
        width: 220,
        valueGetter: (row) => row.row.order?.client_address.address,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.client_address.address}
            </p>
        ),
    },

    {
        field: "order.assigned_state",
        key: "Status",
        headerName: "الحالة",
        width: 230,
        valueGetter: (row) => row.row.order?.assigned_state,
        renderCell: (row) => (
            <strong>
                {/* {console.log(row)} */}

                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className="onlineBtn"
                >
                    {row.row.order?.assigned_state}
                </Button>
            </strong>
        ),
    },

    {
        field: "order.payment_method",
        key: "Payment Method",
        headerName: "طريقة الدفع",
        width: 230,
        renderCell: (row) => (
            <strong>
                {/* {console.log(row)} */}
                {row.row.payment_method == "1" ? (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="onlineBtn"
                    >
                        Online
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="cashBtn"
                    >
                        Cash
                    </Button>
                )}
            </strong>
        ),
    },
    {
        show: true,
        field: "action",
        headerName: "الإجراءات",
        width: 230,
        renderCell: ({ row }) => (
            <NavLink to={`/order_chain_details/${row.order?.id ?? row.id}}`}>
                <SeeMore arabic />
            </NavLink>
        ),
    },
];
export const pickupConfirmed = (cur) => [
    {
        field: "order.id",
        headerName: "Order ID",
        width: 200,
        valueGetter: (row) => row.row.order?.id,
        renderCell: (row) => <p className="tableID">{row.row.order?.id}</p>,
    },

    {
        field: "id",
        headerName: "Ticket ID",
        width: 200,
        renderCell: (row) => <p className="tableID">{row.row.id}</p>,
    },
    {
        field: "seller.store_name",
        headerName: "Store name",
        width: 200,
        valueGetter: (row) =>
            row.row.order?.seller.store_name
                ? row.row.order.seller?.store_name
                : null,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.seller.store_name
                    ? row.row.order.seller?.store_name
                    : "No Name"}
            </p>
        ),
    },
    {
        field: "create_date",
        headerName: "Date Created",
        width: 190,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.create_date?.split("T")[0]}
            </p>
        ),
    },

    {
        field: "create_date",
        headerName: "Request Date",
        width: 170,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.create_date?.split("T")[0]}
            </p>
        ),
    },

    {
        field: "order.promise_date",
        headerName: "Postponed Date",
        width: 220,
        valueGetter: (row) => row.row?.new_date,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.new_date}</p>
        ),
    },

    {
        field: "order.delivery_range",
        headerName: "Delivery Range",
        width: 220,
        valueGetter: (row) => row.row.order?.delivery_range,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.delivery_range?.min}-
                {row.row.order?.delivery_range?.max}
            </p>
        ),
    },

    {
        field: "type",
        headerName: "Shipment Type",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.type ? "Pickup" : "Return"}
            </p>
        ),
    },

    {
        field: "quantity",
        headerName: "Item Quantity",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.quantity}</p>
        ),
    },

    {
        field: "length",
        headerName: "Dimension",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.length}x{row.row.width}x{row.row.height}
            </p>
        ),
    },

    {
        field: "weight",
        headerName: "Weight",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.weight}</p>
        ),
    },
    {
        field: "special_label",
        headerName: "Special Label",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.special_label?.en}</p>
        ),
    },
    //
    // {
    //     field: "comment",
    //     headerName: "Feedback Comment",
    //     width: 270,
    //     renderCell: (row) => (
    //         <p className="paragraphMargin">{row.row.comment?.text}</p>
    //     ),
    // },

    {
        field: "order.sub_total",
        headerName: "Order Value",
        width: 220,
        valueGetter: (row) => row.row.order?.sub_total,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.sub_total} {cur}
            </p>
        ),
    },

    {
        field: "order.delivery_fees",
        headerName: "Shipment Fee",
        width: 220,
        valueGetter: (row) => row.row.order?.sub_total,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.delivery_fees} {cur}
            </p>
        ),
    },

    {
        field: "order.courier.id",
        headerName: "Courier ID",
        width: 220,
        valueGetter: (row) => row.row.order?.courier?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">#{row.row.order?.courier?.id}</p>
        ),
    },

    {
        field: "order.courier.name",
        headerName: "Courier name",
        width: 220,
        valueGetter: (row) => row.row.order?.courier?.user.name,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.courier?.user.name}
            </p>
        ),
    },

    {
        field: "order.courier.branch",
        headerName: "Courier Branch",
        width: 220,
        valueGetter: (row) => row.row.order?.courier?.branch.name,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.courier?.branch.name}
            </p>
        ),
    },

    {
        field: "order.courier.vehicle",
        headerName: "Vehicles",
        width: 220,
        valueGetter: (row) => row.row.order?.courier?.vehicle,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.order?.courier?.vehicle}</p>
        ),
    },

    {
        field: "order.courier.phone",
        headerName: "Courier Phone ",
        width: 220,
        valueGetter: (row) => row.row.order?.courier?.user.phone,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.courier?.user.phone}
            </p>
        ),
    },

    // {
    //     field: "order.seller.id",
    //     headerName: "Seller ID",
    //     width: 220,
    //     valueGetter: (row) => row.row.order?.seller?.id,
    //     renderCell: (row) => (
    //         <p className="paragraphMargin">#{row.row.order?.seller.id}</p>
    //     ),
    // },

    {
        field: "order.seller.name",
        headerName: "Store Name",
        width: 220,
        valueGetter: (row) => row.row.order?.seller?.store_name,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.seller?.store_name}
            </p>
        ),
    },

    {
        field: "order.seller_address",
        headerName: "Store Address",
        width: 220,
        valueGetter: (row) => row.row.order?.seller_address?.address,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.seller_address?.address}
            </p>
        ),
    },

    {
        field: "order.client.name",
        headerName: "Customer Name",
        width: 220,
        valueGetter: (row) => row.row.order?.client?.name,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.order?.client?.name}</p>
        ),
    },
    //
    // {
    //     field: "order.client.id",
    //     headerName: "Customer ID",
    //     width: 220,
    //     valueGetter: (row) => row.row.order?.client?.id,
    //     renderCell: (row) => (
    //         <p className="paragraphMargin">#{row.row.order?.client?.id}</p>
    //     ),
    // },

    {
        field: "order.client.phone",
        headerName: "Customer Phone",
        width: 220,
        valueGetter: (row) => row.row.order?.client?.phone,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.order?.client?.phone}</p>
        ),
    },

    {
        field: "order.client_address",
        headerName: "Customer Address",
        width: 220,
        valueGetter: (row) => row.row.order?.client_address?.address,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.order?.client_address?.address}
            </p>
        ),
    },

    {
        field: "order.assigned_state",
        headerName: "Status",
        width: 230,
        valueGetter: (row) => row.row.order?.assigned_state,
        renderCell: (row) => (
            <strong>
                {/* {console.log(row)} */}

                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className="onlineBtn"
                >
                    {row.row.order?.assigned_state}
                </Button>
            </strong>
        ),
    },
    // {
    //     field: "order.payment_method",
    //     headerName: "Payment Method",
    //     width: 230,
    //     renderCell: (row) => (
    //         <strong>
    //             {/* {console.log(row)} */}
    //             {row.row.payment_method == "1" ? (
    //                 <Button
    //                     variant="contained"
    //                     color="primary"
    //                     size="small"
    //                     className="onlineBtn"
    //                 >
    //                     Online
    //                 </Button>
    //             ) : (
    //                 <Button
    //                     variant="contained"
    //                     color="primary"
    //                     size="small"
    //                     className="cashBtn"
    //                 >
    //                     Cash
    //                 </Button>
    //             )}
    //         </strong>
    //     ),
    // },
    {
        show: true,
        field: "action",
        headerName: "Action",
        width: 230,
        renderCell: ({ row }) => (
            <NavLink to={`/order_chain_details/${row.order?.id ?? row.id}`}>
                <SeeMore />
            </NavLink>
        ),
    },
];
export const moveOrders = [
    {
        field: "id",
        headerName: "Order ID",
        width: 200,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },

    {
        field: "create_branch",
        headerName: "Created Branch",
        width: 200,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.branch.name}</p>
        ),
    },
    {
        field: "current_branch",
        headerName: "Current Branch",
        width: 200,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.current_branch?.name}</p>
        ),
    },
    {
        field: "create_date",
        headerName: "Date Created",
        width: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.create_date?.split("T")[0]}
            </p>
        ),
    },
    {
        field: "delivery_range",
        headerName: "Delivery Range",
        width: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">{`${row.row?.delivery_range?.min.toString()} - ${row.row?.delivery_range?.max.toString()}`}</p>
        ),
    },
];

export const moveOrdersAr = [
    {
        field: "id",
        headerName: "رقم الطلب",
        width: 200,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },

    {
        field: "create_branch",
        headerName: "الفرع المؤسس",
        width: 200,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.branch.name}</p>
        ),
    },
    {
        field: "current_branch",
        headerName: "الفرع الحالي",
        width: 200,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.current_branch?.name}</p>
        ),
    },
    {
        field: "create_date",
        headerName: "تاريخ الإنشاء",
        width: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.create_date?.split("T")[0]}
            </p>
        ),
    },
    {
        field: "delivery_range",
        headerName: "مدي التوصيل",
        width: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {toString(row.row?.delivery_range?.min) -
                    toString(row.row?.delivery_range?.max)}
            </p>
        ),
    },
];
export const runsheetOrders = [
    {
        field: "id",
        headerName: "Order ID",
        width: 200,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },

    {
        field: "store_name",
        headerName: "Store name",
        width: 200,
        valueGetter: (row) =>
            row.row?.seller?.store_name ? row.row.seller?.store_name : null,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.seller?.store_name
                    ? row.row.seller?.store_name
                    : "No Name"}
            </p>
        ),
    },
    {
        field: "create_date",
        headerName: "Date Created",
        width: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.create_date?.split("T")[0]}
            </p>
        ),
    },
    {
        field: "order_value",
        headerName: "Order Value",
        width: 210,
        valueGetter: (row) => row.row?.promise_date,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.sub_total}</p>
        ),
    },

    {
        field: "status",
        headerName: "Status",
        width: 230,
        renderCell: (row) =>
            row.row.runsheet_type?.map((status) => {
                return (
                    <div className={status + " btn btn-outline-primary mx-2"}>
                        {status}
                    </div>
                );
            }),
    },
];

export const runsheetOrders_ar = [
    {
        field: "id",
        headerName: "رقم الطلب",
        width: 200,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },

    {
        field: "store_name",
        headerName: "اسم المتجر",
        width: 200,
        valueGetter: (row) =>
            row.row?.seller?.store_name ? row.row.seller?.store_name : null,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.seller?.store_name
                    ? row.row.seller?.store_name
                    : "No Name"}
            </p>
        ),
    },
    {
        field: "create_date",
        headerName: "تاريخ الانشاء",
        width: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.create_date?.split("T")[0]}
            </p>
        ),
    },
    {
        field: "order_value",
        headerName: "قيمة الطلب",
        width: 210,
        valueGetter: (row) => row.row?.promise_date,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.sub_total}</p>
        ),
    },

    {
        field: "status",
        headerName: "الحالة",
        width: 230,
        renderCell: (row) =>
            row.row.runsheet_type?.map((status) => {
                return (
                    <div className={status + " btn btn-outline-primary mx-2"}>
                        {status}
                    </div>
                );
            }),
    },
];

export const couriers = [
    {
        field: "id",
        headerName: "Courier ID",
        width: 170,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },

    {
        field: "name",
        headerName: "Courier name",
        width: 190,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.user?.name ? row.row.user?.name : "No Name"}
            </p>
        ),
    },
    {
        field: "branch",
        headerName: "Branch",
        width: 190,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.branch?.name}</p>
        ),
    },
    {
        field: "vehichles",
        headerName: "Vehichles",
        width: 190,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.vehicle}</p>
        ),
    },
    {
        field: "phone",
        headerName: "Phone Number",
        width: 240,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.user?.phone}</p>
        ),
    },
    {
        field: "phone_alt",
        headerName: "Phone Number 2",
        width: 240,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.user?.phone_alt}</p>
        ),
    },
];

export const couriers_ar = [
    {
        field: "id",
        headerName: "رقم المندوب",
        width: 170,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },

    {
        field: "name",
        headerName: "اسم المندوب",
        width: 190,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.user.name ? row.row.user?.name : "No Name"}
            </p>
        ),
    },
    {
        field: "branch",
        headerName: "الفرع",
        width: 190,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.branch?.name}</p>
        ),
    },
    {
        field: "vehichles",
        headerName: "وسيلة المواصلات",
        width: 190,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.vehicle}</p>
        ),
    },
    {
        field: "phone",
        headerName: "رقم الهاتف",
        width: 240,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.user?.phone}</p>
        ),
    },
    {
        field: "phone_alt",
        headerName: "رقم الهاتف",
        width: 240,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.user?.phone_alt}</p>
        ),
    },
];

export const logsColumns = [
    {
        field: "date",
        headerName: "Date/Time",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => {
            const date = row.row.history_date?.split("T")[0];
            const timeWithSeconds = row.row.history_date?.split("T")[1]?.split(".")[0];
            const time = timeWithSeconds?.split(":").slice(0, 2).join(":");
        
            return (
                <p className="">{`${date} / ${time}`}</p>
            );
        },
    },

    // {
    //   field: "type",
    //   headerName: "User Type",
    //   width: 190,
    //   // renderCell: (row) => <p className="paragraphMargin">{row.row.branch.name}</p>,
    // },

    {
        field: "Action",
        headerName: "Action Type",
        width: 240,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.history_type == "~"
                    ? "Changed"
                    : row.row.history_type == "-"
                    ? "Delete"
                    : "Create"}
            </p>
        ),
    },
    {
        field: "users who changed",
        headerName: "users who changed",
        width: 190,
        renderCell: (row) => (
            <>
                <strong className="paragraphMargin">
                    {row.row.changed_by?.name}
                </strong>
            </>
        ),
    },
    {
        field: "users who changed type",
        headerName: "User Who Changed Type",
        width: 190,
        renderCell: (row) => (
            <>
                <strong className="paragraphMargin">
                    {row.row.changed_by?.type_name}
                </strong>
            </>
        ),
    },
    {
        field: "userType",
        headerName: "Users Type",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.user?.type_name}</p>
        ),
    },
    {
        field: "userName",
        headerName: "Users Name",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.user?.name}</p>
        ),
    },
    // This column displays the value of the first change in the "changes" array for each row.
    // If the "changes" array exists and has at least one element, the first change value is rendered.
    // Otherwise, an empty paragraph is rendered.
    {
        field: "changed field",
        headerName: "changed field",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">
      {row.row.changes  && row.row.changes.length > 0 && row.row.changes[0][0]}
    </p>
        ),
    },
    {
        field: "prev value",
        headerName: "prev value",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">
      {row.row.changes && row.row.changes.length > 0 && row.row.changes[0][1]}
    </p>
        ),
    },
    {
        field: "next value",
        headerName: "next value",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">
      {row.row.changes && row.row.changes.length > 0 && row.row.changes[0][2]}
    </p>
        ),
    },
    {
        field: "changed reason ",
        headerName: "Change Reason",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.history_change_reason}</p>
        ),
    },
    {
        field: "actionID",
        headerName: "Action ID",

        width: 240,
        renderCell: (row) => <p className="tableID">#{row.row.history_id}</p>,
    },
];

export const logsColumns_ar = [
    {
        field: "date",
        headerName: "الوقت/التاريخ",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => {
            const date = row.row.history_date?.split("T")[0];
            const timeWithSeconds = row.row.history_date?.split("T")[1]?.split(".")[0];
            const time = timeWithSeconds?.split(":").slice(0, 2).join(":");
        
            return (
                <p className="">{`${date} / ${time}`}</p>
            );
        },
    },
    {
        field: "Action",
        headerName: "نوع الاجراء",
        width: 240,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.history_type == "~"
                    ? "تعديل"
                    : row.row.history_type == "-"
                    ? "مسح"
                    : "إنشاء"}
            </p>
        ),
    },
    
    {
        field: "users who changed",
        headerName: "المستخدم القائم بالتعديل",
        width: 190,
        renderCell: (row) => (
            <>
                <strong className="paragraphMargin">
                    {row.row.changed_by?.name}
                </strong>
            </>
        ),
    },
    {
        field: "users who changed type",
        headerName: "نوع المستخدم القائم بالتعديل",
        width: 190,
        renderCell: (row) => (
            <>
                <strong className="paragraphMargin">
                    {row.row.changed_by?.type_name}
                </strong>
            </>
        ),
    },
    {
        field: "userType",
        headerName: "نوع المستخدم المعدل",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.user?.type_name}</p>
        ),
    },
    {
        field: "userName",
        headerName: "اسم المستخدم المعدل",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.user?.name}</p>
        ),
    },
    {
        field: "changed field",
        headerName: "التعديل",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">
      {row.row.changes  && row.row.changes.length > 0 && row.row.changes[0][0]}
    </p>
        ),
    },
    {
        field: "prev value",
        headerName: "القيمة السابقة",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">
      {row.row.changes && row.row.changes.length > 0 && row.row.changes[0][1]}
    </p>
        ),
    },
    {
        field: "next value",
        headerName: "القيمة الجديدة",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">
      {row.row.changes && row.row.changes.length > 0 && row.row.changes[0][2]}
    </p>
        ),
    },
    {
        field: "changed reason ",
        headerName: "سبب التعديل",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.history_change_reason}</p>
        ),
    },
    {
        field: "actionID",
        headerName: "رقم الاجراء",

        width: 240,
        renderCell: (row) => <p className="tableID">#{row.row.history_id}</p>,
    },
];

export const archivedRunsheets = [
    {
        field: "id",
        headerName: "Runsheet ID",
        width: 190,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">#{row.row?.id}</p>,
    },

    {
        field: "created_by",
        headerName: "Created by",
        width: 200,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.courier?.user?.name
                    ? row.row.courier?.user?.name
                    : "No Name"}
            </p>
        ),
    },
    {
        field: "date_created",
        headerName: "Date Created",
        width: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.create_date?.split("T")[0]}
            </p>
        ),
    },
    {
        field: "courier_id",
        headerName: "Courier ID",
        width: 190,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="tableID paragraphMargin">{row.row?.courier?.id}</p>
        ),
    },
    {
        field: "date_closed",
        headerName: "Date Closed",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.modify_date?.split("T")[0]}
            </p>
        ),
    },
    {
        field: "branch",
        headerName: "Branch",
        width: 180,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.courier?.branch?.name}</p>
        ),
    },
    {
        field: "courier_name",
        headerName: "Courier Name",
        width: 240,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.courier?.user?.name}</p>
        ),
    },
];

export const archivedRunsheets_ar = [
    {
        field: "id",
        headerName: "رقم خط السير",
        width: 190,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">#{row.row?.id}</p>,
    },

    {
        field: "created_by",
        headerName: "انشاء بواسطة",
        width: 200,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.courier.user.name
                    ? row.row.courier?.user?.name
                    : "No Name"}
            </p>
        ),
    },
    {
        field: "date_created",
        headerName: "تاريخ الانشاء",
        width: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.create_date?.split("T")[0]}
            </p>
        ),
    },
    {
        field: "courier_id",
        headerName: "رقم المندوب",
        width: 190,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="tableID paragraphMargin">{row.row?.courier?.id}</p>
        ),
    },
    {
        field: "date_closed",
        headerName: "تاريخ الانتهاء",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row.modify_date?.split("T")[0]}
            </p>
        ),
    },
    {
        field: "branch",
        headerName: "الفرع",
        width: 180,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.courier?.branch?.name}</p>
        ),
    },
    {
        field: "courier_name",
        headerName: "اسم المندوب",
        width: 240,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row.courier?.user?.name}</p>
        ),
    },
];

export const productInfo = [
    {
        field: "id",
        headerName: "Order ID",
        width: 190,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.order?.id}</p>,
    },
    {
        field: "tid",
        headerName: "Ticket ID",
        width: 190,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },
    {
        field: "store",
        headerName: "Store Name",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.order?.seller?.store_name}
            </p>
        ),
    },
    {
        field: "date",
        headerName: "Create Date",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.order?.seller?.create_date.split("T")[0]}
            </p>
        ),
    },
    {
        field: "weight",
        headerName: "Weight",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.weight}</p>
        ),
    },
    {
        field: "Dimension",
        headerName: "Dimension",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.width}x{row.row?.height}{" "}
            </p>
        ),
    },
    {
        field: "price",
        headerName: "Order value",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.price}</p>
        ),
    },
    {
        field: "label",
        headerName: "Label",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.special_label?.en}</p>
        ),
    },
];

export const productInfo_ar = [
    {
        field: "id",
        headerName: "رقم الطلب",
        width: 190,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.order.id}</p>,
    },
    {
        field: "tid",
        headerName: "رقم البوليصة",
        width: 190,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },
    {
        field: "store",
        headerName: "اسم المتجر",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.order.seller.store_name}
            </p>
        ),
    },
    {
        field: "date",
        headerName: "تاريخ الانشاء",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.order.seller.create_date.split("T")[0]}
            </p>
        ),
    },
    {
        field: "weight",
        headerName: "الوزن",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.weight}</p>
        ),
    },
    {
        field: "Dimension",
        headerName: "الابعاد",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.width}x{row.row?.height}{" "}
            </p>
        ),
    },
    {
        field: "price",
        headerName: "قيمة الطلب",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.price}</p>
        ),
    },
    {
        field: "label",
        headerName: "اشارة مميزة",
        width: 220,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.special_label?.en}</p>
        ),
    },
];

export const managerCol = [
    {
        field: "mid",
        title: "Employee ID",
        width: 250,
        // valueGetter: (row) => row.row?.id,
        render: (_, row) => <p className="tableID">{row.id}</p>,
    },
    {
        field: "name",
        title: "Employee name",
        width: 290,
        // valueGetter: (row) => row.id,
        render: (_, row) => <p className="paragraphMargin">{row.user?.name}</p>,
    },
    {
        field: "phone",
        title: "Phone Number",
        width: 250,
        // valueGetter: (row) => row.id,
        render: (_, row) => (
            <p className="paragraphMargin">{row.user?.phone}</p>
        ),
    },
    {
        field: "branch",
        title: "Branch",
        width: 190,
        // valueGetter: (row) => row.id,
        render: (_, row) => (
            <p className="paragraphMargin">{row.branch?.name}</p>
        ),
    },

    {
        field: "E-mail",
        title: "E-mail",
        width: 250,
        // valueGetter: (row) => row.id,
        render: (_, row) => (
            <p className="paragraphMargin">{row.user?.email}</p>
        ),
    },
];

export const managerCol_ar = [
    {
        field: "mid",
        title: "رقم الموظف",
        width: 250,
        // valueGetter: (row) => row.id,
        render: (_, row) => <p className="tableID">{row.id}</p>,
    },
    {
        field: "name",
        title: "الاسم",
        width: 290,
        // valueGetter: (row) => row.id,
        render: (_, row) => <p className="paragraphMargin">{row.user?.name}</p>,
    },
    {
        field: "phone",
        title: "رقم الهاتف",
        width: 250,
        // valueGetter: (row) => row.id,
        render: (_, row) => (
            <p className="paragraphMargin">{row.user?.phone}</p>
        ),
    },
    {
        field: "branch",
        title: "الفرع",
        width: 190,
        // valueGetter: (row) => row.id,
        render: (_, row) => (
            <p className="paragraphMargin">{row.branch?.name}</p>
        ),
    },

    {
        field: "E-mail",
        title: "البريد الالكترونى",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        render: (_, row) => (
            <p className="paragraphMargin">{row.user?.email}</p>
        ),
    },
];

export const sellerCol = [
    {
        field: "sid",
        headerName: "Seller Id",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },
    {
        field: "name",
        headerName: "Seller Name",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.user?.name}</p>
        ),
    },

    {
        field: "phone",
        headerName: "phone number",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.user?.phone}</p>
        ),
    },
    {
        field: "work model",
        headerName: "work model",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.work_model?.name}</p>
        ),
    },
];
export const sellerCol_ar = [
    {
        field: "sid",
        headerName: "رقم التاجر",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },
    {
        field: "name",
        headerName: "الاسم",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.user?.name}</p>
        ),
    },

    {
        field: "phone",
        headerName: "رقم الهاتف",
        width: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.user?.phone}</p>
        ),
    },
    {
        field: "work model",
        headerName: "نموذج العمل",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.work_model?.name}</p>
        ),
    },
];

export const courierCol = [
    {
        field: "cid",
        headerName: "Courier ID",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },
    {
        field: "name",
        headerName: "Courier name",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.user?.name}</p>
        ),
    },
    {
        field: "phone",
        headerName: "Phone Number",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.user?.phone}</p>
        ),
    },
    {
        field: "branch",
        headerName: "Branch",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.branch?.name}</p>
        ),
    },
    {
        field: "Vichels",
        headerName: "Vichels",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.vehicle}</p>
        ),
    },
];
export const courierCol_ar = [
    {
        field: "cid",
        headerName: "رقم المندوب",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.id}</p>,
    },
    {
        field: "name",
        headerName: "الاسم",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.user?.name}</p>
        ),
    },
    {
        field: "phone",
        headerName: "رقم الهاتف",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.user?.phone}</p>
        ),
    },
    {
        field: "branch",
        headerName: "الفرع",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.branch?.name}</p>
        ),
    },
    {
        field: "Vichels",
        headerName: "وسيلة التنقل",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.vehicle}</p>
        ),
    },
];
export const archivedUsers = [
    {
        field: "cid",
        headerName: "user ID",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">#{row.row?.id}</p>,
    },
    {
        field: "name",
        headerName: "user name",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.history_user?.name}</p>
        ),
    },
    {
        field: "usertype",
        headerName: "type",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.history_user?.type_name}
            </p>
        ),
    },
    {
        field: "userbranch",
        headerName: "Branch",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.branch?.name}</p>
        ),
    },
    {
        field: "Archived Date",
        headerName: "Archived Date",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.history_date.split("T")[0]}
            </p>
        ),
    },
    {
        field: "Archived By",
        headerName: "Archived By",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.changed_by?.type_name}</p>
        ),
    },
];
export const archivedUsers_ar = [
    {
        field: "cid",
        headerName: "رقم المستخدم",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">#{row.row?.id}</p>,
    },
    {
        field: "name",
        headerName: "الاسم",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.history_user?.name}</p>
        ),
    },
    {
        field: "usertype",
        headerName: "النوع",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.history_user?.type_name}
            </p>
        ),
    },
    {
        field: "userbranch",
        headerName: "الفرع",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.branch?.name}</p>
        ),
    },
    {
        field: "Archived Date",
        headerName: "التاريخ",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.history_date.split("T")[0]}
            </p>
        ),
    },
    {
        field: "Archived By",
        headerName: "تمت بواسطة",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.changed_by?.type_name}</p>
        ),
    },
];

export const reports = [
    {
        field: "branch",
        headerName: "Branch",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.branch}</p>
        ),
    },

    {
        field: "orders",
        headerName: "Total Orders",
        width: 190,
        valueFormatter: (row) => `${row.row?.total}`,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.total}</p>
        ),
    },
    {
        field: "received",
        headerName: "Received",
        width: 190,
        valueFormatter: (row) =>
            `${
                row.row?.data?.filter((state) => state.state_id === 3).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 3)[0]
                          .total
                    : "No Data"
            }`,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.data?.filter((state) => state.state_id === 3).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 3)[0]
                          .total
                    : "No Data"}
            </p>
        ),
    },
    {
        field: "inHub",
        headerName: "In Hub",
        width: 190,
        valueFormatter: (row) =>
            `${
                row.row?.data?.filter((state) => state.state_id === 7).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 7)[0]
                          .total
                    : "No Data"
            }`,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.data?.filter((state) => state.state_id === 7).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 7)[0]
                          .total
                    : "No Data"}
            </p>
        ),
    },
    {
        field: "onDelivery",
        headerName: "On Delivery",
        width: 190,
        valueFormatter: (row) =>
            `${
                row.row?.data.filter((state) => state.state_id === 8).length > 0
                    ? row.row?.data?.filter((state) => state.state_id === 8)[0]
                          .total
                    : "No Data"
            }`,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.data.filter((state) => state.state_id === 8).length >
                0
                    ? row.row?.data.filter((state) => state.state_id === 8)[0]
                          .total
                    : "No Data"}
            </p>
        ),
    },
    {
        field: "delivered",
        headerName: "Delivered",
        width: 190,
        valueFormatter: (row) =>
            `${
                row.row?.data?.filter((state) => state.state_id === 6).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 6)[0]
                          .total
                    : "No Data"
            }`,
        renderCell: (row) => {
            // console.log(row.row?.data.filter(state=> state.state_id === 9)[0].total)
            return (
                <p className="paragraphMargin">
                    {row.row?.data?.filter((state) => state.state_id === 6)
                        .length > 0
                        ? row.row?.data?.filter(
                              (state) => state.state_id === 6
                          )[0].total
                        : "No Data"}
                </p>
            );
        },
    },

    {
        field: "returned",
        headerName: "Returned",
        width: 190,
        valueFormatter: (row) =>
            `${
                row.row?.data?.filter((state) => state.state_id === 9).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 9)[0]
                          .total
                    : "No Data"
            }`,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.data?.filter((state) => state.state_id === 9).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 9)[0]
                          .total
                    : "No Data"}
            </p>
        ),
    },
    {
        field: "cash_on_delivery",
        headerName: "Cash on delivery",
        width: 220,
        valueFormatter: (row) => `${row.row?.cash}`,
        renderCell: (row) => <p className="paragraphMargin">{row.row?.cash}</p>,
    },
    {
        field: "online_payment",
        headerName: "Online payment",
        width: 220,
        valueFormatter: (row) => `${row.row?.online}`,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.online}</p>
        ),
    },
    {
        field: "archived",
        headerName: "Archived",
        width: 190,
        valueFormatter: (row) => `${row.row?.archived}`,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.archived}</p>
        ),
    },
];

export const reports_ar = [
    {
        field: "branch",
        headerName: "الفرع",
        width: 190,
        valueFormatter: (row) => `${row.row?.branch}`,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.branch}</p>
        ),
    },

    {
        field: "orders",
        headerName: "مجموع الطلبات",
        width: 210,
        valueFormatter: (row) => `${row.row?.total}`,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.total}</p>
        ),
    },
    {
        field: "received",
        headerName: "المستلمة",
        width: 190,
        valueFormatter: (row) =>
            `${
                row.row?.data?.filter((state) => state.state_id === 3).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 3)[0]
                          .total
                    : "لا يوجد بيانات"
            }`,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.data?.filter((state) => state.state_id === 3).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 3)[0]
                          .total
                    : "لا يوجد بيانات"}
            </p>
        ),
    },
    {
        field: "inHub",
        headerName: "فى المخزن",
        width: 190,
        valueFormatter: (row) =>
            `${
                row.row?.data?.filter((state) => state.state_id === 7).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 7)[0]
                          .total
                    : "لا يوجد بيانات"
            }`,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.data?.filter((state) => state.state_id === 7).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 7)[0]
                          .total
                    : "لا يوجد بيانات"}
            </p>
        ),
    },
    {
        field: "onDelivery",
        headerName: "جارى التوصيل",
        width: 190,
        valueFormatter: (row) =>
            `${
                row.row?.data?.filter((state) => state.state_id === 8).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 8)[0]
                          .total
                    : "لا يوجد بيانات"
            }`,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.data?.filter((state) => state.state_id === 8).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 8)[0]
                          .total
                    : "لا يوجد بيانات"}
            </p>
        ),
    },
    {
        field: "delivered",
        headerName: "تم التوصيل",
        width: 190,
        valueFormatter: (row) =>
            `${
                row.row?.data?.filter((state) => state.state_id === 6).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 6)[0]
                          .total
                    : "لا يوجد بيانات"
            }`,
        renderCell: (row) => {
            // console.log(row.row?.data.filter(state=> state.state_id === 9)[0].total)
            return (
                <p className="paragraphMargin">
                    {row.row?.data?.filter((state) => state.state_id === 6)
                        .length > 0
                        ? row.row?.data?.filter(
                              (state) => state.state_id === 6
                          )[0].total
                        : "لا يوجد بيانات"}
                </p>
            );
        },
    },

    {
        field: "returned",
        headerName: "المرتجع",
        width: 190,
        valueFormatter: (row) =>
            `${
                row.row?.data?.filter((state) => state.state_id === 9).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 9)[0]
                          .total
                    : "لا يوجد بيانات"
            }`,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.data?.filter((state) => state.state_id === 9).length >
                0
                    ? row.row?.data?.filter((state) => state.state_id === 9)[0]
                          .total
                    : "لا يوجد بيانات"}
            </p>
        ),
    },
    {
        field: "cash_on_delivery",
        headerName: "نقدى عند الاستلام",
        width: 230,
        valueFormatter: (row) => `${row.row?.cash}`,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="paragraphMargin">{row.row?.cash}</p>,
    },
    {
        field: "online_payment",
        headerName: "دفع الكترونى",
        width: 190,
        valueFormatter: (row) => `${row.row?.online}`,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.online}</p>
        ),
    },
    {
        field: "archived",
        headerName: "ارشيف",
        width: 190,
        valueFormatter: (row) => `${row.row?.archived}`,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.archived}</p>
        ),
    },
];

export const scan = [
    {
        field: "date",
        headerName: "Date Created",
        flex: 2,
        minWidth: 200,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.create_date.split("T")[0]}
            </p>
        ),
    },
    {
        field: "postpone_date",
        headerName: "Postponed Date",
        flex: 2,
        minWidth: 230,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.new_date}</p>
        ),
    },
    {
        field: "order_id",
        headerName: "Order ID",
        flex: 2,
        minWidth: 180,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.order.id}</p>
        ),
    },

    {
        field: "id",
        headerName: "Ticket ID",
        flex: 1,
        minWidth: 180,
        renderCell: (row) => <p className="paragraphMargin">{row.row?.id}</p>,
    },
    {
        field: "seller_id",
        headerName: "Seller ID",
        flex: 1,
        minWidth: 180,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.order.seller.id}</p>
        ),
    },
    {
        field: "store_name",
        headerName: "Store Name",
        flex: 1,
        minWidth: 200,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.order?.seller.store_name}
            </p>
        ),
    },
    {
        field: "delivery_range",
        headerName: "Delivery Range",
        flex: 1,
        minWidth: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">{`${row.row?.order?.delivery_range?.min.toString()} - ${row.row?.order?.delivery_range?.max.toString()}`}</p>
        ),
    },
    {
        field: "special_label",
        headerName: "Special Label",
        flex: 1,
        minWidth: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.special_label?.en}</p>
        ),
    },
    {
        field: "feedback_comment",
        headerName: "Feedback Comment",
        flex: 1,
        minWidth: 270,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.comment?.text}</p>
        ),
    },
    {
        field: "status",
        headerName: "Status",
        flex: 1,
        minWidth: 180,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.state?.title}</p>
        ),
    },
    {
        field: "quantity",
        headerName: "Item Quantity",
        flex: 1,
        minWidth: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.quantity}</p>
        ),
    },
];

export const scan_ar = [
    {
        field: "date",
        headerName: "تاريخ الانشاء",
        flex: 2,
        minWidth: 200,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.create_date.split("T")[0]}
            </p>
        ),
    },
    {
        field: "postpone_date",
        headerName: "تاريخ التأجيل",
        flex: 2,
        minWidth: 210,
        valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.new_date}</p>
        ),
    },
    {
        field: "order_id",
        headerName: "رقم الطلب",
        flex: 2,
        minWidth: 180,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.order.id}</p>
        ),
    },

    {
        field: "id",
        headerName: "رقم البوليصة",
        flex: 1,
        minWidth: 200,
        renderCell: (row) => <p className="paragraphMargin">{row.row?.id}</p>,
    },
    {
        field: "seller_id",
        headerName: "رقم التاجر",
        flex: 1,
        minWidth: 180,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.order?.seller?.id}</p>
        ),
    },
    {
        field: "store_name",
        headerName: "اسم المتجر",
        flex: 1,
        minWidth: 180,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.order?.seller?.store_name}
            </p>
        ),
    },
    {
        field: "delivery_range",
        headerName: "متوسط ايام الدفع",
        flex: 1,
        minWidth: 230,
        renderCell: (row) => (
            <p className="paragraphMargin">{`${row.row?.order?.delivery_range?.min.toString()} - ${row.row?.order?.delivery_range?.max.toString()}`}</p>
        ),
    },
    {
        field: "special_label",
        headerName: "علامة مميزة",
        flex: 1,
        minWidth: 210,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.special_label.en}</p>
        ),
    },
    {
        field: "feedback_comment",
        headerName: "التعليقات",
        flex: 1,
        minWidth: 260,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.comment?.text_ar}</p>
        ),
    },
    {
        field: "status",
        headerName: "الحالة",
        flex: 1,
        minWidth: 180,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.state.title}</p>
        ),
    },
    {
        field: "quantity",
        headerName: "الكمية",
        flex: 1,
        minWidth: 220,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.quantity}</p>
        ),
    },
];

export const archivedTickets = [
    {
        field: "cid",
        headerName: "Ticket ID",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">#{row.row?.id}</p>,
    },
    {
        field: "name",
        headerName: "Order ID",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.order?.id}</p>,
    },
    {
        field: "usertype",
        headerName: "Store Name",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.order?.seller?.store_name}
            </p>
        ),
    },
    {
        field: "userbranch",
        headerName: "Seller Name",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.order?.seller?.user.name}
            </p>
        ),
    },
    {
        field: "Archived Date",
        headerName: "Archived Date",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.history_date?.split("T")[0]}
            </p>
        ),
    },
    {
        field: "Archived By",
        headerName: "Archived By",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.history_user?.name}</p>
        ),
    },
];

export const archivedTickets_ar = [
    {
        field: "cid",
        headerName: "رقم البوليصة",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">#{row.row?.id}</p>,
    },
    {
        field: "name",
        headerName: "رقم الطلب",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => <p className="tableID">{row.row?.order?.id}</p>,
    },
    {
        field: "usertype",
        headerName: "اسم المتجر",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.order?.seller?.store_name}
            </p>
        ),
    },
    {
        field: "userbranch",
        headerName: "اسم التاجر",
        width: 190,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.order?.seller?.user?.name}
            </p>
        ),
    },
    {
        field: "Archived Date",
        headerName: "تاريخ",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">
                {row.row?.history_date.split("T")[0]}
            </p>
        ),
    },
    {
        field: "Archived By",
        headerName: "بواسطة",
        width: 220,
        // valueGetter: (row) => row.row?.id,
        renderCell: (row) => (
            <p className="paragraphMargin">{row.row?.history_user?.name}</p>
        ),
    },
];

export const useCloseRunsheetColumns = () => {
    const { t } = useTranslation();
    const lang = useLanguage();
    return [
        {
            title: t("orderId"),
            dataIndex: "id",
            render: (_, row) => <span className="tableID">{row.id}</span>,
        },

        {
            title: t("store"),
            dataIndex: ["seller", "store_name"],
        },
        {
            title: t("createDate"),
            render: (_, row) => (
                <p className="paragraphMargin">
                    {row.create_date?.split("T")[0]}
                </p>
            ),
        },
        {
            title: t("orderValue"),
            render: (_, row) => (
                <p className="paragraphMargin">
                    {parseFloat(row.sub_total) + parseFloat(row.delivery_fees)}
                </p>
            ),
        },
    ];
};

export const useSupportTicketColumns = (deleteTicket, showComment) => {
    const { t } = useTranslation();
    return [
        {
            title: t("sideMenu.shipmentId"),
            dataIndex: "shipment_order.id",
            render: (_, row) => (
                <span className="tableID">{row.shipment_order?.id}</span>
            ),
        },
        {
            title: t("orderId"),
            dataIndex: ["shipment_order", "order", "id"],
            render: (_, row) => (
                <span className="tableID">{row.shipment_order?.order?.id}</span>
            ),
        },
        {
            title: t("store"),
            dataIndex: ["shipment_order", "order", "seller", "store_name"],
        },
        {
            title: t("createDate"),
            render: (_, row) => (
                <p className="paragraphMargin">
                    {row.create_date?.split("T")[0]}
                </p>
            ),
        },
        {
            title: t("sideMenu.complain"),
            dataIndex: "complaint",
        },
        {
            title: t("comments"),
            render: (_, record, i) => (
                <div>
                    <span className="tableID" onClick={() => showComment?.(i)}>
                        {t("showComments")}
                    </span>
                </div>
            ),
        },
        {
            title: t("sideMenu.action"),
            render: (_, row) => (
                <i
                    className="fas fa-trash-alt"
                    role="button"
                    onClick={() => deleteTicket(row.id)}
                ></i>
            ),
        },
    ];
};

export const useShipmentOrderColumns = () => {
    const { t } = useTranslation();
    const lang = useLanguage();
    return [
        {
            title: t("shipmentId"),
            dataIndex: "id",
            render: (_, row) => (
                <NavLink to={`/shipments/${row.id}`}>
                    <span className="tableID">{row.id}</span>
                </NavLink>
            ),
        },
        {
            title: t("orderId"),
            dataIndex: ["order", "id"],
            render: (_, row) => <span className="tableID">{row.order.id}</span>,
        },
        {
            title: t("store"),
            dataIndex: ["order", "seller", "store_name"],
        },
        {
            title: t("createDate"),
            render: (_, row) => (
                <p className="paragraphMargin">
                    {row.create_date?.split("T")[0]}
                </p>
            ),
        },
        {
            title: t("orderValue"),
            render: (_, row) => (
                <p className="paragraphMargin">
                    {parseFloat(row.order.sub_total) +
                        parseFloat(row.order.delivery_fees)}
                </p>
            ),
        },
        {
            title: t("status"),
            render: (_, row) => <p className="paragraphMargin">{row.state}</p>,
        },
    ];
};

export const usePaymentTransactionColumns = () => {
    const { t } = useTranslation();
    const lang = useLanguage();
    return [
        {
            title: t("sideMenu.paymentId"),
            dataIndex: "id",
            render: (_, row) => <span className="tableID">{row.id}</span>,
        },
        {
            title: t("sideMenu.transactionId"),
            dataIndex: "reference_id",
            render: (_, row) => (
                <span className="tableID">{row.reference_id}</span>
            ),
        },
        {
            title: t("sideMenu.invoiceAmount"),
            dataIndex: "invoice_amount",
            render: (_, row) => (
                <p className="paragraphMargin">{row.invoice_amount}</p>
            ),
        },
        {
            title: t("sideMenu.totalAmount"),
            dataIndex: "total_amount",
            render: (_, row) => (
                <p className="paragraphMargin">{row.total_amount}</p>
            ),
        },
        {
            title: t("sideMenu.status"),
            dataIndex: "payment_status",
            render: (_, row) => (
                <p className="paragraphMargin">
                    {t(`sideMenu.${row.payment_status}`)}
                </p>
            ),
        },
        {
            title: t("sideMenu.paymentMethod"),
            dataIndex: "payment_method_name",
            render: (_, row) => (
                <p className="paragraphMargin">{row.payment_method_name}</p>
            ),
        },
        {
            title: t("sideMenu.paymentDate"),
            dataIndex: "payment_created_at",
            render: (_, row) => (
                <p className="paragraphMargin">
                    {new Date(row.payment_created_at).toLocaleString()}
                </p>
            ),
        },
        
        {
            title: t("sideMenu.paymentFailureReason"),
            dataIndex: "payment_failed_reason",
            render: (_, row) => (
                <p className="paragraphMargin">{row.payment_failed_reason}</p>
            ),
        },
    ];
};

const FileViewer = ({ src }) => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);
    const history = useHistory();
    return (
        <div className="centered">
            {src ? (
                <span
                    className="tableID"
                    onClick={() => {
                        if (src?.match(/\.(jpg|jpeg|png|gif)$/i))
                            setVisible(true);
                        else history.push(src);
                    }}
                >
                    {t("clickToView")}
                </span>
            ) : (
                <span>{t("noProof")}</span>
            )}
            {visible && (
                <Image
                    width={200}
                    style={{ display: "none" }}
                    src={src}
                    preview={{
                        visible,
                        src,
                        onVisibleChange: (value) => {
                            setVisible(value);
                        },
                    }}
                />
            )}
        </div>
    );
};
export const useRefundColumns = (onClick) => {
    const { t } = useTranslation();
    const lang = useLanguage();
    return [
        {
            title: t("requestId"),
            dataIndex: "id",
            render: (id) => (
                <span onClick={() => onClick?.(id)} className="tableID">
                    {id}
                </span>
            ),
        },
        {
            title: t("refundAmount"),
            dataIndex: "refund_amount",
        },
        {
            title: t("refundReason"),
            dataIndex: "refund_reason",
        },
        {
            title: t("refundProof"),
            dataIndex: "refund_proof",
            width: 250,
            render: (proof) => {
                return <FileViewer src={proof} />;
            },
        },
        {
            title: t("trackNumber"),
            dataIndex: "track_number",
          },
          {
            title: t("shipState"),
            dataIndex: "shipment_status",
          },
        {
            title: t("sideMenu.status"),
            dataIndex: "state",
            render: (state) => (
                <p
                    className="paragraphMargin"
                    style={{
                        color:
                            +state === 1
                                ? "orange"
                                : +state === 2
                                ? "green"
                                : "red",
                    }}
                >
                    {+state === 1
                        ? t("pending")
                        : +state === 2
                        ? t("approved")
                        : t("rejected")}
                </p>
            ),
        },
       
    ];
};

export const useSellerWalletColumns = () => {
    const { t } = useTranslation();
    const cur = useSelector(
        (state) => state.auth.user.user.organization.country.currency
    );
    return [
        {
            title: t("sellerId"),
            dataIndex: ["seller", "id"],
            render: (_, row) => (
                <NavLink to={`/seller_wallet/${row.seller.id}`}>
                    <span className="tableID">{row.seller.id}</span>
                </NavLink>
            ),
        },
        {
            title: t("sideMenu.sellerName"),
            dataIndex: ["seller", "user", "name"],
            render: (_, row) => (
                <span className="tableID">{row.seller.user.name}</span>
            ),
        },
        {
            title: t("sideMenu.sellerStore"),
            dataIndex: ["seller", "store_name"],
            render: (_, row) => (
                <p className="paragraphMargin">{row.seller.store_name}</p>
            ),
        },
        {
            title: t("sideMenu.rechargeTotal"),
            dataIndex: ["summary", "total_transactions"],
            render: (_, row) => (
                <p className="paragraphMargin">
                    {row.summary.total_transactions}
                </p>
            ),
        },
        {
            title: t("sideMenu.shipmentTotal"),
            dataIndex: ["summary", "total_orders"],
            render: (_, row) => (
                <p className="paragraphMargin">{row.summary.total_orders}</p>
            ),
        },
        {
            title: t("sideMenu.balance"),
            dataIndex: ["summary", "balance"],
            render: (balance) => <p className="paragraphMargin">{balance}</p>,
        },
    ];
};

export const useSettleRequestColumns = (setOpen, handleUpdateAdmin, handleUpdateMarkteer, handleImagePreview, setRid , handleViewBankAccount) => {
    const userRole = useSelector((state) => state.auth.user.role);
    const { t } = useTranslation();
    const statusLabels = {
        "1": t("Accepted_s"),
        "2": t("Pending_s"),
        "3": "Noted",
        "4": t("Rejected_s")
    };
    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { month: "long", year: "numeric" };
        return date.toLocaleDateString("ar", options);
    }

    const actionMenu = (row) => (
        <Menu>
            <Menu.Item key= {t("Accepted_s")}>
                <Button style={{width:'100%' , backgroundColor:'#00bf63'}} onClick={() => handleUpdateAdmin(row?.id, true)}>
                    {t("Accepted_s")}
                </Button>
            </Menu.Item>
            <Menu.Item key={t("Rejected_s")}>
                <Button  style={{width:'100%' ,backgroundColor:'#f30408'}} onClick={() => handleUpdateAdmin(row?.id, false)}>
                    {t("Rejected_s")}
                </Button>
            </Menu.Item>
        </Menu>
    );
    return [
        {
            title: t("requestId"),
            dataIndex: "id",
        },
        {
            title: t("marketerName"),
            dataIndex: "marketer_name",
        },
        // {
        //     title: t("bankaccount"),
        //     dataIndex: "id",
        //     render: (id, record) => (
        //         <AntdButton  type="link" onClick={() => handleViewBankAccount(record)}>
        //             {t("bankaccount")}
        //         </AntdButton>
        //     ),
        // },
        {
            title: t("createDate"),
            dataIndex: "create_date",
            render: createDate => formatDate(createDate)
        },
        {
            title: t("commission"),
            dataIndex: "total"
        },
        // {
        //     title: t("status"),
        //     dataIndex: "status",
        //     render: (status) => (
        //         <p className="paragraphMargin">{statusLabels[status]}</p>
        //     ),
        // },
        {
            title: t("status"),
            dataIndex: "id",
            render: (id, row) => {
                if (userRole === "Organization Admin") {
                    return (
                        <Dropdown overlay={actionMenu(row)} trigger={['click']} defaultVisible={false}>
                            <Button style={{backgroundColor:'#41a6f5'}}>
                                {t("Pending_s")}
                            </Button>
                        </Dropdown>
                    );
                } else {
                    return null;
                }
            }
        },
      
        
        {
            title: t("approval_img"),
            dataIndex: "id",
            render: (id, row) => (
                    <>
                        <AntdButton  type="link" onClick={() => handleImagePreview(row.approval_img)}>
                        View Image
                        </AntdButton>
                    
                    </>
            ),
        },
        // {
        //     title: t("admin_note"),
        //     dataIndex: "admin_note",
        //     render: (id, row) => (
        //       !row.admin_note && row?.status === "2" && row?.changed_by.type_name === "Marketer" && userRole === "Organization Admin" ? (
        //         <AntdButton type="primary" 
        //         onClick={() => {
        //           setRid(row.id)
        //           setOpen(true)
        //         }
        //       }
        //       >
        //         {t("admin_note")}
        //       </AntdButton>
        //       ) : (
        //         <p>{row?.admin_note}</p>
        //       )
        //     ),

        // },
        // {
        //     title: t("marketer_note"),
        //     dataIndex: "marketer_note",
        //     render: (id, row) => (
        //       !row.marketer_note && row?.status === "2" && row?.changed_by.type_name === "Organization Admin" && userRole === "Marketer" ? (
        //         <AntdButton type="primary" 
        //         onClick={() => {
        //           setRid(row.id)
        //           setOpen(true)
        //         }
        //       }
        //       >
        //         {t("marketer_note")}
        //       </AntdButton>
        //       ) : (
        //         <p>{row?.marketer_note}</p>
        //       )
        //     ),
        // },
        // {
        //     title: t("sideMenu.action"),
        //     dataIndex: "id",
        //     render: (id, row) => {
        //          if (userRole === "Organization Admin") {
        //     return (
        //     <div className="d-flex justify-content-start align-items-start gap-2">
        //         <AntdButton type="primary" onClick={()=>{handleUpdateAdmin(row?.id ,true );}}>
        //         {t("accept")}
        //         </AntdButton>
        //         <AntdButton type="danger" onClick={()=>{handleUpdateAdmin(row?.id , false);}}>
        //         {t("reject")}
        //         </AntdButton>
        //     </div>
        //     );
        //   } else {
        //     return  (
        //     //     <div className="d-flex justify-content-start align-items-start gap-2">
        //     //         <AntdButton type="primary" onClick={()=>{handleUpdateMarkteer(row?.id  , true);}}>
        //     //         {t("accept")}
        //     // </AntdButton>
        //     //         <AntdButton type="danger" onClick={()=>{handleUpdateMarkteer(row?.id  , false);}}>
        //     //         {t("reject")}
        //     //         </AntdButton>
        //     //     </div>
        //     null
        //         );
        //   }
        //     }
        // }
    ];
};


export const useSettleRequestStatusColumns = ( handleImagePreview) => {
    const userRole = useSelector((state) => state.auth.user.role);
    const { t } = useTranslation();
    const statusLabels = {
            "1": t("Accepted_s"),
            "2": t("Pending_s"),
            "3": "Noted",
            "4": t("Rejected_s")
        };
    function getStatusColor(status) {
        switch (status) {
            case "1": // Accepted
                return "green";
            case "2": // Pending
                return "#41a6f5";
            case "3": // Noted
                return "yellow";
            case "4": // Rejected
                return "red";
            default:
                return "black"; // Default color if status is unknown
        }
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { month: "long", year: "numeric" };
        return date.toLocaleDateString("ar", options);
    }
    return [
        {
            title: t("requestId"),
            dataIndex: "id",
        },
        {
            title: t("marketerName"),
            dataIndex: "marketer_name",
        },
        {
            title: t("createDate"),
            dataIndex: "create_date",
            render: createDate => formatDate(createDate)
        },
        {
            title: t("commission"),
            dataIndex: "total"
        },
            
       
       
        {
            title: t("status"),
            dataIndex: "status",
            render: (status) => (
                <p
                    className="paragraphMargin"
                    style={{
                        backgroundColor: getStatusColor(status), 
                        color : 'white',
                        margin: 0, 
                        padding: '8px',
                        width: '80px', // Adjust the width as needed
                        height : '40px', // Adjust the height as needed
                        textAlign: 'center'
                    }}
                >
                    {statusLabels[status]}
                </p>
            ),
        },
        
        {
            title: t("approval_img"),
            dataIndex: "status",
            render: (status, row) => (
                status === "1" ? (
                    <AntdButton type="link" onClick={() => handleImagePreview(row.approval_img)}>
                        {t("View Image")}
                    </AntdButton>
                ) : (
                    <p
                        className="paragraphMargin"
                        style={{
                           
                            margin: 0,
                            padding: '8px',
                            width: '80px', // Adjust the width as needed
                            height: '40px', // Adjust the height as needed
                            textAlign: 'center'
                        }}
                    >
                        {statusLabels[status]}
                    </p>
                )
            ),
        },
       
    ];
}

// export const OrderColumns = [
//     {
//       title: "Order ID",
//       dataIndex: "id",
//       key: "id",
//     },
//     {
//       title: "Seller",
//       dataIndex: ["seller", "store_name"],
//       key: "seller",
//     },
//     {
//       title: "Client",
//       dataIndex: ["client", "name"],
//       key: "client",
//     },
//     {
//       title: "Order Type",
//       dataIndex: "type",
//       key: "type",
//       render: (type) => {
//         return type === "كامل" ? <Tag color="green">Complete</Tag> : <Tag color="orange">Partial</Tag>;
//       },
//     },
//     {
//       title: "Payment Method",
//       dataIndex: "payment_method",
//       key: "payment_method",
//     },
//     {
//       title: "Order State",
//       dataIndex: "state",
//       key: "state",
//       render: (state) => {
//         let color = "";
//         let text = "";
//         switch (state) {
//           case "1":
//             color = "red";
//             text = "Rejected";
//             break;
//           case "2":
//             color = "yellow";
//             text = "Pending";
//             break;
//           case "3":
//             color = "blue";
//             text = "Assigned";
//             break;
//           default:
//             color = "default";
//             text = "Unknown";
//         }
//         return <Tag color={color}>{text}</Tag>;
//       },
//     },
//     {
//       title: "Create Date",
//       dataIndex: "create_date",
//       key: "create_date",
     
//        render: (createDate) => {
//         return <span>{new Date(createDate).toLocaleDateString()}</span>;
//      },
//     },
    
//   ];
  export const OrderColumns = () => {

    const { t } = useTranslation();

    const createDateSorter = (a, b) => {
        const dateA = new Date(a.create_date);
        const dateB = new Date(b.create_date);
        return dateA - dateB;
      };

    return [
    {
      title: t("orderId"),
      dataIndex: "id",
      key: "id",
      render: (_, row) => <span className="tableID">#{row.id}</span>,
    },
    {
      title: t("createDate"),
      dataIndex: "create_date",
      sorter: (a, b) => createDateSorter(a, b),
      render: (date) => {
        const dateParts = date?.split("T") ?? [];
        return (
          <>
            <span>{dateParts[0]}</span>{" "}
            <span>{dateParts[1].substr(0, 5)}</span>
          </>
        );
      },
    },
    {
      title: t("shipmentProvider"),
      dataIndex: ["shipment_order", "provider_name"],
      key: "shipment_provider",
    },
    {
      title: t("shipmentType"),
      dataIndex: "type",
      key: "shipment_type",
      render: (type) => {
                return type === "كامل" ? <Tag color="green">Complete</Tag> : <Tag color="orange">Partial</Tag>;
              },
    },
    {
      title: t("customerName"),
      dataIndex: ["client", "name"],
      key: "customer_name",
    },
    {
      title: t("store"),
      dataIndex: ["seller", "store_name"],
      key: "store",
    },
    {
      title: t("sellerAddress"),
      dataIndex: ["seller_address", "address"],
      key: "seller_address",
    },
    {
      title: t("orderDetails"),
      key: "order_details",
      render: (_, row) => (
        <NavLink to={`/order_chain_details/${row.order?.id ?? row.id}`}>
          <SeeMoreButton />
        </NavLink>
      ),
    },
  ];
}
export const useOrdersColumns2 = (onClick) => {
    const { t } = useTranslation();
    return [
        {
            title: t("orderId"),
            dataIndex: "id",
            render: (id) => (
                <span onClick={() => onClick?.(id)} className="tableID">
                    {id}
                </span>
            ),
        },
        
        
        
    ];
};

export const useRefundColumns2 = (onClick) => {
    const { t } = useTranslation();
    const lang = useLanguage();
    return [
       
        // {
        //     title: t("refundAmount"),
        //     dataIndex: "refund_amount",
        // },
        
        
    ];
};


export const usePaymentTransactionColumns2 = () => {
    const { t } = useTranslation();
    const { Search } = Input;
    const lang = useLanguage();
    const handleReferenceIdSearch = (selectedKeys, confirm) => {
        confirm();
        // Apply the filter logic here based on selectedKeys
        // Update the state or trigger a search function with the selectedKeys
      };
    
      const handleReferenceIdReset = (clearFilters) => {
        clearFilters();
        // Reset the filter logic here
        // Update the state or trigger a function to reset the filter
      };
    return [
        // {
        //     title: t("orderId"),
        //     dataIndex: "id",
        //     render: (_, row) => <span className="tableID">{row.id}</span>,
        // },
        {
            title: t('sideMenu.transactionId'),
            dataIndex: 'payment_invoice_id',
            render: (_, row) => <span className="tableID">{row.payment_invoice_id}</span>,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
              <div style={{ padding: 8 }}>
                <Input
                  placeholder={`Search ${t('sideMenu.transactionId')}`}
                  value={selectedKeys[0]}
                  onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                  onPressEnter={() => handleReferenceIdSearch(selectedKeys, confirm)}
                  style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                  type="primary"
                  onClick={() => handleReferenceIdSearch(selectedKeys, confirm)}
                  size="small"
                  style={{ width: 90, marginRight: 8 }}
                >
                  {t('Search')}
                </Button>
                <Button onClick={() => handleReferenceIdReset(clearFilters)} size="small" style={{ width: 90 }}>
                  {t('Reset')}
                </Button>
              </div>
            ),
            onFilter: (value, record) => record.payment_invoice_id && record.payment_invoice_id.toLowerCase().includes(value.toLowerCase()),
            filterIcon: (filtered) => (
              <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
          },
        {
            title: t('createDate'),
            dataIndex: 'payment_created_at',
            render: (_, row) => (
              <p className="paragraphMargin">
                {new Date(row.payment_created_at).toLocaleDateString()} {' '}
                {new Date(row.payment_created_at).toLocaleTimeString()}
              </p>
            ),
          },
        
        {
            title: t("orderValue"),
            dataIndex: "total_amount",
            render: (_, row) => (
                <p className="paragraphMargin">{row.total_amount}</p>
            ),
        },
        
        {
            title: t("sideMenu.paymentMethod"),
            dataIndex: "payment_method_name",
            render: (_, row) => (
                <p className="paragraphMargin">{row.payment_method_name}</p>
            ),
        },
        {
            title: t('wafeqInvoice'),
            dataIndex: 'wafeq_invoice',
            render: (_, row) => (
                <Space>
                    <a href={row.wafeq_invoice} target="_blank" rel="noopener noreferrer">
                        {t('Invoice')}
                    </a>
                </Space>
            ),
        },
        {
            title: t("paymentState"),
            dataIndex: "payment_status",
            render: (_, row) => {
                let statusText;
        
                switch (row.payment_status) {
                    case "pending":
                        statusText = t("pending");
                        break;
                    case "success":
                        statusText = t("sideMenu.success");
                        break;
                    case "failed":
                        statusText = t("sideMenu.failed");
                        break;
                    default:
                        statusText = t("");
                        // You can replace "unknownStatus" with a default status text if needed
                        break;
                }
        
                return <p className="paragraphMargin">{statusText}</p>;
            },
        },
        
    
    ];
};

// export const useOrdersColumns2 = (onClick) => {
//     const { t } = useTranslation();
//     return [
//         {
//             title: t("orderId"),
//             dataIndex: "id",
//             render: (id) => (
//                 <span onClick={() => onClick?.(id)} className="tableID">
//                     {id}
//                 </span>
//             ),
//         },
        
        
        
//     ];
// };

// export const useRefundColumns2 = (onClick) => {
//     const { t } = useTranslation();
//     const lang = useLanguage();
//     return [
       
//         // {
//         //     title: t("refundAmount"),
//         //     dataIndex: "refund_amount",
//         // },
        
        
//     ];
// };


// export const usePaymentTransactionColumns2 = () => {
//     const { t } = useTranslation();
//     const { Search } = Input;
//     const lang = useLanguage();
//     const handleReferenceIdSearch = (selectedKeys, confirm) => {
//         confirm();
//         // Apply the filter logic here based on selectedKeys
//         // Update the state or trigger a search function with the selectedKeys
//       };
    
//       const handleReferenceIdReset = (clearFilters) => {
//         clearFilters();
//         // Reset the filter logic here
//         // Update the state or trigger a function to reset the filter
//       };
//     return [
//         // {
//         //     title: t("orderId"),
//         //     dataIndex: "id",
//         //     render: (_, row) => <span className="tableID">{row.id}</span>,
//         // },
//         {
//             title: t('sideMenu.transactionId'),
//             dataIndex: 'payment_invoice_id',
//             render: (_, row) => <span className="tableID">{row.payment_invoice_id}</span>,
//             filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
//               <div style={{ padding: 8 }}>
//                 <Input
//                   placeholder={`Search ${t('sideMenu.transactionId')}`}
//                   value={selectedKeys[0]}
//                   onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
//                   onPressEnter={() => handleReferenceIdSearch(selectedKeys, confirm)}
//                   style={{ width: 188, marginBottom: 8, display: 'block' }}
//                 />
//                 <Button
//                   type="primary"
//                   onClick={() => handleReferenceIdSearch(selectedKeys, confirm)}
//                   size="small"
//                   style={{ width: 90, marginRight: 8 }}
//                 >
//                   {t('Search')}
//                 </Button>
//                 <Button onClick={() => handleReferenceIdReset(clearFilters)} size="small" style={{ width: 90 }}>
//                   {t('Reset')}
//                 </Button>
//               </div>
//             ),
//             onFilter: (value, record) => record.payment_invoice_id && record.payment_invoice_id.toLowerCase().includes(value.toLowerCase()),
//             filterIcon: (filtered) => (
//               <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
//             ),
//           },
//         {
//             title: t('createDate'),
//             dataIndex: 'payment_created_at',
//             render: (_, row) => (
//               <p className="paragraphMargin">
//                 {new Date(row.payment_created_at).toLocaleDateString()} {' '}
//                 {new Date(row.payment_created_at).toLocaleTimeString()}
//               </p>
//             ),
//           },
        
//         {
//             title: t("orderValue"),
//             dataIndex: "total_amount",
//             render: (_, row) => (
//                 <p className="paragraphMargin">{row.total_amount}</p>
//             ),
//         },
        
//         {
//             title: t("sideMenu.paymentMethod"),
//             dataIndex: "payment_method_name",
//             render: (_, row) => (
//                 <p className="paragraphMargin">{row.payment_method_name}</p>
//             ),
//         },
        
    
//     ];
// };


export const useArchivedOrdersColumns = ( sendRequest , delete_archive ) => {
    const { t } = useTranslation();
   
   
    const getTicketColumns = () => {
        return [
            {
                title: t('width'),
                dataIndex: 'width',
            },
            {
                title: t('height'),
                dataIndex: 'height',
            },
            {
                title: t('length'),
                dataIndex: 'length',
            },
            {
                title: t('weight'),
                dataIndex: 'weight',
            },
            // {
            //     title: t('product'),
            //     dataIndex: 'product',
            //     key: 'product',
            // },
            {
                title: t('quantity'),
                dataIndex: 'quantity',
            },
            {
                title: t('products'),
                dataIndex: 'product_name',
            },
            {
                title: t("specialLabel"),
                dataIndex: "special_label",
                render: (specialLabel) => (
                    <>
                        {specialLabel && specialLabel.toLowerCase() === 'true' ? (
                            <CheckCircleOutlined style={{ color: 'green' }} />
                        ) : (
                            <CloseCircleOutlined style={{ color: 'red' }} />
                        )}
                    </>
                ),
            },
           
        ];
    };
    
    const columns = [
        {
            title: t('orderId'),
            dataIndex: 'id',
            key: 'id',
            render: (id) => (
                <span className="tableID">
                    {id}
                </span>
            ),
        },
        {
            title: t('createDate'),
            dataIndex: 'create_date',
            key: 'create_date',
            render: (text, record) => (
                <span>{new Date(record.create_date).toLocaleDateString()}</span>
            ),
        },
        {
            title: t('customerInfo'),
            dataIndex: ['archived_data','clientData','name'],
            key: 'client_name',
        },
        {
            title: t('store'),
            dataIndex: [ "archived_data",'sellerData','store_name'],
            key: 'store_name',
        },
        {
            title: t('sellerAddress'),
            dataIndex: ['archived_data', 'sellerData', 'saved_address', 0, 'area', 'name'],
            key: 'address',
        },
        {
            title: t('orderDetails'),
            key: 'tickets',
            render: (_, record) => (
                <AntdButton type="link" onClick={() => showTickets(record)}>{t('details')}</AntdButton>
            ),
        },
        {
            title: t('actions'),
            key: 'actions',
            render: (_, record) => (
                <div style={{display:'flex' , gap:'8px'}}>
                    <AntdButton type="primary" style={{width:'100px' , borderRadius:'5px' , backgroundColor: 'green', borderColor: 'green'}} onClick={() => sendRequest(record)}>{t('completeArchive')}</AntdButton>
                    
                    <AntdButton type="danger" style={{width:'80px' , borderRadius:'5px'}} onClick={() => delete_archive(record)}>{t('deleteArchive')}</AntdButton>
                </div>
            ),
        },
    ];

   
    const showTickets = (record) => {
        Modal.info({
            title: t('productInfo'),
            width:  700, // Add extra space for margins/padding
            content: (
                <div>
                    <Table
                        dataSource={record.archived_data.tickets}
                        columns={getTicketColumns()}
                        pagination={false}
                        style={{ width: '100%' }}
                        scroll={true}
                    />
                </div>
            ),
        });
    };

    return columns;
};


export const useCommissionColumns = () => {
    const { t } = useTranslation();
    const cur = useSelector(
        (state) => state.auth.user.user.organization.country.currency
    );
    const columns = [
        {
            title: t('orderId'),
            dataIndex: ['order','id'],
            key: 'id',
            render: (id) => (
                <span className="tableID">
                    {id}
                </span>
            ),
        },
        {
            title: t('createDate'),
            dataIndex: ['order', 'create_date'],
            key: 'create_date',
            render: (text, record) => (
                <span>{new Date(record.order.create_date).toLocaleDateString()}</span>
            ),
        },
        {
            title: t('status'),
            dataIndex: ['setteled'],
            key: 'setteled',
            render: (setteled) => (
                setteled ? <Tag color="green">{t('true')}</Tag> : <Tag color="red">{t('false')}</Tag>
            ),
        },
        {
            title: t('seller'),
            dataIndex: [ "order",'seller_name'],
            key: 'seller_name',
        },
        {
            title: t('store'),
            dataIndex: [ "order",'seller_store'],
            key: 'store_name',
        },
        {
            title: t('commission'),
            dataIndex: [ 'commission_value'],
            key: 'commission_value',
        },
        
    ];
    console.log("Columns:", columns);
    return columns;
};


export const useExtraOrderColumns = (userRole , payExtraOrders) => {
    const { t, i18n } = useTranslation();

    const formatDate = (dateString) => {
        const options = {
            year: "numeric",
            month: "long",
        };
        return new Date(dateString).toLocaleDateString(i18n.language, options);
    };

    const columns =  [
        {
            title: t("createDate"),
            dataIndex: "user_added_date",
            render: (_, row) => (
                <p className="paragraphMargin">
                    {row.create_date ? formatDate(row.user_added_date) : ""}
                </p>
            ),
        },
        {
            title: t("orderId"),
            dataIndex: "id",
            render: (id) => (
                <span  className="tableID">
                    {id}
                </span>
            ),
        },
        {
            title: t("store"),
            dataIndex: "seller_store_name",
            render: (storeName) => (
                <p className="paragraphMargin">
                    {storeName ? storeName : "No Name"}
                </p>
            ),
        },
        {
            title: t("shipmentProvider"),
            dataIndex: "shipment_provider",
        },
        {
            title: t("trackNumber"),
            dataIndex: "track_number",
        },
        {
            title: t("weight"),
            dataIndex: "weight",
        },
        {
            title: t("extraWeight"),
            dataIndex: "extra_weight",
        },
        {
            title: t("extraWeightCharge"),
            dataIndex: "extra_weight_charge",
        },
        {
            title: t("status"),
            dataIndex: "paid",
            render: (paid) => (
                <div style={{ backgroundColor: paid ? 'green' : 'red', padding: '8px' }}>
                    {paid ? 'تم السداد' : 'لم يتم السداد'}
                </div>
            ),
        },
        
    ];


    if (userRole === 'Seller') {
        columns.push({
            title: t("paid"),
            dataIndex: "id",
            render: (id, row) => (
                row.paid === false && (
                    <Button type="primary" onClick={() => payExtraOrders(id)}>{t("paid")}</Button>
                )
            ),
        });
    }
    if (userRole === "Organization Admin" ) {
        columns.push({
            title: t("edited"),
            dataIndex: "edited",
            render: (edited) => (
                <div style={{ backgroundColor: edited ? '#729eff' : '#c1ff72', padding: '8px' }}>
                    {edited ? 'تم التعديل' : 'معتمد'}
                </div>
            ),
        },);
        
    }
    if (userRole === "Organization Admin" ) {
        columns.push({
            title: t("paid"),
            dataIndex: "id",
            render: (id, row) => (
                <Link to={`/extra-order/${id}`}>{t("orderDetails")}</Link>
            ),
        });
        
    }

    return columns;
};




const PaymentsColumns = (handleRetryPayment) => {
    const { t } = useTranslation();

    return [
        {
            title: t("id"),
            dataIndex: "id",
            render: (id) => (
                <span  className="tableID">
                    {id}
                </span>
            ),
        },
        {
            title: t('sellerStoreName'),
            dataIndex: 'seller_store_name',
        },
        {
            title: t('sellerName'),
            dataIndex: 'seller_name',
        },
        {
            title: t('amount'),
            dataIndex: 'amount',
        },
        {
            title: t('paymentStatus'),
            dataIndex: 'payment_status',
            render: (status) => {
                let backgroundColor;
                let textColor = 'white'; 
                let fontWeight = 'bold'; 
                let textAlign = 'center';
                let fontSize = '16px';
                switch (status) {
                    case 'success':
                        backgroundColor = 'green';
                        break;
                    case 'pending':
                        backgroundColor = 'yellow';
                        break;
                    case 'failed':
                        backgroundColor = 'red';
                        break;
                    default:
                        backgroundColor = 'white';
                }
                return (
                    <div
                        style={{
                            backgroundColor,
                            color: textColor,
                            fontWeight,
                            textAlign,
                            fontSize
                        }}
                    >
                        {t(status)}
                    </div>
                );
            },
        },
        {
            title: t('paymentMethod'),
            dataIndex: 'payment_method',
        },
        {
            title: t('paymentDateCreatedAt'),
            dataIndex: 'payment_date_created_at',
            render: (paymentDate) => moment(paymentDate).format('YYYY-MM-DD HH:mm'), // Format date and time
        },
        {
            title: t('paymentInvoiceId'),
            dataIndex: 'payment_invoice_id',
        },
        {
            title: t('referenceId'),
            dataIndex: 'reference_id',
        },
        {
            title: t('paymentUrl'),
            dataIndex: 'payment_url',
            render: (paymentUrl) => (
                <a href={paymentUrl} target="_blank" rel="noopener noreferrer">
                    {t('paymentLink')}
                </a>
            ),
        },
        {
            title: t('retryPayment'),
            dataIndex: 'payment_status',
            render: (status, row) =>
                status !== 'success' && row.payment_date_created_at && row.payment_invoice_id && row.amount ? (
                    <Button
                        size="small"
                        onClick={() => handleRetryPayment(row.id)}>{t('retry')}</Button>
                ) : null,
        },
        

    ];
};

export default PaymentsColumns;