import { combineReducers } from "redux";
import authReducer from "./authReducer";
import tokenReducer from "./tokenReducer";
import stateReducer from "./stateReducer";
import getSellerReducer from "./getSelectedReducer";
import searchReducer from "./searchReducer";
import isValidReducer from "./isValidReducer";
import addOrderReducer from "./addOrderReducer";
import addAccountReducer from "./addAccountReducer";
import userTypeReducer from "./userTypeReducer";
import addRunsheetReducer from "./addRunsheetReducer";
import rsOrderReducer from "./rsOrderReducer";
import reassignedRsReducer from "./reassignedRsReducer";
import isReassignedReducer from "./isReassignedReducer";
import branchReducer from "./branchReducer";
import zoneReducer from "./zoneReducer";
import postponedOrdersReducer from "./postponedOrdersReducer";
import postponedOrdersIds from "./postponedOrdersIds";
import postponeDate from "./postponeDate";
import ticketsReturnedReducer from "./ticketsReturnedReducer";
import getSelectedUserReducer from "./getSelectedUserReducer";
import ticketPrintedReducer from "./ticketPrintedReducer";
import CopyWorkModelReducer from "./CopyWorkModelReducer";
import dimentionRdeuser from "./dimentionRdeuser";
import plan from "./plan";
import codeReducer from "./codeReducer";
const allReducers = combineReducers({
    auth: authReducer,
    accessToken: tokenReducer,
    selectedState: stateReducer,
    selectedData: getSellerReducer,
    search: searchReducer,
    order: addOrderReducer,
    isValid: isValidReducer,
    account: addAccountReducer,
    userType: userTypeReducer,
    runsheet: addRunsheetReducer,
    rsOrders: rsOrderReducer,
    branch: branchReducer,
    zone: zoneReducer,
    postponed: postponedOrdersReducer,
    postponedIds: postponedOrdersIds,
    postponeDate: postponeDate,
    selectedState: stateReducer,
    selectedData: getSellerReducer,
    search: searchReducer,
    reAssignedRs: reassignedRsReducer,
    isReassigned: isReassignedReducer,
    returnedTickets: ticketsReturnedReducer,
    selectedUser: getSelectedUserReducer,
    ticketPrintedData: ticketPrintedReducer,
    copyWorkModel: CopyWorkModelReducer,
    dimention: dimentionRdeuser,
    plan: plan,
    code: codeReducer,
});

export default allReducers;
