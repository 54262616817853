import { Col, Row } from "react-bootstrap";
import useLanguage from "../../../hooks/useLanguage";

function Arrow({ language }) {
    return (
        <i
            className="fas fa-chevron-right px-3 title2"
            style={{
                rotate: language === "ar" ? "180" : "0",
            }}
        />
    );
}

export default function PageHeader({ group, title, subLocations }) {
    const language = useLanguage();

    return (
        <header>
            <Row>
                <Col sm="12">
                    <span className="title1">{group}</span>

                    {subLocations ? (
                        subLocations.map((sub) => (
                            <span key={sub}>
                                <Arrow language={language} />
                                <span className="title2">{sub}</span>
                            </span>
                        ))
                    ) : (
                        <span>
                            <Arrow language={language} />
                            <span className="title2">{title}</span>
                        </span>
                    )}
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <h2 className="reqTypeTitle">{title}</h2>
                </Col>
            </Row>
        </header>
    );
}
