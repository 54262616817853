import React, { useState } from "react";
import InformationCard from "../../../../../components/layout/informationCard";
import { FormControlLabel, Switch } from "@mui/material";
import { Spin } from "antd";
import { Icon } from "@shopify/polaris";
import { CircleDisableMinor, UploadMajor } from "@shopify/polaris-icons";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    SuccessfulRequest,
    UnSuccessfulRequest,
} from "../../../../../components/modals/confirmationModal";
import ErrorHandler from "../../../../../error-handler/ErrorHandler";
import axiosInstance from "../../../../../api/axios";

function CreateBulkOrder() {
    const { t } = useTranslation();
    const history = useHistory();
    const clearDispatch = useDispatch();
    const [errors, setErrors] = useState([]);
    const [errorMessage, setErrorMessage] = useState();
    const [show, setShow] = useState(false);
    const [msg, setMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [shippingType, setShippingType] = useState({
        weight: true,
        dimention: false,
    });
    const hide = () => {
        setShow(false);
        history.push("/dashboard");
    };
    const fileHandler = async (event) => {
        let fileObj = event.target.files[0];
        //just pass the fileObj as parameter
        let formdata = new FormData();
        formdata.append("file", fileObj);
        setLoading(true);
        let result = await axiosInstance
            .post(
                `/BulkOrder/?shipment_type=${
                    shippingType.weight ? "weight" : "dimention"
                }`,
                formdata
            )
            .catch((err) => {
                console.log(Object.entries(err.response.data));
                console.log(Object.values(err.response.data));

                if (err.response?.status === 500) {
                    setErrorMessage(t("server_error"));
                } else {
                    setErrors(err.response.data);
                }
                setLoading(false);
            });
        if (result) {
            setLoading(false);
            console.log(result);
            setMsg(result.data);
            setErrors([]);
            setShow(true);
            console.log("good");
        }
        event.target.value = null;
    };

    const downloadTemp = async () => {
        let result = await axiosInstance.get(
            `/BulkOrder/?order_type=${
                shippingType.weight ? "weight" : "dimentions"
            }`,
            {
                responseType: "blob",
            }
        );
        if (result) {
            const url = window.URL.createObjectURL(result.data);
            const link = document.createElement("a");
            link.href = url;
            link.target = "blank";
            link.download = "bulk_order.xlsx";
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
    };
    return (
        <>
            <InformationCard
                title={t("infoBulkTitle")}
                subTitle={t("infoBulkSub")}
                list={[
                    <p>{t("choose_template_type")}</p>,
                    <FormControlLabel
                        sx={{
                            ".MuiSwitch-switchBase": {
                                top: "8px",
                            },
                        }}
                        control={
                            <Switch
                                checked={shippingType.dimention}
                                onChange={() => {
                                    setShippingType({
                                        weight: !shippingType.weight,
                                        dimention: !shippingType.dimention,
                                    });
                                }}
                                name="checkedB"
                                color="success"
                            />
                        }
                        label={
                            shippingType.weight ? t("weight") : t("dimentions")
                        }
                    />,
                    <p>
                        {t("infoBulkStep1")}{" "}
                        <a
                            // href={`${BASE_URL}/media/order_bulk${
                            //     localStorage.getItem("lang") == "ar"
                            //         ? "_ar"
                            //         : ""
                            // }.xlsx`}
                            // target="_blank"
                            onClick={downloadTemp}
                        >
                            ({t("bulkHyperLink")})
                        </a>{" "}
                    </p>,
                    <p>{t("infoBulkStep2")}</p>,
                    <p>{t("infoBulkStep3")}</p>,
                ]}
            />

            <div className="uploaderCard">
                {loading ? (
                    <Spin />
                ) : (
                    <Icon source={UploadMajor} color="base" />
                )}

                <p className="bulk-upload-title">{t("uploadFileText")}</p>
                <p className="bulk-upload-sub">
                    <strong>{t("uploadFileSub")}</strong>
                </p>
                <input
                    disabled={loading}
                    type="file"
                    className="uploaderInput"
                    onChange={fileHandler}
                />
            </div>
            {errors.length > 0 ? (
                <Row>
                    <Col className="error-bg" sm={1}>
                        <p>{t("row")}</p>
                    </Col>
                    <Col className="error-bg">
                        <p>{t("issues")}</p>
                    </Col>
                </Row>
            ) : null}

            {Object.keys(errors).map((issue) => {
                return (
                    <Row>
                        <Col sm={1} className="error-bg p-2">
                            <p>{issue}</p>
                        </Col>

                        <Col className="error-bg p-2">
                            {errors[issue].map((field) => {
                                console.log(field);
                                console.log(Object.entries(field));

                                return (
                                    <>
                                        <p className="error-icon">
                                            <Icon
                                                source={CircleDisableMinor}
                                                color="base"
                                            />
                                            {Object.keys(field)[0]} :{" "}
                                            {Object.values(field)[0]}
                                        </p>
                                    </>
                                );
                            })}
                        </Col>
                    </Row>
                );
            })}
            <SuccessfulRequest
                body={msg}
                title={t("bulkSuccessTitle")}
                show={show}
                hide={hide}
            />
            <UnSuccessfulRequest
                show={errorMessage}
                hide={() => setErrorMessage(undefined)}
                title={t("postponeConfirmation")}
                body={errorMessage}
            />
        </>
    );
}

export default ErrorHandler(CreateBulkOrder, axiosInstance);
