import Dashboard from "../../features/common/dashboard/ui";
import CreateAccount from "../../features/admin/accounts/create/createAccount";
import BulkAccount from "../../components/pages/BulkAccount";
import DeliveryRanges from "../../components/pages/DeliveryRanges";
import AccountsTabs from "../../components/layout/Accounts/AccountsTabs";
import NewMarketers from "../../components/layout/Accounts/NewMarketers";
import ActivityLogs from "../../components/pages/ActivityLogs";
import ArchivedUsers from "../../components/layout/Accounts/ArchivedUsers";
import AccountInfo from "../../components/pages/AccountInfo";
import PermissionsTabs from "../../components/pages/settings/permissions/permissionsTabs";
import StoreReportsPage from "../../components/pages/Reports/StoreReportsPage";
import BranchReportsPage from "../../components/pages/Reports/BranchReportsPage";
import ReportsTypeSelection from "../../components/pages/Reports/ReportsTypeSelection";
import UpdatePassword from "../../components/pages/settings/UpdatePassword";
import ForgetPassword from "../../components/Login-screens/ForgetPassword";
import Registeration from "../../components/pages/registeration";
import RegisterationAr from "../../components/pages/registerationAr";
import MarketerRegisterForm from "../../components/pages/marketerRegister"
import ConfirmationCode from "../../components/Login-screens/ConfirmationCode";
import ResetPassword from "../../components/Login-screens/ResetPassword";
import ExtraOrdersList from "../../components/pages/ExtraOrders/ExtraOrdersList";
import ExtraOrderDetails from "../../components/pages/ExtraOrders/ExtraOrderDetails";
import CreateExtraOrder from "../../components/pages/ExtraOrders/CreateExtraOrder";
import CommissionOrders  from "../../components/pages/wallets/marketerWallet/commissionOrders"
import AdminCommissionOrders  from "../../components/pages/wallets/marketerWallet/commissionOrders_admin"
import SettleRequests  from "../../components/pages/wallets/marketerWallet/settlerequest"
import SettleRequestStatus  from "../../components/pages/wallets/marketerWallet/settlerequestsStatus"
import WalletSummary from "../../components/pages/wallets/marketerWallet/marketerWallet"
import SellerStores  from "../../components/pages/wallets/marketerWallet/sellersStores"
import {
    AnalyticsMajor,
    BalanceMajor,
    CustomersMajor,
    HomeMajor,
    ReportsMajor,
    TransactionFeeDollarMajor,
    WholesaleMajor,
    ProductsMajor,
} from "@shopify/polaris-icons";
import orders, { useAdminOrdersRoute, useSellerOrdersRoute } from "./orders";
import settings from "./settings";
import { useTranslation } from "react-i18next";
import useOrdersRoutes from "./orders";
import useSettingsRoutes from "./settings";
import Login from "../../components/Login-screens/Login";
import CreateProduct from "../../features/seller/products/ui/CreateProduct";
import AllProducts from "../../features/seller/products/ui/AllProducts";
import SupportTicket from "../../components/pages/SupportTicket";
import AllSupportTickets from "../../components/pages/AllSupportTickets";
import AllSellerWallets from "../../components/pages/AllSellerWallets";
import SellerWallet from "../../components/pages/SellerWallet";
import RechargeSellerWallet from "../../components/pages/RechargeSellerWallet";
import MyFatoorahPaymentResult from "../../components/pages/MyFatoorahPaymentResult";
import Register from "../../features/seller/register/ui";
import Security from "../../components/pages/settings/security";
import RequestRefund from "../../features/seller/wallet/refund/request-refund/ui/request-refund";
import ReviewRefunds from "../../features/admin/wallet/refunds/ui/review-refunds";
import RefundsStatus from "../../features/admin/wallet/refunds/ui/refunds";
import AllOrderTable from "../../features/seller/history/AllOrders";
import PriceList from "../../components/pages/ExtraOrders/price_list";
import Payments from "../../features/admin/wallet/refunds/ui/payments";
export function useAdminRoutes() {
    const { t } = useTranslation();
    return {
        common: {
            "/dashboard": {
                component: Dashboard,
                title: t("sideMenu.dashboard"),
                icon: HomeMajor,
            },
            "/delivery_range": {
                component: DeliveryRanges,
            },
        },
        accounts: {
            meta: {
                title: t("sideMenu.accounts"),
                icon: CustomersMajor,
            },
            "/create_account": {
                component: CreateAccount,
                title: t("sideMenu.createNewAcc"),
                rules: [41],
            },
            "/create_bulk_accounts": {
                component: BulkAccount,
                title: t("sideMenu.createBulk"),
                rules: [41],
            },
            "/all_accounts": {
                component: AccountsTabs,
                title: t("sideMenu.allAccounts"),
                rules: [44],
            },
            "/NewMarketers": {
                component: NewMarketers,
                title: t("sideMenu.newMarketer"),
                rules: [44],
            },
            
            "/archived_users": {
                component: ArchivedUsers,
                title: t("sideMenu.archived"),
                rules: [44],
            },
            "/user_details/:type/:id": {
                component: AccountInfo,
                rules: [44],
            },
            "/permissions": {
                component: PermissionsTabs,
                isOpen: true,
            },
        },
        orders: { ...useOrdersRoutes(), ...useAdminOrdersRoute() },
        extra_orders: {
            meta: {
                title: t("extra_orders"),
                icon: BalanceMajor,
            },
            "/create_extraorder": {
                component: CreateExtraOrder ,
                title: t("createExtraOrder"),
            },
            "/extra_orders": {
                component: ExtraOrdersList ,
                title: t("extra_orders"),
            },
            "/extra-order/:id": {
                component: ExtraOrderDetails,
                title : t("extra_orders"),
            },
        },

        supportTickets: {
            meta: {
                icon: TransactionFeeDollarMajor,
                title: t("sideMenu.supportTickets"),
            },
            "/create_support_ticket": {
                component: SupportTicket,
                title: t("sideMenu.createSupportTicket"),
                rules: [113],
            },
            "/all_support_tickets": {
                component: AllSupportTickets,
                title: t("sideMenu.allSupportTickets"),
                rules: [113],
            },
            "/archived_support_tickets": {
                component: AllSupportTickets,
                title: t("sideMenu.archivedSupportTickets"),
                rules: [113],
            },
        },
        wallet: {
            meta: {
                title: t("sideMenu.wallet"),
                icon: BalanceMajor,
            },
            "/sellers_wallets": {
                component: AllSellerWallets,
                title: t("sideMenu.sellers"),
                rules: [117, 118, 119, 120 ],
            },
            "/seller_wallet/:id": {
                component: SellerWallet,
                title: t("sideMenu.sellerWallet"),
                rules: [117, 118, 119, 120],
            },
            "/review_refunds": {
                component: ReviewRefunds,
                title: t("sideMenu.reviewRefunds"),
                rules: [117, 118, 119, 120 , 140],
            },
            "/refunds_status": {
                component: RefundsStatus,
                title: t("sideMenu.refundsStatus"),
                rules: [117, 118, 119, 120 , 140],
            },
            "/marketer_settle": {
                component: SettleRequests ,
                title: t("settleRequest"),
            },
            "/settle_status": {
                component: SettleRequestStatus ,
                title: t("settleRequestStatus"),
            },
            "/payments": {
                component: Payments,
                title: t("recharge"),
            },
        },
           Activity: {
            meta: {
                title: t("Activity"),
                icon: BalanceMajor,
            },
            "/h-orders/:id": {
                component: AllOrderTable,
                title : t("orders"),
            },
        },
        reports: {
            "/select_reports": {
                component: ReportsTypeSelection,
                title: t("sideMenu.reports"),
                icon: ReportsMajor,
                rules: [309 , 357],
            },
            "/branch_reports": {
                component: BranchReportsPage,
                rules: [309 , 357],
            },
            "/store_reports": {
                component: StoreReportsPage,
                rules: [309 , 357],
            },
        },
        settings: {
            ...useSettingsRoutes(),
            "/security": {
                component: Security,
            },
     
        },
        unused: {
            "/logs": {
            component: ActivityLogs,
            title: t("sideMenu.logs"),
            icon : AnalyticsMajor,
            },
            "/marketer_commission/:id": {
                component: AdminCommissionOrders ,
                title: t("commissionOrders"),
            },
        },
        unAuthed: {
            "/admin_forget_password": {
                component: ForgetPassword,
            },
            "/registeration": {
                component: Registeration,
            },
            "/registerationar": {
                component: RegisterationAr,
            },
            "/marketer-registeration": {
                component: MarketerRegisterForm,
            },
            "/admin_confirm": {
                component: ConfirmationCode,
            },
            "/admin_reset_password": {
                component: ResetPassword,
            },
            "/login": {
                component: Login,
            },
        },
    };
}

export function useSellerRoutes() {
    const { t } = useTranslation();
    return {
        common: {
            "/dashboard": {
                component: Dashboard,
                isOpen: true,
                title: t("sideMenu.dashboard"),
                icon: HomeMajor,
            },
        },
        products: {
            meta: {
                title: t("sideMenu.products"),
                icon: ProductsMajor,
            },
            "/add_product": {
                component: CreateProduct,
                title: t("sideMenu.createProduct"),
            },
            "/products": {
                component: AllProducts,
                title: t("sideMenu.allProducts"),
            },
        },
        orders: {
            meta: {
                title: t("orders"),
                icon: WholesaleMajor,
            },
            ...useSellerOrdersRoute(),
            ...useOrdersRoutes(),
        },
         // Extra Weight Orders Routes
         extra_orders: {
            meta: {
                title: t("extra_orders"),
                icon: BalanceMajor,
            },
            "/extra_orders": {
                component: ExtraOrdersList ,
                title: t("extra_orders"),
            },
            "/price_list": {
                component: PriceList ,
                title: t("price_list"),
            },
        },
        supportTickets: {
            meta: {
                icon: TransactionFeeDollarMajor,
                title: t("sideMenu.supportTickets"),
            },
            "/create_support_ticket": {
                component: SupportTicket,
                title: t("sideMenu.createSupportTicket"),
            },
            "/all_support_tickets": {
                component: AllSupportTickets,
                title: t("sideMenu.allSupportTickets"),
            },
            "/archived_support_tickets": {
                component: AllSupportTickets,
                title: t("sideMenu.archivedSupportTickets"),
            },
        },
        wallet: {
            meta: {
                title: t("sideMenu.wallet"),
                icon: BalanceMajor,
            },
            "/seller_wallet": {
                component: SellerWallet,
                title: t("sideMenu.sellerWallet"),
            },
            "/recharge_seller_wallet": {
                component: RechargeSellerWallet,
                title: t("sideMenu.rechargeSellerWallet"),
            },
            "/fatoorah-payment-result/:result": {
                component: MyFatoorahPaymentResult,
                title: t("sideMenu.fatoorahPaymentResult"),
            },
            "/request_refund": {
                component: RequestRefund,
                title: t("sideMenu.requestRefund"),
            },

          
        },
        
        settings: {
            ...useSettingsRoutes(),
            "/security": {
                component: UpdatePassword,
            },
        },
        unAuthed: {
            "/seller-login": {
                component: Login,
            },
            "/forget_password": {
                component: ForgetPassword,
            },
            "/registeration": {
                component: Registeration,
            },
            "/registerationar": {
                component: RegisterationAr,
            },
            "/confirm": {
                component: ConfirmationCode,
            },
            "/reset_password": {
                component: ResetPassword,
            },
            "/register": {
                component: Register,
            },
        },
    };
}
export function useMarketerRoutes() {
    const { t } = useTranslation();
    return {
        common: {
            "/dashboard": {
                component: Dashboard,
                isOpen: true,
                title: t("sideMenu.dashboard"),
                icon: HomeMajor,
            },
            "/sellers-stores": {
                component: SellerStores ,
                isOpen: true,
                title: t("Seller Stores"),
                icon: HomeMajor,
            },
        },
        wallet: {
            meta: {
                title: t("sideMenu.wallet"),
                icon: BalanceMajor,
            },
            "/marketer_wallet": {
                component: CommissionOrders ,
                title: t("commissionOrders"),
            },
            "/marketer_settle": {
                component: SettleRequests ,
                title: t("settleRequest"),
            },
            "/settle_status": {
                component: SettleRequestStatus ,
                title: t("settleRequestStatus"),
            },
            "/marketer_balance": {
                component: WalletSummary ,
                title: t("sideMenu.balance"),
            },
        },
        settings: {
            ...useSettingsRoutes(),
            "/security": {
                component: UpdatePassword,
            },
            
           
        },
        unAuthed: {
            "/seller-login": {
                component: Login,
            },
            "/forget_password": {
                component: ForgetPassword,
            },
            "/registeration": {
                component: Registeration,
            },
            "/registerationar": {
                component: RegisterationAr,
            },
            "/confirm": {
                component: ConfirmationCode,
            },
            "/reset_password": {
                component: ResetPassword,
            },
            "/register": {
                component: Register,
            },
        },
    };
}
