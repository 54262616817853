import React, { useEffect, useState } from "react";
import styles from "../OrderChainDetails.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import OrderTag from "../OrderTag";
import { Collapse } from "antd";
import { NavLink, useParams } from "react-router-dom";
import SeeMoreButton from "../../../SeeMoreButton";
import TablePro from "../../../tables/TablePro";
import OrderChainHeader from "../OrderChainHeader";
import OrderChainRepository from "../../../../api/OrderChainRepository";
import TicketChainDetails from "../TicketChain";
import axiosInstance from "../../../../api/axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSelector } from "react-redux";
import AccountTypeForm from "../../../../features/admin/accounts/create/accoutTypeForm";
import AccountPersonalInfoForm from "../../../../features/admin/accounts/create/personal-info/accountPersonalInfoForm";
import DetailsTab from "./DetailsTab";
import ClientDetails from "./ClientDetails";
import MerchantDetails from "./MerchantDetails";
import OrderDetails from "./OrderDetails";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function OrderChainDetails() {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const user = useSelector((state) => state.auth.user);
    const [tab, setTab] = useState(0);

    const { id } = useParams();

    async function fetchOrder() {
        const res = await axiosInstance.get(`/Order/${id}`);

        if (res.data) setData(res.data);
    }

    useEffect(() => {
        fetchOrder();
    }, []);
    return (
        <Container className={styles.container}>
            <OrderChainHeader id={id} />
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={tab}
                    aria-label="basic tabs example"
                    className={`${
                        user.role_id === "Seller" ? "seller" : "admin"
                    }`}
                >
                    <Tab
                        label={t("orderDetails")}
                        onClick={() => setTab(0)}
                        {...a11yProps(0)}
                    />
                    <Tab
                        label={t("clientInfo")}
                        onClick={() => setTab(1)}
                        {...a11yProps(1)}
                    />
                    <Tab
                        label={t("sellerInfo")}
                        onClick={() => setTab(2)}
                        {...a11yProps(2)}
                    />
                </Tabs>
            </Box>
            <TabPanel value={tab} index={0}>
                <OrderDetails data={data} />
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <ClientDetails data={data} />
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <MerchantDetails data={data} />
            </TabPanel>
        </Container>
    );
}
