import React, { useEffect, useMemo, useState } from "react";
import Header from "./Header";
import TicketScanRepository from "../data";
import { useParams } from "react-router-dom";
import ErrorHandler from "../../../../error-handler/ErrorHandler";
import axiosInstance from "../../../../api/axios";
import { useTranslation } from "react-i18next";
import ErrorPage from "../../../../components/NotFound";
import { Form, Input, Progress } from "antd";
import WMButton from "../../../../components/pages/AllWorkModels/WMButton";
import scannerListener from "../domain/scanner-listener";
import TicketsTable from "./TicketsTable";
import { Col, Row } from "react-bootstrap";
import styles from "./TicketsScan.module.css";
import ScanProgress from "./ScanProgress";
import {
    SuccessfulRequest,
    UnSuccessfulRequest,
} from "../../../../components/modals/confirmationModal";
import useLanguage from "../../../../hooks/useLanguage";
import { useHistory } from "react-router-dom";

function TicketScanSession() {
    const { id } = useParams();
    const { t } = useTranslation();
    const history = useHistory();
    const [runSheet, setRunSheet] = useState({
        found: true,
        data: {
            details: {},
            tickets: [],
        },
    });
    const language = useLanguage();
    const [ticketsStates, setTicketsStates] = useState({});
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState();
    const [error, setError] = useState();
    const courierDetails = [
        {
            title: t("couriername"),
            value: runSheet.data?.courier?.user.name,
        },
        {
            title: t("courierBranch"),
            value: runSheet.data?.courier?.branch.name,
        },
        {
            title: t("courierVehicle"),
            value: runSheet.data?.courier?.vehicle,
        },
        // {
        //     title: t("branch"),
        //     value: runSheet.data?.branch,
        // },
    ];

    async function fetchActiveRunSheet() {
        const res = await TicketScanRepository.getActiveRunSheet(id);

        if (res.success) {
            console.log(res.data);
            setRunSheet(res.data);
        }
    }

    async function checkTicketAvailability(ticket) {
        return runSheet.data.tickets.find((it) => it.id.toString() === ticket);
    }

    function markTicketAsScanned(ticket) {
        setTicketsStates((prev) => ({
            ...prev,
            [ticket]: {
                state: TicketScanRepository.scanStates.scanned,
            },
        }));
    }

    async function confirmScan() {
        const res = await TicketScanRepository.confirmScan(id, ticketsStates);
        setSuccess(res.success);
        if (res.error) setError(res.error);
    }

    useEffect(() => {
        setLoading(true);
        fetchActiveRunSheet().then(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (runSheet.data.tickets.length === 0) return;
        const cancel = scannerListener((ticket) => {
            checkTicketAvailability(ticket).then((isFound) => {
                if (!isFound) return;
                markTicketAsScanned(ticket);
            });
        });

        return () => cancel();
    }, [runSheet.data.tickets.length]);
    return (
        <main>
            <Header />
            <section>
                {runSheet.found || true ? (
                    <div>
                        <Form layout="vertical" className="grid-form">
                            {courierDetails.map((it) => (
                                <Form.Item label={it.title} key={it.title}>
                                    <Input
                                        value={it.value}
                                        disabled
                                        className="lighter"
                                    />
                                </Form.Item>
                            ))}
                        </Form>
                        <Form
                            layout="vertical"
                            className="grid-form"
                            style={{
                                marginTop: "32px",
                            }}
                            onFinish={(data) => {
                                const ticket = data.ticket;
                                checkTicketAvailability(ticket).then(
                                    (isFound) => {
                                        if (!isFound) return;
                                        markTicketAsScanned(ticket);
                                    }
                                );
                            }}
                        >
                            <Form.Item label={t("ticket")} name={"ticket"}>
                                <Input />
                            </Form.Item>
                        </Form>
                        <Row className={styles.ticketActions}>
                            <ScanProgress
                                ticketsStates={ticketsStates}
                                runSheet={runSheet}
                            />
                            <Col
                                className={styles.buttonActionContainer}
                                md={6}
                                sm={12}
                            >
                                <WMButton onClick={() => confirmScan()}>
                                    {t("endScanSession")}
                                </WMButton>
                            </Col>
                        </Row>
                        <TicketsTable
                            loading={loading}
                            markTicketAsScanned={markTicketAsScanned}
                            data={runSheet.data.tickets}
                            ticketsStates={ticketsStates}
                        />
                    </div>
                ) : (
                    <ErrorPage>{t("noRunsheet")}</ErrorPage>
                )}
            </section>
            <SuccessfulRequest
                show={success === true}
                title={t("ticketScan")}
                body={
                    language.includes("en")
                        ? `You have recieved ${
                              Object.keys(ticketsStates).length
                          } tickets from courier ${
                              runSheet.data.courier?.user?.name
                          } with ID ${runSheet.data.courier?.id}`
                        : `لقد تم استلام عدد ${
                              Object.keys(ticketsStates).length
                          } بوليصة من التاجر ${
                              runSheet.data.courier?.name
                          } برقم ${runSheet.data.courier?.id}`
                }
                hide={() => {
                    setSuccess(undefined);
                    history.push("/");
                }}
            />
            <UnSuccessfulRequest
                show={success === false}
                title={t("ticketScan")}
                body={error}
                hide={() => setSuccess(undefined)}
            />
        </main>
    );
}

export default ErrorHandler(TicketScanSession, axiosInstance);
