import { SET_DIMENTIONS } from "../actions/actionTypes";
const initialState = {
    diementions: "",
};

export default function (state = initialState, action) {
    //  (action.payload);
    switch (action.type) {
        case SET_DIMENTIONS:
            return {
                ...state,
                diementions: action.payload,
            };
        default:
            return state;
    }
}
