import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Input } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../../../../api/axios';
import useLanguage from '../../../../hooks/useLanguage';
import { useTranslation } from 'react-i18next';
import TablePro from '../../../tables/TablePro';



const SellerStores = () => {
  const { t } = useTranslation();
  const lang = useLanguage();
  const marketerID = useSelector((state) => state.auth.user.user.id);
  const marketerName = useSelector((state) => state.auth.user.user.name);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [columns, setColumns] = useState([
    {
      title: t('sellerId'),
      dataIndex: ['seller_id'],
      key: 'id',
      render: (id) => (
          <span className="tableID">
              {id}
          </span>
      ),
  },
  {
    title: t('seller'),
    dataIndex: ['seller_name'],
    key: 'seller_name',
  },
  {
      title: t('store'),
      dataIndex: ['store_name'],
      key: 'store_name',
  },
  ]);

  const handleSearch = (value) => {
    setSearchText(value);
    setPage(1); // Reset pagination when performing a new search
  };

  const fetchSellerData = async () => {
    
    setLoading(true);
    try {
      const result = await axiosInstance.get(`/marketer-orders/${marketerID}/`, {
        params: {
          page,
          page_size: 10,
          seller_id: searchText, // Pass seller_id as a query parameter for search
        },
      });

      if (result) {
        setData(
          result.data.seller_names.map((sellerName, index) => ({
            seller_id: result.data.seller_ids[index],
            seller_name: sellerName,
            store_name: result.data.store_name[index],
          }))
        );
      }
    } catch (err) {
      console.error('Error fetching seller data:', err);
      toast.error('Failed to fetch seller data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSellerData();
  }, [page, searchText]);

  const memoizedColumns = useMemo(() => columns.map((col) => ({ ...col })), []);

  return (
    <>
      <Row className="mb-4">
        <Col sm="12">
          <span className="title1">{marketerName}</span>
          <i className="fas fa-chevron-right px-3 title2"></i>
          <span className="title2">{t('sideMenu.sellers')}</span>
        </Col>
        <Col sm="12">
          <h2 className="pageTitle">{t('sideMenu.sellers')}</h2>
        </Col>

        <ToastContainer />
        <Col></Col>
        <Col>
          <Input.Search
            placeholder={t('sellerId')}
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
            style={{ marginBottom: 10 }}
          />
        </Col>
      </Row>

      <div className="reqTableContainer">
        <div className="tableHeader">
          <h6>{t('orderDetails')}</h6>
        </div>

        <div className="requestTable" dir={lang === 'en' ? 'ltr' : 'rtl'}>
          <TablePro
            columns={memoizedColumns}
            dataSource={data}
            pagination={{
              current: page,
              total: data.length,
              onChange: (page) => setPage(page),
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SellerStores;
