import React, { useEffect, useState } from "react";
import { Container, Row, Col, Dropdown , Table , Pagination} from "react-bootstrap";
import { DatePicker, Select  , Input} from "antd";
import { DataGrid } from "@mui/x-data-grid";
import "../../style-sheets/table.css";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { logsColumns, logsColumns_ar } from "../layout/tableColumns";
import { useSelector } from "react-redux";
import { SettingsCellOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";

const { RangePicker } = DatePicker;
const ActivityLogs = () => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [selectedDate, setSelectedDate] = useState([]);
  const [actionType, setAction] = useState(null);
  const { Option } = Select;
  const user = useSelector((state) => state.auth.user);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  const handleActionType = (value) => {
    setAction(value);
  };
  function onChange(value, dateString) {
    console.log(dateString)
    setSelectedDate(dateString);
    if(dateString[0] === '' && dateString[1] === ''){
      console.log("here")
      setData([])
      setCount(1)
    }
  }
  useEffect(() => {
    console.log(actionType);
  }, [actionType]);

  const getLogs = async () => {
    let result = await axiosInstance.get(
      `/ActivityLog/?action=${actionType}&history_user=${user.user.id}&start=${selectedDate[0]}&end=${selectedDate[1]}&page=1`
    );
    console.log(result);
    if (result) {
      setData(result.data.data);
      setCount(result.data.count);
    }
  };
  const onpageChangeHandle = async (page) => {
    setPage(page);
    let result = await axiosInstance.get(
      `/ActivityLog/?action=${actionType}&history_user=${user.user.id}&start=${
        selectedDate[0]
      }&end=${selectedDate[1]}&page=${page + 1}`
    );
    console.log(result.data.data);
    setData(result.data.data);
    setCount(result.data.count);
  };
  const lang = useLanguage();
  

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
    setIsSearching(false);
  };

  const handleSearchSubmit = () => {
    setIsSearching(true);
  };

  const filterData = () => {
    if (searchQuery.trim() === "") {
      setFilteredData(data);
    } else {
      const lowerCaseQuery = searchQuery.toLowerCase();
      const filtered = data.filter((item) => {
        
        const columnValue = item.user.name.toLowerCase();
        return columnValue.includes(lowerCaseQuery);
      });
      setFilteredData(filtered);
    }
  };
  useEffect(() => {
   
  
    filterData();
  }, [isSearching, searchQuery, data]);

  const [gridColumns, setGridColumns] = useState(logsColumns); // Initial columns

// Update columns when language changes
useEffect(() => {
  if (lang === 'ar') {
    setGridColumns(logsColumns_ar);
  } else {
    setGridColumns(logsColumns);
  }
  
}, [lang]);

const [currentPage, setCurrentPage] = useState(1);
const [pageSize, setPageSize] = useState(10); // Set your desired page size here

// Calculate start and end indexes based on pagination
const indexOfLastLog = currentPage * pageSize;
const indexOfFirstLog = indexOfLastLog - pageSize;
const currentLogs = filteredData.slice(indexOfFirstLog, indexOfLastLog);

// Update the rendering logic to use currentLogs for displaying data
// ...

// Function to handle page change
const handlePageChange = (pageNumber) => {
  setCurrentPage(pageNumber);
};

// Pagination component
const paginationItems = [];
for (let number = 1; number <= Math.ceil(filteredData.length / pageSize); number++) {
  paginationItems.push(
    <Pagination.Item
      key={number}
      active={number === currentPage}
      onClick={() => handlePageChange(number)}
    >
      {number}
    </Pagination.Item>
  );
}

  return (
    <div>
      <Container >
        <Row>
          <Col sm="12">
            <h2 className="pageTitle">{t("sideMenu.logs")}</h2>
          </Col>
        </Row>
        <Row>
          <Col  sm="12" md="8">
            <RangePicker placeholder={lang === "en" ? ["Start Date" , "End Date"] : ["تاريخ البداية" , "تاريخ الإنتهاء"]} format="YYYY-MM-DD" onChange={onChange} />

            <Select
              onChange={handleActionType}
              value={actionType}
              style={{ width: 140 }}
              placeholder={t("selectAction")}
            >
             {/* <Option name="runsheet" value="runsheet">
                {t("sideMenu.runsheets")}
              </Option>
              <Option name="ticket" value="ticket">
                {t("sideMenu.tickets")}
              </Option>
              */}
              <Option name="sellers" value="seller">
                {t("sideMenu.sellers")}
              </Option>
              <Option name="refunds" value="refunds">
                {t("sideMenu.refund")}
              </Option>
              <Option name="accounts" value="employee">
                {t("sideMenu.accounts")}
              </Option>
            </Select>
          </Col>
          <Col style={{display : "flex" , justifyContent : "flex-end"}} sm="12" md="4">
            <button
              disabled={actionType == null || (selectedDate == "" || (selectedDate[0] === '' || selectedDate[1] === '')) ? true : false}
              className="confirm"
              onClick={getLogs}
            >
              {t("viewResults")}
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
          
          <Input
            type="text"
            value={searchQuery}
            style={{ width: 200 }}
            onChange={handleSearchQueryChange}
            placeholder="Search by user name"
            disabled={isSearching}
          />
         
           </Col>
        </Row>
        <Row>
        <div  className="requestTable rs mt-4">



       {/* <DataGrid
              getRowId={(r) => r.history_id}
              rows={filteredData}
              columns={lang == "ar" ? logsColumns_ar : logsColumns}
              //   disableSelectionOnClick
              pagination
              paginationMode="server"
              rowCount={count}
              pageSize={10}
              rowsPerPageOptions={[10]}
              //   checkboxSelection
              //   selectionModel={selectionModel}
              onPageChange={onpageChangeHandle}
              //   onSelectionModelChange={(ids) => {
              //     setChanged(!changed);
              //     setSelectionModel(ids);
              //     if (ids.length > 1) {
              //       const selectionSet = new Set(selectionModel);
              //       const result = ids.filter((s) => !selectionSet.has(s));
              //       const selectedRowData = sheets.filter((row) =>
              //         selectionSet.has(row.id)
              //       );
              //       setSelectedRs(selectedRowData);
              //       console.log(selectedRowData);
              //       setSelectionModel(result);
              //     } else {
              //       const selectionSet = new Set(ids);
              //       const selectedRowData = sheets.filter((row) =>
              //         selectionSet.has(row.id)
              //       );
              //       setSelectedRs(selectedRowData);
              //       setSelectionModel(ids);
              //     }
              //   }}
              //   isRowSelectable={(params) =>
              //     params.row.state == "3" || params.row.state == "2"
              //   }
              //   checkboxSelection
            />
            */}
          <Table striped bordered hover>
              <thead>
                <tr>
                  {gridColumns.map((column) => (
                    <th key={column.field}>{column.headerName}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredData.map((row) => (
                  <tr key={row.history_id}>
                    {gridColumns.map((column) => (
                      <td key={column.field}>
                        {column.renderCell({ row })} {/* Assuming renderCell function works independently */}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
              
            </Table>
             {/* Pagination component */}
          <Pagination>
            {paginationItems}
          </Pagination>

            {" "}
            {data.length !== 0 ? null : (
              <div className="noDataDiv">
                <h6 className="noDataHead">{t("logsInfo1")}</h6>
                <p>{t("logsInfo2")}</p>
                {/* <button className="confirm">Refresh</button> */}
              </div>
            )}
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ErrorHandler(ActivityLogs, axiosInstance);
