import { GET_SELLER , GET_CLIENT,GET_TICKETS_RETURNED,SELECTED_USER,TICKET_PRINTED} from './actionTypes'

export const getSeller = (info) => {

    return {
        type: GET_SELLER,
        payload: info
    }

}

export const getClient = (info)=>{
    return {
        type : GET_CLIENT,
        payload : info
    }
}

export const getTicketsReturned = (info)=>{
    return {
        type : GET_TICKETS_RETURNED,
        payload : info
    }
}

export const getSelectedUser = (info)=>{
    return {
        type : SELECTED_USER,
        payload : info
    }
}

export const getTicketPrintedData = (info)=>{
    return {
        type : TICKET_PRINTED,
        payload : info
    }
}