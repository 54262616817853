import DetailsTab from "./DetailsTab";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useLanguage from "../../../../hooks/useLanguage";

export default function OrderDetails({ data }) {
    const { t } = useTranslation();
    console.log({ data });
    const ticket = data?.tickets?.data?.[0] ?? {};
    const cur = useSelector(
        (state) => state.auth.user.user?.organization.country.currency
    );
    const dateParts = data.create_date?.split("T") ?? [];
    const lang = useLanguage();
    return (
        <DetailsTab
            items={[
                {
                    label: t("createDate"),
                    value: `${dateParts[0]} ${dateParts[1]?.substr(0, 5)}`,
                },
                {
                    label: t("shipmentState"),
                    value: lang.includes("en")
                        ? ticket.state?.title
                        : ticket.state?.title_ar,
                },
                {
                    label: t("shipmentProvider"),
                    value: data.shipment_order?.provider_name,
                },

                {
                    label: t("itemQuantity"),
                    value: ticket.quantity,
                },
                {
                    label: t("dimensions"),
                    value: `${ticket.length}x${ticket.width}x${ticket.height}`,
                    hidden: !ticket.length,
                },
                {
                    label: t("specialLabel"),
                    value: ticket.special_label,
                },
                {
                    label: t("orderValue"),
                    value: `${data.sub_total} ${cur}`,
                },
                {
                    label: t("shipmentFees"),
                    value: `${data.delivery_fees} ${cur}`,
                },

            ]}
        />
    );
}
