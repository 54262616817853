import React from "react";
import styles from "./BulkAccount.module.css";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InformationCard from "../../layout/informationCard";

export default function BulkAccount({}) {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.accounts")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("sideMenu.createBulk")}</span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("sideMenu.createBulk")}</h2>
                </Col>
            </Row>

            <div>
                <InformationCard
                    title={t("bulkAccountTitle")}
                    subTitle={t("bulkAccountInfo")}
                    list={[
                        <p>{t("bulkList1")}</p>,
                        <p>{t("bulkList2")}</p>,
                    ]}
                />
            </div>
        </div>
    );
}
