import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navbar, Container, NavDropdown } from "react-bootstrap";
import img from "../../images/Avatar.png";
import arrow from "../../images/Vector.svg";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { LogOut } from "../../global-state/actions/authAction";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../contexts/LanguageContext";
import { useSelector } from "react-redux";
import logo from "../../images/box.png";
import logo2 from "../../images/box.png";
import logo3 from "../../images/box.png";
import logo4 from "../../images/box.png";
import { CircleDownMajor } from "@shopify/polaris-icons";
import { Icon } from "@shopify/polaris";
import axiosInstance, { BASE_URL } from "../../api/axios";
import useLanguage from "../../hooks/useLanguage";
import { NavLink, useHistory } from "react-router-dom";
import ErrorHandler from "../../error-handler/ErrorHandler";

function LanguageItem({ language, code }) {
    const { t, i18n } = useTranslation();
    const { setLanguage } = useLanguageContext();
    return (
        <NavDropdown.Item
            onClick={() => {
                setLanguage(code);
                localStorage.setItem("lang", code);
            }}
        >
            {language}
        </NavDropdown.Item>
    );
}

const Header = () => {
    const org = useSelector((state) => state.auth.user.org);
    const dispatch = useDispatch();
    const history = useHistory();
    const logout = () => {
        dispatch(LogOut());
        history.push("/login");
        window.location.reload();
    };
    const { t } = useTranslation();
    const user = useSelector((state) => state.auth.user);
    const lang = useLanguage();
    const [wallet, setWallet] = useState({});

    async function getSellerWallet() {
        const response = await axiosInstance.get("/SellerWallet/");
        if (response.data) {
            setWallet(response.data);
        }
    }

    const { refresh } = useLanguageContext();
    useEffect(() => {
        getSellerWallet();
    }, [refresh]);
    return (
        <div>
            <Navbar className="header">
                <Container fluid>
                    <Navbar.Brand href="/dashboard">
                        <div className={"navbar-brand"}>
                            {org?.logo !== null ? (
                                <img
                                    src={BASE_URL + org.logo}
                                    alt=""
                                    className="img-fluid logo logoAr"
                                />
                            ) : user.role == "Seller" ? (
                                lang == "en" ? (
                                    <img
                                        src={logo2}
                                        alt=""
                                        className="img-fluid logo"
                                    />
                                ) : (
                                    <img
                                        src={logo4}
                                        alt=""
                                        className="img-fluid logo"
                                    />
                                )
                            ) : lang == "en" ? (
                                <img
                                    src={logo}
                                    alt=""
                                    className="img-fluid logo"
                                />
                            ) : (
                                <img
                                    src={logo3}
                                    alt=""
                                    className="img-fluid logo logoAr"
                                />
                            )}
                            <div className="header-extra">
                                <div className="header-welcome">
                                    {t("welcome")}{" "}
                                    <span>{user?.user?.name}</span>
                                </div>
                                {user.role !== "Marketer" ?
                                <div className="header-links">
                                    <NavLink to={"/create_order"}>
                                        {t("sideMenu.createNewTicket")}
                                    </NavLink>
                                    <NavLink
                                        to={
                                            "/dashboard?details=true&search=true"
                                        }
                                    >
                                        {t("sideMenu.track")}
                                    </NavLink>
                                </div> : null } 
                            </div>
                            {/* {user.role === "Seller" && (
                                <div className={"header-wallet"}>
                                    <span>{t("headerCredit")}</span>
                                    <div className={"header-wallet-number"}>
                                        <span>
                                            {wallet.summary?.balance ?? 0}
                                        </span>
                                        <NavLink to={"/recharge_seller_wallet"}>
                                            {t("recharge")}
                                        </NavLink>
                                    </div>
                                </div>
                            )} */}
                           
                            {user.role === "Seller" && (
                                <div className={"header-wallet"}>
                                    <span>{t("headerCredit")}</span>
                                    <div className={"header-wallet-number"}>
                                        {wallet.summary?.balance !== undefined ? (
                                            <>
                                                <span>{wallet.summary?.balance ?? 0}</span>
                                                <NavLink to={"/recharge_seller_wallet"}>
                                                    {t("recharge")}
                                                </NavLink>
                                            </>
                                        ) : (
                                            <Spin
                                                indicator={
                                                <LoadingOutlined
                                                    style={{
                                                    fontSize: 24,
                                                    color: '#5c55a5',
                                                    }}
                                                    spin
                                                />
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </Navbar.Brand>

                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        {/* <NavDropdown
              className=""
              title={t("currentLang")}
            >
              <LanguageItem language={t("ar")} code={"ar"} />
              <LanguageItem language={t("en")} code={"en"} />
            </NavDropdown> */}
                        {/* <img
                            src={img}
                            alt=""
                            className={`img-fluid ${
                                user.role == "Seller"
                                    ? "SellerprofileImg"
                                    : "profileImg"
                            } `}
                        />{" "} */}
                        <NavDropdown
                            title={
                                <div
                                    style={{
                                        display: "inline-flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginTop: "0.5rem",
                                    }}
                                >
                                    <img
                                        src={img}
                                        alt=""
                                        className={`img-fluid ${
                                            user.role == "Seller"
                                                ? "SellerprofileImg"
                                                : "profileImg"
                                        } `}
                                    />
                                    <div
                                        style={{
                                            display: "inline-block",
                                            margin: "0 0.25rem 0 1rem",
                                        }}
                                    >
                                        {user?.user?.name}
                                    </div>
                                    <div style={{ height: "30px" }}>
                                        <Icon
                                            source={CircleDownMajor}
                                            color="base"
                                        />
                                    </div>
                                </div>
                            }
                            id="basic-nav-dropdown"
                        >
                            <NavDropdown.Item href="/settings">
                                {t("sideMenu.settings")}
                            </NavDropdown.Item>
                            {/* <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item> */}

                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={logout}>
                                {t("logout")}
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
};

export default ErrorHandler(Header, axiosInstance);
