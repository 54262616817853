import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {Spinner} from "react-bootstrap";

function CreateOrderLoading({ show }) {
    const { t } = useTranslation();

    return (
        <Modal
            show={show}
            size="md"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t("Creating order")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column justify-content-center align-items-center py-5 gap-3">
                    <Spinner animation="border" variant="success" />
                    <span>
                        {
                            t("Please be patient, the order is being created")                        }
                    </span>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default CreateOrderLoading;