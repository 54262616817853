import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import "../../../../../style-sheets/form.css";
import { Select, Form as AntForm, Input } from "antd";
import {
    PaginatedSelect,
    Radio,
    TextField,
} from "../../../../../components/form/TextInputFields";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../../../../../api/axios";
import ModifiedForm from "../../../../../components/form/ModifiedForm";
import { addAccount } from "../../../../../global-state/actions/addAccountAction";
import {
    AccountConfirmationModal,
    CreationConfirmationModal,
    PaymentAlert,
} from "../../../../../components/modals/confirmationModal";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { SHOW_ACCOUNT_CREATION_ERROR } from "../../../../../global-state/actions/actionTypes";
import useLanguage from "../../../../../hooks/useLanguage";
import FieldsList from "../../../../../components/FieldsList";
import { addOrder } from "../../../../../global-state/actions/addOrderAction";
import SelectSearch from "../../../../../components/select-search/SelectSearch";
import SelectAreas from "../../../../../components/select-search/AreasSelect";
import PhoneInput, { prefix } from "../../../../../components/phone-input";

export default function SellerPersonalInfo({ value, handleChange, onBack }) {
    const history = useHistory();

    const account = useSelector((state) => state.account);
    const errors = useSelector((state) => state.account.errors) ?? "";
    const dispatch = useDispatch();
    const [workModels, setWorkModeals] = useState([]);
    const [next, SetNext] = useState("");
    const [loadMore, setLoadMore] = useState(true);
    const [workModelAreas, setWorkModelAreas] = useState([]);
    const [cities, setCities] = useState([]);
    const [branches, setBranches] = useState([]);
    const [areas, setAreas] = useState([]);
    const [selectedZone, setSelectedZone] = useState([]);
    const [show, setShow] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState([]);
    const [selectedArea, setSelectedArea] = useState([]);
    const [ID, setID] = useState("");
    const [addressErr, setAddressErr] = useState("");
    const [password, setPassword] = useState("");
    const [selectedAddress, setSelectedAddress] = useState([]);
    const [selectedModel, setSelectedModel] = useState([]);
    const [addresses, setAddresses] = useState([
        {
            address: "",
            branch: "1",
            //   city: "",
            area: "",
            new_work_model: "",
        },
    ]);
    const [invitationcode , setInvitaionCode] = useState('');

    const { t } = useTranslation();
    console.log({ account });
    const getErrors = (e) => {
        console.log(e);
        dispatch({
            type: SHOW_ACCOUNT_CREATION_ERROR,
            payload: e,
        });
    };
    const showModal = () => {
        setShow(true);
    };

    const showConfirmModal = () => {
        setShowConfirm(true);
    };

    const hide = () => {
        setShow(false);
    };

    const hideConfirm = () => {
        setShowConfirm(false);
        history.push("/all_accounts");
    };

    const handleInput = (e, index) => {
        const { name, value } = e.target;
        const list = [...addresses];
        list[index][name] = value;
        const addressesList = [...selectedAddress];
        addressesList[index] = e.target.value;
        setSelectedAddress(addressesList);
        setAddresses(list);
    };

    const handleSelectedZone = (e, i) => {
        const list = [...addresses];
        list[i].city = e;
        const copy = [...selectedZone];
        copy[i] = e;
        setSelectedZone(copy);
    };

    const handleSelectedArea = (e, i) => {
        const list = [...addresses];
        list[i].area = e;
        const copy = [...selectedArea];
        copy[i] = e;
        setSelectedArea(copy);
        setAddresses(list);
    };

    const handleSelectedBranch = (e, i) => {
        const list = [...addresses];
        list[i].branch = e;
        const copy = [...selectedBranch];
        copy[i] = e;
        console.log(e);
        console.log(copy);
        setSelectedBranch(copy);
        setAddresses(list);
    };

    useEffect(() => {
        console.log(selectedBranch);
    }, [selectedBranch]);
    const addAnotherAddress = () => {
        const addressList = [...addresses];
        setAddresses([
            ...addressList,
            {
                address: "",
                branch: "1",
                // city: "",
                area: "",
                new_work_model: "",
            },
        ]);
    };
    const getAreas = async (workModelId, i) => {
        console.log({
            workModelId,
            workModels,
            i,
            workModelsI: workModels[i],
            new_work_model: workModels[i]?.find((it) => it.id == workModelId),
        });
        let result = await axiosInstance
            .get(`/FilterAreas/`, {
                params: {
                    page_size: 100000,
                },
            })
            .catch((err) => console.log(err));

        setAreas(result?.data?.data);
    };
    const validate = (data) => {
        console.log(`Form Data = ${data}`);
        // console.log(formState.isValid)
          
        const shipmentProviderPrices = shipmentProviders.map((provider) => ({
            shipment_provider: provider.id,
            price: parseFloat(provider.price),
        }));
    
        if (
            value === 1 &&
            (selectedArea.length === 0 || selectedAddress.length === 0)
        ) {
            setAddressErr(t("addressErr"));
        } else {
            setAddressErr("");
            console.log(addresses);
            data.seller_addresses = addresses;
            console.log(shipmentProviderPrices);
            data.shipmentProviders = shipmentProviderPrices;
            console.log(data);
            data.marketer_invitation_code = invitationcode;
            console.log(data.marketer_invitation_code);

            if (value === 1) {
                dispatch(addAccount(data));
                dispatch({ type: SHOW_ACCOUNT_CREATION_ERROR, payload: "" });
                handleChange(undefined, value + 1);
            } else {
                showModal();
            }
        }

        // handleChange(null, value + 1)
    };

    const getWorkModels = async (selectedBranch) => {
        const result = await axiosInstance
            .get(`/NewWorkModel/?branch=${selectedBranch}&page_size=10000`)
            .catch((err) => console.log(err));

        return result.data.data;
    };

    //   useEffect(() => {
    //     // setWorkModeals([]);
    //     getWorkModels();
    //     getAreas();
    //     // return () => setWorkModeals([]);
    //   }, [selectedBranch]);
    const getBranches = async (e, i) => {
        const result = await axiosInstance
            .get("/Branch/?page_size=100000")
            .catch((err) => console.log(err));
        if (result) {
            setBranches(result.data.data);
            SetNext(result.data.next);
        }
    };
    const [shipmentProviders, setShipmentProviders] = useState([]);
    const getShipmentProviders = async () => {

        let result = await axiosInstance
            .get(`/client_shipment_service_provider/`)
            .catch((err) => console.log(err));
        if (result) {
            setShipmentProviders(result.data.data);
        }
    };

    useEffect(() => {
        getBranches();
        getAreas();
        getShipmentProviders();
        
        console.log(account);
    }, []);

    const getCities = async (e, i) => {
        console.log(i);
        console.log(selectedBranch[i]);
        const result = await axiosInstance
            .get("/City/?page_size=100000")
            .catch((err) => console.log(err));
        if (result) {
            setCities(result.data.data);
            SetNext(result.data.next);
        }
    };

    // const getAreas = async (e, i) => {
    //     console.log(i);
    //     console.log(selectedZone[i]);
    //     const result = await axiosInstance
    //         .get(
    //             `/FilterAreas/?page_size=100000&city=${
    //                 branches.find((b) => b.id === selectedBranch[i]).city
    //             }`
    //         )
    //         .catch((err) => console.log(err));
    //     if (result) {
    //         setAreas(result.data.data);
    //         SetNext(result.data.next);
    //     }
    // };

    const onScroll = async (event, link) => {
        const target = event.target;
        let result;
        if (
            loadMore &&
            target.scrollTop + target.offsetHeight === target.scrollHeight
        ) {
            result = await axiosInstance
                .get(`${next}`)
                .catch((err) => console.log(err));

            if (result) {
                setWorkModeals([...workModels, ...result.data.data]);
                SetNext(result.data.next);
                if (result.data.next === null) {
                    setLoadMore(false);
                }
            }
        }
    };

    const back = () => {
        handleChange(null, value - 1);
    };
    const deleteAddress = (e, i) => {
        const list = [...addresses];
        const branches = [...selectedBranch];
        const zones = [...selectedZone];
        const areas = [...selectedArea];

        branches.splice(i, 1);
        zones.splice(i, 1);
        areas.splice(i, 1);
        list.splice(i, 1);

        setSelectedBranch(branches);
        setSelectedZone(zones);
        setSelectedArea(areas);
        setAddresses(list);
    };

    const lang = useLanguage();
    const formattedAccount = {
        ...account,
        phone: account.phone?.split(prefix)[1] ?? account.phone,
        phone_alt: account.phone_alt?.split(prefix)[1] ?? account.phone_alt,
    };
    return (
        <>
            <ModifiedForm
                submitFunction={validate}
                defaultValues={formattedAccount ?? undefined}
            >
                <fieldset disabled={value === 2}>
                    <Row>
                        <Col sm="12" md="6">
                            <div className="text-area-form">
                                <TextField
                                    id="name"
                                    className="formTextField text-area"
                                    title={t("fullname")}
                                    disabled={false}
                                    name="name"
                                    validation={{ required: true }}
                                    shouldRegister
                                />

                                <PhoneInput shouldRegister errors={errors} />
                                <TextField
                                    id="email"
                                    className="formTextField text-area"
                                    title={t("email")}
                                    shouldRegister
                                    backendErrors={
                                        errors !== ""
                                            ? errors.email?.length > 0
                                                ? errors?.email[0]
                                                : null
                                            : null
                                    }
                                    validation={{ required: true }}
                                    name="email"
                                    disabled={false}
                                />
                                <TextField
                                    id="commercial_account"
                                    className="formTextField text-area"
                                    title={t("commercial_account")}
                                    shouldRegister
                                    name="commercial_account"
                                    placeholder={t(
                                        "commercial_account_placeholder"
                                    )}
                                    disabled={false}
                                />
                            </div>
                        </Col>
                        <Col sm="12" md="6">
                            <div className="text-area-form">
                                <TextField
                                    id="store_name"
                                    className="formTextField text-area"
                                    title={t("storename")}
                                    shouldRegister
                                    validation={{ required: true }}
                                    name="store_name"
                                    backendErrors={
                                        errors !== ""
                                            ? errors.store_name?.length > 0
                                                ? errors?.store_name[0]
                                                : null
                                            : null
                                    }
                                    disabled={false}
                                />
                                <PhoneInput
                                    id="phone_alt"
                                    errors={errors}
                                    title={t("altPhone")}
                                    shouldRegister
                                    name="phone_alt"
                                />

                                <TextField
                                    id="delivery_fees"
                                    className="formTextField text-area"
                                    title={t("deliveryPrice")}
                                    shouldRegister
                                    backendErrors={
                                        errors !== ""
                                            ? errors.delivery_fees?.length > 0
                                                ? errors?.delivery_fees[0]
                                                : null
                                            : null
                                    }
                                    type="number"
                                    validation={{ required: true }}
                                    name="delivery_fees"
                                    disabled={false}
                                    inputType="number"
                                />
                                <div className="mt-2 position-relative providers-list">
                                {shipmentProviders.map((provider) => (
                                    <div
                                         key={provider.id}
                                        className={'provider-card'}
                                    >
                                        <img
                                            src={
                                                provider.shipment_provider
                                                    .image ??
                                                "https://www.bonbonentertainment.nl/wp-content/uploads/2021/06/no-image.jpg"
                                            }
                                        />
                                        <span>
                                            {provider.shipment_provider.name}
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "12px",
                                                fontWeight: "400",
                                            }}
                                        >
                                        </span>
                                        {console.log(provider.price)}
                                        <Input
                                            id="provider_price"
                                            className="formTextField text-area"
                                            title={t("deliveryPrice")}
                                            shouldRegister
                                            type="number"
                                            name="provider_price"
                                            inputType="number"
                                            value={provider.price}
                                            onChange={(e) => {
                                                const updatedProviders = shipmentProviders.map((item) => {
                                                    if (item.id === provider.id) {
                                                        return {
                                                            ...item,
                                                            price: parseFloat(e.target.value),
                                                        };
                                                    }
                                                    return item;
                                                });
                                                setShipmentProviders(updatedProviders);
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                            </div>
                        </Col>
                        <Col className="mb-3" sm="12" md="6">
                                <Form.Label>{t("invitaioncode")}</Form.Label>
                                <Form.Control
                                    name="invitaion code"
                                    placeholder="Enter invitation code"
                                    onChange={(e) => 
                                        setInvitaionCode(e.target.value)}
                                    // Assuming handleInvitationCodeChange is a function to update state
                                />
                            </Col>
                    </Row>

                    {value !== 2 &&
                        addresses.map((address, i) => {
                            return (
                                <div key={i} className="address-container mt-4">
                                    {value !== 2 && (
                                            <Row>
                                                <Col
                                                    className="btnPosition"
                                                    sm="12"
                                                >
                                                    <i
                                                        onClick={(e) =>
                                                            deleteAddress(e, i)
                                                        }
                                                        disabled={
                                                            addresses.length === 1
                                                        }
                                                        className="fas fa-times px-2"
                                                    >
                                                        {" "}
                                                    </i>
                                                </Col>
                                            </Row>
                                        )}

                                    <Row>
                                        <Col sm="12" md="6">
                                            <div className="text-area-form">
                                                {/*
                                                <label>
                                                    {t("sideMenu.branches")}
                                                </label>

                                                <Select
                                                    // onSearch={onSearch}
                                                    // filterOption={false}
                                                    // showSearch
                                                    value={selectedBranch[i]}
                                                    onChange={(e) =>
                                                        handleSelectedBranch(
                                                            e,
                                                            i
                                                        )
                                                    }
                                                    disabled={value === 2}
                                                    allowClear
                                                    // ref={ref}
                                                    // onPopupScroll={onScroll}
                                                    className="form-control paginated"
                                                    name="zone"
                                                >
                                                    {branches.map((branch) => (
                                                        <Select.Option
                                                            key={branch.id}
                                                            name="branch"
                                                            value={branch.id}
                                                        >
                                                            {branch.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
*/}

                                                {/* <label>{t("zones")}</label>

                                                <Select
                                                    // onSearch={onSearch}
                                                    // filterOption={false}
                                                    // showSearch
                                                    value={selectedZone[i]}
                                                    disabled={value === 2 || selectedBranch[i] ===
                                                        undefined}
                                                    onChange={(e) =>
                                                      handleSelectedZone(e, i)
                                                    }
                                                    allowClear
                                                    // ref={ref}

                                                    onClick={(e) =>
                                                      getCities(e, i)
                                                    }
                                                    // onPopupScroll={onScroll}
                                                    className="form-control paginated"
                                                    name="zone"
                                                >
                                                    {cities.map((city) => (
                                                        <Select.Option
                                                            key={city.id}
                                                            name="zone"
                                                            disabled={
                                                                value === 2
                                                            }
                                                            value={city.id}
                                                        >
                                                            {city.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select> */}
                                                <label>{t("area")}</label>

                                                <SelectAreas
                                                    value={selectedArea[i]}
                                                    disabled={value === 2}
                                                    onChange={(e) =>
                                                        handleSelectedArea(e, i)
                                                    }
                                                    allowClear
                                                    className="form-control paginated"
                                                    name="area"
                                                />
                                            </div>
                                        </Col>
                                        <Col sm="12" md="6">
                                            <div className="text-area-form">
                                                {/* <label>
                                                    {t("sideMenu.workModels")}
                                                </label>
                                                <Select
                                                    // id="new_work_model"
                                                    // title={t("sideMenu.workModels")}
                                                    // validation={{ required: true }}
                                                    // onScroll={(e) => onScroll(e, next)}
                                                    onChange={(e) =>
                                                        handleSelectedModel(
                                                            e,
                                                            i
                                                        )
                                                    }
                                                    disabled={
                                                        selectedBranch[i] ==
                                                        undefined ||
                                                        workModels[i] ===
                                                        undefined
                                                    }
                                                    Value={selectedModel[i]}
                                                    allowClear
                                                    className="form-control paginated"
                                                    // backendErrors={
                                                    //     errors !== ""
                                                    //       ? errors.work_model?.length > 0
                                                    //         ? errors.work_model[0]
                                                    //         : null
                                                    //       : null
                                                    // }
                                                    name="new_work_model"
                                                >
                                                    {(workModels[i] ?? []).map(
                                                        (WorkModel) => {
                                                            return (
                                                                <Select.Option
                                                                    key={
                                                                        WorkModel.id
                                                                    }
                                                                    value={
                                                                        WorkModel.id
                                                                    }
                                                                    name="new_work_model"
                                                                >
                                                                    {
                                                                        WorkModel.name
                                                                    }
                                                                </Select.Option>
                                                            );
                                                        }
                                                    )}
                                                </Select> */}

                                                <label className="label">
                                                    {t("address")}
                                                </label>

                                                <Form.Control
                                                    className="formTextField text-area"
                                                    // title={"Weight"}
                                                    name="address"
                                                    onChange={(e) =>
                                                        handleInput(e, i)
                                                    }
                                                />
                                                
                                            </div>
                                            
                                        </Col>
                                         {/*  add another address field   */}
                    {value === 1 && (
                        <Row className="mt-4">
                            <Col sm="12">
                                <p
                                    onClick={addAnotherAddress}
                                    className="addBtn"
                                >
                                    <i className="fas fa-plus-circle mx-2"></i>
                                    {t("addAddress")}
                                </p>
                            </Col>
                        </Row>
                    )}
                                    </Row>
                                </div>
                            );
                        })}
                    <p className="error">
                        {addressErr ? `${t("addressFields")}` : ""}
                    </p>
                   

                </fieldset>

                <Row>
                    <Col className="text-end buttons-margin" sm="12">
                        <button
                            className="cancel"
                            type="button"
                            onClick={onBack}
                        >
                            {t("back")}
                        </button>
                        <input
                            className="confirm"
                            value={t("proceed")}
                            type="submit"
                        />
                    </Col>
                </Row>
            </ModifiedForm>
            <CreationConfirmationModal
                getErrors={getErrors}
                setID={setID}
                setPassword={setPassword}
                title={lang === "ar" ? "انشاء حساب" : "Create Account"}
                show={show}
                showConfirm={showConfirmModal}
                hide={hide}
                account={account}
                onBack={onBack}
            />
            <AccountConfirmationModal
                show={showConfirm}
                hide={hideConfirm}
                id={ID}
                password={password}
                account={account}
            />
            {/* <button className="cancel" onClick={back}>
                        Back
                    </button> */}
        </>
    );
}
