import React from "react";
import styles from "./WMButton.module.css";
import { Button as AntButton, Spin } from "antd";

export default function WMButton({
    className,
    type,
    icon: icon,
    iconAlt,
    iconPosition,
    loading,
    children,
    ...rest
}) {
    return (
        <AntButton
            type={rest.type}
            htmlType={rest.type}
            className={[
                styles.primary,
                "row-centered",
                iconPosition === "end" ? styles.iconEnd : "",
                type ? `button-${type}` : "button-default",
                className,
            ].join(" ")}
            icon={!loading && icon}
            disabled={loading}
            {...rest}
        >
            {loading ? <Spin className={styles.spinner} /> : children}
        </AntButton>
    );
}
