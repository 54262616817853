import React from "react";
import { Col, Row } from "react-bootstrap";
import styles from "./OrderChainDetails.module.css";
import OrderTag from "./OrderTag";
import { useTranslation } from "react-i18next";

export default function OrderChainHeader({ id }) {
    const { t } = useTranslation();
    return (
        <Row className="mb-4">
            <Col sm="12">
                <span className="title1">{t("sideMenu.tickets")}</span>
                <i className="fas fa-chevron-right px-3 title2" />
                <span className="title2">{t("sideMenu.clientDetails")}</span>
            </Col>
            <div className={styles.title}>
                <div>
                    <h2 className="pageTitle"> {t("orderId")} #{id}</h2>
                </div>
            </div>
        </Row>
    );
}
