export const createWorkModelLocalization = {
    en: {
        info_header: {
            title: "Information About Work Model.",
            sub: "Configure your work model(s) over here with simple steps.",
            steps: [
                "Step 1: You should choose a work model branch which represent your Hub Pickup point.",
                "Step 2: Select The Work Model Type.",
                "Step 3: Enter Constant Parameters.",
                "Step 4: Adjust Areas & Cites.",
                "Step 5: Fill Your Prices.",
            ],
        },
        work_model_details: "Enter Your Work Model Details",
        work_model_name: "Work Model Name",
        work_model_name_ph: "Work Model Name",
        work_model_name_req: "Work Model is Required",
        branch_name: "Branch",
        branch_name_ph: "Select Branch",
        branch_name_req: "Branch is Required",
        work_direction_select: "Select your Work Direction",
        weight: "Weight",
        weight_desc:
            "If you work by default weight and extra price for every extra kilo.",
        dimensions: "Dimensions",
        default_weight: "Default Weight",
        default_weight_enter: "Enter Default Weight",
        dimensions_desc:
            "If you work by boxes you can add all your boxes sizes with height, length and width.",
        shipping_fees: "Shipping Fee",
        return_fees: "Return Fee",
        replace_fees: "Replacement Fee",
        delivery_time: "Delivery Time . hrs",
        return_time: "Return Time . hrs",
        weight_table: "E . Weight / K",
        success_create: "Work Model Created Successfully",
        initial_cities: "Initial Cities",
        choose_cities: "Choose Cities",
        choose_cities_placeholder: "Select your cities from here",
        applyOneValueOnAllAreas: "Apply One Value on all Areas",

    },
    ar: {
        info_header: {
            title: "معلومات حول نموزج العمل.",
            sub: "يمكنك تخصيص نموزج العمل الخاص بك عبر اتباع هذه الخطوات.",
            steps: [
                "الخطوة الأولى: اختر الفرع الذي سيمثل نقطة الانطلاق",
                "الخطوة الثانية: اختر نوع نموزج العمل",
                "الخطوة الثالثة: ادخل الثوابت",
                "الخطوة الرابعة: خصص المدن والمناطق.",
                "الخطوة الخامسة: ادخل اسعارك.",
            ],
        },
        work_model_details: "ادخل تفاصيل نموزج العمل",
        work_model_name: "اسم نموزج العمل",
        work_model_name_ph: "ادخل اسم نموزج العمل",
        work_model_name_req: "برجاء ادخال نموزج العمل",
        branch_name: "الفرع",
        branch_name_ph: "اختر الفرع",
        branch_name_req: "برجاء ادخال الفرع",
        work_direction_select: "اختر طريقة العمل",
        weight: "بالوزن",
        weight_desc:
            "اذا كنت تتعامل بالاوزان، فيمكنك اختيار الوزن الرئيسي وتخصيص سعر لكل وزن اضافي",
        dimensions: "بالابعاد",
        dimensions_desc:
            "اذا كنت تتعامل بالصناديق يمكنك اختيار اسعار لكل احجام الصناديق الخاصة بك.",
        default_weight: "الوزن الاساسي",
        default_weight_enter: "ادخل الوزن الاساسي",
        shipping_fees: "مصاريف الشخن",
        return_fees: "مصاريف الاسترجاع",
        replace_fees: "مصاريف الاستبدال",
        delivery_time: "مدة التوصيل بالساعة",
        return_time: "مدة الاسترجاع بالساعة",
        weight_table: "الوزن يالكيلو",
        success_create: "تم انشاء نموزج العمل بنجاح",
        initial_cities: "المناطق المبدئية",
        choose_cities: "اختر المدن",
        choose_cities_placeholder: "اختر المدن من هنا",
        applyOneValueOnAllAreas: "طبق قيمة واحدة على جميع المناطق"
    },
};
