import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../../../../api/axios";
import { useTranslation } from "react-i18next";
import useLanguage from "../../../../hooks/useLanguage";
import { Col, Row } from "react-bootstrap";
import { Input, Select, Spin, Button, Modal, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SelectAreas from "../../../select-search/AreasSelect";
import "../../../../style-sheets/form.css";
import DetailsTab from "../../OrderChainDetails/OrderChain/DetailsTab";

function SellerDetails() {
    const user = useSelector((state) => state.auth.user.user);
    const { t } = useTranslation();
    const lang = useLanguage();

    const [data, setData] = useState({ saved_address: [] });
    const [zones, setZones] = useState([]);
    const [branches, setBranches] = useState([]);
    const [areas, setAreas] = useState({ old: {}, new: [] });
    const [selectedZone, setSelectedZone] = useState([]);
    const [next, setNext] = useState("");
    const [selectedBranch, setSelectedBranch] = useState([]);
    const [selectedBranchesID, setSelectedBranchesID] = useState([]);
    const [selectedZoneID, setSelectedZoneID] = useState([]);
    const [selectedArea, setSelectedArea] = useState([]);
    const [newAddresses, setNewAddresses] = useState([]);
    const [oldAddresses, setOldAddresses] = useState([]);
    const [isWithoutAddress, setIsWithoutAddress] = useState(false);
    const [deletedAddresses, setDeletedAddresses] = useState([]);
    const [models, setModel] = useState([]);
    const [selectedBranches, setSelectedBranches] = useState([]);
    const [newWorkModels, setNewWorkModels] = useState({ old: {}, new: [] });
    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal');
    

    const getOldAddresses = useCallback(async () => {
        const old = await Promise.all(
            data.saved_address.map(async (a, i) => {
                const newA = { ...a, branch: a.branch.id, area: String(a.area.id), special_name: a.special_name || "", store_phone: a.store_phone || "" , sender_name: a.sender_name || "" };
                const suc = await getArea(i, "old", a.branch.city);
                return suc ? { obj: newA, areas: suc } : null;
            })
        );
        setAreas((prev) => ({ ...prev, old: old.map((o) => o.areas) }));
        const finalAddresses = old.map((o) => o.obj);
        setIsWithoutAddress(finalAddresses.length === 0);
        setOldAddresses(
            finalAddresses.length > 0
                ? finalAddresses
                : [{ branch: undefined, area: "", address: undefined, special_name: "", store_phone: "" ,  sender_name: "" }]
        );
    }, [data.saved_address]);

    const fetchSeller = useCallback(async () => {
        try {
            const res = await axiosInstance.get(`/Seller/${user.user_id}`);
            setData(res.data);
            if (res.data.saved_address.length > 0) {
                const copy = res.data.saved_address.map((address) => address.branch.name);
                const branchesIDs = res.data.saved_address.map((address) => address.branch.id);
                setSelectedBranchesID(branchesIDs);
                await getOldAddresses();
            }
        } catch (error) {
            console.error("Error fetching seller:", error);
        }
    }, [user.user_id, getOldAddresses]);

    const getBranches = useCallback(async () => {
        try {
            const result = await axiosInstance.get("/Branch/?page_count=100");
            setBranches(result.data.data);
        } catch (err) {
            console.error("Error fetching branches:", err);
        }
    }, []);

    const getZones = async (i) => {
        try {
            const result = await axiosInstance.get(`/FilterZones/?branch=${selectedBranchesID[i]}&page_size=1000000`);
            setZones(result.data.data);
            setNext(result.data.next);
        } catch (err) {
            console.log(err);
        }
    };

    const getArea = async (i, type, city = null) => {
        const id = type === "new" ? newAddresses[i]?.["new_work_model"] : oldAddresses[i]?.["new_work_model"];
        try {
            const areasData = await axiosInstance.get(`/FilterAreas/?page_size=10000`);
            let copy = { ...areas[type] };
            copy[i] = areasData.data.data;
            setAreas({ ...areas, [type]: copy });
            return city ? areasData.data.data : null;
        } catch (err) {
            console.log(err);
        }
    };

    const handleSelectChange = (value, index, field, addresses, setAddresses) => {
        const addressesCopy = [...addresses];
        addressesCopy[index][field] = value;
        setAddresses(addressesCopy);
    };

    const addNewAddress = () => {
        const newAddress = { branch: "", area: "", new_work_model: "", address: "", special_name: "", store_phone: "" , sender_name: "" };
        setNewAddresses([...newAddresses, newAddress]);
    };

    const addNewAddressesToServer = async () => {
        if (newAddresses.length > 0) {
            try {
                for (const address of newAddresses) {
                    const addressData = {
                        seller: data.id,
                        branch: address.branch || 1,
                        area: address.area,
                        address: address.address,
                        special_name: address.special_name,
                        sender_name: address.sender_name,
                        store_phone: address.store_phone,
                        approved: true,
                    };
                    await axiosInstance.post("/SellerAddress/", addressData);
                }
                setNewAddresses([]);
                setShow(true);
            } catch (error) {
                console.error("Error adding new addresses:", error);
                const errorResponse = error.response?.data;
                if (errorResponse) {
                    if (Array.isArray(errorResponse)) {
                        errorResponse.forEach((errItem) => {
                            Object.keys(errItem).forEach((key) => {
                                message.error(errItem[key], 5);
                            });
                        });
                    } else {
                        Object.keys(errorResponse).forEach((key) => {
                            message.error(errorResponse[key], 5);
                        });
                    }
                }
            }
        }
    };

    const handleOk = () => {
        setModalText(t('address_modal_message'));
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
        }, 2000);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const edit = async (e) => {
        e.preventDefault();

        const handleError = (err) => {
            const error = err.response?.data;
            if (error) {
                if (Array.isArray(error)) {
                    error.forEach((errItem) => {
                        Object.keys(errItem).forEach((key) => {
                            message.error(errItem[key], 5);
                        });
                    });
                } else {
                    Object.keys(error).forEach((key) => {
                        message.error(error[key], 5);
                    });
                }
            }
        };

        try {
            await addNewAddressesToServer();
            if (deletedAddresses.length > 0) {
                const deletePromises = deletedAddresses.map(async (id) => {
                    await axiosInstance.delete(`SellerAddress/${id}`).catch(handleError);
                });
                await Promise.all(deletePromises);
            }

            const updateOldAddresses = async () => {
                if (oldAddresses.length === 0) return;
                const updatePromises = oldAddresses.map((address) => {
                    if (address.id) {
                        return axiosInstance.put(`/SellerAddress/${address.id}`, {
                            ...address,
                            seller: data.id,
                        }).catch(handleError);
                    } else {
                        return axiosInstance.post("/SellerAddress/", {
                            ...address,
                            seller: data.id,
                            branch: 1,
                        }).catch(handleError);
                    }
                });
                return Promise.all(updatePromises);
            };
            await updateOldAddresses();
            setNewAddresses([]);
            setShow(true);

            // Refetch data to reload states
            await fetchSeller();
            await getOldAddresses();
            // Reload the page
            window.location.reload();
        } catch (err) {
            handleError(err);
        }
    };

    useEffect(() => {
        fetchSeller();
        getBranches();
        getOldAddresses();
    }, [data.id]);

    return (
        <div>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.settings")}</span>
                    <i className="fas fa-chevron-right px-3 title2" />
                    <span className="title2">{t("sellerAddressTitle")}</span>
                </Col>
                <div>
                    <h2 className="pageTitle"> {t("sellerAddressTitle")}</h2>
                </div>
            </Row>
            <DetailsTab
                items={[
                    {
                        label: t("name"),
                        value: data.user?.name,
                    },
                    {
                        label: t("phone"),
                        value: data.user?.phone,
                    }
                ]}
            />
            <hr />

            {oldAddresses.length > 0 ? (
                oldAddresses.map((address, i) => (
                    <div key={i} className="addressCard mt-2" style={{ position: "relative" }}>
                        <div
                            role="button"
                            onClick={() => {
                                setDeletedAddresses((prev) => [...prev, address.id]);
                                const copy = [...oldAddresses];
                                copy.splice(i, 1);
                                setOldAddresses(copy);
                            }}
                            style={{
                                position: "absolute",
                                top: "0.75rem",
                                left: "0.75rem",
                                color: "#57BE6C",
                                fontSize: "1.2em",
                            }}
                        >
                            <i className="fas fa-times-circle"></i>
                        </div>
                        <Row>
                            <Col sm="12" md="6">
                                <label>{t("specialName")}</label>
                                <Input
                                    placeholder={t("specialName")}
                                    name="Special Name"
                                    value={address.special_name}
                                    onChange={(e) => {
                                        const copy = [...oldAddresses];
                                        copy[i].special_name = e.target.value;
                                        setOldAddresses(copy);
                                    }}
                                />
                            </Col>
                            <Col sm="12" md="6">
                                <label>{t("senderName")}</label>
                                <Input
                                    placeholder={t("senderName")}
                                    name="Sender Name"
                                    value={address.sender_name}
                                    onChange={(e) => {
                                        const copy = [...oldAddresses];
                                        copy[i].sender_name = e.target.value;
                                        setOldAddresses(copy);
                                    }}
                                />
                            </Col>
                            <Col sm="12" md="6">
                                <label>{t("phone")}</label>
                                <Input
                                    placeholder={t("phone")}
                                    name="Store Phone"
                                    value={address.store_phone}
                                    onChange={(e) => {
                                        const copy = [...oldAddresses];
                                        copy[i].store_phone = e.target.value;
                                        setOldAddresses(copy);
                                    }}
                                />
                            </Col>
                            <Col sm="12" md="6">
                                <label>{t("area")}</label>
                                <SelectAreas
                                    style={{ display: "block" }}
                                    onChange={(e) => handleSelectChange(e, i, "area", oldAddresses, setOldAddresses)}
                                    initialOptions={areas.old[i]}
                                    showSearch
                                    value={address.area ? +address.area : undefined}
                                    placeholder={t("area")}
                                    aria-label="Default select example"
                                />
                            </Col>
                            <Col sm="12" md="6">
                                <label>{t("address")}</label>
                                <Input
                                    placeholder={t("address")}
                                    name="Address Description"
                                    value={address.address}
                                    onChange={(e) => {
                                        const copy = [...oldAddresses];
                                        copy[i].address = e.target.value;
                                        setOldAddresses(copy);
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                ))
            ) : (
                <div className="my-3">
                    <Spin
                        indicator={<LoadingOutlined style={{ fontSize: 24, color: "#5c55a5" }} spin />}
                    />
                </div>
            )}

            {newAddresses.map((address, i) => (
                <div key={i} className="addressCard mt-2" style={{ position: "relative" }}>
                    <div
                        role="button"
                        onClick={() => {
                            const copy = [...newAddresses];
                            copy.splice(i, 1);
                            setNewAddresses(copy);
                        }}
                        style={{
                            position: "absolute",
                            top: "0.75rem",
                            left: "0.75rem",
                            color: "#57BE6C",
                            fontSize: "1.2em",
                        }}
                    >
                        <i className="fas fa-times-circle"></i>
                    </div>
                    <Row>
                        <Col sm="12" md="6">
                            <label>{t("specialName")}</label>
                            <Input
                                placeholder={t("specialName")}
                                name="Special Name"
                                value={address.special_name}
                                onChange={(e) => {
                                    const copy = [...newAddresses];
                                    copy[i].special_name = e.target.value;
                                    setNewAddresses(copy);
                                }}
                            />
                        </Col>
                        <Col sm="12" md="6">
                            <label>{t("senderName")}</label>
                            <Input
                                placeholder={t("senderName")}
                                name="Sender Name"
                                value={address.sender_name}
                                onChange={(e) => {
                                    const copy = [...newAddresses];
                                    copy[i].sender_name = e.target.value;
                                    setNewAddresses(copy);
                                }}
                            />
                        </Col>
                        <Col sm="12" md="6">
                            <label>{t("phone")}</label>
                            <Input
                                placeholder={t("phone")}
                                name="Store Phone"
                                value={address.store_phone}
                                onChange={(e) => {
                                    const copy = [...newAddresses];
                                    copy[i].store_phone = e.target.value;
                                    setNewAddresses(copy);
                                }}
                            />
                        </Col>
                        <Col sm="12" md="6">
                            <label>{t("area")}</label>
                            <SelectAreas
                                style={{ display: "block" }}
                                onChange={(e) => handleSelectChange(e, i, "area", newAddresses, setNewAddresses)}
                                initialOptions={areas.old[i]}
                                showSearch
                                value={address.area ? +address.area : undefined}
                                placeholder={t("area")}
                                aria-label="Default select example"
                            />
                        </Col>
                        <Col sm="12" md="6">
                            <label>{t("address")}</label>
                            <Input
                                placeholder={t("address")}
                                name="Address Description"
                                value={address.address}
                                onChange={(e) => {
                                    const copy = [...newAddresses];
                                    copy[i].address = e.target.value;
                                    setNewAddresses(copy);
                                }}
                            />
                        </Col>
                    </Row>
                </div>
            ))}

            <div
                className="confirm mt-3 text-center"
                role="button"
                onClick={addNewAddress}
            >
                <i className="fas fa-plus-circle"></i> {t("addAddress")}
            </div>

            <button onClick={edit} className="confirm mt-3">
                {t("saveChanges")}
            </button>

            <Modal
                title={modalText}
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                {/* <p>{modalText}</p> */}
            </Modal>
        </div>
    );
}

export default SellerDetails;
