import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { Row, Col } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Select } from "antd";
import { useHistory } from "react-router-dom";
import { addRunsheetOrders } from "../../../global-state/actions/rsOrdersAction";
import { useTranslation } from "react-i18next";
import ConfirmPayment from "../../../features/seller/wallet/confirm-payment/ui";
import TablePro from "../../tables/TablePro";

function SellersOrders(props) {
    const dispatchOrders = useDispatch();
    const { t } = useTranslation();
    const cur = useSelector(
        (state) => state.auth.user.user.organization.country.currency
    );
    const history = useHistory();
    const branch = useSelector((state) => state.branch.branch);

    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [page, setPage] = useState(1);
    const [type, setType] = useState("1");
    const [seller, setSeller] = useState({});
    const [selectionModel, setSelectionModel] = useState([]);
    const [changed, setChanged] = useState(false);
    const [orders, setOrders] = useState([]);
    const [columns, setColumns] = useState([
        {
            dataIndex: "id",
            title: `${t("orderID")}`,
            render: (_, row) => (
                <p className="paragraphMargin tableID">#{row.id}</p>
            ),
        },
        {
            title: `${t("shipmentFee")}`,
            render: (_, row) => (
                <p className="paragraphMargin credit">{row.delivery_fees}</p>
            ),
        },
        {
            dataIndex: "value",
            title: `${t("orderValue")}`,
            render: (_, row) => (
                <p className="paragraphMargin credit">
                    {cur} {row.sub_total}
                </p>
            ),
        },
        {
            dataIndex: "method",
            title: `${t("paymentMethod")}`,
            render: (_, row) => (
                <p className="paragraphMargin">{row.payment_method}</p>
            ),
        },
    ]);

    const chooseType = (e) => {
        setType(e);
    };

    const getSellersOrders = async () => {
        let result = await axiosInstance
            .get(`/SellerOrders/${props.match.params.id}`, {
                params: {
                    type,
                    page,
                },
            })
            .catch((err) => console.log(err));

        if (result) {
            setData(result.data.data);
            setCount(result.data.count);
        }
    };

    const pay = async () => {
        console.log({ orders, seller, branch });
        if (type === "1") {
            dispatchOrders(addRunsheetOrders(orders));
            console.log("clicked", type);
        } else {
            dispatchOrders(addRunsheetOrders(orders));
            console.log("type", type);
        }
        history.push(`/settelment/${type}`);
    };

    useEffect(() => {
        getSellersOrders();
    }, [page, type]);
    return (
        <>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.wallet")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("totalBalance")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">{t("sellerWallet")}</span>
                </Col>
                <Col sm="12">
                    <h2 className="pageTitle">{t("sideMenu.wallet")}</h2>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col className="column-display" sm="12" md="12">
                    <Select className="type" value={type} onChange={chooseType}>
                        <Select.Option selected value="1">
                            {t("debit")}
                        </Select.Option>
                        <Select.Option value="2">{t("credit")}</Select.Option>
                    </Select>
                    <button
                        disabled={selectionModel.length === 0}
                        onClick={pay}
                        className="pay"
                    >
                        {type === "1" ? `${t("payTo")}` : `${t("recieveFrom")}`}
                    </button>
                </Col>
            </Row>

            <TablePro
                title={
                    <h6>
                        {branch} {t("orderDetails")}
                    </h6>
                }
                dataSource={data}
                columns={columns}
                pagination={{
                    current: page,
                    total: count,
                    onChange: (page) => setPage(page),
                }}
                setSelectedRecords={setOrders}
                selection={"checkbox"}
                selectedRows={selectionModel}
                setSelectedRows={setSelectionModel}
            />
        </>
    );
}

export default ErrorHandler(SellersOrders, axiosInstance);
