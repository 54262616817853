import { REASSIGNED_RUNSHEET,IS_REASSIGNED } from "./actionTypes";

export const getReassignedSheet = (id) => {
  return {
    type: REASSIGNED_RUNSHEET,
    payload: id,
  };
};



export default function isReAssigned (data) {
  return {
    type: IS_REASSIGNED,
    payload: data,
  };
};