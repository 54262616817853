import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../../contexts/LanguageContext";

function MyFatoorahPaymentResult() {
    const { result } = useParams();
    const history = useHistory();
    const { t } = useTranslation();
    const { refreshCredit } = useLanguageContext();
    useEffect(() => {
        setTimeout(() => {
            history.push("/seller_wallet");
            refreshCredit();
        }, 2000);
    }, []);

    return (
        <div>
            <h1>{result}</h1>
            {result === "success" ? (
                <div className="alert alert-success">
                    <h4 className="alert-heading">{t("sideMenu.wellDone")}</h4>
                    <p>
                        {t("sideMenu.You successfully recharged your wallet")}
                    </p>
                    <hr />
                    <p className="mb-0">
                        {t(
                            "sideMenu.You will be redirected to the wallet page in 2 seconds"
                        )}
                    </p>
                </div>
            ) : (
                <div className="alert alert-danger">
                    <h4 className="alert-heading">{t("sideMenu.error")}</h4>
                    <p>{t("sideMenu.The payment process failed")}</p>
                    <hr />
                    <p className="mb-0">
                        {t(
                            "sideMenu.You will be redirected to the wallet page in 2 seconds"
                        )}
                    </p>
                </div>
            )}
        </div>
    );
}

export default MyFatoorahPaymentResult;
