import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useLanguage from "../../../hooks/useLanguage";
import { useExtraOrderColumns } from "../../layout/tableColumns"
import TablePro from "../../tables/TablePro";
import { Modal , notification } from 'antd';


function ExtraOrdersList(props) {
  const { t } = useTranslation();
  const lang = useLanguage();
  const { id } = useParams();
  const user_role = useSelector((state) => state.auth.user.role);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  




const payExtraOrders = async (id) => {
  setLoading(true);
  try {
    Modal.confirm({
      title: 'تأكيد عملية الدفع',
      content: 'هل تريد اتمام عملية الدفع ؟',
      onOk: async () => {
        const result = await axiosInstance.put(`/extra-orders/${id}/`, { paid: true });
        // Check if the request was successful
        if (result.status === 200) {
          // Handle success
          notification.success({
            message: 'Success',
            description: 'Extra order paid successfully.',
          });
        } else {
          // Handle failure
          notification.error({
            message: 'Error',
            description: 'Failed to pay extra order. Please try again later.',
          });
        }
      },
      onCancel: () => {
        // Handle cancelation
        console.log('Payment canceled');
      },
    });
  } catch (err) {
    console.error("Error paying extra order:", err);
    // Handle error
    notification.error({
      message: 'Error',
      description: 'Failed to pay extra order. Please try again later.',
    });
  } finally {
    setLoading(false);
  }
};


  const getExtaOrders = async () => {
    setLoading(true);
    try {
      const result = await axiosInstance.get(`/extra-orders/`, {
        params: {
          page_size: 10,
          page: page,
        },
      });

      if (result) {
        setData(result.data);
        setCount(result.count);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getExtaOrders();
  }, [page]);

  useEffect(() => {
    console.log("Data:", data);
  }, [data]);
  
  const ExtraOrderColumns = useExtraOrderColumns(user_role , payExtraOrders);
  return (
    <>
      <Row className="mb-4">
        
        <Col sm="12">
          <h2 className="pageTitle">{t("extra_orders")}</h2>
        </Col>
      </Row>

      <div className="reqTableContainer">
        <TablePro
          columns={ExtraOrderColumns}  
          dataSource={data}
          pagination={{
            current: page,
            total: count,
            onChange: (page) => setPage(page),
          }}
        />
      </div>
    </>
  );
}

export default ErrorHandler(ExtraOrdersList, axiosInstance);