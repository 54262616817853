import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Row, Col, Modal } from "react-bootstrap";
import ThreeDotsMenu from "../layout/threeDotsMenu";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DimensionRepository from "../../api/DimensionRepository";
import useLanguage from "../../hooks/useLanguage";
import TablePro from "../tables/TablePro";

const Dimentions = (props) => {
    const { t } = useTranslation();
    const lang = useLanguage();

    const [type, setType] = useState("");
    const [show, setShow] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(1);
    const [width, setWidth] = useState("");
    const [height, setHeight] = useState("");
    const [length, setLength] = useState("");
    const [name, setName] = useState("");
    const [editedDimention, setEditedDimention] = useState({});
    const [loading, setLoading] = useState(false);

    const [columns, setColumns] = useState([
        {
            key: "name",
            title: `${t("dimention")}`,
            dataIndex: "name",
        },
        {
            key: "width",
            title: `${t("width")}`,
            dataIndex: "width",
        },
        {
            key: "height",
            title: `${t("height")}`,
            dataIndex: "height",
        },
        {
            key: "length",
            title: `${t("length")}`,
            dataIndex: "length",
        },
        {
            key: "actions",
            title: `${t("actions")}`,
            render: (_, row) => (
                <ThreeDotsMenu
                    onClick={() => settingEditedData(row)}
                    link="DefaultDiemention"
                    setEdited={setShow}
                    data={row}
                />
            ),
        },
    ]);
    const settingEditedData = (data) => {
        setType("edit");
        setName(data.name);
        setWidth(data.width);
        setHeight(data.height);
        setLength(data.length);
        setEditedDimention(data);
    };
    const createDimention = () => {
        setType("create");
        setShow(true);
    };
    const handleInput = (e, setFuntion) => {
        setFuntion(e.target.value);
    };
    const getDimentions = async () => {
        let result = await DimensionRepository.get({ page: page, limit: 10 });
        if (result.success) {
            setData(result.data);
            setCount(result.count);
        }
    };
    useEffect(() => {
        getDimentions();
    }, [page]);

    const confirmRequest = async (type) => {
        let data = {
            name,
            width,
            height,
            length,
        };
        let result;
        setLoading(true);
        if (type === "create") {
            result = await DimensionRepository.post(data);
        } else {
            result = await DimensionRepository.put(data, editedDimention.id);
        }

        if (result.success) {
            hide();
            setLoading(false);
            getDimentions();
            setWidth("");
            setHeight("");
            setLength("");
        }
    };
    const hide = () => {
        setShow(false);
        setWidth("");
        setHeight("");
        setLength("");
        setName("");
    };
    return (
        <div>
            <Row>
                <Col sm="12" className="btnPosition">
                    <button onClick={createDimention}>
                        <i className="fas fa-plus-circle"></i>{" "}
                        {t("addNewDimention")}
                    </button>
                </Col>
            </Row>
            <TablePro
                dataSource={data}
                columns={columns}
                pagination={{
                    current: page,
                    total: count,
                    onChange: (page) => setPage(page),
                }}
            />
            <Modal
                dir={lang == "ar" ? "rtl" : "ltr"}
                show={show}
                onHide={hide}
                style={{ zIndex: 1050 }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {type === "create"
                            ? t("addNewDimention")
                            : t("editDimention")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>{t("name")}</label>
                    <Form.Control
                        value={name}
                        onChange={(e) => handleInput(e, setName)}
                        type="text"
                    />
                    <label>{t("width")}</label>

                    <Form.Control
                        value={width}
                        onChange={(e) => handleInput(e, setWidth)}
                        type="text"
                    />

                    <label>{t("height")}</label>
                    <Form.Control
                        value={height}
                        onChange={(e) => handleInput(e, setHeight)}
                        type="text"
                    />
                    <label>{t("length")}</label>
                    <Form.Control
                        value={length}
                        onChange={(e) => handleInput(e, setLength)}
                        type="text"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={hide} className="cancel">
                        {t("close")}
                    </button>
                    <button
                        className="confirm"
                        disabled={loading}
                        onClick={() => confirmRequest(type)}
                    >
                        {type === "create" ? t("addNewDimention") : t("edit")}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ErrorHandler(Dimentions, axiosInstance);
