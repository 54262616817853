import React, { useState, useEffect, useCallback, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import "../../../style-sheets/table.css";
import axiosInstance from "../../../api/axios";
import ErrorHandler from "../../../error-handler/ErrorHandler";
import {
    productInfo,
    productInfo_ar,
    useTicketsColumns,
} from "../../layout/tableColumns";
import { useDispatch, useSelector } from "react-redux";
import {
    getSeller,
    getTicketsReturned,
} from "../../../global-state/actions/getSelectedAction";
import { useTranslation } from "react-i18next";
import useLanguage from "../../../hooks/useLanguage";
import { CheckBox } from "../../form/TextInputFields";
import { Select } from 'antd';
import TablePro from "../../tables/TablePro";


const { Option } = Select;
const ProductInfoTable = (props) => {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [keyword, setKeyword] = useState("");
    const [selectedTicket, setSelectedTicket] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const orderSellerDispatch = useDispatch();
    const selectedTickets = useDispatch();
    const user = useSelector((state) => state.auth.user);

    const getData = async () => {
        setLoading(true);
        const params = {
            page,
            state : 5 ,
            search: keyword,
        };
        let result;
        if (user.role == "Seller") {
            result = await axiosInstance.get(`/FilterTicket/`, {
                params: {
                    ...params,
                    order__seller__id: user.user.user_id,
                },
            });
        } else {
            result = await axiosInstance.get(`/FilterTicket/`, {
                params,
            });
        }

        if (result) {
            setData(result.data.data);
            setCount(result.data.count);
        }

        setLoading(false);
    };

    useEffect(() => {
        console.log(selectedTicket);
        props.getReturnedTicketsObject(selectedTicket);
    }, [selectedTicket]);

 

    useEffect(() => {
       
            getData();
        
    }, [ keyword,page]);

    const proceed = () => {
        
        if (selectedTicket.length !== 0) {
            props.handleChange(null, props.value + 1);
            orderSellerDispatch(getSeller(selectedTicket[0].order.seller));
        }
    };
    const { t } = useTranslation();


    const handleProductSelect = (value) => {
        const selectedProduct = data.find((product) => product.id === value);
        setSelectedTicket([selectedProduct]);
      };

    return (
        <div>
            <Container>


            <Select
                    showSearch
                    style={{ width: '30%', marginBottom: '150px' }}
                    placeholder="Search orders"
                    optionFilterProp="children"
                    onChange={handleProductSelect}
                    onSearch={setKeyword}
                    loading={loading}
                >
                    {data.map((product) => (
                        <Option key={product.id} value={product.id}>
                            {product.order.id}
                        </Option>
                    ))}
                </Select>
                
                <Row>
                    <Col className="text-end mt-2" sm="12">
                        <button className="cancel" disabled>
                            {t("discard")}
                        </button>
                        <button
                            disabled={
                                selectedTicket.length !== 0 ? false : true
                            }
                            className={
                                user.role == "Seller"
                                    ? "confirmSeller"
                                    : "confirm"
                            }
                            onClick={proceed}
                            type="submit"
                        >
                            {t("proceed")}
                        </button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ErrorHandler(ProductInfoTable, axiosInstance);
