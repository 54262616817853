import { POSTPONE_DATE } from "../actions/actionTypes";
const initialState = {
  
};

export default function (state = initialState, action) {
  //  (action.payload);
  switch (action.type) {
    case POSTPONE_DATE:
      return {
        ...state, 
        date :action.payload
      };

    
    default:
      return state;
  }
}