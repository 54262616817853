import axios from "axios";

const domain = window.location.host.split(".")[0];

// testing
// const BASE_URL = "https://my-box6.treecode.website";
// const BASE_URL = `http://${domain}.localhost:1111`;
// const BASE_URL = `https://mybox.treecode.website`;

// production
const BASE_URL = `https://mybox.treecode.website`

const axiosInstance = axios.create({
    baseURL: BASE_URL,
});
export { BASE_URL };
export default axiosInstance;
