import React, { useEffect, useState } from "react";
import { Row, Container, Col } from "react-bootstrap";
import { Collapse, Input, Modal, Select } from "antd";
import { Checkbox } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";

const { Panel } = Collapse;
const { Option } = Select;

const CreateTableHeader = (props) => {
  const lang = useLanguage();

  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [seller, setSeller] = useState([]);
  const [date, setDate] = useState([]);
  const [ticket, setTicket] = useState([]);
  const [courier, setCourier] = useState([]);
  const [states, setState] = useState([]);
  const [count, setCount] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedHeader, setSelectedHeader] = useState([]);

  const handleState = (e) => {
    setSelectedState(e);
  };
  const getHeaders = async () => {
    let result = await axiosInstance.get("/TableHeader/ ");
    if (result) {
      console.log(result);
      setData(result.data);
    }
  };

  const getState = async () => {
    let result = await axiosInstance.get("/State/?page=1");
    if (result) {
      console.log(result);
      setState(result.data.data);
      setCount(result.data.count);
    }
  };
  useEffect(() => {
    let customer = data.filter((val) => val.name.includes("Customer"));
    setCustomer(customer);
    let seller = data.filter(
      (val) => val.name.includes("Seller") || val.name.includes("Store")
    );
    setSeller(seller);
    let courier = data.filter((val) => val.name.includes("Courier"));
    setCourier(courier);
    let date = data.filter((val) => val.name.includes("Date"));
    setDate(date);
    let ticket = data.filter(
      (val) =>
        val.name.includes("Order") ||
        val.name.includes("Ticket") ||
        val.name.includes("Shipment") ||
        val.name.includes("Item") ||
        val.name.includes("Dimension") ||
        val.name.includes("Weight") ||
        val.name.includes("Special Label") ||
        val.name.includes("Feedback Comment") ||
        val.name.includes("Status") ||
        val.name.includes("Assign") ||
        val.name.includes("Fee") ||
        val.name.includes("Payment")
    );
    setTicket(ticket);
  }, [data]);
  useEffect(() => {
    getHeaders();
    getState();
  }, []);

  const handleSelectedHeaders = (e) => {
    let isChecked = e.target.checked;
    if (isChecked) {
      setSelectedHeader([...selectedHeader, e.target.value]);
    } else {
      let index = selectedHeader.indexOf(e.target.value);
      let copy = [...selectedHeader];
      copy.splice(index, 1);
      console.log(copy);
      // selectedHeader.splice(index, 1);
      setSelectedHeader(copy);
    }
  };
  // useEffect(() => {
  //   console.log(selectedHeader);
  // }, [selectedHeader]);

  const create = async () => {
    let data = {
      headers_active: selectedHeader
    };
    console.log(data);
    let result = await axiosInstance
      .post(`/StateTableHeader/${selectedState}`, data)
      .catch((err) => {
        console.log(err.response);
      });
    if (result) {
      console.log(result);
      if (result.data) {
        props.hideCreateModal();
      }
    }
  };

  return (
    <Modal
      width={500}
      visible={props.showCreateModel}
      onCancel={props.hideCreateModal}
      
    >
      <div dir={lang === "en" ? "ltr" : "rtl"}>
      <h6 className="p-3">{t("tableHeaders")}</h6>

      {/* {props.details.headers.map((header) => {
        return (
          <div className="stateList">
            <p className="ps-3">{header.header_status__active?header.name:""}</p>
          </div>
        );
      })} */}

      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        defaultActiveKey={["0"]}
        // expandIconPosition="right"
        ghost
      >
        <Panel header={lang === "en" ? "Ticket" : "بوليصة"} key="1">
          {ticket.length !== 0
            ? ticket.map((item, i) => {
                return (
                  <>
                    {" "}
                    <p className="ps-3 headersItems">
                      {/* <Checkbox
                        value={name.id}
                        checked={selectedHeader[i]}
                        onChange={(e) => handleSelectedHeaders(e, i)}
                      >
                        {name.name}
                      </Checkbox> */}
                      <input
                        type="checkbox"
                        value={item.id}
                        name={item.name}
                        onChange={handleSelectedHeaders}
                      />{" "}
                      {item.name}
                    </p>{" "}
                  </>
                );
              })
            : null}
        </Panel>
        <Panel header={lang === "en" ? "Date" : "تاريخ"} key="2">
          {date.length !== 0
            ? date.map((item) => {
                return (
                  <>
                    {" "}
                    <p className="ps-3 headersItems">
                      <input
                        type="checkbox"
                        value={item.id}
                        name={item.name}
                        onChange={handleSelectedHeaders}
                      />{" "}
                      {item.name}
                    </p>{" "}
                  </>
                );
              })
            : null}
        </Panel>
        <Panel header={lang === "en" ? "Customer" : "عميل"} key="3">
          {customer.length !== 0
            ? customer.map((item) => {
                return (
                  <>
                    {" "}
                    <p className="ps-3 headersItems">
                      <input
                        type="checkbox"
                        value={item.id}
                        name={item.name}
                        onChange={handleSelectedHeaders}
                      />{" "}
                      {item.name}
                    </p>{" "}
                  </>
                );
              })
            : null}
        </Panel>
        <Panel header={lang === "en" ? "Seller" : "بائع"} key="4">
          {seller.length !== 0
            ? seller.map((item) => {
                return (
                  <>
                    {" "}
                    <p className="ps-3 headersItems">
                      <input
                        type="checkbox"
                        value={item.id}
                        name={item.name}
                        onChange={handleSelectedHeaders}
                      />{" "}
                      {item.name}
                    </p>{" "}
                  </>
                );
              })
            : null}
        </Panel>
        <Panel header={lang === "en" ? "Courier" : "مندوب"} key="5">
          {courier.length !== 0
            ? courier.map((item) => {
                return (
                  <>
                    {" "}
                    <p className="ps-3 headersItems">
                      <input
                        type="checkbox"
                        value={item.id}
                        name={item.name}
                        onChange={handleSelectedHeaders}
                      />{" "}
                      {item.name}
                    </p>{" "}
                  </>
                );
              })
            : null}
        </Panel>
      </Collapse>
      <Row>
        <Col className="px-4" sm="12">
          <label>{t("stateTitle")}</label>
          <Select
            onChange={handleState}
            value={selectedState}
            style={{ width: 100 }}
            placeholder="Select State"
            onClick={getState}
            style={{ display: "block" }}
            //   onPopupScroll={onScrollStates}
          >
            {states.map((state) => {
              return (
                <Option name={state.title} key={state.id} value={state.id}>
                  {lang==="en" ? state.title : state.title_ar}
                </Option>
              );
            })}
          </Select>{" "}
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: "end", padding: "12px", marginRight: "15px" }}>
          <button onClick={props.hideCreateModal} className="cancel">
            {t("close")}
          </button>
          <button
            disabled={
              selectedHeader.length == 0 || selectedState == "" ? true : false
            }
            onClick={create}
            className="confirm"
          >
            {t("apply")}
          </button>
        </Col>
      </Row>
      </div>
    </Modal>
  );
};

export default ErrorHandler(CreateTableHeader, axiosInstance);
