import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../api/axios";
import { Row, Col } from "react-bootstrap";
import { notification } from "antd";
import RechargeCards from "./RechargeCards";
import RechargeDetails from "./RechargeDetails";
import errorHandler from "../../../error-handler/ErrorHandler";
import { Payment } from "@mui/icons-material";
import useLanguage from "../../../hooks/useLanguage";
import { useLanguageContext } from "../../contexts/LanguageContext";

function RechargeSellerWallet() {
    const { t } = useTranslation();
    const [rechargePrice, setRechargePrice] = useState();
    const [methodLoading, setMethodLoading] = useState(false);
    const [excutionLoading, setExcutionLoading] = useState(false);
    const [paymentData, setPaymentData] = useState();
    const [paymentMethod, setPaymentMethod] = useState();
    const [language] = useLanguage();

    const fetchPaymentMethods = async () => {
        setMethodLoading(true);
        const result = await axiosInstance
            .post("/initiate-payment/", { invoice_amount: rechargePrice })
            .catch((err) => {
                if (err.response.data) {
                    Object.entries(err.response.data).forEach(
                        ([key, value]) => {
                            notification.error({
                                message: key,
                                description: value,
                                placement: "bottomRight",
                            });
                        }
                    );
                } else {
                    notification.error({
                        message: "Error",
                        description: "Something went wrong",
                        placement: "bottomRight",
                    });
                }
                setRechargePrice(null);
            });
        if (result) {
            setPaymentData(result.data);
        }
        setMethodLoading(false);
    };

    const handleExcutePayment = async () => {
        setExcutionLoading(true);
        const data = {
            payment_method_id: paymentMethod.PaymentMethodId,
            payment_method_name: paymentMethod.PaymentMethodEn,
            total_amount: paymentMethod.TotalAmount,
        };
        const result = await axiosInstance
            .post(
                `/execute-payment/${paymentData.payment_transaction.id}/`,
                data
            )
            .catch((err) => {
                if (err.response?.data) {
                    Object.entries(err.response.data).forEach(
                        ([key, value]) => {
                            notification.error({
                                message: key,
                                description: value,
                                placement: "bottomRight",
                            });
                        }
                    );
                } else {
                    notification.error({
                        message: "Error",
                        description: "Something went wrong",
                        placement: "bottomRight",
                    });
                }
                setExcutionLoading(false);
            });
        if (result?.status === 200) {
            notification.success({
                message: "Success",
                description:
                    "You will be redirected to the payment page in 2 seconds",
                placement: "bottomRight",
            });
            setTimeout(() => {
                window.location.replace(result.data.payment_url);
            }, 10);
        }
    };

    useEffect(() => {
        if (rechargePrice) {
            fetchPaymentMethods();
        }
    }, [rechargePrice]);

    const { refreshCredit } = useLanguageContext();
    return (
        <div dir={language === "a" ? "rtl" : "ltr"}>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.wallet")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">
                        {t("sideMenu.rechargeSellerWallet")}
                    </span>
                </Col>
                <Col className="titleDisplay" sm="12">
                    <h2 className="pageTitle mt-2">
                        {t("sideMenu.rechargeSellerWallet")}
                    </h2>
                </Col>
            </Row>
            {!methodLoading && !rechargePrice && (
                <RechargeCards setRechargePrice={setRechargePrice} />
            )}
            {methodLoading && (
                <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
            )}
            {paymentData && (
                <RechargeDetails
                    data={paymentData}
                    loading={excutionLoading}
                    handleExcutePayment={handleExcutePayment}
                    setPaymentMethod={setPaymentMethod}
                    paymentMethod={paymentMethod}
                />
            )}
        </div>
    );
}

export default errorHandler(RechargeSellerWallet, axiosInstance);
