import { Route } from "react-router-dom";
import React from "react";
import { NoPermission } from "../../components/NotFound";

export const permissionGranted = (routePermissions, user) => {
    return (
        user.role_id === "1" ||
        user.role === "Organization Admin" ||
        routePermissions === undefined ||
        user.user.permissions.reduce(
            (prev, cur) => prev || routePermissions.includes(cur[0]),
            false
        )
    );
};
export default function generateRoutes(routesGroup, user) {
    const permission = user ? user.user.user.permissions : undefined;

    if (permission) {
        return Object.keys(routesGroup).map((path) => {
            return (
                <Route
                    path={path}
                    exact
                    component={
                        permissionGranted(routesGroup[path].rules, user.user)
                            ? routesGroup[path].component
                            : NoPermission
                    }
                />
            );
        });
    } else
        return Object.keys(routesGroup).map((path) => {
            return (
                <Route
                    path={path}
                    exact
                    component={routesGroup[path].component}
                />
            );
        });
}
