import React, { useState } from "react";
import styles from "./TicketChainDetails.module.css";
import OrderChainHeader from "../OrderChainHeader";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TablePro from "../../../tables/TablePro";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function TicketChainDetails({ data }) {
    const user = useSelector((state) => state.auth.user);
    const { t } = useTranslation();
    const [tabValue, setTabValue] = useState(0);

    const columns = [
        {
            title: t("actionStage"),
            key: "state",
            render: (value, row, index) => {
                if (index === 0)
                    return {
                        children: t(`states.${value.state}`),
                        props: { rowSpan: data[value.state].length },
                    };
                else
                    return {
                        props: { rowSpan: 0 },
                    };
            },
        },
        {
            title: t("id"),
            dataIndex: "id",
            key: "id",
        },
        {
            title: t("totalAmount"),
            dataIndex: "price",
            key: "price",
        },
        {
            title: t("date"),
            dataIndex: "trans_date",
            key: "date",
            render: (_, row) => row.trans_date?.split("T")[0] ?? "-",
        },
        {
            title: t("with_shipment"),
            dataIndex: "with_shipment",
            key: "with_shipment",
            render: (_, row) => (row.with_shipment ? t("yes") : t("no")),
        },
        {
            title: t("settled"),
            dataIndex: "setteld",
            key: "settled",
            render: (_, row) => (row.setteld ? t("yes") : t("no")),
        },
    ];
    return (
        <div className={styles.container} style={{ marginTop: "36px" }}>
            {/*<OrderChainHeader isTicket />*/}

            <div>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    {/*<Tabs*/}
                    {/*    value={tabValue}*/}
                    {/*    onChange={(_, n) => setTabValue(n)}*/}
                    {/*    aria-label="basic tabs example"*/}
                    {/*    className={`${*/}
                    {/*        user.role == "Seller" ? "seller" : "admin"*/}
                    {/*    }`}*/}
                    {/*>*/}
                    {/*    <Tab label={t("all")} {...a11yProps(0)} />*/}
                    {/*    <Tab label={t("entitlements")} {...a11yProps(1)} />*/}
                    {/*    <Tab label={t("debts")} {...a11yProps(2)} />*/}
                    {/*</Tabs>*/}
                </Box>
                <div>
                    {Object.entries({
                        ...data,
                    }).map(([key, group], i) => (
                        <>
                            <TablePro
                                columns={columns}
                                dataSource={group}
                                disableHeaders={i !== 0}
                                pagination={false}
                            />
                        </>
                    ))}
                </div>
            </div>
        </div>
    );
}
