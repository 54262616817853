import React, { useMemo } from "react";
import List from "@mui/material/List";
import { NavLink } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Icon } from "@shopify/polaris";
import { HomeMajor } from "@shopify/polaris-icons";
import ListItemText from "@mui/material/ListItemText";
import { useSelector } from "react-redux";
import { permissionGranted } from "../../../global-state/routes/generate-routes";

export default function SingleRoute(
    { className, path, details, isSeller, overrideIcon, setMobileOpen } = {
        isSeller: false,
    }
) {
    const user = useSelector((state) => state.auth.user);

    const isPermissionGranted = useMemo(
        () => permissionGranted(details.rules, user),
        [user?.role_id, user.role]
    );
    return (
        <>
            {isPermissionGranted ? (
                <List>
                    <NavLink
                        className={className}
                        activeClassName={
                            isSeller ? "sellerActiveLink" : "activeLink"
                        }
                        to={path}
                        onClick={() => setMobileOpen(false)}
                    >
                        <ListItem button key={path}>
                            <ListItemIcon>
                                {overrideIcon ?? (
                                    <Icon source={details.icon} color="base" />
                                )}
                            </ListItemIcon>
                            <ListItemText primary={details.title} />
                        </ListItem>
                    </NavLink>
                </List>
            ) : undefined}
        </>
    );
}
