import React, { useState, useEffect } from "react";
import { Container, Row, Col,  Form, InputGroup  } from "react-bootstrap";
import "../../style-sheets/form.css";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { SuccessfulRequest } from "../modals/confirmationModal";
import { Input, Select, Spin  , Button , Modal , Image , Switch} from "antd";
import { useTranslation } from "react-i18next";
import { width } from "@mui/system";
import { message } from "antd";
import { getSelectedUser } from "./../../global-state/actions/getSelectedAction";
import workModalData from "../modals/workModalData";
import useLanguage from "../../hooks/useLanguage";
import SelectSearch from "../select-search/SelectSearch";
import SelectAreas from "../select-search/AreasSelect";
import { prefix, smallPattern } from "../phone-input";
import { LoadingOutlined } from "@ant-design/icons";
import { CopyOutlined } from '@ant-design/icons';


const AccountInfo = (props) => {
    const selectedUserState = useSelector(
        (state) => state.selectedUser.selectedUser
    );

    
    const { t } = useTranslation();
    const [type , setType] = useState(selectedUserState[0].type);
    const [taxNumber , setTaxnumber] = useState(selectedUserState[0].tax_registration_number);
    const [api_key , setApikey] = useState(selectedUserState[0].api_key);
    const [hmac_secret , setHmac] = useState(selectedUserState[0].hmac_secret);
    const [third_address , setThird_address] = useState(selectedUserState[0].address);
    const [third_website , setThird_website] = useState(selectedUserState[0].website);
    const [third_fb , setThird_fb] = useState(selectedUserState[0].facebook);
    const [third_ig , setThird_ig] = useState(selectedUserState[0].instagram);
    const [third_twitter , setThird_twitter] = useState(selectedUserState[0].twitter);
    const [third_active , setThird_active] = useState(selectedUserState[0].active);
    const [name, setName] = useState(selectedUserState[0].user.name);
    const [phone, setPhone] = useState(selectedUserState[0].user.phone);
    const [phone2, setPhone2] = useState(selectedUserState[0].user.phone_alt);
    const [status , setStatus] = useState(selectedUserState[0].approval_status)
    const [email, setEmail] = useState(selectedUserState[0].user.email);
    const [storeName, setStore] = useState(selectedUserState[0].store_name);
    const [marketer, setMarketer] = useState(selectedUserState[0].invitation_code);
    const initialInvitationCode = selectedUserState[0]?.invitation_code?.invitation_code || '';
    const initialseller_type = selectedUserState[0]?.seller_type || '';
    const [seller_type , setSellerType] = useState(initialseller_type);
    const [invitationcode , setInvitaionCode] = useState(initialInvitationCode);
    const [bank , setBank] = useState(selectedUserState[0].bankacc);
    const [zones, setZones] = useState([]);
    const [branches, setBranches] = useState([]);
    const [areas, setAreas] = useState({ old: {}, new: [] });
    const [selectedZone, setSelectedZone] = useState([]);
    const [next, SetNext] = useState("");
    const [selectedBranch, setSelectedBranch] = useState([]);
    const [selectedBranchesID, setSelectedBranchesID] = useState([]);
    const [selectedZoneID, setSelectedZoneID] = useState([]);
    const [selectedArea, setselectedArea] = useState([]);
    const [newAddresses, setNewAddresses] = useState([]);
    const [oldAddresses, setOldAddresses] = useState([]);
    const [isWithoutAddress, setIsWithoutAddress] = useState();
    const [newWorkModels, setNewWorkModels] = useState({ old: {}, new: [] });
    const [selectedWorkModel, setWorkModel] = useState(
        selectedUserState[0].work_model?.name
    );
    const [deletedAddresses, setDeletedAddresses] = useState([]);
    const [models, setModel] = useState([]);
    const [selectedBranches, setSelectedBranches] = useState([]);
    const [deliveryFees, setDeliveryFees] = useState();
    const [commercialAccount, setCommercialAccount] = useState(
        selectedUserState[0].commercial_account
    );
    const dispatch = useDispatch();
    const [show, setShow] = useState("");
     
    const hideModel = () => {
        setShow(false);
    };

    const updateCommission = (newCommissionValue) => {
        setMarketer({
            ...marketer,
            commission: newCommissionValue,
        });
    };

    const [shipmentProviders, setShipmentProviders] = useState([
        {
            id: 0, // Assuming an initial value for id
            shipment_provider_name: '',
            price: '',
            changed_by: null,
            shipment_provider: 0 // Assuming an initial value for shipment_provider
        }
    ]);

   
    
    const getArea = async (i, type, city = null) => {
        const id =
            type === "new"
                ? newAddresses[i]?.["new_work_model"]
                : oldAddresses[i]?.["new_work_model"];
        console.log({
            b: branches.find((b) => b.id == id)?.city.map((it) => it.id),
            city,
        });

        const areasData = await axiosInstance.get(
            `/FilterAreas/?page_size=10000`
        );
        if (areasData) {
            let copy = { ...areas[type] };
            copy[i] = areasData.data.data;
            console.log(areas, copy, "from_area");
            setAreas({ ...areas, [type]: copy });
            if (city) return areasData.data.data;
        }
    };
    const getNewWorkModel = async (i, type, branch = null) => {
        const id =
            type === "new"
                ? newAddresses[i].branch
                : branch === null
                ? oldAddresses[i]?.branch
                : branch;
        const workModelsData = await axiosInstance.get(
            `/NewWorkModel/?branch=${id}&page_size=1000000
            `
        );
        if (workModelsData) {
            let copy = { ...newWorkModels[type] };
            copy[i] = workModelsData.data.data;
            setNewWorkModels({ ...newWorkModels, [type]: copy });
        }
        if (branch) return workModelsData.data.data;
    };
    const updateUser = async () => {
        const result = await axiosInstance.get(
            `/FilterSeller/?id=${selectedUserState[0].id}`
        );
        dispatch(getSelectedUser(result.data.data));
    };
    const edit = async (e) => {
        const handleError = (err) => {
            const error = err.response?.data;
            console.log(error);
            
            if (error) {
                if (Array.isArray(error))
                    err.response.data.map((error) => {
                        Object.keys(error).map((key) => {
                            message.error(error[key], 5);
                        });
                    });
                else
                    Object.keys(error).map((key) => {
                        message.error(error[key], 5);
                    });
            }
        };
        e.preventDefault();
        if (props.match.params.type === "manager") {
            let data = {
                name: name,
                phone: phone,
                phone_alt: phone2,
                email: email,
                approval_status : status,
                invitation_code: marketer,
                   
               
                
            };
            console.log(data);
           
            let result = await axiosInstance
                .put(`/OrgEmployee/${props.match.params.id}`, data)
                .catch((err) => {
                    console.log(err);
                });
            if (result) {
                console.log(result);
                setShow(true);
            }
        } else if (props.match.params.type === "seller") {
            let edited = true;
            addNewAddressesToServer();

            if (deletedAddresses.length > 0) {
                const deletePromises = deletedAddresses.map(async (id) => {
                  try {
                    await axiosInstance.delete(`SellerAddress/${id}`);
                  } catch (err) {
                    if (err.response?.data) {
                      err.response.data.map((error) => {
                        Object.keys(error).map((key) => {
                          message.error(error[key], 5);
                        });
                      });
                    }
                  }
                });
              
                // Wait for all delete requests to complete
                await Promise.all(deletePromises);
              
              
             } else
            edited = await axiosInstance
                .put(`/Seller/${props.match.params.id}`, {

                    name: name,
                    phone: phone,
                    phone_alt: phone2,
                    email: email,
                    store_name: storeName,
                    delivery_fees: deliveryFees,
                    shipment_provider: shipmentProviders ,
                    // ...(invitationcode !== '' && { marketer_invitation_code: invitationcode }),
                    marketer_invitation_code: invitationcode,
                    tax_registration_number : taxNumber,
                    seller_type: seller_type,
                })
                .catch(handleError);
            const result = isWithoutAddress
                ? await axiosInstance
                      .post("/SellerAddress/", [
                          {
                              ...(oldAddresses[0] ?? {}),
                              seller: props.match.params.id,
                              branch: 1,
                          },
                      ])
                      .catch(handleError)
                : await axiosInstance
                      .put(`/SellerAddress/${oldAddresses[0]?.id}`, {
                          ...oldAddresses[0],
                          seller: props.match.params.id,
                      })
                      .catch(handleError);

            if (result?.data) {
                setNewAddresses([]);
                updateUser();
                setShow(true);
            }
        }
        else if (props.match.params.type === "thirdparty") {
            let data = {
                name: name,
                email: email,
                phone: phone,
                phone_alt: phone2,
                active : third_active,
                facebook: third_fb,
                website: third_website,
                twitter: third_twitter,
                instagram: third_ig,
            };
        
            try {
                let result = await axiosInstance.put(`/ThirdPartyCompany/${props.match.params.id}`, data);
                console.log(result);
                setShow(true);
            } catch (err) {
                console.error("Error updating third-party company:", err);
                // Handle error, show error message, etc.
            }
        }
    };         
    const getBranches = async (e, i) => {
        let result = await axiosInstance
            .get("/Branch/?page_count=100")
            .catch((err) => console.log(err));
        if (result) {
            setBranches(result.data.data);
            SetNext(result.data.next);
        }
    };

    const getZones = async (i) => {
        let result = await axiosInstance
            .get(
                `/FilterZones/?branch=${selectedBranchesID[i]}&page_size=1000000`
            )
            .catch((err) => console.log(err));
        if (result) {
            setZones(result.data.data);
            SetNext(result.data.next);
        }
    };

    const handleSelectedBranch = (e) => {
        console.log(e);
        setSelectedBranch(e);
        // setSelectedBranchID(e);
    };
    const handleSelectedZone = (e) => {
        setSelectedZone(e);
        setSelectedZoneID(e);
    };
    const handleSelectedArea = (e) => {
        setselectedArea(e);
    };
    const handleSelectChange = (v, i, field, addresses, setAddresses) => {
        let addressesCopy = [...addresses];
        addressesCopy[i][field] = v;
        setAddresses(addressesCopy);
    };
    const getOldAddresses = async () => {
        console.log("eheheeheheeh");
        let old = await Promise.all(
            selectedUserState[0].saved_address.map(async (a, i) => {
                let newA = {
                    ...a,
                    branch: a.branch.id,
                    area: String(a.area.id),
                    // new_work_model: a.new_work_model.id,
                };
                let suc = await getArea(i, "old", a.branch.city);
                if (suc) {
                    return { obj: newA, areas: suc };
                }
            })
        );
        setAreas({ ...areas, old: old.map((o) => o.areas) });
        setNewWorkModels({
            ...newWorkModels,
            old: old.map((o) => o.new_work_models),
        });

        const finalAddresses = old.map((o) => o.obj);
        setIsWithoutAddress(finalAddresses.length === 0);
        setOldAddresses(
            finalAddresses.length > 0
                ? finalAddresses
                : [
                      {
                          branch: undefined,
                          area: "",
                          address: undefined,
                      },
                  ]
        );
    };
    useEffect(() => {
        if (props.match.params.type === "seller") {
            getBranches();
            let copy = [];
            let branchesIDs = [];
            selectedUserState[0].saved_address.map((address, i) => {
                copy[i] = address.branch.name;
                setSelectedBranches(copy);
                console.log(copy);
                

                branchesIDs[i] = address.branch.id;
                setSelectedBranchesID(branchesIDs);
                // setSelectedBranch(address.area[0].zone[0].branch[0].name);
                // setSelectedBranchID(address.area[i].zone[i].branch[i].id);
                // setSelectedZone(address.area[i].zone[0].name);
                // setSelectedZoneID(address.area[i].zone[0].id);
                // setselectedArea(address.area[i].name);
            });
            getOldAddresses();
            setDeliveryFees(selectedUserState[0].delivery_fees);
        }
        setShipmentProviders(selectedUserState[0].shipment_provider)

    }, [selectedUserState]);
    const getForAddress = (i, field, getFunc) => {};
    const getWorkModels = async () => {
        let result = await axiosInstance
            .get("/WorkModel/")
            .catch((err) => console.log(err));
        if (result) {
            setModel(result.data.data);
            SetNext(result.data.next);
        }
    };
    const handleWorkModel = (e) => {
        setModel(e);
    };
    const addNewAddress = () => {
        // Creating a new address object with default values
        const newAddress = {
            branch: "",
            area: "",
            new_work_model: "",
            address: ""
        };
    
        // Updating the state with the new address object
        setNewAddresses([
            ...newAddresses,
            newAddress
        ]);
    };

    const addNewAddressesToServer = async () => {
        if (newAddresses.length > 0) {
            try {
                const addressesData = newAddresses.map((address) => ({
                    seller: props.match.params.id,
                    // branch: address.branch,
                    branch: 1,
                    area: address.area,
                    address: address.address,
                }));
    
                // Send all new addresses to the server for addition
                const response = await axiosInstance.post("/SellerAddress/", addressesData);
    
                // Check if all new addresses were added successfully
                if (response.status === 201) {
                    setNewAddresses([]); // Clear the newAddresses state
                    updateUser();
                    setShow(true);
                } else {
                    // Handle the scenario where some addresses failed to be added
                    // Display an error message or perform error handling
                }
            } catch (error) {
                // Handle any errors that occur during the API request
                // Display an error message or perform error handling
                console.error("Error adding new addresses:", error);
            }
        }
    };
    
    
    const lang = useLanguage();
    useEffect(() => {
        console.log(areas, newWorkModels, oldAddresses);
        console.log(selectedUserState);
    }, [oldAddresses]);


    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal');
    const showModal = () => {
      setOpen(true);
    };
    const handleOk = () => {
      setModalText('The modal will be closed after two seconds');
      setConfirmLoading(true);
      setTimeout(() => {
        setOpen(false);
        setConfirmLoading(false);
      }, 2000);
    };
    const handleCancel = () => {
      console.log('Clicked cancel button');
      setOpen(false);
    };

     // Validation function for tax number
     const validateTaxNumber = (input) => {
        const isValid = /^3\d{13}3$/.test(input);
        return isValid;
    };
    const [copied, setCopied] = useState(false);

    const copyToClipboard = (value) => {
        navigator.clipboard.writeText(value);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    };
    const handleRegeneration = (keyName) => {
        const id = props.match.params.id;
        axiosInstance.post(`/RegenrateKeys/${id}`, { key_name: keyName })
            .then(response => {
                console.log("Regeneration request sent successfully");
                // Conditionally update state based on keyName
                if (keyName === "api_key") {
                    setApikey(response.data.api_key);
                } else if (keyName === "hmac_secret") {
                    setHmac(response.data.hmac_secret);
                }
            })
            .catch(error => {
                console.error("Error sending regeneration request:", error);
            });
    };
    const handleSwitchChange = () => {
        if (seller_type === '2') {
            setSellerType('1');
        }
    };
    return (
        <div>
            <Container>
                <Row className="mb-2">
                    <Col sm="12">
                        <span className="title1">{t("sideMenu.accounts")}</span>
                        <i class="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">
                            {t("sideMenu.allAccounts")}
                        </span>
                        <i class="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">{t("personalInfo")}</span>
                    </Col>
                    <Col sm="12">
                        <h2 className="pageTitle">
                            {t("sideMenu.allAccounts")}
                        </h2>
                    </Col>
                </Row>
                <Row className="">
                    <Col sm="12">
                        <h4 className="pageTitle">
                            {t("personalInfo")} #{selectedUserState[0].id}
                        </h4>
                    </Col>
                </Row>
                <Form>
                    {" "}
                    <Row>
                        <Col className="mb-3" sm={12} md="6">
                            <Form.Label>{t("fullname")}</Form.Label>
                            <Form.Control
                                placeholder={t("fullname")}
                                name="fullName"
                                value={name}
                                // disabled
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Col>
                        {props.match.params.type === "seller" || props.match.params.type === "thirdparty"? (
                            <Col className="mb-3" sm={12} md="6">
                                <Form.Label>{t("email")}</Form.Label>

                                <Form.Control
                                    placeholder={t("email")}
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Col>
                        ) : (
                            <Col className="mb-3" sm={12} md="6">
                                <Form.Label>
                                    {t("sideMenu.branches")}
                                </Form.Label>
                                <Form.Control
                                    placeholder={t("sideMenu.branches")}
                                    name="branch"
                                    value={selectedUserState[0].branch?.name}
                                />
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col className="mb-3" sm={12} md="6">
                            <Form.Label>{t("phone")}</Form.Label>
                            <p className="note">{t("phonePlaceholder")}</p>
                            <InputGroup>
                                <InputGroup.Text>{prefix}</InputGroup.Text>
                                <Form.Control
                                    placeholder={t("phone")}
                                    name="phone"
                                    value={phone?.split(prefix)?.[1]}
                                    onChange={(e) =>
                                        setPhone(`${prefix}${e.target.value}`)
                                    }
                                    validation={{
                                        length: 9,
                                        required: true,
                                        pattern: smallPattern,
                                    }}
                                />
                            </InputGroup>
                        </Col>
                        {props.match.params.type === "manager" ? (
                            <Col className="mb-3" sm={12} md="6">
                                <Form.Label>{t("email")}</Form.Label>

                                <Form.Control
                                    placeholder={t("email")}
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                          
                            </Col>
                        ) : props.match.params.type === "seller" ? (
                            <Col className="mb-3" sm={12} md="6">
                                <Form.Label>{t("storename")}</Form.Label>

                                <Form.Control
                                    placeholder={t("storename")}
                                    name="storeName"
                                    value={storeName}
                                    onChange={(e) => setStore(e.target.value)}
                                />
                            </Col>
                            
                        ): null}

                        {props.match.params.type === "thirdparty" && (
                            <Col className="mb-3" sm="12" md="6">
                                <Form.Label>{t("address")}</Form.Label>
                                <Form.Control
                                    placeholder={t("address")}
                                    name="address"
                                    value={third_address}
                                    onChange={(e) =>
                                        setThird_address(e.target.value)
                                    }
                                />
                        
                            </Col>
                            
                            
                        )}
                        
                         {props.match.params.type === "thirdparty" && (
                            <Col className="mb-3" sm="12" md="6">
                                <Form.Label>{t("website")}</Form.Label>
                                <Form.Control
                                    placeholder={t("website")}
                                    name="website"
                                    value={third_website}
                                    onChange={(e) =>
                                        setThird_website(e.target.value)
                                    }
                                />
                        
                            </Col>
                            
                            
                        )}
                        {props.match.params.type === "thirdparty" && (
                            <Col className="mb-3" sm="12" md="6">
                                <Form.Label>{t("facebook")}</Form.Label>
                                <Form.Control
                                    placeholder={t("facebook")}
                                    name="facebook"
                                    value={third_fb}
                                    onChange={(e) =>
                                        setThird_fb(e.target.value)
                                    }
                                />
                        
                            </Col>
                            
                            
                        )}
                        {props.match.params.type === "thirdparty" && (
                            <Col className="mb-3" sm="12" md="6">
                                <Form.Label>{t("twitter")}</Form.Label>
                                <Form.Control
                                    placeholder={t("twitter")}
                                    name="twitter"
                                    value={third_twitter}
                                    onChange={(e) =>
                                        setThird_twitter(e.target.value)
                                    }
                                />
                        
                            </Col>
                            
                            
                        )}
                        {props.match.params.type === "thirdparty" && (
                            <Col className="mb-3" sm="12" md="6">
                                <Form.Label>{t("instegram")}</Form.Label>
                                <Form.Control
                                    placeholder={t("instegram")}
                                    name="instegram"
                                    value={third_ig}
                                    type="number"
                                    onChange={(e) =>
                                        setThird_ig(e.target.value)
                                    }
                                />
                        
                            </Col>
                            
                            
                        )}
                    </Row>
                    <Row>
                        <Col className="mb-3" sm="12" md="6">
                            <Form.Label>{t("phone")}</Form.Label>
                            <p className="note">{t("phonePlaceholder")}</p>
                            <InputGroup>
                                <InputGroup.Text>{prefix}</InputGroup.Text>
                                <Form.Control
                                    placeholder={t("altPhone")}
                                    name="Phone2"
                                    value={phone2?.split(prefix)?.[1]}
                                    // disabled
                                    validation={{
                                        length: 9,
                                        required: true,
                                        pattern: smallPattern,
                                    }}
                                    onChange={(e) =>
                                        setPhone2(`${prefix}${e.target.value}`)
                                    }
                                />
                            </InputGroup>
                        </Col>

                        {props.match.params.type === "thirdparty" && (
                            <Col  sm="12" md="6">
                                    <Form.Group>
                                        <Form.Label>{t("approvalstatus")}</Form.Label>
                                        <Form.Check
                                            type="switch"
                                            id="approvalSwitch"
                                            checked={third_active}
                                            onChange={(e) => setThird_active(e.target.checked)}
                                            style={{marginTop: "1.5rem"}}
                                        />
                                    </Form.Group>
                             </Col>
                        
                            
                        )}

                        
                        {props.match.params.type === "manager" && selectedUserState.length > 0 && selectedUserState[0].type === "Marketer" && (
                        <Row>
                            <Col className="mb-3" sm="12" md="6">
                                <Form.Label>{t("approvalstatus")}</Form.Label>
                                <InputGroup>
                                        <Form.Select
                                            name="approval status"
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                        >
                                            <option value="1">{t("Pending")}</option>
                                            <option value="2">{t("Approved")}</option>
                                            <option value="3">{t("Rejected")}</option>
                                        </Form.Select>
                                    </InputGroup>
                             </Col>
                        
                        <Col className="mb-3" sm="12" md="6">
                            <Form.Label>{t("commission")}</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    name="commission"
                                    value={marketer.commission}
                                    onChange={(e) => updateCommission(e.target.value)}
                                />
                                
                            </InputGroup>
                        </Col>
                        <Col className="mb-3" sm="12" md="6">
                            <Form.Label>{t("invitaioncode")}</Form.Label>
                            <Form.Control
                                    name="invitaion code"
                                    value={marketer.invitation_code}
                                    readOnly 
                                /> 
                        </Col>
                            <Col>
                                <p></p>
                                <Button className="confirm mt-3 text-center" onClick={showModal}>
                                    {t("bankaccount")} 
                                </Button>
                                <Modal
                                    title="-"
                                    open={open}
                                    onOk={handleOk}
                                    confirmLoading={confirmLoading}
                                    onCancel={handleCancel}
                                >    
                                    <p>Full Name :{bank.full_name}</p>
                                    <p>IBAN : {bank.IBAN}</p>
                                    <p>Account Number :{bank.bank_account_number}</p>
                                    <p>Bank Name :{bank.bank_name}</p>
                                    <p>Accept Terms :{bank.accept_terms}</p>
                                </Modal>
                            </Col>
                        </Row> 
                        )}          
                        {props.match.params.type === "seller" && (
                            <Col className="mb-3" sm="12" md="6">
                                <Form.Label>{t("deliveryPrice")}</Form.Label>
                                <Form.Control
                                    placeholder={t("deliveryFees")}
                                    name="deliveryFees"
                                    value={deliveryFees}
                                    type="number"
                                    onChange={(e) =>
                                        setDeliveryFees(e.target.value)
                                    }
                                />
                        
                            </Col>
                            
                            
                        )}
                    
                        {props.match.params.type === "seller" && (
                          

                            <Col className="mb-3" sm="12" md="6">
                                <Form.Label>{t("tax_number")}</Form.Label>
                                <Form.Control
                                    placeholder={t("tax_number")}
                                    name={t("tax_number")}
                                    value={taxNumber}
                                    type="text"  // Change the type to "text" to support 15-digit numbers
                                    onChange={(e) => {
                                        const input = e.target.value;
                                        if (validateTaxNumber(input)) {
                                            setTaxnumber(input);
                                        }
                                    }}
                                />
                                {/* Display a validation message if needed */}
                                {!validateTaxNumber(taxNumber) && (
                                    <p className="text-danger">رقم التسجيل الضريبي يجب ان يتكون من 15 رقم و يبدأ ب 3 و ينتهي ب 3</p>
                                )}
                            </Col>
                            
                            
                        )}
                     {/* 
                        )} */}
                        
                        {props.match.params.type === "seller"  &&(
                            <Col className="mb-3" sm="12" md="6">
                                <Form.Label>{t("invitaioncode")}</Form.Label>
                                <Form.Control
                                    name="invitaion_code"
                                    placeholder={t("invitaioncode")}
                                    value={invitationcode}
                                    onChange={(e) => {
                                        console.log(selectedUserState[0].invitation_code);
                                        setInvitaionCode(e.target.value);
                                    }}
                                    
                                />
                            </Col>
                        )}
                        
                    </Row>
                   
                    <Row>
                    {props.match.params.type === "seller" && (
                        <>
                            <Col className="mb-3" sm="12" md="6">
                                <Form.Label>
                                    {t("commercial_account")}
                                </Form.Label>
                                <Form.Control
                                    placeholder={t(
                                        "commercial_account_placeholder"
                                    )}
                                    name="commercial_account"
                                    value={commercialAccount}
                                    onChange={(e) =>
                                        setCommercialAccount(e.target.value)
                                    }
                                />
                            </Col>

                            <Col className="mb-3" sm="12" md="6" >
                                <Form.Label>
                                {t(
                                        "seller_type"
                                    )}
                                    
                                </Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="seller-type-switch"
                                    label={seller_type === '1' ? t("company") : t('individual')}
                                    checked={seller_type === '1'}
                                    disabled={seller_type === '1'}
                                    onChange={handleSwitchChange}
                                    
                                />
                            </Col>
                        </>
                        
                    )}
                    {props.match.params.type == 'seller'&&(
                         
                                <Col className="mb-3" sm="12" md="6">
                            
                                
                                    
                                    
                                    <div className=" position-relative providers-list">
                                        {shipmentProviders.map((provider , index) => (
                                            
                                            <div key={provider.id} className='provider-card'>
                                                
                                                <span>
                                                    {provider.shipment_provider_name}
                                                </span>
                                            
                                                <Form.Control
                                                placeholder = "fees"
                                                name= "provider price"
                                                type="number"
                                                id={`price-${provider.id}`}
                                                value={provider.price}
                                                onChange={(e) => {
                                                    const updatedProviders = [...shipmentProviders];
                                                    updatedProviders[index].price = e.target.value;
                                                    setShipmentProviders(updatedProviders);
                                                }}
                                                />
                                            
                                            </div>
                                        ))}
                                    </div>
                                </Col>
                    )}
                    
                    </Row>
                    
                    {props.match.params.type === "seller" &&
                    oldAddresses.length > 0 ? (
                        oldAddresses.map((address, i) => {
                            return (
                                <div
                                    style={{ position: "relative" }}
                                    className="addressCard mt-2"
                                >
                                    <div
                                        role="button"
                                        onClick={() => {
                                            setDeletedAddresses((prev) => [
                                                ...prev,
                                                address.id,
                                            ]);

                                            let copy = [...oldAddresses];
                                            copy.splice(i, 1);
                                            setOldAddresses(copy);
                                        }}
                                        style={{
                                            position: "absolute",
                                            top: "0.75rem",
                                            left: "0.75rem",
                                            color: "#57BE6C",
                                            fontSize: "1.2em",
                                        }}
                                    >
                                        <i class="fas fa-times-circle"></i>
                                    </div>
                                    <Row>
                                        <Col sm="12" md="6">
                                            <label>{t("area")}</label>

                                            <SelectAreas
                                                style={{
                                                    display: "block",
                                                }}
                                                onChange={(e) =>
                                                    handleSelectChange(
                                                        e,
                                                        i,
                                                        "area",
                                                        oldAddresses,
                                                        setOldAddresses
                                                    )
                                                }
                                                initialOptions={areas.old[i]}
                                                showSearch={true}
                                                value={
                                                    address.area
                                                        ? +address.area
                                                        : undefined
                                                }
                                                placeholder={t("area")}
                                                aria-label="Default select example"
                                            >
                                            </SelectAreas>
                                        </Col>
                                        <Col sm="12" md="6">
                                            <label>{t("address")}</label>
                                            <Input
                                                placeholder="Address Description"
                                                name="Address Description"
                                                value={address.address}
                                                onChange={(e) => {
                                                    let copy = [
                                                        ...oldAddresses,
                                                    ];
                                                    copy[i].address =
                                                        e.target.value;
                                                    setOldAddresses(copy);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            );
                        })
                    ) : props.match.params.type === "seller" ? (
                        <div className="my-3">
                            <Spin
                                indicator={
                                    <LoadingOutlined
                                        style={{
                                            fontSize: 24,
                                            color: "#5c55a5",
                                        }}
                                        spin
                                    />
                                }
                            />
                        </div>
                    ) : null}
                    {props.match.params.type === "seller"
                        ? newAddresses.map((address, i) => {
                              return (
                                  <div
                                      style={{ position: "relative" }}
                                      className="addressCard mt-2"
                                  >
                                      <div
                                          role="button"
                                          onClick={() => {
                                              let copy = [...newAddresses];
                                              copy.splice(i, 1);
                                              setNewAddresses(copy);
                                          }}
                                          style={{
                                              position: "absolute",
                                              top: "0.75rem",
                                              left: "0.75rem",
                                              color: "#57BE6C",
                                              fontSize: "1.2em",
                                          }}
                                      >
                                          <i class="fas fa-times-circle"></i>
                                      </div>
                                      <Row>
                                          <Col sm="12" md="6">
                                              <label>{t("area")}</label>
                                              <SelectAreas
                                                style={{
                                                    display: "block",
                                                }}
                                                onChange={(e) =>
                                                    handleSelectChange(
                                                        e,
                                                        i,
                                                        "area",
                                                        newAddresses,
                                                        setNewAddresses
                                                    )
                                                }
                                                initialOptions={areas.old[i]}
                                                showSearch={true}
                                                value={
                                                    address.area
                                                        ? +address.area
                                                        : undefined
                                                }
                                                placeholder={t("area")}
                                                aria-label="Default select example"
                                            >
                                            </SelectAreas>
                                          </Col>
                                      
                                          <Col sm="12" md="6">
                                              <label>{t("address")}</label>
                                              
                                              <Input
                                                  placeholder={t("address")}
                                                  name="Address Description"
                                                  value={address.address}
                                                  onChange={(e) => {
                                                      let copy = [
                                                          ...newAddresses,
                                                      ];
                                                      copy[i].address =
                                                          e.target.value;
                                                      setNewAddresses(copy);
                                                  }}
                                              />
                                          </Col>
                                      </Row>
                                  </div>
                              );
                          })
                        : null}
                    {props.match.params.type === "seller" ? (
                        <div
                            className="confirm mt-3 text-center"
                            role="button"
                            onClick={addNewAddress}
                        >
                            <i className="fas fa-plus-circle"></i>{" "}
                            {t("addAddress")}
                        </div>
                    ) : null}
                    <button onClick={edit} className="confirm mt-3">
                        {t("saveChanges")}
                    </button>


                    <Row>
                    {props.match.params.type === "thirdparty" && (
                        
                            <Col className="mb-3" sm="12" md="6">
                                <Form.Label>API KEY</Form.Label>
                                <div className="d-flex align-items-center">
                                    <Form.Control
                                        name="api_key"
                                        value={api_key}
                                        readOnly
                                        
                                    />
                                    <Button
                                        variant="primary"
                                        onClick={() => copyToClipboard(api_key)}
                                        className="ml-2"
                                    >
                                         <CopyOutlined />
                                    </Button>

                                </div>
                                    <Button
                                        variant="secondary"
                                        onClick={() => 
                                            handleRegeneration("api_key")
                                        }
                                        className="confirm mt-3"
                                    >
                                        Regenerate
                                    </Button>
                                
                            </Col>
                        
                     )}
                       {props.match.params.type === "thirdparty" && (
                        
                            <Col className="mb-3" sm="12" md="6">
                            <Form.Label>HMAC SECRET</Form.Label>
                                <div className="d-flex align-items-center">
                                    <Form.Control
                                        name="hmac_secret"
                                        value={hmac_secret}
                                        readOnly
                                        
                                    />
                                    <Button
                                        variant="primary"
                                        onClick={() => copyToClipboard(hmac_secret)}
                                        className="ml-2"
                                    >
                                         <CopyOutlined />
                                    </Button>
                                </div>
                                    <Button
                                        variant="secondary"
                                        onClick={() => 
                                            handleRegeneration('hmac_secret')
                                        }
                                        className="confirm mt-3"
                                    >
                                        Regenerate
                                    </Button>
                            </Col>
                        
                     )}
                     </Row>
                </Form>
            </Container>
            <SuccessfulRequest
                show={show}
                hide={hideModel}
                title={
                    lang == "ar" ? "تحديث معلومات المستخدم" : "Update User Info"
                }
                body={
                    lang == "ar"
                        ? "تم تحديث المعلومات بنجاح"
                        : "This user has been updated successfully"
                }
            />
        </div>
    );
};

export default ErrorHandler(AccountInfo, axiosInstance);
