import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../api/axios";
import { useState, useEffect } from "react";
import TablePro from "../../tables/TablePro";
import { useSupportTicketColumns } from "../../layout/tableColumns";
import { useLocation } from "react-router-dom";
import errorHandler from "../../../error-handler/ErrorHandler";
import { notification } from "antd";
import SupportTicketCommentsModal from "./support-ticket-comments-modal";
import { toast, ToastContainer } from "react-toastify";
import useLanguage from "../../../hooks/useLanguage";

function AllSupportTickets() {
    const deleteSupportTicket = async (id) => {
        await axiosInstance.delete(`/SupportTicket/${id}`).catch((err) => {
            if (err.response.data) {
                Object.entries(err.response.data).forEach(([key, value]) => {
                    notification.error({
                        message: key,
                        description: value,
                        placement: "bottomRight",
                    });
                });
            } else {
                notification.error({
                    message: "Error",
                    description: "Something went wrong",
                    placement: "bottomRight",
                });
            }
        });
        fetchSupportTickets();
    };

    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(1);
    const [search, setSearch] = useState();
    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [commentTicketIndex, setCommentTicketIndex] = useState();
    const location = useLocation();
    const columns = useSupportTicketColumns(
        deleteSupportTicket,
        (ticketIndex) => {
            console.log({ ticketIndex });
            setCommentTicketIndex(ticketIndex);
        }
    );

    const prepareFetchUrlQuery = () => {
        let url;
        if (location.pathname === "/all_support_tickets") {
            url = "/FilterSupportTicket/?";
            if (search) {
                url += `search=${search}&`;
            }
            url += `page=${page}`;
        } else {
            url = "/HistoricalSupportTicket/deleted";
        }
        return url;
    };

    const fetchSupportTickets = async () => {
        setLoading(true);
        const result = await axiosInstance
            .get(prepareFetchUrlQuery())
            .catch((err) => {
                console.log(err.response.data);
            });
        if (result) {
            setCount(result.data.count);
            setData(result.data.data);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchSupportTickets();
    }, [search, page, location.pathname]);
    const lang = useLanguage();

    async function sendMessage(ticket) {
        try {
            const result = await axiosInstance.put(
                `/SupportTicket/${ticket.id}`,
                { ...ticket, shipment_order: ticket.shipment_order?.id }
            );
            console.log({ result });
            return true;
        } catch (error) {
            console.log({ error });
            toast.error(
                lang.includes("en")
                    ? "Couldn't publish your comment, Please try again later"
                    : "لم نتمكن من نشر تعليقك ، يرجى المحاولة مرة أخرى في وقت لاحق"
            );
            return false;
        }
    }

    return (
        <div>
            <ToastContainer
                position={lang.includes("en") ? "bottom-right" : "bottom-left"}
            />
            <SupportTicketCommentsModal
                ticket={
                    commentTicketIndex !== undefined
                        ? data[commentTicketIndex]
                        : undefined
                }
                onClose={() => {
                    setCommentTicketIndex(undefined);
                }}
                onSendComment={async (message, userTypeId) => {
                    const newTickets = [...data];
                    newTickets[commentTicketIndex].replies.push({
                        reply: message,
                        reply_by: userTypeId,
                    });
                    setData(newTickets);
                    const isCommentSent = await sendMessage(
                        newTickets[commentTicketIndex]
                    );
                    console.log({ isCommentSent });
                    if (!isCommentSent) {
                        setData((prev) => {
                            const newData = [...prev];
                            newData[commentTicketIndex].replies.pop();
                            return newData;
                        });
                    }
                }}
            />
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">
                        {t("sideMenu.supportTickets")}
                    </span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">
                        {location.pathname === "/all_support_tickets"
                            ? t("sideMenu.allSupportTickets")
                            : t("sideMenu.archivedSupportTickets")}
                    </span>
                </Col>
                <Col className="titleDisplay" sm="12">
                    <h2 className="pageTitle mt-2">
                        {location.pathname === "/all_support_tickets"
                            ? t("sideMenu.allSupportTickets")
                            : t("sideMenu.archivedSupportTickets")}
                    </h2>
                </Col>
            </Row>
            <div>
                <TablePro
                    loading={loading}
                    // headerExtra={
                    //     <div className="row-centered-horizontal gap-1">
                    //         {Object.keys(props.filters ?? {}).map((key) => (
                    //             <Select
                    //                 key={key}
                    //                 onChange={(v) => {
                    //                     setFilters({
                    //                         ...filters,
                    //                         [key]: v,
                    //                     });
                    //                 }}
                    //                 value={
                    //                     props.filters[key]?.selectedValue
                    //                 }
                    //                 allowClear
                    //                 style={{ minWidth: "150px" }}
                    //                 placeholder={props.filters[key].title}
                    //             >
                    //                 {props.filters[key].values.map((it) => (
                    //                     <Select.Option
                    //                         key={it.id}
                    //                         value={it.id}
                    //                     >
                    //                         {it.name}
                    //                     </Select.Option>
                    //                 ))}
                    //             </Select>
                    //         ))}
                    //     </div>
                    // }
                    selection={"radio"}
                    columns={columns}
                    dataSource={data}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    pagination={{
                        current: page,
                        total: count,
                        onChange: (page) => setPage(page),
                    }}
                    // onRowClick={(i, record) => {
                    //     setSelectedOrder([record]);
                    // }}
                    searchPlaceholder={t("search")}
                    onSearch={setSearch}
                />
            </div>
        </div>
    );
}

export default errorHandler(AllSupportTickets, axiosInstance);
