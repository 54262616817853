import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../api/axios";
import { Row, Col } from "react-bootstrap";
import TablePro from "../../tables/TablePro";
import { useSellerWalletColumns } from "../../layout/tableColumns";
import errorHandler from "../../../error-handler/ErrorHandler";

function AllSellerWallets() {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(1);
    const [search, setSearch] = useState();
    const [selectedRows, setSelectedRows] = useState([]);
    const columns = useSellerWalletColumns();

    const fetchWallets = async () => {
        setLoading(true);
        const result = await axiosInstance
            .get(`/SellerWallet/`, {
                params: {
                    page: page,
                    search: search,
                },
            })
            .catch((err) => {
                console.log(err);
            });
        if (result) {
            setCount(result.data.count);
            setData(result.data.data);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchWallets();
    }, [page, search]);

    return (
        <div>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.wallet")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">
                        {t("sideMenu.allSellerWallet")}
                    </span>
                </Col>
                <Col className="titleDisplay" sm="12">
                    <h2 className="pageTitle mt-2">
                        {t("sideMenu.allSellerWallet")}
                    </h2>
                </Col>
            </Row>
            <div>
                <TablePro
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    pagination={{
                        current: page,
                        total: count,
                        onChange: (page) => setPage(page),
                    }}
                    onSearch={(value) => {
                        setSearch(value);
                        setPage(1);
                    }}
                    searchPlaceholder={t("search")}
                    title={t("sideMenu.allSellerWallet")}
                />
            </div>
        </div>
    );
}

export default errorHandler(AllSellerWallets, axiosInstance);
