import React, {
    useState,
    useEffect,
    useCallback,
    useMemo,
    useRef,
} from "react";
import { Container, Row, Col } from "react-bootstrap";
import StatusCard from "../../../../components/layout/StatusCard";
import RequestsTable from "../../../../components/layout/RequestsTable";
import { Select, Slider, Spin } from "antd";
import ErrorHandler from "../../../../error-handler/ErrorHandler";
import axiosInstance from "../../../../api/axios";
import {
    pickupRequests,
    pickupConfirmed,
    pickupConfirmedArabic,
    pickupRequestsArabic,
} from "../../../../components/layout/tableColumns";
import {
    ConfirmationModal,
    DeleteModal,
    UnSuccessfulRequest,
} from "../../../../components/modals/confirmationModal";
import { useDispatch, useSelector } from "react-redux";
import { plans } from "../../../../global-state/actions/plan";
import { search } from "../../../../global-state/actions/searchAction";
import { selectedState } from "../../../../global-state/actions/stateAction";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { PaymentAlert } from "../../../../components/modals/confirmationModal";
import { LoadingOutlined } from "@ant-design/icons";
import Tour from "reactour";
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from "body-scroll-lock";
import useLanguage from "../../../../hooks/useLanguage";
import CardsHeader from "./CardsHeader";
import TableTitle from "./TableTitle";
import TablePro from "../../../../components/tables/TablePro";
import {
    useConfirmedRequestsColumns,
    useUnConfirmedRequestsColumns,
} from "./dashboardColumns";
import DashboardRequestsTable from "./DashboardRequestsTable";
import useQuery from "../../../../hooks/useQuery";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackwardIcon from "@mui/icons-material/ArrowBack";
// const lang = localStorage.getItem("lang");
const steps = [
    {
        selector: ".first-step",
        content: "This is my first Step",
    },
    // ...
];

const disableBody = (target) => disableBodyScroll(target);
const enableBody = (target) => enableBodyScroll(target);

const Dashboard = React.memo(() => {
    const { query, appendQuery } = useQuery();

    const isTableShown = query.details === "true";
    const isSearching = query.search;
    const lang = useLanguage();
    const history = useHistory();
    const user = useSelector((state) => state.auth.user);
    console.log({
        user,
    });
    const planDispatch = useDispatch();
    const { t } = useTranslation();
    const paymentPlan = useSelector((state) => state.plan.plan);

    const [showPaymentAlert, setPaymentAlert] = useState(false);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState();
    const [count, setCount] = useState(1);
    const [data, setData] = useState([]);
    const [plan, setPlan] = useState({});
    const [showConfirm, setShowConfirm] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [status, setStatus] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState();
    const [filters, setFilters] = useState({});
    console.log({ filters });
    const [nextState, setNextState] = useState({
        name: lang === "en" ? "Choose State" : "اختر الحالة",
        id: "",
        background: "",
    });
    const [tableSelection, setTableSelection] = useState([]);
    const defaultState = useMemo(
        () => ({
            count: 1,
            state: {
                color: "#CEE1FF",
                accent_color: "#00000",
                description: null,
                id: 1,
                title: "Pickup Request",
                headers: [],
                title_ar: "معاملات غير مكتملة",
            },
        }),
        []
    );
    const [currentState, setCurrentState] = useState(defaultState);
    const currentStateTitle = lang.includes("en")
        ? currentState?.state?.title
        : currentState?.state?.title_ar;
    const unConfirmedStates = {
        pickup: 5,
        return: 8,
    };
    const isUnConfirmedRequest = useMemo(
        () =>
            isSearching
                ? false
                : Object.values(unConfirmedStates).includes(
                      +(currentState.state?.type ?? 5)
                  ),
        [currentState.state?.type, isSearching]
    );

    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    useEffect(() => {
        if (!showConfirm && !showDelete) {
            getSummary();
            selectState(currentState);
            if (isUnConfirmedRequest) {
                getPlans();
            }
            fetchTableData();
        }
    }, [showConfirm, showDelete, pageSize]);

    const getPlans = async () => {
        let result = await axiosInstance
            .get("/PlanConsumption/")
            .catch((err) => console.log(err));

        if (result) {
            console.log(result.data[0]);
            planDispatch(plans(result.data[0]));
            setPlan(result.data[0]);
        }
    };

    const getSummary = async () => {
        setLoading(true);
        let result = await axiosInstance.get("/Summary/").catch((err) => {
            console.log(err);
            if (err.response?.status === 401) {
                window.location.reload();
            }
        });

        if (result) {
            console.log(result.data);
            setStatus(result.data);
            console.log(result.data);
        }
        setLoading(false);
    };
    const showConfirmModal = () => {
        setShowConfirm(true);
    };
    const showDeleteModal = () => {
        setShowDelete(true);
    };
    const hideModal = () => {
        setShowConfirm(false);
        setShowDelete(false);
        setNextState({
            ...nextState,
            name: lang === "en" ? "Choose State" : "اختر الحالة",
            id: "",
        });
    };
    useEffect(() => {
        appendQuery("details", "false");
        setFilters({});
    }, []);

    async function fetchTableData() {
        setTableLoading(true);
        let result;

        const stateType = +currentState.state.type;

        const extractOrderType = () => {
            console.log({ stateType });

            if (stateType === unConfirmedStates.pickup) return 1;
            else if (stateType === unConfirmedStates.return) return 2;
            else if (stateType === unConfirmedStates.replace) return 3;
            else return undefined;
        };
        if (isUnConfirmedRequest && !isSearching) {
            result = await axiosInstance.get("/FilterOrder/", {
                params: {
                    page,
                    page_size: pageSize,
                    state: 3,
                    // order_type: extractOrderType(),
                    create_date__gte: filters.start_date,
                    create_date__lte: filters.end_date,
                    client__name: filters.client_name,
                    search: filters.id,
                    track_number : filters.track_number
                },
            });
        } else {
            result = await axiosInstance.get("/FilterTicket/", {
                params: {
                    page,
                    page_size: pageSize,
                    state: isSearching ? undefined : currentState.state?.id,
                    search: filters.id,
                    create_date__gte: filters.start_date,
                    create_date__lte: filters.end_date,
                    order__client__name: filters.client_name,
                    track_number : filters.track_number
                },
            });
        }
        if (result) {
            setData(result.data.data);
            setCount(result.data.count);
        }
        setTableLoading(false);
    }

    function selectState(newState) {
        appendQuery("details", "true");
        setFilters({});
        setCurrentState(newState);
        setTableSelection([]);
        setPage(1);
        setSearch(undefined);
    }

    useEffect(() => {
        fetchTableData();
    }, [currentState, search, page, pageSize, filters, isSearching]);

    const changeState = (e, v) => {
        console.log(e);
        console.log(v);
        console.log({ v });
        if (v.type === unConfirmedStates.return) {
            history.push("/return_ticket");
        } else {
            setNextState({
                ...nextState,
                name: e,
                id: v.id,
                background: v.style.background,
            });
            showConfirmModal();
        }
    };

    useEffect(() => {
        if (paymentPlan?.orders_count >= paymentPlan?.orders_remaining) {
            setPaymentAlert(true);
        }
        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    const closeTour = () => {
        setIsOpen(false);
    };
    const role = useSelector((state) => state.auth.user.role);

    return (
        <div>
            <Container>
                {!isTableShown ? (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {role !== "Marketer" ?
                            <NavLink to={"/dashboard?details=true&search=true"}>
                                <button className={"searchButton"}>
                                    <span> {t("search")}</span>
                                    <span className={"searchButtonIcon"}>
                                        {lang.includes("en") ? (
                                            <ArrowForwardIcon />
                                        ) : (
                                            <ArrowBackwardIcon />
                                        )}
                                        {/*<Icon*/}
                                        {/*    source={*/}
                                        {/*        lang.includes("en")*/}
                                        {/*            ? ArrowRightMinor*/}
                                        {/*            : ArrowLeftMinor*/}
                                        {/*    }*/}
                                        {/*    color="base"*/}
                                        {/*/>*/}
                                    </span>
                                </button>
                            </NavLink> : null } 
                        </div> 
                        <CardsHeader
                            selectState={selectState}
                            plan={plan}
                            currentStateTitle={currentStateTitle}
                            loading={loading}
                            status={status}
                        />
                    </div>
                ) : (
                    <Row>
                        <TableTitle
                            currentStateTitle={
                                isSearching
                                    ? t("search")
                                    : lang.includes("en")
                                    ? currentState.state.title
                                    : currentState.state.title_ar
                            }
                        />
                        <Col sm="12">
                            <DashboardRequestsTable
                                setFilters={setFilters}
                                currentState={currentState}
                                isUnConfirmedRequest={isUnConfirmedRequest}
                                data={data}
                                onSearch={setSearch}
                                currentStateTitle={currentStateTitle}
                                onRefresh={() => fetchTableData()}
                                loading={tableLoading}
                                selected={tableSelection}
                                setSelected={setTableSelection}
                                pagination={{
                                    current: page,
                                    total: count,
                                    pageSize: pageSize,
                                    defaultPageSize: 10,
                                    pageSizeOptions: [10, 30, 50, 80, 100],
                                    onChange: (page, pageSize) => {
                                        setPage(page);
                                        setPageSize(pageSize);
                                    },
                                }}
                            >
                                {currentState?.state?.type === 5 &&
                                    user.role === "Organization Admin" && (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                marginTop: "1.2rem",
                                            }}
                                        >
                                            <button
                                                disabled={
                                                    tableSelection.length === 0
                                                }
                                                onClick={showConfirmModal}
                                                className="confirm"
                                            >
                                                {t("confirm")}
                                            </button>
                                        </div>
                                    )}
                            </DashboardRequestsTable>
                        </Col>
                    </Row>
                )}

                <ConfirmationModal
                    isUnConfirmedRequest={isUnConfirmedRequest}
                    currentState={currentState}
                    nextState={nextState}
                    selectedCount={tableSelection.length}
                    selectedOrders={tableSelection}
                    show={showConfirm}
                    onError={setError}
                    hide={hideModal}
                />
                <DeleteModal
                    currentState={currentState}
                    selectedOrders={tableSelection}
                    nextState={nextState}
                    selectedCount={tableSelection.length}
                    show={showDelete}
                    hide={hideModal}
                />
                <PaymentAlert
                    setShow={setPaymentAlert}
                    show={showPaymentAlert}
                />
                <UnSuccessfulRequest
                    show={error}
                    hide={() => setError(undefined)}
                    title={t("changeStatusConfirmation")}
                    body={error}
                />
            </Container>
            <Tour
                steps={steps}
                isOpen={isOpen}
                onRequestClose={closeTour}
                onAfterOpen={disableBody}
                onBeforeClose={enableBody}
            />
        </div>
    );
});
export default ErrorHandler(Dashboard, axiosInstance);
// {/*<TableTitle currentStateTitle={currentStateTitle} />*/}
// {/*  <Row>*/}
// {/*      {user.role === "Seller" ? null : (*/}
// {/*          <Col className="text-end" sm="12">*/}
// {/*              /!* <button disabled={orders.length === 0} style={{display : "inline-block",border : "none"}} onClick={()=>console.log("clicked")}>*/}
// {/*<Icon  className="mx-2" source={TransferWithinShopifyMajor} color="base" />*/}
// {/*</button> *!/*/}
// {/*              {[1, 8].includes(*/}
// {/*                  +(currentState.state?.id ?? 1)*/}
// {/*              ) ? (*/}
// {/*                  <button*/}
// {/*                      disabled={*/}
// {/*                          tableSelection.length !== 0*/}
// {/*                              ? false*/}
// {/*                              : true*/}
// {/*                      }*/}
// {/*                      onClick={showDeleteModal}*/}
// {/*                      className="cancel"*/}
// {/*                  >*/}
// {/*                      {t("cancelOrder")}*/}
// {/*                  </button>*/}
// {/*              ) : null}*/}
//
// {/*              {!isUnConfirmedRequest ? (*/}
// {/*                  currentState.state.accepted_states.length >*/}
// {/*                  0 ? (*/}
// {/*                      <Select*/}
// {/*                          placeholder={t("changeStatus")}*/}
// {/*                          className="changeStatusSelect"*/}
// {/*                          onChange={changeState}*/}
// {/*                          value={nextState.name}*/}
// {/*                          disabled={*/}
// {/*                              tableSelection.length !== 0*/}
// {/*                                  ? false*/}
// {/*                                  : true*/}
// {/*                          }*/}
// {/*                      >*/}
// {/*                          /!* <Select.Option selected hidden value={0}>Change Status</Select.Option> *!/*/}
// {/*                          {currentState.state.accepted_states.map(*/}
// {/*                              (state) => {*/}
// {/*                                  return (*/}
// {/*                                      <Select.Option*/}
// {/*                                          style={{*/}
// {/*                                              background:*/}
// {/*                                                  state.color,*/}
// {/*                                              marginBottom: "5px",*/}
// {/*                                              padding: "5px",*/}
// {/*                                              color: "black",*/}
// {/*                                              fontWeight: "bold",*/}
// {/*                                          }}*/}
// {/*                                          value={*/}
// {/*                                              lang === "en"*/}
// {/*                                                  ? state.title*/}
// {/*                                                  : state.title_ar*/}
// {/*                                          }*/}
// {/*                                          id={state.id}*/}
// {/*                                      >*/}
// {/*                                          {lang === "en"*/}
// {/*                                              ? state.title*/}
// {/*                                              : state.title_ar}*/}
// {/*                                      </Select.Option>*/}
// {/*                                  );*/}
// {/*                              }*/}
// {/*                          )}*/}
// {/*                      </Select>*/}
// {/*                  ) : null*/}
// {/*              ) : (*/}
// {/*                  <button*/}
// {/*                      disabled={*/}
// {/*                          tableSelection.length !== 0*/}
// {/*                              ? false*/}
// {/*                              : true*/}
// {/*                      }*/}
// {/*                      onClick={showConfirmModal}*/}
// {/*                      className="confirm"*/}
// {/*                  >*/}
// {/*                      {t("confirm")}*/}
// {/*                  </button>*/}
// {/*              )}*/}
// {/*          </Col>*/}
// {/*      )}*/}
// {/*  </Row>*/}
