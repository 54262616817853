import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import axiosInstance from '../../api/axios';
import { Form, Row, Col, InputGroup  , Stack, Container , FormControl} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';

const MarketerRegisterForm = () => {

  const { t } = useTranslation();
  const history = useHistory();
  const [errors, setErrors] = useState({}); 
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    type: '3', 
    org_id : 1,
    branch_id : 1,
    phone: '',
    type_name : 'marketer',
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
      
  //     const response = await axiosInstance.post('/MarketerOrgEmployee/', formData);
  //     console.log('Registration successful', response.data);

  //     history.push('/login');
  //   } catch (error) {
  //     // Handle registration error 
  //     toast.error('Registration failed',error.response.data);
  //   }
  // };

  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password.length < 9) {
      toast.error('Password must be at least 9 characters long.');
      return;
    }

    if (!/[A-Z]/.test(formData.password)) {
      toast.error('Password must contain at least one uppercase letter.');
      return;
    }
  
    if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(formData.password)) {
      toast.error('Password must contain at least one special character.');
      return;
    }
  
  
    try {
      const response = await axiosInstance.post('/MarketerOrgEmployee/', formData);
      console.log('Registration successful', response.data);
  
      history.push('/login');
    }  catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        if (error.response.status === 400) {
          // Validation errors from the server
          const backendErrors = error.response.data;
          console.log(backendErrors);
          if (backendErrors && typeof backendErrors === 'object') {
            // // Update the form data with errors for each field
            // setFormData((prevData) => ({
            //   ...prevData,
            //   ...backendErrors,
            // }));
  
            // Display toast notifications for each field error
            Object.keys(backendErrors).forEach((fieldName) => {
              toast.error(`${fieldName}: ${backendErrors[fieldName]}`);
            });
          } else {
            // Handle unexpected format of validation errors
            toast.error('Unexpected format of validation errors from the server.');
          }
        } else {
          // Handle other types of errors
          toast.error('Registration failed', error.response.data.message);
        }
      } else {
        // Handle network errors
        toast.error('Network error. Please try again later.');
      }
    }
  };
  

   useEffect(() => {
        localStorage.setItem("lang", "en");
    }, []);

  return (
    <div className="loginContainer">
       <ToastContainer />
    <Container className="pageContainer">
        <Stack gap={1} className="col-md-10 mx-auto">
                <form className="text-center" onSubmit={handleSubmit}>

                <p className="form-title">{t("signUp")}</p>
                <div className="field-wrapper">
                     <i class="fas fa-lg fa-user"></i>
                      <Form.Control
                          type="text"
                          name="name"
                          placeholder="Name"
                          validation={{ required: true }}
                          value={formData.name}
                          onChange={handleChange}
                      />
                </div>
                <div className="field-wrapper">
                        <i class="fas fa-envelope"></i>
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder="Email"
                            validation={{ required: true }}
                            value={formData.email}
                            onChange={handleChange}
                        />
                  </div>

                  <div className="field-wrapper">
                      <i class="fas fa-phone"></i> 
                      <Form.Control
                          type="phone"
                          name="phone"
                          placeholder="Phone Numbber"
                          validation={{ required: true }}
                          value={formData.phone}
                          onChange={handleChange}
                      />
                  </div>
                  {/* <div className="field-wrapper">
                      <i class="fas fa-lock"></i>
                      <Form.Control
                          type="password"
                          name="password"
                          placeholder="Password"
                          validation={{ required: true, minLength: 9 }}
                          value={formData.password}
                          onChange={handleChange}
                      />

                  </div> */}

                  <div className="field-wrapper">
                        <InputGroup>
                        <i class="fas fa-lock"></i>
                          <FormControl
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            placeholder="Password"
                            validation={{ required: true, minLength: 9 }}
                            value={formData.password}
                            onChange={handleChange}
                          />
                          <InputGroup.Text>
                            <i className={`far ${showPassword ? 'fa-eye-slash' : 'fa-eye'} password-toggle-icon`} onClick={handleTogglePassword}></i>
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                  
               
                  <button
                      
                      className="confirm mt-4"
                      type="submit"
                  >
                      {t("register")}
                  </button>
                </form>
                </Stack>
            </Container>
            </div>
  );
};

export default MarketerRegisterForm;
