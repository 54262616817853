import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { Select } from "antd";
import { DataGrid } from "@mui/x-data-grid";
import "../../style-sheets/table.css";
import axiosInstance from "../../api/axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
// import { managerCol,sellerCol,courierCol } from "../../layout/tableColumns";
import { useSelector } from "react-redux";
import { CreationConfirmation } from "../modals/confirmationModal";
import {
    archivedTickets,
    archivedTickets_ar,
    useTicketsColumns,
} from "../layout/tableColumns";
import { useTranslation } from "react-i18next";
import useLanguage from "../../hooks/useLanguage";
import TablePro from "../tables/TablePro";

const ArchivedTickets = (props) => {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [page, setPage] = useState(1);
    const [keyword, setKeyword] = useState("");
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null);

    const { Option } = Select;
    const user = useSelector((state) => state.auth.user);

    async function fetchData() {
        let result = await axiosInstance.get("/FilterHistoricalTickets/", {
            params: {
                page,
                search: keyword,
                order__branch__id:
                    user.role === "Seller" ? undefined : selectedBranch,
            },
        });
        if (result) {
            setData(result.data.data);
            setCount(result.data.count);
        }
    }

    const getBranches = async () => {
        const result = await axiosInstance.get("/Branch/", {
            params: { page_size: 1000000 },
        });
        if (result) {
            setBranches(result.data.data);
            setCount(result.data.count);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, keyword, selectedBranch]);

    useEffect(() => {
        getBranches();
    }, []);

    const { t } = useTranslation();
    const columns = useTicketsColumns().filter((it) =>
        [
            t("ticketId"),
            t("weight"),
            t("dimensions"),
            t("specialLabel"),
        ].includes(it.title)
    );

    return (
        <div>
            <Container>
                <Row className="mb-4">
                    <Col sm="12">
                        <span className="title1">{t("sideMenu.tickets")}</span>
                        <i class="fas fa-chevron-right px-3 title2"></i>
                        <span className="title2">{t("sideMenu.archived")}</span>
                    </Col>
                    <Col sm="12">
                        <h2 className="pageTitle">{t("sideMenu.archived")}</h2>
                    </Col>
                </Row>
                <TablePro
                    onSearch={setKeyword}
                    headerExtra={
                        <>
                            {user.role !== "Seller" && (
                                <Select
                                    onChange={setSelectedBranch}
                                    value={selectedBranch}
                                    style={{ width: 150 }}
                                    placeholder={t("sideMenu.branches")}
                                >
                                    {branches.map((branch) => {
                                        return (
                                            <Option
                                                name={branch.name}
                                                key={branch.id}
                                                value={branch.id}
                                            >
                                                {branch.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            )}
                        </>
                    }
                    dataSource={data}
                    columns={columns}
                    pagination={{
                        current: page,
                        total: count,
                        onChange: (page) => setPage(page),
                    }}
                />
            </Container>
        </div>
    );
};

export default ErrorHandler(ArchivedTickets, axiosInstance);
