import React, { useEffect, useState } from "react";
import Header from "./Header";
import ScanInformation from "./ScanInformation";
import { Button, Form, Select } from "antd";
import { useTranslation } from "react-i18next";
import TicketScanRepository from "../data";
import ErrorHandler from "../../../../error-handler/ErrorHandler";
import axiosInstance from "../../../../api/axios";
import { Col } from "react-bootstrap";
import WMButton from "../../../../components/pages/AllWorkModels/WMButton";
import { useHistory } from "react-router-dom";

export function TicketScanStart() {
    const { t } = useTranslation();
    const history = useHistory();
    const [couriers, setCouriers] = useState([]);

    async function fetchCouriers(search) {
        const res = await TicketScanRepository.getCouriers(search);
        if (res.success) setCouriers(res.data);
    }

    useEffect(() => {
        fetchCouriers();
    }, []);
    return (
        <main>
            <Header />
            <ScanInformation />

            <Form
                onFinish={({ courier }) => {
                    history.push(`/ticket_scan/${courier}`)
                }}
                layout="vertical"
                style={{ marginTop: "48px", width: "100%" }}
            >
                <Col sm={12} md={7} lg={6}>
                    <Form.Item
                        name="courier"
                        label={t("chooseCourier")}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            placeholder={t("searchCouriers")}
                            showSearch
                            onSearch={(v) => fetchCouriers(v)}
                        >
                            {couriers.map((option) => (
                                <Select.Option
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.user.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <WMButton
                    htmlType={"submit"}
                    style={{ width: "164px", marginTop: "32px" }}
                >
                    {t("next")}
                </WMButton>
            </Form>
        </main>
    );
}

export default ErrorHandler(TicketScanStart, axiosInstance);
