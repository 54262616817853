import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Switch, FormControlLabel } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Row, Col } from "react-bootstrap";
import "../../style-sheets/form.css";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axiosInstance from "../../api/axios";
import { useSelector, useDispatch } from "react-redux";
import SellerInfoForm from "../forms/sellerInfoForm";
import CustomerInfoForm from "../forms/customerInfoForm";
import ProductInfoForm from "../forms/productInfoForm";
import OrderInfoForm from "../forms/orderInfoForm";
import { useTranslation } from "react-i18next";
import { addOrder } from "../../global-state/actions/addOrderAction";
import InformationCard from "../layout/informationCard";
import { Icon } from "@shopify/polaris";
import {
    CircleDisableMinor,
    PackageMajor,
    SoftPackMajor,
    UploadMajor,
    CircleLeftMajor,
} from "@shopify/polaris-icons";
import { BASE_URL } from "../../api/axios";
import { SuccessfulRequest } from "../modals/confirmationModal";
import { useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
const CreateOrderTabs = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const clearDispatch = useDispatch();
    const cards = [
        {
            icon: PackageMajor,
            title: `${t("parcel")}`,
            description: `${t("orgSubTitle")}`,
            method: () => {
                setType("parcel");
            },
        },

        {
            icon: SoftPackMajor,
            title: `${t("bulk")}`,
            description: `${t("preferencesSubTitle")}`,
            method: () => {
                setType("bulk");
            },
        },
    ];
    const [value, setValue] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [type, setType] = useState("");
    const [errors, setErrors] = useState([]);
    const [show, setShow] = useState(false);
    const [msg, setMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [shippingType, setShippingType] = useState({
        weight: true,
        dimention: false,
    });

    const hide = () => {
        setShow(false);
        history.push("/dashboard");
    };
    const fileHandler = async (event) => {
        let fileObj = event.target.files[0];
        //just pass the fileObj as parameter
        let formdata = new FormData();
        formdata.append("file", fileObj);
        setLoading(true);
        let result = await axiosInstance
            .post(
                `/BulkOrder/?shipment_type=${
                    shippingType.weight ? "weight" : "dimention"
                }`,
                formdata
            )
            .catch((err) => {
                console.log(Object.entries(err.response.data));
                console.log(Object.values(err.response.data));

                setErrors(err.response.data);
                setLoading(false);
            });
        if (result) {
            setLoading(false);
            console.log(result);
            setMsg(result.data);
            setErrors([]);
            setShow(true);
            console.log("good");
        }
        event.target.value = null;
    };
    const handleChange = (event, newValue) => {
        setValue(parseInt(newValue));
    };

    const selectedSeller = useSelector(
        (state) => state.selectedData.selectedSeller
    );

    useEffect(() => {}, [selectedProduct]);
    const user = useSelector((state) => state.auth.user);

    const selectType = (type) => {
        setType(type);
    };
    const downloadTemp = async () => {
        let result = await axiosInstance.get(
            `/BulkOrder/?order_type=${
                shippingType.weight ? "weight" : "dimentions"
            }`,
            {
                responseType: "blob",
            }
        );
        if (result) {
            const url = window.URL.createObjectURL(result.data);
            const link = document.createElement("a");
            link.href = url;
            link.target = "blank";
            link.download = "bulk_order.xlsx";
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
    };
    return (
        <div>
            <Row className="mb-4">
                <Col sm="12">
                    <span className="title1">{t("sideMenu.tickets")}</span>
                    <i class="fas fa-chevron-right px-3 title2"></i>
                    <span className="title2">
                        {t("sideMenu.createNewTicket")}
                    </span>
                </Col>
                <Col className="titleDisplay" sm="12">
                    {type !== "" ? (
                        <div
                            className="back"
                            onClick={() => {
                                selectType("");
                                clearDispatch(addOrder(""));
                            }}
                        >
                            <Icon source={CircleLeftMajor} color="base" />
                        </div>
                    ) : null}
                    <h2 className="pageTitle">
                        {t("sideMenu.createNewTicket")}
                    </h2>
                </Col>
            </Row>
            {type == "" ? (
                <>
                    <Row>
                        {cards.map((card) => {
                            return (
                                <Col sm="6">
                                    <div className="setting-card">
                                        <div className="setting-icon">
                                            <Icon
                                                source={card.icon}
                                                color="base"
                                            />
                                        </div>
                                        <div>
                                            <p
                                                style={{ fontSize: "18px" }}
                                                className="setting-card-title"
                                                target="_blank"
                                                onClick={() => card.method()}
                                            >
                                                {card.title}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                </>
            ) : type === "parcel" ? (
                <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        {user.role == "Seller" ? (
                            <>
                                <Tabs
                                    value={value}
                                    // onChange={handleChange}
                                    aria-label="basic tabs example"
                                    className={`${
                                        user.role == "Seller"
                                            ? "seller"
                                            : "admin"
                                    }`}
                                >
                                    <Tab
                                        label={t("customerInfo")}
                                        {...a11yProps(0)}
                                    />
                                    <Tab
                                        label={t("ticketInfo")}
                                        {...a11yProps(1)}
                                    />
                                    <Tab
                                        label={t("orderInfo")}
                                        {...a11yProps(2)}
                                    />
                                </Tabs>
                                <TabPanel value={value} index={0}>
                                    <CustomerInfoForm
                                        value={value}
                                        handleChange={handleChange}
                                    />
                                </TabPanel>

                                <TabPanel value={value} index={1}>
                                    <ProductInfoForm
                                        value={value}
                                        handleChange={handleChange}
                                    />
                                </TabPanel>

                                <TabPanel value={value} index={2}>
                                    <OrderInfoForm
                                        value={value}
                                        handleChange={handleChange}
                                    />
                                </TabPanel>
                            </>
                        ) : (
                            <>
                                {" "}
                                <Tabs
                                    value={value}
                                    // onChange={handleChange}
                                    aria-label="basic tabs example"
                                    className={`${
                                        user.role == "Seller"
                                            ? "seller"
                                            : "admin"
                                    }`}
                                >
                                    <Tab
                                        label={t("sellerInfo")}
                                        {...a11yProps(0)}
                                    />
                                    <Tab
                                        label={t("customerInfo")}
                                        {...a11yProps(1)}
                                    />
                                    <Tab
                                        label={t("productInfo")}
                                        {...a11yProps(2)}
                                    />
                                    <Tab
                                        label={t("orderInfo")}
                                        {...a11yProps(3)}
                                    />
                                </Tabs>
                                <TabPanel value={value} index={0}>
                                    <SellerInfoForm
                                        value={value}
                                        handleChange={handleChange}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <CustomerInfoForm
                                        value={value}
                                        handleChange={handleChange}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <ProductInfoForm
                                        value={value}
                                        handleChange={handleChange}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                    <OrderInfoForm
                                        value={value}
                                        handleChange={handleChange}
                                    />
                                </TabPanel>
                            </>
                        )}
                    </Box>
                </Box>
            ) : (
                <>
                    <InformationCard
                        title={t("infoBulkTitle")}
                        subTitle={t("infoBulkSub")}
                        list={[
                            <p>{t("choose_template_type")}</p>,
                            <FormControlLabel
                                sx={{
                                    ".MuiSwitch-switchBase": {
                                        top: "8px",
                                    },
                                }}
                                control={
                                    <Switch
                                        checked={shippingType.dimention}
                                        onChange={() => {
                                            setShippingType({
                                                weight: !shippingType.weight,
                                                dimention:
                                                    !shippingType.dimention,
                                            });
                                        }}
                                        name="checkedB"
                                        color="success"
                                    />
                                }
                                label={
                                    shippingType.weight
                                        ? t("weight")
                                        : t("dimentions")
                                }
                            />,
                            <p>
                                {t("infoBulkStep1")}{" "}
                                <a
                                    // href={`${BASE_URL}/media/order_bulk${
                                    //     localStorage.getItem("lang") == "ar"
                                    //         ? "_ar"
                                    //         : ""
                                    // }.xlsx`}
                                    // target="_blank"
                                    onClick={downloadTemp}
                                >
                                    ({t("bulkHyperLink")})
                                </a>{" "}
                            </p>,
                            <p>{t("infoBulkStep2")}</p>,
                            <p>{t("infoBulkStep3")}</p>,
                        ]}
                    />

                    <div className="uploaderCard">
                        {loading ? (
                            <Spin />
                        ) : (
                            <Icon source={UploadMajor} color="base" />
                        )}

                        <p className="bulk-upload-title">
                            {t("uploadFileText")}
                        </p>
                        <p className="bulk-upload-sub">
                            <strong>{t("uploadFileSub")}</strong>
                        </p>
                        <input
                            disabled={loading}
                            type="file"
                            className="uploaderInput"
                            onChange={fileHandler}
                        />
                    </div>
                    {errors.length > 0 ? (
                        <Row>
                            <Col className="error-bg" sm={1}>
                                <p>{t("row")}</p>
                            </Col>
                            <Col className="error-bg">
                                <p>{t("issues")}</p>
                            </Col>
                        </Row>
                    ) : null}

                    {Object.keys(errors).map((issue) => {
                        return (
                            <Row>
                                <Col sm={1} className="error-bg p-2">
                                    <p>{issue}</p>
                                </Col>

                                <Col className="error-bg p-2">
                                    {errors[issue].map((field) => {
                                        console.log(field);
                                        console.log(Object.entries(field));

                                        return (
                                            <>
                                                <p className="error-icon">
                                                    <Icon
                                                        source={
                                                            CircleDisableMinor
                                                        }
                                                        color="base"
                                                    />
                                                    {Object.keys(field)[0]} :{" "}
                                                    {Object.values(field)[0]}
                                                </p>
                                            </>
                                        );
                                    })}
                                </Col>
                            </Row>
                        );
                    })}
                </>
            )}

            <SuccessfulRequest
                body={msg}
                title={t("bulkSuccessTitle")}
                show={show}
                hide={hide}
            />
        </div>
    );
};

export default ErrorHandler(CreateOrderTabs, axiosInstance);
