import React, { useState, useEffect } from "react";
import { Container, Row, Col , Stack } from "react-bootstrap";
import { Button } from "antd";
import loginImg from "../../images/loginImg.png";
import "../../style-sheets/login.css";
import ReactCodeInput from "react-verification-code-input";
import ReactInputVerificationCode from "react-input-verification-code";
import logoSeller from "../../images/box.png";
import logoAdmin from "../../images/box.png";
import arabicLogo from "../../images/box.png";
import logoSellerAr from "../../images/box.png";
import adminConfirm from "../../images/adminConfirm.png";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { BASE_URL } from "../../api/axios";
import { useDispatch, useSelector } from "react-redux";
import { code } from "../../global-state/actions/codeAction";

const ConfirmationCode = (props) => {
    const { t } = useTranslation();

    const email = useSelector((state) => state.code.code);
    const dispatch = useDispatch();
    const [codeState, setCode] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [counter, setCounter] = useState(60);
    const [disable, setDisable] = useState(false);
    const handleCode = (e) => {
        console.log(e);
        setCode(e);
    };

    const resendCode = async () => {
        setCounter(10);
        let result = await axios.post(BASE_URL + "/forgot_password", {
            email: email,
        });
    };
    const submit = async () => {
        setLoading(true);
        setError(null);
        let result = await axios
            .post(BASE_URL + "/code_response", { code: code })
            .catch((err) => {
                console.log(err.response);
                setError(err.response.data);
            });
        setLoading(false);
        console.log(code);
        dispatch(code(codeState));
        props.history.location.pathname == "/admin_confirm"
            ? props.history.push("/admin_reset_password")
            : props.history.push("/reset_password");
    };
    useEffect(() => {
        console.log("called");
        setTimeout(() => {
            if (counter > 0) {
                setDisable(true);
                setCounter((prev) => prev - 1);
            } else {
                setDisable(false);
                // c=60
            }
        }, 1000);
    }, [counter]);

    return (
        <div className="loginContainer">
            
            <Container className="pageContainer">
            <Stack gap={2} className="col-md-8 mx-auto">
                    {/*<Col md="6" sm="12">
                        {props.history.location.pathname == "/admin_confirm" ? (
                            <img src={adminConfirm} className="img-fluid" />
                        ) : (
                            <img src={adminConfirm} className="img-fluid" />
                        )}
                    </Col>
                        <Col md="1" sm="12"></Col>*/}


                        <div className=" login-header ">
                        <div className="logo">
                            {props.history.location.pathname == "/admin_confirm" ? (
                                <img
                                    src={
                                        localStorage.getItem("lang") === "en"
                                            ? logoAdmin
                                            : arabicLogo
                                    }
                                    className="img-fluid logo"
                                />
                            ) : (
                                <img
                                    src={
                                        localStorage.getItem("lang") === "en"
                                            ? logoSeller
                                            : logoSellerAr
                                    }
                                    className="img-fluid logo"
                                />
                            )}
                            <p>SHIPPING GATEWAY</p>
                            </div>
                        </div>
                    <div>
                        <h3 className="title" >{t("emailedYou")}</h3>
                        <p className="subTitle mt-2">{t("enterCode")}</p>
                        {/*<p className="mt-5">{t("confirmationCode")}</p>*/}
                        <div dir="ltr" className="custom-styles ">
                            <ReactCodeInput
                                fields={6}
                                type="text"
                                value={codeState}
                                onChange={handleCode}
                            />

                            <p className="error">{error ?? ""}</p>
                        </div>
                        <div className="mt-5">
                            {props.history.location.pathname ==
                            "/admin_confirm" ? (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={loading}
                                    className="login-form-button adminBtn"
                                    onClick={submit}
                                >
                                    {t("confirm")}
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={loading}
                                    className="login-form-button sellerBtn"
                                    onClick={submit}
                                >
                                    {t("confirm")}
                                </Button>
                            )}
                            
                            
                                <Button
                                    type="link"
                                    disabled={disable}
                                    onClick={resendCode}
                                    className="resend-button"
                                >
                                    {t("resendCode")}
                                </Button>
                                <p className="counter-text">{counter}</p>
                            
                        </div>
                    </div>
                </Stack>
            </Container>
        </div>
    );
};

export default ConfirmationCode;
